var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        title: "Mutate Affiliates",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: { ok: _vm.mutateAffiliate, hidden: _vm.clear },
    },
    [
      _c(
        "form",
        { attrs: { autocomplete: "off" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Schedule",
                "label-for": "publication-comp",
                horizontal: "",
              },
            },
            [
              _c("Publication", {
                attrs: {
                  id: "publication-comp",
                  "from-field": "active_from",
                  "to-field": "active_to",
                },
                model: {
                  value: _vm.item,
                  callback: function ($$v) {
                    _vm.item = $$v
                  },
                  expression: "item",
                },
              }),
            ],
            1
          ),
          _vm.item.type == "recurring"
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Recurring compensation amount",
                    "label-for": "amount-input",
                    horizontal: "",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "amount-input" },
                    model: {
                      value: _vm.recurringCompensation,
                      callback: function ($$v) {
                        _vm.recurringCompensation = $$v
                      },
                      expression: "recurringCompensation",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Offers",
                "label-for": "affiliate-offer-editor",
                horizontal: "",
              },
            },
            [
              _c("AffiliateOffers", {
                attrs: {
                  id: "affiliate-offer-editor",
                  type: _vm.item.type,
                  "affiliate-compensations": _vm.item.affiliate_compensations,
                },
                on: { update: _vm.updateAffiliateCompensations },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _c(
            "b-alert",
            {
              attrs: {
                show: _vm.alert.dismissCountdown,
                dismissible: "",
                variant: _vm.alert.variant,
              },
              on: {
                dismissed: function ($event) {
                  _vm.alert.dismissCountdown = 0
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.alert.msg) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }