var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-input-group",
    [
      _c("b-input", { attrs: { id: _vm.id, value: _vm.value, readonly: "" } }),
      _c(
        "b-input-group-append",
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              attrs: {
                variant: "btn-outline-light",
                title: "Copy ID to Clipboard",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.copyTextToClipboard.apply(null, arguments)
                },
              },
            },
            [_c("Icon", { attrs: { icon: "Copy_id" } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }