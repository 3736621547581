var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-4", staticStyle: { "margin-left": "30px" } },
    [
      _c("EmbeddedMedia", {
        attrs: {
          "media-type": "playlist_page",
          value: _vm.itemPlaylists,
          "show-controls": true,
          parent: _vm.item,
        },
        on: {
          save: function ($event) {
            return _vm.$emit("save", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }