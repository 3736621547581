<template>
  <b-modal  ref="modal"
            v-model="visible"
            size="lg"
            title="Publish Check Results"
            scrollable
            :ok-only="!data.isSaveResult"
            auto-focus-button="ok"
            @ok="handleOk">

    <template v-if="data.status == 'ok'">
      <h5 class="text-success">All items are ready to Publish</h5>
      <p>Click OK to publish the selected items on the live environment.</p>
    </template>

    <template v-else>
      <h5 class="text-danger">{{ data.headerText }}</h5>
      <p class="mb-4">{{ data.subHeaderText }}</p>

      <b-card v-for="r in data.results" :key="r.id"
          :title="r.title"
          title-tag="h5"
          tag="article"
          class="mb-2"
        >
          <b-card-text>
            <b-badge class="mb-4">{{ `Item ID: ${r.id}` }}</b-badge>

            <ul>
              <li v-for="(rr, index) in r.results" :key="index">
                <span :class="rr.level == 'warning' ? 'text-warning' : 'text-danger'">
                  {{ rr.level == 'warning' ? 'Warning: ' : 'Error: ' }}
                </span>
                <span>
                  {{ rr.language ? `${$helpers.getLanguageName(rr.language)} ${rr.message}` : rr.message }}
                </span>
              </li>
            </ul>
          </b-card-text>
        </b-card>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'PublishCheckResults',

  computed: {
    modal() {
      return this.$store.state.media.modals.publishCheckResults;
    },
    
    data() {
      return this.modal.data
    },

    visible: {
      get() {
        return this.modal.visible
      },

      set(value) {
        let modal = this.modal
        modal.visible = value
        this.$store.commit('media/updateModal', {publishCheckResults: modal})
      }
    }
  },

  methods: {
    handleOk() {
      if(this.data.isSaveResult) {
        this.$emit('save', {id: this.data.payload, skipPublishCheck: true})
      }
    }
  }
}
</script>

<style scoped>
</style>
