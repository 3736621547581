<template>
  <div class="my-4" style="margin-left: 30px">

    <EmbeddedMedia media-type="category" :value="itemDashboard" :show-controls="!tabbed" :parent="item" @save="$emit('save', $event)"/>
  </div>
</template>

<script>
import EmbeddedMedia from "./EmbeddedMedia.vue"

export default {
  name: 'EmbeddedDashboard',

  components: {
    EmbeddedMedia
  },


  props: {
    item: Object,
  },

  computed: {
    tabbed() {
      return this.item && (this.item.type == 'product' || this.item.type == 'page')
    },
    itemDashboard() {
      const item = this.item
      return (item && this.$helpers.isArray(item.dashboard)) ? item.dashboard : []
    }
  }

}
</script>

<style scoped>
</style>
