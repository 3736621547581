<template>
  <div>
    <svg v-if="type == 'video'" xmlns="http://www.w3.org/2000/svg" width="12.75" height="8.5" viewBox="0 0 12.75 8.5"><path class="a" d="M12.053-9.562l2.822-2.822v7.77L12.053-7.437v2.49a.65.65,0,0,1-.216.5.733.733,0,0,1-.515.2h-8.5a.676.676,0,0,1-.5-.2.676.676,0,0,1-.2-.5v-7.105a.676.676,0,0,1,.2-.5.676.676,0,0,1,.5-.2h8.5a.733.733,0,0,1,.515.2.65.65,0,0,1,.216.5Z" transform="translate(-2.125 12.75)"/></svg>
    <svg v-else-if="type == 'series' || type == 'season' || type == 'episode'" xmlns="http://www.w3.org/2000/svg" width="15.75" height="14" viewBox="0 0 15.75 14"><path class="a" d="M6.563-12.25H-6.562a1.313,1.313,0,0,0-1.312,1.313v7.875A1.313,1.313,0,0,0-6.562-1.75H6.563A1.313,1.313,0,0,0,7.875-3.062v-7.875A1.313,1.313,0,0,0,6.563-12.25ZM6.4-3.062H-6.4a.165.165,0,0,1-.164-.164v-7.547a.165.165,0,0,1,.164-.164H6.4a.165.165,0,0,1,.164.164v7.547A.165.165,0,0,1,6.4-3.062ZM5.25,1.094A.655.655,0,0,0,4.594.438H1.9L1.389-1.088a.327.327,0,0,0-.312-.224H-1.075a.327.327,0,0,0-.312.224L-1.895.438h-2.7a.655.655,0,0,0-.656.656.655.655,0,0,0,.656.656H4.594A.655.655,0,0,0,5.25,1.094Z" transform="translate(7.875 12.25)"/></svg>
    <svg v-else-if="type == 'book'" xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16"><path class="a" d="M36,265a2,2,0,0,0-2,2v12a2,2,0,0,0,2,2Zm1,0v16h9a2,2,0,0,0,2-2V267a2,2,0,0,0-2-2v5l-2-2-2,2v-5Z" transform="translate(-34 -265)"/></svg>
    <svg  v-else-if="type == 'magazine' || type == 'year' || type == 'issue'" xmlns="http://www.w3.org/2000/svg" width="16" height="13.334" viewBox="0 0 16 13.334"><path class="a" d="M22.208,48.863A12.3,12.3,0,0,0,17.067,48a1.045,1.045,0,0,0-.6.178A1.066,1.066,0,0,0,16,49.06V58.6a1.076,1.076,0,0,0,1.067,1.131c2.369,0,4.745.221,6.168,1.567a.137.137,0,0,0,.231-.1V49.957a.53.53,0,0,0-.182-.4A4.771,4.771,0,0,0,22.208,48.863Zm9.322-.69a1.044,1.044,0,0,0-.6-.177,12.279,12.279,0,0,0-5.141.867,4.777,4.777,0,0,0-1.077.693.531.531,0,0,0-.182.4V61.193a.131.131,0,0,0,.223.094c.856-.85,2.357-1.561,6.178-1.56A1.067,1.067,0,0,0,32,58.66v-9.6A1.067,1.067,0,0,0,31.529,48.173Z" transform="translate(-16 -47.997)"/></svg>
    <svg  v-else-if="type == 'music_album' || type == 'album_track'" xmlns="http://www.w3.org/2000/svg" width="16" height="13.334" viewBox="0 0 512 512"><path class="a" d="M511.1 367.1c0 44.18-42.98 80-95.1 80s-95.1-35.82-95.1-79.1c0-44.18 42.98-79.1 95.1-79.1c11.28 0 21.95 1.92 32.01 4.898V148.1L192 224l-.0023 208.1C191.1 476.2 149 512 95.1 512S0 476.2 0 432c0-44.18 42.98-79.1 95.1-79.1c11.28 0 21.95 1.92 32 4.898V126.5c0-12.97 10.06-26.63 22.41-30.52l319.1-94.49C472.1 .6615 477.3 0 480 0c17.66 0 31.97 14.34 32 31.99L511.1 367.1z"/></svg>
    <svg  v-else-if="type == 'audio'" xmlns="http://www.w3.org/2000/svg" width="16" height="13.334" viewBox="0 0 640 512"><path class="a" d="M412.6 182c-10.28-8.334-25.41-6.867-33.75 3.402c-8.406 10.24-6.906 25.35 3.375 33.74C393.5 228.4 400 241.8 400 255.1c0 14.17-6.5 27.59-17.81 36.83c-10.28 8.396-11.78 23.5-3.375 33.74c4.719 5.806 11.62 8.802 18.56 8.802c5.344 0 10.75-1.779 15.19-5.399C435.1 311.5 448 284.6 448 255.1S435.1 200.4 412.6 182zM473.1 108.2c-10.22-8.334-25.34-6.898-33.78 3.34c-8.406 10.24-6.906 25.35 3.344 33.74C476.6 172.1 496 213.3 496 255.1s-19.44 82.1-53.31 110.7c-10.25 8.396-11.75 23.5-3.344 33.74c4.75 5.775 11.62 8.771 18.56 8.771c5.375 0 10.75-1.779 15.22-5.431C518.2 366.9 544 313 544 255.1S518.2 145 473.1 108.2zM534.4 33.4c-10.22-8.334-25.34-6.867-33.78 3.34c-8.406 10.24-6.906 25.35 3.344 33.74C559.9 116.3 592 183.9 592 255.1s-32.09 139.7-88.06 185.5c-10.25 8.396-11.75 23.5-3.344 33.74C505.3 481 512.2 484 519.2 484c5.375 0 10.75-1.779 15.22-5.431C601.5 423.6 640 342.5 640 255.1S601.5 88.34 534.4 33.4zM301.2 34.98c-11.5-5.181-25.01-3.076-34.43 5.29L131.8 160.1H48c-26.51 0-48 21.48-48 47.96v95.92c0 26.48 21.49 47.96 48 47.96h83.84l134.9 119.8C272.7 477 280.3 479.8 288 479.8c4.438 0 8.959-.9314 13.16-2.835C312.7 471.8 320 460.4 320 447.9V64.12C320 51.55 312.7 40.13 301.2 34.98z" /></svg>
    <svg  v-else-if="type == 'podcast' || type == 'podcast_episode'" xmlns="http://www.w3.org/2000/svg" width="16" height="13.334" viewBox="0 0 448 512"><path class="a" d="M224 0C100.3 0 0 100.3 0 224c0 92.22 55.77 171.4 135.4 205.7c-3.48-20.75-6.17-41.59-6.998-58.15C80.08 340.1 48 285.8 48 224c0-97.05 78.95-176 176-176s176 78.95 176 176c0 61.79-32.08 116.1-80.39 147.6c-.834 16.5-3.541 37.37-7.035 58.17C392.2 395.4 448 316.2 448 224C448 100.3 347.7 0 224 0zM224 312c-32.88 0-64 8.625-64 43.75c0 33.13 12.88 104.3 20.62 132.8C185.8 507.6 205.1 512 224 512s38.25-4.375 43.38-23.38C275.1 459.9 288 388.8 288 355.8C288 320.6 256.9 312 224 312zM224 280c30.95 0 56-25.05 56-56S254.1 168 224 168S168 193 168 224S193 280 224 280zM368 224c0-79.53-64.47-144-144-144S80 144.5 80 224c0 44.83 20.92 84.38 53.04 110.8c4.857-12.65 14.13-25.88 32.05-35.04C165.1 299.7 165.4 299.7 165.6 299.7C142.9 282.1 128 254.9 128 224c0-53.02 42.98-96 96-96s96 42.98 96 96c0 30.92-14.87 58.13-37.57 75.68c.1309 .0254 .5078 .0488 .4746 .0742c17.93 9.16 27.19 22.38 32.05 35.04C347.1 308.4 368 268.8 368 224z"/></svg>
    <svg v-else-if="type == 'photo' || type == 'photo_package'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 307.308 307.308">
      <path class="a" d="M284.909,66.146h-81.345l-16.426-27.595c-1.607-2.698-4.514-4.351-7.654-4.351h-51.662c-3.14,0-6.048,1.653-7.654,4.351
        l-16.426,27.595H77.049v-6.082c0-4.919-3.988-8.907-8.907-8.907H35.185c-4.92,0-8.907,3.988-8.907,8.907v6.082h-3.88
        C10.027,66.146,0,76.174,0,88.543v162.166c0,12.37,10.027,22.398,22.397,22.398h262.512c12.37,0,22.398-10.028,22.398-22.398
        V88.543C307.308,76.174,297.279,66.146,284.909,66.146z M153.653,233.379c-35.21,0-63.753-28.543-63.753-63.754
        c0-35.209,28.543-63.753,63.753-63.753c35.21,0,63.753,28.544,63.753,63.753C217.406,204.836,188.863,233.379,153.653,233.379z
        M270.935,112.322h-27.91c-4.919,0-8.907-3.988-8.907-8.908c0-4.92,3.988-8.908,8.907-8.908h27.91c4.921,0,8.908,3.988,8.908,8.908
        C279.843,108.334,275.855,112.322,270.935,112.322z"/>
      <circle class="a" cx="153.653" cy="169.625" r="44.538"/>
    </svg>
    <svg v-else-if="type == 'brand'" xmlns="http://www.w3.org/2000/svg" width="16.842" height="14" viewBox="0 0 16.842 14"><path class="a" d="M16.461-9.007,14.686-11.84a.875.875,0,0,0-.741-.41H2.9a.875.875,0,0,0-.741.41L.383-9.007a2.439,2.439,0,0,0,1.608,3.73,2.844,2.844,0,0,0,.375.025,2.7,2.7,0,0,0,2.018-.905A2.7,2.7,0,0,0,6.4-5.253a2.7,2.7,0,0,0,2.018-.905,2.7,2.7,0,0,0,2.018.905,2.7,2.7,0,0,0,2.018-.905,2.709,2.709,0,0,0,2.018.905,2.813,2.813,0,0,0,.375-.025A2.437,2.437,0,0,0,16.461-9.007ZM14.479-4.375a3.47,3.47,0,0,1-.807-.1V-1.75H3.172V-4.479a3.622,3.622,0,0,1-.807.1,3.69,3.69,0,0,1-.492-.033,3.439,3.439,0,0,1-.448-.1V.875A.874.874,0,0,0,2.3,1.75H14.55a.874.874,0,0,0,.875-.875V-4.506a2.793,2.793,0,0,1-.448.1A3.8,3.8,0,0,1,14.479-4.375Z" transform="translate(-0.002 12.25)"/></svg>
    <svg v-else-if="type == 'page' || type == 'artist_page' || type == 'playlist_page'" xmlns="http://www.w3.org/2000/svg" width="15" height="11.25" viewBox="0 0 15 11.25"><path class="a" d="M.469-9.375H12.656a.469.469,0,0,0,.469-.469v-.937a.469.469,0,0,0-.469-.469H.469A.469.469,0,0,0,0-10.781v.938A.469.469,0,0,0,.469-9.375ZM14.531-6.562H2.344a.469.469,0,0,0-.469.469v.938a.469.469,0,0,0,.469.469H14.531A.469.469,0,0,0,15-5.156v-.937A.469.469,0,0,0,14.531-6.562ZM12.656-1.875H.469A.469.469,0,0,0,0-1.406v.938A.469.469,0,0,0,.469,0H12.656a.469.469,0,0,0,.469-.469v-.937A.469.469,0,0,0,12.656-1.875Z" transform="translate(0 11.25)"/></svg>
    <svg v-else-if="type == 'external'" xmlns="http://www.w3.org/2000/svg" width="15.75" height="14" viewBox="0 0 15.75 14"><path class="a" d="M12.25-5.638a.328.328,0,0,0-.56-.232l-.656.656a.328.328,0,0,0-.1.232V.273a.164.164,0,0,1-.164.164h-9.3A.164.164,0,0,1,1.313.273v-9.3a.164.164,0,0,1,.164-.164H9.952a.377.377,0,0,0,.232-.1l.656-.656a.328.328,0,0,0-.232-.56h-9.3A1.312,1.312,0,0,0,0-9.187V.438A1.312,1.312,0,0,0,1.313,1.75h9.625A1.312,1.312,0,0,0,12.25.438Zm3.172-6.612H11.7a.328.328,0,0,0-.232.56l1.318,1.318L5.346-2.929a.328.328,0,0,0,0,.464l.619.619a.328.328,0,0,0,.464,0l7.443-7.443L15.19-7.971a.328.328,0,0,0,.56-.232v-3.719A.328.328,0,0,0,15.422-12.25Z" transform="translate(0 12.25)"/></svg>
  </div>
</template>

<script>

export default {
  name: 'MediaIcon',

  props: {
    size: {type: Number, default: 16},
    type: String
  },

  computed: {
    path() {
      return 'icons/' + this.icon + ".svg"
    }
  }
}
</script>

<style scoped>
div {
  margin-bottom: 4px;
  width: 17px;
  height: 17px;
}

.a {
  fill: #0D1B2B
}
</style>
