<template>
  <div class="ml-auto py-2 pl-2">
    <div class="d-flex align-items-center justify-content-end">
      <b-button class="mr-2" id="add-button" variant="link" text="Add" @click="$root.$emit('affiliate-add')">
        <Icon icon="Add"/>
        <span class="ml-2">Add</span>
      </b-button>

      <b-button @click="$root.$emit('affiliate-mutate')" variant="link">
        <font-awesome-icon icon="fire"/>
        <span class="ml-2">Mutate</span>
      </b-button>

      <div class="mx-1" style="margin-top: 2px;">
        <Icon icon="Scheiding" :size="24"/>
      </div>

      <b-button class="mr-2" @click="$root.$emit('affiliate-activate')" variant="link">
        <font-awesome-icon icon="box-open"/>
        <span class="ml-2">Activate</span>
      </b-button>

      <b-button @click="$root.$emit('affiliate-deactivate')" variant="link">
        <Icon icon="Archive"/>
        <span class="ml-2">Deactivate</span>
      </b-button>

      <b-button @click="$root.$emit('affiliate-archive')" variant="link">
        <font-awesome-icon icon="trash-alt"/>
        <span class="ml-2">Archive</span>
      </b-button>

      <div class="mx-1" style="margin-top: 2px;">
        <Icon icon="Scheiding" :size="24"/>
      </div>

      <b-button-group>
        <b-form-select class="mr-2" style="max-width: 250px" :value="selectedPartner" @input="updatePartner($event)" :options="partnerOptions"></b-form-select>
        <b-form-select class="mr-2" style="max-width: 150px" :value="selectedType" @input="updateType($event)" :options="typeOptions"></b-form-select>
        <b-form-select class="mr-2" style="max-width: 150px" :value="selectedStatus" @input="updateStatus($event)" :options="statusOptions"></b-form-select>
        <b-form-select style="max-width: 180px" :value="selectedSortOrder" @input="updateSortOrder($event)" :options="sortOrderOptions"></b-form-select>
      </b-button-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AffiliateToolbar',

  data() {
    return {
      selectedPartner: "__all__",
      partnerOptions: [],
      selectedType: "*",
      typeOptions: [],
      selectedStatus: "active",
      statusOptions: [],
      selectedSortOrder: "date-asc",
      sortOrderOptions: [
        {value: "partner-asc", text: "Partner ascending"},
        {value: "partner-desc", text: "Partner descending"},
        {value: "date-asc", text: "Date ascending"},
        {value: "date-desc", text: "Date descending"}
      ]
    }
  },

  props: {
    mediaType: String
  },

  computed: {
    addTitle() {
      return "Add " + this.$_.capitalize(this.mediaType);
    },

    exportTitle() {
      return "Export " + this.$_.capitalize(this.mediaType);
    }
  },

  methods: {
    getPartnerOptions() {
      fetch("/admin/api/v2/partners/select-options?include-all")
      .then(this.$helpers.JSONHandler)
      .then(resp => {
        this.partnerOptions = resp.data
      });
    },

    getStatusOptions() {
      fetch("/admin/api/v2/affiliates/status-options")
      .then(this.$helpers.JSONHandler)
      .then(resp => {
        this.statusOptions = resp.data
        this.statusOptions.splice(0, 0, {value: '*', text: 'Any Status'})
      });
    },

    getTypeOptions() {
      fetch("/admin/api/v2/affiliates/type-options")
      .then(this.$helpers.JSONHandler)
      .then(resp => {
        this.typeOptions = resp.data
        this.typeOptions.splice(0, 0, {value: '*', text: 'Any Type'})
      });
    },

    updatePartner(partner) {
      if(partner) {
        this.selectedPartner = partner
        this.$store.dispatch('media/setPartner', partner)
        this.$root.$emit('affiliate-partner-filter', partner);
      }
    },

    updateSortOrder(sortOrder) {
      this.$root.$emit('affiliate-sort-order', sortOrder);
    },

    updateType(type) {
      this.$root.$emit('affiliate-type-filter', type);
    },

    updateStatus(status) {
      this.$root.$emit('affiliate-status-filter', status);
    }


  },

  mounted() {
    this.selectedPartner = this.$store.state.media.partnerFilter
    this.$root.$emit('affiliate-partner', this.selectedPartner);
  },

  created() {
    this.getPartnerOptions()
    this.getStatusOptions()
    this.getTypeOptions()
  }
}
</script>
