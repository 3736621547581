<template>
  <div :id="id">
    <div class="d-flex justify-content-between align-items-center">
      <div class="geos">
        <span class="geo" v-for="g in value" :key="g">
          <b-badge variant="secondary">
            {{ codeToName(g) }}
            <b-badge v-if="showEditor" href="#" @click.stop="removeGeo" :data-value="g">&#x2716;</b-badge>
          </b-badge>
        </span>
      </div>
      <div class="edit-geos-button ml-auto">
        <b-button size="" :variant="showEditor ? 'warning' : 'primary'" @click.stop="toggleEditor">
          <Icon icon="edit"/>
              Edit
        </b-button>
      </div>
    </div>
    <b-input-group class="geo-editor" v-if="showEditor">
      <AutoComplete :input-attrs="{class: 'form-control'}" v-model="geo" :items="filteredGeos" :get-label="getName" :component-item="template" @update-items="updateGeos" :min-len="0" @keyup.enter.native="addGeo"></AutoComplete>
      <b-button @click.stop="addGeo">Add</b-button>
    </b-input-group>
  </div>
</template>

<script>
import AutoComplete from "v-autocomplete"
import GeoTemplate from "./GeoTemplate.vue"

export default {
  name: 'Geos',

  components: {
    AutoComplete
  },

  data: function() {
    return {
      showEditor: false,
      allGeos: [],
      filteredGeos: [],
      geo: {code: "", name: ""},
      template: GeoTemplate
    };
  },

  props: {
    id: String,
    url: String,
    value: Array
  },

  methods: {
    toggleEditor() {
      this.showEditor = !this.showEditor;

      if(this.showEditor) this.getGeos();
    },

    getName(geo) {
      return geo ? geo.name : ""
    },

    codeToName(code) {
      for(var i = 0; i < this.allGeos.length; ++i) {
        if(this.allGeos[i].code == code) return this.allGeos[i].name
      }

      return code;
    },

    getGeos() {
      fetch(this.url)
      .then(function(resp) {
        return resp.json();
      })
      .then(resp => {
        this.allGeos = resp.data;
        this.filteredGeos = this.allGeos.slice();
      });
    },

    addGeo() {
      if(this.geo && this.value.indexOf(this.geo) == -1) {
        this.$emit('input', this.$_.concat(this.value, this.geo.code))
      }
    },

    removeGeo(ev) {
      let geoCode = ev.target.dataset["value"];
      this.$emit('input', this.value.filter(g => g != geoCode))
    },

    updateGeos(text) {
      if(text) {
        this.filteredGeos = this.allGeos.filter((item) => {
          return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
        })
      }
      else {
        this.filteredGeos = this.allGeos.slice();
      }
    }
  },

  created() {
    this.getGeos()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .geo {
    margin-left: 4px;
  }

  .geos {
    text-align: left;
  }

.v-autocomplete-list {
    background-color: #1B3B5B;
    width: 100%;
    text-align: left;
    border: 1px solid #80bdff;
    border-radius: 4px;
    max-height: 400px;
    overflow-y: auto;
    z-index: 2;
}

.v-autocomplete-item-active {
  background-color: #005885 !important;
}

.geo-editor {
  margin-top: 10px;
}

</style>
