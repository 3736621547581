<template>
  <div class="rev-share mt-3">
    <div class="d-flex flex-row-reverse">
      <ProcessAdyenReport ref="processAdyenReportModal"/>
    </div>
    <b-tabs @input="activeTab" pills class="mt-3">
      <b-tab title="Content">
        <hr class="mt-3"/>
        <RevShareContentOverview/>
      </b-tab>
      <b-tab v-if="$config.enableShopping" title="Shopping">
        <hr class="mt-3"/>
        <ShoppingOverview/>
      </b-tab>
      <b-tab v-if="$config.enableAffiliates" title="Affiliates">
        <hr class="mt-3"/>
        <AffiliateOverview/>
      </b-tab>
      <b-tab v-if="$config.enablePromoCodes" title="Promo Codes">
        <hr class="mt-3"/>
        <PromoCodeOverview/>
      </b-tab>
      <b-tab title="Billing">
        <hr class="mt-3"/>
        <RevShareBilling/>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import RevShareContentOverview from '../components/RevShareContentOverview.vue'
import AffiliateOverview from '../components/AffiliateOverview.vue'
import ShoppingOverview from '../components/ShoppingOverview.vue'
import PromoCodeOverview from '../components/PromoCodeOverview.vue'
import RevShareBilling from '../components/RevShareBilling.vue'
import ProcessAdyenReport from '../components/ProcessAdyenReport.vue'

export default {
  name: 'Finance',

  components: {
    RevShareContentOverview,
    AffiliateOverview,
    ShoppingOverview,
    PromoCodeOverview,
    RevShareBilling,
    ProcessAdyenReport
  },

  data: function() {
    const shoppingIndex = this.$config.enableShopping ? 1 : 0
    const affiliatesIndex = this.$config.enableAffiliates ? shoppingIndex + 1 : shoppingIndex
    const promoCodesIndex = this.$config.enablePromoCodes ? affiliatesIndex + 1 : affiliatesIndex
    const billingIndex = promoCodesIndex + 1
  
    return {
      contentIndex: 0,
      shoppingIndex: this.$config.enabeShopping ? shoppingIndex : -1,
      affiliateIndex: this.$config.enableAffiliates ? affiliatesIndex : -1,
      promoCodeIndex: this.$config.enablePromoCodes ? promoCodesIndex : -1,
      billingIndex: billingIndex

    };
  },

  props: {

  },

  methods: {
    activeTab(tabNum) {
      switch(tabNum) {
        case 0:
          this.$root.$emit('set-toolbar', 'finance-toolbar', 'content')
          break

        case this.shoppingIndex:
          this.$root.$emit('set-toolbar', 'finance-toolbar', 'shopping')
          break

        case this.affiliateIndex:
          this.$root.$emit('set-toolbar', 'finance-toolbar', 'affiliate')
          break

        case this.promoCodeIndex:
          this.$root.$emit('set-toolbar', 'finance-toolbar', 'promo_code')
          break

        case this.billingIndex:
          this.$root.$emit('set-toolbar', 'finance-toolbar', 'billing')
          break

        default:
          return
      }
    },

    processAdyenReport() {
      this.$refs.processAdyenReportModal.show()
    }

  },

  mounted() {
    this.$root.app.setActivePageTitle("Finance", false)
    this.$root.$emit('set-toolbar', 'finance-toolbar', 'content')
  }

}
</script>

<style scoped>
</style>
