var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-content-between" },
    [
      !_vm.expanded
        ? _c("b-input", {
            staticClass: "mr-2",
            attrs: { id: _vm.id, value: _vm.primaryAssetSource, readonly: "" },
          })
        : _vm._e(),
      !_vm.expanded
        ? _c(
            "span",
            { staticClass: "mx-2", staticStyle: { "margin-top": "3px" } },
            [
              _vm._v(
                _vm._s(_vm.$helpers.durationToString(_vm.primaryAssetDuration))
              ),
            ]
          )
        : _vm._e(),
      _vm.expanded
        ? _c(
            "b-list-group",
            {
              staticClass: "mr-2",
              staticStyle: { width: "100%", "text-align": "left" },
            },
            _vm._l(_vm.items, function (item) {
              return _c(
                "b-list-group-item",
                { key: item.id },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Status",
                                    "label-class": "font-weight-bold",
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(item.status))])]
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Source",
                                    "label-class": "font-weight-bold",
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(item.source))])]
                              ),
                              item.format == "video" && item.profile
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Profile",
                                        "label-class": "font-weight-bold",
                                      },
                                    },
                                    [_c("span", [_vm._v(_vm._s(item.profile))])]
                                  )
                                : _vm._e(),
                              item.status == "ready" && item.transcode_job_ready
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Transcode Job Ready",
                                        "label-class": "font-weight-bold",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$helpers.utcToLocalString(
                                              item.transcode_job_ready
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              item.format == "video"
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Embedded subtitles",
                                        "label-class": "font-weight-bold",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-list-group",
                                        _vm._l(item.subtitles, function (s) {
                                          return _c(
                                            "b-list-group-item",
                                            { key: s.source },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold mr-2",
                                                },
                                                [_vm._v(_vm._s(s.language))]
                                              ),
                                              _c("span", [
                                                _vm._v(_vm._s(s.source)),
                                              ]),
                                            ]
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button-group",
                            { staticClass: "float-right" },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  attrs: {
                                    id: `download-asset-${item.id}`,
                                    title: "Download source file",
                                    variant: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadAsset(item.id)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "download" },
                                  }),
                                ],
                                1
                              ),
                              item.status == "ready" && _vm.format == "video"
                                ? _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          modifiers: { hover: true },
                                        },
                                      ],
                                      attrs: {
                                        title: "Generate Preview...",
                                        variant: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.generatePreview(item.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "photo-video" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  attrs: {
                                    title: `Retranscode ${_vm.format}`,
                                    variant: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.retranscodeAsset(item.id)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "undo-alt" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  attrs: {
                                    title: `Delete ${_vm.format}`,
                                    variant: "danger",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteAsset(item.id)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "trash-alt" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: `download-asset-${item.id}`,
                                triggers: "click blur",
                                placement: "bottom",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "bright-download-url",
                                  attrs: {
                                    href: _vm.downloadUrl,
                                    target: "_blank",
                                    download: "",
                                  },
                                },
                                [_vm._v("Download file...")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _c("AddAsset", {
        attrs: {
          format: _vm.format,
          language: _vm.language,
          "item-id": _vm.itemId,
        },
        on: {
          finished: function ($event) {
            return _vm.$emit("update")
          },
        },
      }),
      _c(
        "div",
        { staticClass: "ml-2", staticStyle: { "max-height": "40px" } },
        [
          _c(
            "b-button",
            {
              staticStyle: { "max-height": "40px", width: "4.8rem" },
              attrs: {
                size: "",
                variant: _vm.expanded ? "warning" : "primary",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toggleExpand.apply(null, arguments)
                },
              },
            },
            [_c("Icon", { attrs: { icon: "edit" } }), _vm._v(" Edit ")],
            1
          ),
        ],
        1
      ),
      _c("SimpleModal", {
        attrs: { value: _vm.mutateModalVisible, msg: _vm.mutateModalMsg },
        on: {
          ok: _vm.doMutateAsset,
          cancel: function ($event) {
            _vm.mutateModalVisible = false
          },
        },
      }),
      _c("GeneratePreview", {
        attrs: { "asset-id": _vm.previewAssetId },
        model: {
          value: _vm.generatePreviewVisible,
          callback: function ($$v) {
            _vm.generatePreviewVisible = $$v
          },
          expression: "generatePreviewVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }