import Vue from 'vue'
import Vuex from 'vuex'

import affiliate from './store/affiliate'
import app from './store/app'
import finance from './store/finance'
import media from './store/media'
import partner from './store/partner'
import promo from './store/promo'
import session from './store/session'
import user from './store/user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    affiliate,
    app,
    finance,
    media,
    partner,
    promo,
    session,
    user
  },

  actions: {
    fetchAll({dispatch, rootState}) {
      return dispatch('app/fetchAppHost')
        .then(() => dispatch('app/fetchCountries'))
        .then(() => dispatch('app/fetchLanguages'))
        .then(() => dispatch('session/fetchUser'))
        .then(() => {
          if(rootState.session.user.type != "support") {
            return dispatch('partner/fetchPartners')
          }
        })
        .then(() => {
          if(rootState.session.user.type != "support" && rootState.session.user.type != "finance") {
            return dispatch('media/fetchOptions')
          }
        })
        .then(() => {
          if(rootState.session.user.type != "support" && rootState.session.user.type != "finance") {
            return dispatch('media/fetchPremiumValueOptions')
          }
        })
        .then(() => {
          if(rootState.session.user.type != "support" && rootState.session.user.type != "finance") {
            return dispatch('media/fetchPlanOptions')
          }
        })
        .then(() => {
          if(rootState.session.user.type != "support" && rootState.session.user.type != "finance") {
            return dispatch('media/fetchTranscodeOptions')
          }
        })
        .then(() => {
          if(rootState.session.user.type != "support" && rootState.session.user.type != "finance") {
            return dispatch('media/fetchItems')
          }
        })
        .then(() => {
          if(rootState.session.user.type != "support" && rootState.session.user.type != "finance") {
            return dispatch('media/fetchCategories')
          }
        })
        .then(() => dispatch('app/initReady'))
    }

  }
})