var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.mediaType },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center p-2 shadow-sm rounded bg-light justify-content-between",
          staticStyle: { width: "33%" },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c("h5", { staticClass: "m-0" }, [
                _vm._v(
                  _vm._s(
                    _vm.mediaType == "playlist_page"
                      ? "Embedded Playlists"
                      : "Embedded Dashboard"
                  )
                ),
              ]),
              _vm.showControls
                ? _c(
                    "b-button-group",
                    { staticClass: "ml-3" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            id: `add-${_vm.mediaType}-button`,
                            variant: "primary",
                            text: "Add",
                          },
                          on: { click: _vm.addItem },
                        },
                        [
                          _c("Icon", { attrs: { icon: "Add" } }),
                          _c("span", [_vm._v(" Add")]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.reorderMode
            ? _c(
                "b-button-group",
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: { title: "Save Reordering", variant: "primary" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.saveReordering.apply(null, arguments)
                        },
                      },
                    },
                    [_c("Icon", { attrs: { icon: "Save" } })],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: {
                        title: "Restore original order",
                        variant: "primary",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.restoreReordering.apply(null, arguments)
                        },
                      },
                    },
                    [_c("font-awesome-icon", { attrs: { icon: "undo-alt" } })],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "draggable",
        {
          staticClass: "embedded-content",
          on: { update: _vm.updatedOrder },
          model: {
            value: _vm.content,
            callback: function ($$v) {
              _vm.content = $$v
            },
            expression: "content",
          },
        },
        _vm._l(_vm.content, function (m) {
          return _c("TreeNode", {
            key: m.id,
            ref: m.id,
            refInFor: true,
            attrs: { media: m },
            on: {
              save: function ($event) {
                return _vm.$emit("save", $event)
              },
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }