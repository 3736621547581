var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-content-end align-items-center" },
    [
      !_vm.expanded
        ? _c(
            "div",
            { staticClass: "mr-2 w-100" },
            _vm._l(_vm.subtitles, function (s) {
              return _c(
                "b-badge",
                { key: s.source, attrs: { variant: "primary" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$helpers.getLanguageName(s.language)) + " "
                  ),
                ]
              )
            }),
            1
          )
        : _vm._e(),
      _vm.expanded
        ? _c(
            "b-list-group",
            {
              staticClass: "mr-2",
              staticStyle: { width: "100%", "text-align": "left" },
            },
            _vm._l(_vm.subtitles, function (s) {
              return _c(
                "b-list-group-item",
                { key: s.source },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Language",
                                    "label-class": "font-weight-bold",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$helpers.getLanguageName(s.language)
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Source",
                                    "label-class": "font-weight-bold",
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(s.source))])]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button-group",
                            { staticClass: "float-right" },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  attrs: {
                                    title: `Delete subtitle`,
                                    variant: "danger",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteSubtitle(s.source)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "trash-alt" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _c("AddSubtitle", {
        attrs: { language: _vm.$config.defaultLanguage, "item-id": _vm.itemId },
        on: {
          finished: function ($event) {
            return _vm.$emit("reload")
          },
        },
      }),
      _c(
        "div",
        { staticClass: "ml-2", staticStyle: { "max-height": "40px" } },
        [
          _c(
            "b-button",
            {
              staticStyle: { "max-height": "40px", width: "4.8rem" },
              attrs: {
                size: "",
                variant: _vm.expanded ? "warning" : "primary",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toggleExpand.apply(null, arguments)
                },
              },
            },
            [_c("Icon", { attrs: { icon: "edit" } }), _vm._v(" Edit ")],
            1
          ),
        ],
        1
      ),
      _c("SimpleModal", {
        attrs: { value: _vm.mutateModalVisible, msg: _vm.mutateModalMsg },
        on: {
          ok: _vm.doMutateSubtitle,
          cancel: function ($event) {
            _vm.mutateModalVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }