<template>
  <S3UploadModal :item-id="itemId" :name="subtitleObjectName" format= "subtitle" @file-change="setSubtitleFileName" modal-title="Add Subtitle" @finished="$emit('finished')" @start-upload-request="$event.handler()">
    <b-form-group label="Title">
      <b-form-input v-model="subtitleTitle"></b-form-input>
    </b-form-group>
    <b-form-group label="Language">
      <b-form-select v-model="subtitleLanguage" :options="subtitleLanguageOptions"></b-form-select>
    </b-form-group>
  </S3UploadModal>
</template>

<script>
import S3UploadModal from "./S3UploadModal.vue"

export default {
  name: "AddSubtitle",

  components: {
    S3UploadModal
  },

  props: {
    itemId: String,
    language: String
  },

  data() {
    return {
      subtitleFileName: "",
      subtitleTitle: "",
      subtitleLanguage: this.$config.defaultLanguage,
      subtitleLanguageOptions: []
    };
  },

  watch: {
    subtitleFileName(val) {
      if(val) {
        const fileParts = val.split('.')
        this.subtitleTitle = fileParts[0]
      }
    },

    language(val) {
      if(val) {
        this.subtitleLanguage = val
      }
    }
  },
  computed: {
    subtitleObjectName() {
      let name = this.subtitleTitle ? `${this.$helpers.slugify(this.subtitleTitle)}.subtitle.${this.subtitleLanguage}` : `subtitle.${this.subtitleLanguage}`

      return name
    }
  },

  methods: {
    setSubtitleFileName(ev) {
      if(ev && ev.name) {
        this.subtitleFileName = ev.name
      }
    }
  },

  mounted() {
    this.subtitleLanguage = this.language

    this.subtitleLanguageOptions = this.$store.getters['app/languageCodes'].map(code => {
      return {value: code, text: this.$helpers.getLanguageName(code)}
    })
  }
};
</script>


<style scoped>
</style>

