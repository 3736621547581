<template>
  <div>
    <b-table hover :items="items" :fields="fields">
      <template v-slot:cell(publish_status)="row">
        <span :class="publishClass(row.value)" class="rounded">{{row.value}}</span>
      </template>
    </b-table>

  </div>
</template>

<script>
export default {
  name: 'RecentlyAddedCategoryContent',

  data: function() {
    return {
      fields: ["title", "type", "plan", {key: "publish_status", label: "Status"}],

      items: [],

      debouncedPostJSON: null,

    };
  },
  props: {
    subject: String,
    language: String,
  },

  watch: {

},

  computed: {
  },

  methods: {
    publishClass(status) {
      return {
        'bg-live': status == 'live',
        'bg-incomplete': status == 'incomplete',
        'bg-complete': status == 'complete',
        'bg-overdue': status == 'overdue',
        'bg-upcoming': status == 'upcoming',
        'bg-done': status == 'done',
        'bg-invalid': status == 'invalid'
        }
    },

    loadContent() {
      this.debouncedPostJSON(
        `/admin/api/v2/media/recently-added?language=${this.language}&status=draft,ready,published`,
        {
        },
        (resp) => {
          this.items = this.processItems(resp.data)
        },
        (status, html) => {
          this.$root.app.showErrorDialog(html, status.toString())
        }
      )
    },

    processItems(items) {
      return items.map(item => {
        item._actionsVisible = false
        if(item.meta) item.title = item.meta.title
        return item;
      })
    },

  },

  created() {
    this.debouncedPostJSON = this.$_.debounce(this.$http.postJSON, 500)
    this.loadContent()
  }
}
</script>

<style scoped>
.category-edit-form {
  margin-top: 2rem;
}
</style>
