<template>
  <b-modal  ref="errorModal"
            hide-footer
            header-bg-variant="danger"
            header-text-variant="dark"
            :title="header">
      <div class="d-block text-center">
        <p id="error-body" v-html="body"></p>
      </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ErrorDialog',

  data() {
    return {
      header: 'Error',
      body: 'An error occured'
    }
  },

  methods: {
    show (body, header) {
      this.header = (header || 'Error').toString()
      this.body = body
      this.$refs.errorModal.show()
    },
    hide () {
      this.$refs.errorModal.hide()
    }
  }
}
</script>

<style scoped>
  #error-body {
    overflow: hidden;
  }
</style>

