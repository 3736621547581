 <template>
  <div :class="mediaType">
    <div v-if="mediaType != 'category' || $store.state.media.searchMode" class="d-flex">
      <b-badge class="mx-1 mt-3" style="opacity: 80%; font-size: 0.9rem;" v-for="label in filterLabels" :key="label.text" variant="dark">
        <Icon v-if="label.icon" v-b-tooltip.hover :title="$helpers.prettify(label.type)" :icon="label.icon"/>
        <span class="mx-1">{{label.text}}</span>
        <b-badge v-if="label.type != 'stats'" href="#" @click.stop="removeMediaLabelFilter(label)">&#x2716;</b-badge>
      </b-badge>
    </div>
    <div id="content" class="mb-4" :class="{'no-text-select': disableTextSelect}">
      <TreeNode v-for="m in mediaPageSelection" :key="m.id" :media="m" :partner="partnerName(m.partner_id)" :ref="m.id" @node-selected="selectNode" @save="saveMedia($event)"/>
    </div>

    <div v-if="perPage" class="d-flex justify-content-center mt-4" style="opacity: 0.7">
      <b-pagination :total-rows="mediaSelection.length" :per-page="perPage" v-model="currentPage"/>
    </div>

    <SimpleModal v-model='archiveModalVisible' @ok="archiveMedia" @cancel="$store.commit('media/updateModal', {archive: {visible: false}})" msg="Are you sure you want to archive this item?"/>
    <AddMedia/>
    <ImportMedia/>
    <DuplicateMedia v-model="duplicateModalVisible" ref="duplicateMediaModal"/>
    <PublishCheckResults ref="PublishModal" @save="saveMedia($event)"/>
    <EditLabels :labels="$store.state.media.managedLabels" @update="handleLabelsUpdate" v-model="labelsModalVisible" :allow-new="true" :allow-manage="true"/>
    <EditLabels label-type="artist" :labels="$store.state.media.managedArtists" @update="handleArtistsUpdate" v-model="artistsModalVisible" :allow-new="true" :allow-manage="true"/>
    <EditLabels label-type="genre" :labels="$store.state.media.managedGenres" @update="handleGenresUpdate" v-model="genresModalVisible" :allow-new="true" :allow-manage="true"/>
    <ManageProperties @update="handlePropertiesUpdate" v-model="propertiesModalVisible" :count="$store.state.media.selectedNodes.length"/>
    <iframe id="download-frame" style="display:none;"></iframe>
  </div>
</template>

<script>
import TreeNode from "./TreeNode.vue";
import SimpleModal from "./SimpleModal.vue"
import AddMedia from './AddMedia.vue'
import ImportMedia from './ImportMedia.vue'
import DuplicateMedia from './DuplicateMedia.vue'
import EditLabels from './EditLabels.vue'
import ManageProperties from './ManageProperties.vue'
import PublishCheckResults from './PublishCheckResults.vue'
import { mapState } from 'vuex';


export default {
  name: "Media",

  components: {
    AddMedia,
    ImportMedia,
    DuplicateMedia,
    EditLabels,
    TreeNode,
    SimpleModal,
    PublishCheckResults,
    ManageProperties
  },

  data() {
    return {
      showArchiveModal: false,
      showCounter: true,
      toArchiveMediaIds: null,
      disableTextSelect: true,

      currentPage: 1,
      perPage: 25,

      sorter: null

    };
  },

  props: {
      mediaType: String,
  },

  computed: {
    mediaSelection() {
      if(this.mediaType == 'category') {
        if(this.$store.state.media.searchMode) {
          const filtered = this.$store.state.media.categories.filter(m => {
            const publishStatusMatch = this.$store.getters['media/publishStatusMatch'](m)
            const searchMatch = this.$store.getters['media/searchMatch'](m, true)
            return publishStatusMatch && searchMatch
          })

          return this.$store.state.media.categorySorter ? filtered.sort(this.$store.state.media.categorySorter) : filtered
        }
        else {
          return this.$store.state.media.categories
        }
      }
      else {
        const filtered = this.$store.state.media.items.filter((m) => {
          const labelMatch = this.$store.getters['media/labelMatch'](m)
          const typeMatch = this.$store.getters['media/typeMatch'](m)
          const publishStatusMatch = this.$store.getters['media/publishStatusMatch'](m)
          const searchMatch = this.$store.getters['media/searchMatch'](m)
          return labelMatch && typeMatch && publishStatusMatch && searchMatch
        })

        return this.$store.state.media.sorter ? filtered.sort(this.$store.state.media.sorter) : filtered
      }
    },

    mediaPageSelection() {
      if(this.perPage > 0) {
        const begin = (this.currentPage - 1) * this.perPage
        const end = begin + this.perPage

        return this.mediaSelection.slice(begin, end)
      }
      else {
        return this.mediaSelection
      }
    },

    filterLabels() {
      let labels = []

      if(this.mediaType != 'category') {
        labels = this.labelFilter.map(label => { return {text: label, icon: "labels", type: "label"} })
      }

      if(!this.$store.getters['media/typeAllSet'] && this.mediaType != 'category') {
        for(const type in this.typeFilter) {
          if(this.typeFilter[type]) {
            switch(type) {
              case 'album_track': labels.push({text: "Music Album Tracks", icon: "Menu_music", value: type, type: "type"}); break;
              case 'artist_page': labels.push({text: "Artist Page", icon: "Menu_Categories", value: type, type: "type"}); break;
              case 'audio': labels.push({text: "Audio", icon: "Menu_audio", value: type, type: "type"}); break;
              case 'book': labels.push({text: "Books", icon: "Menu_books", value: type, type: "type"}); break;
              case 'brand': labels.push({text: "Brands", icon: "Menu_Brands", value: type, type: "type"}); break;
              case 'episode': labels.push({text: "Series Episodes", icon: "Menu_Series", value: type, type: "type"}); break;
              case 'external': labels.push({text: "Externals", icon: "Menu_externals", value: type, type: "type"}); break;
              case 'issue': labels.push({text: "Magazine Issues", icon: "Menu_Magazines", value: type, type: "type"}); break;
              case 'magazine': labels.push({text: "Magazines", icon: "Menu_Magazines", value: type, type: "type"}); break;
              case 'music_album': labels.push({text: "Music Albums", icon: "Menu_music", value: type, type: "type"}); break;
              case 'page': labels.push({text: "Micropage", icon: "Menu_Categories", value: type, type: "type"}); break;
              case 'photo': labels.push({text: "Photo", icon: "Menu_camera", value: type, type: "type"}); break;
              case 'photo_package': labels.push({text: "Photo Packages", icon: "Menu_camera", value: type, type: "type"}); break;
              case 'playlist_page': labels.push({text: "Playlist Page", icon: "Menu_Categories", value: type, type: "type"}); break;
              case 'podcast': labels.push({text: "Podcasts", icon: "Menu_podcast", value: type, type: "type"}); break;
              case 'podcast_episode': labels.push({text: "Podcast Episodes", icon: "Menu_podcast", value: type, type: "type"}); break;
              case 'season': labels.push({text: "Series Seasons", icon: "Menu_Series", value: type, type: "type"}); break;
              case 'series': labels.push({text: "Series", icon: "Menu_Series", value: type, type: "type"}); break;
              case 'video': labels.push({text: "Videos", icon: "Menu_Videos", value: type, type: "type"}); break;
              case 'year': labels.push({text: "Magazine Years", icon: "Menu_Magazines", value: type, type: "type"}); break;
            }
          }
        }
      }

      if(!this.$store.getters['media/publishStatusAllSet']) {
        for(const publishStatus in this.publishStatusFilter) {
          if(this.publishStatusFilter[publishStatus]) {
            switch(publishStatus) {
              case 'incomplete': labels.push({text: "Incomplete", icon: "globe", value: publishStatus, type: "status"}); break;
              case 'complete': labels.push({text: "Complete", icon: "globe", value: publishStatus, type: "status"}); break;
              case 'overdue': labels.push({text: "Overdue", icon: "globe", value: publishStatus, type: "status"}); break;
              case 'upcoming': labels.push({text: "Upcoming", icon: "globe", value: publishStatus, type: "status"}); break;
              case 'live': labels.push({text: "Live", icon: "globe", value: publishStatus, type: "status"}); break;
              case 'done': labels.push({text: "Done", icon: "globe", value: publishStatus, type: "status"}); break;
              case 'invalid': labels.push({text: "Invalid", icon: "globe", value: publishStatus, type: "status"}); break;
            }
          }
        }
      }

      if(this.partners && this.partnerFilter != "__all__" && this.mediaType != 'category') {
        const partner = this.partners[this.partnerFilter]
        if(partner) {
          labels.push({text: partner.name, icon: "Menu_Partners", type: "partner"})
        }
      }

      if(this.searchFilterString && !this.$store.state.media.searchMode) {
        labels.push({text: `"${this.searchFilterString}"`, icon: "search", type: "search"})
      }

      if(this.$config.enableCounters && this.showCounter) {
        const count = this.mediaSelection.length
        const quantifier = count == 1 ? 'item' : 'items'
        labels.push({text: `${count} ${quantifier}`, icon: "Menu_Stats", value: count, type: "stats"})
      }

      return labels
    },

    ...mapState('media', {
      labelFilter: 'labelFilter',
      partnerFilter: 'partnerFilter',
      searchFilter: 'searchFilter',
      searchFilterString: 'searchFilterString',
      publishStatusFilter: 'publishStatusFilter',
      selectedNodes: 'selectedNodes'
    }),

    ...mapState('partner', {
      partners: 'values'
    }),

    typeFilter: {
      get() {
        return this.$store.state.media.searchMode ? this.$store.state.media.searchTypeFilter : this.$store.state.media.typeFilter
      }
    },

    archiveModalVisible: {
      get() {
        return this.$store.state.media.modals.archive.visible
      },
      set(value) {
        this.$store.commit('media/updateModal', {archive: {visible: value}})
      }
    },

    duplicateModalVisible: {
      get() {
        return this.$store.state.media.modals.duplicate.visible
      },
      set(value) {
        let modal = this.$store.state.media.modals.duplicate
        modal.visible = value
        this.$store.commit('media/updateModal', {duplicate: modal})
      }
    },

    labelsModalVisible: {
      get() {
        return this.$store.state.media.modals.labels.visible
      },
      set(value) {
        let modal = this.$store.state.media.modals.labels
        modal.visible = value
        this.$store.commit('media/updateModal', {labels: modal})
      }
    },

    artistsModalVisible: {
      get() {
        return this.$store.state.media.modals.artists.visible
      },
      set(value) {
        let modal = this.$store.state.media.modals.artists
        modal.visible = value
        this.$store.commit('media/updateModal', {artists: modal})
      }
    },

    genresModalVisible: {
      get() {
        return this.$store.state.media.modals.genres.visible
      },
      set(value) {
        let modal = this.$store.state.media.modals.genres
        modal.visible = value
        this.$store.commit('media/updateModal', {genres: modal})
      }
    },

    propertiesModalVisible: {
      get() {
        return this.$store.state.media.modals.properties.visible
      },
      set(value) {
        let modal = this.$store.state.media.modals.properties
        modal.visible = value
        this.$store.commit('media/updateModal', {properties: modal})
      }
    }



  },

  methods: {
    partnerName(id) {
      const p = this.partners[id]
      return p ? p.name : ''
    },

    selectNode(node, mod, range) {
      //let node = this.getNode(id)
      let selectedNodes = this.selectedNodes.map(n => n)

      if(!mod && selectedNodes.length > 0) {
        selectedNodes.forEach(node => node.highlight(false))
        selectedNodes = []
      }
      else if(this.hasNode(selectedNodes, node)) {
        node.highlight(false);
        selectedNodes = this.removeNode(selectedNodes, node)
      }
      else if(mod == "shift") {
        selectedNodes = this.selectRange(selectedNodes, node.media.id, range)
      }
      else {
        node.highlight(true);
        selectedNodes.push(node)
      }

      this.$store.commit('media/putSelectedNodes', selectedNodes)
    },

    hasNode(nodes, node) {
      let hasNode = false
      const id = node.media.id
      nodes.forEach(n => {
        if(n.media.id == id) hasNode = true
      })

      return hasNode
    },

    removeNode(nodes, node) {
      const id = node.media.id
      return nodes.filter(n => n.media.id != id)
    },

    selectRange(selectedNodes, id, range) {
      if(!range) {
        range = {}
        range.items = this.mediaSelection
      }

      let selectedNdx = this.$helpers.indexOfById(range.items, id)

      if(selectedNdx != -1) {
        let ndxs = []
        for(var i = 0; i < selectedNodes.length; ++i) {
          const node = this.selectedNodes[i]
          const ndx = this.$helpers.indexOfById(range.items, node.getId())

          if(ndx != -1) {
            ndxs.push(ndx)
          }
        }
        if(ndxs.length == 0) return selectedNodes

        ndxs.sort((a, b) => a < b ? -1 : a > b ? 1 : 0)

        if(selectedNdx < ndxs[0]) {
          for(let i = selectedNdx; i < ndxs[0]; ++i) {
            let node = this.getNode(range.items[i].id, range.nodes)
            node.highlight(true);
            selectedNodes.push(node)
          }
        }
        else if(selectedNdx > ndxs[ndxs.length - 1]) {
          for(let i = selectedNdx; i > ndxs[ndxs.length - 1]; --i) {
            let node = this.getNode(range.items[i].id, range.nodes)
            node.highlight(true);
            selectedNodes.push(node)
          }
        }
        else {
          let node = this.getNode(range.items[i].id, range.nodes)
          node.highlight(true);
          selectedNodes.push(node)
        }
      }
      return selectedNodes
    },

    getNode(id, nodes) {
      nodes = nodes || this.$refs
      let node = nodes[id]
      if(node) return node[0]
    },

    saveMedia({id, skipPublishCheck}) {
      this.$store.dispatch('media/saveEdit', {id, skipPublishCheck})
        .then(result => {
          if(result == "no-change") {
            this.$root.app.showAlert("Nothing to save", "warning")
          }
          else {
            this.$root.app.showAlert("Save successful", "success")
          }
        })
        .catch(({status, json}) => {
          if(status == 400) {
            if(json.type == 'published_but_not_ready') {
              this.$store.dispatch('media/publishCheckResults', {
                status: "error",
                results: json.data,
                payload: id,
                isSaveResult: true
              })
            }
            else {
              this.$root.app.showAlert(`An error occured while saving: ${json.data}`, "danger")
            }

          }
          else {
            this.$root.app.showErrorDialog(json, status)
          }
        })
    },

    saveMediaUpdates(updates) {
      this.$store.dispatch('media/updateItems', updates)
        .then(() => this.$root.app.showAlert("Save successful", "success"))
        .catch(error => {
          this.$root.app.showAlert(`An error occured while saving: ${error.text}`, "danger")
        }
      )
    },

    requestArchive(ids) {
      this.$store.commit('media/requestArchive', ids)
    },

    archiveMedia() {
      this.$store.dispatch('media/archiveSelected')
        .then(() => this.$root.app.showAlert("Archive successful", "success"))
        .catch(() =>  this.$root.app.showAlert('Archive Error', 'danger'))
    },

    publishMedia(ids) {
      this.$store.dispatch('media/publishItems', ids)
        .then(() => this.$root.app.showAlert("Publish successful", "success"))
        .catch(error => this.$root.app.showAlert(`Publish failed with reason: ${error.text}`, "danger"))
    },

    exportSelectedNodes() {
      this.$store.dispatch('media/exportSelectedNodes')
        .then(resp => {
          let file_name = "/admin/api/v2/media/download-export/" + resp.data;
          document.getElementById('download-frame').src = file_name;
        })
        .catch(error => {
          this.$root.app.showAlert(`Export failed with reason: ${error.text}`, "danger")
        })
   },

    removeMediaLabelFilter(label) {
      if(label.type == "label") {
        this.$store.dispatch('media/updateLabelFilter', {toRemove: [label.text]})
      }
      else if(label.type == "type") {
        this.$store.dispatch('media/updateTypeFilter', {type: label.value, value: false})
        .then(() => this.$store.commit('media/typeFilterSetAllWhenAllOff'))
      }
      else if(label.type == "status") {
        this.$store.commit('media/updatePublishStatusFilter', {publish_status: label.value, value: false})
        this.$store.commit('media/publishStatusFilterSetAllWhenAllOff')
      }
      else if(label.type == "partner") {
        this.$store.commit('media/partnerFilterReset')
      }
      else if(label.type == "search") {
        this.$store.commit('media/updateSearchFilter')
      }
      else if(label.type == "stats") {
        this.showCounter = false
      }
    },

    handlePropertiesUpdate(update) {
      this.$store.dispatch('media/mutateMediaProperties', update)
        .then(() => this.$root.app.showAlert("Properties updated", "success"))
        .catch(error => this.$root.app.showAlert(`Properties update failed with error: ${error.text}`, "danger"))
    },

    handleLabelsUpdate(mut) {
      this.$store.dispatch('media/mutateMediaLabels', mut)
        .then(() => this.$root.app.showAlert("Labels updated", "success"))
        .catch(error => this.$root.app.showAlert(`Labels update failed with error: ${error.text}`, "danger"))

    },
    handleArtistsUpdate(mut) {
      this.$store.dispatch('media/mutateMediaArtists', mut)
        .then(() => this.$root.app.showAlert("Artists updated", "success"))
        .catch(error => this.$root.app.showAlert(`Artists update failed with error: ${error.text}`, "danger"))
    },
    handleGenresUpdate(mut) {
      this.$store.dispatch('media/mutateMediaGenres', mut)
        .then(() => this.$root.app.showAlert("Genres updated", "success"))
        .catch(error => this.$root.app.showAlert(`Genres update failed with error: ${error.text}`, "danger"))
    }
  },

  created() {
    this.$root.$on('media-export', this.exportSelectedNodes)
  },

  beforeDestroy() {
    this.$root.$off('media-export', this.exportSelectedNodes)
  }

};
</script>

<style>
#content {
  margin-top: 20px;
}


</style>


