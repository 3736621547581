var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "affiliates" },
    [
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("b-table", {
            attrs: {
              hover: "",
              striped: "",
              selectable: "",
              "select-mode": "multi",
              "selected-variant": "info",
              items: _vm.items,
              fields: _vm.fields,
              "per-page": _vm.perPage,
              "current-page": _vm.currentPage,
            },
            on: { "row-selected": _vm.rowSelected },
            scopedSlots: _vm._u([
              {
                key: "cell(show_details)",
                fn: function (row) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-center" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-2",
                            attrs: { size: "sm", variant: "primary" },
                            on: { click: row.toggleDetails },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "info" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "row-details",
                fn: function (row) {
                  return [
                    _c("b-container", [
                      _c(
                        "form",
                        { attrs: { autocomplete: "off" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "ID",
                                "label-for": "id-input",
                                horizontal: "",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "id-input",
                                  value: row.item.id,
                                  readonly: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Type",
                                "label-for": "affiliates-input-type",
                                horizontal: "",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "affiliates-input-type",
                                  value: _vm.$_.capitalize(row.item.type),
                                  readonly: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Partner",
                                "label-for": "affiliates-input-partner",
                                horizontal: "",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "affiliates-input-partner",
                                  value: _vm.getPartnerName(row.item.partner),
                                  readonly: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Schedule",
                                "label-for": "publication-comp",
                                horizontal: "",
                              },
                            },
                            [
                              _c("Publication", {
                                attrs: {
                                  id: "publication-comp",
                                  "from-field": "active_from",
                                  "to-field": "active_to",
                                  value: row.item,
                                  readonly: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Affiliate link",
                                "label-for": "link-input",
                                horizontal: "",
                              },
                            },
                            [
                              _c("id-input", {
                                attrs: {
                                  id: "link-input",
                                  value: row.item.url,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.usePromoCode(row.item)
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Promo Code",
                                    "label-for": "promo-code-input",
                                    horizontal: "",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "promo-code-input",
                                      value: row.item.promo_code.code,
                                      readonly: "",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          row.item.type == "recurring"
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Recurring compensation amount",
                                    "label-for": "amount-input",
                                    horizontal: "",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "amount-input",
                                      value: _vm.getRecurringCompensation(
                                        row.item.affiliate_compensations
                                      ),
                                      readonly: "",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Offers",
                                "label-for": "affiliate-offer-editor",
                                horizontal: "",
                              },
                            },
                            [
                              _c("AffiliateOffers", {
                                attrs: {
                                  id: "affiliate-offer-editor",
                                  readonly: "",
                                  type: row.item.type,
                                  "affiliate-compensations":
                                    row.item.affiliate_compensations,
                                  "use-promo-code": _vm.usePromoCode(row.item),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.perPage
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-center" },
            [
              _c("b-pagination", {
                attrs: {
                  "total-rows": _vm.items.length,
                  "per-page": _vm.perPage,
                },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("AddAffiliate", { ref: "addAffiliate" }),
      _c("MutateAffiliate", { ref: "mutateAffiliate" }),
      _c("SimpleModal", {
        attrs: {
          value: _vm.archiveModalVisible,
          msg: `Are you sure you want to archive ${
            this.selectedAffiliates.length > 1
              ? "these affiliates"
              : "this affiliate"
          }? Archiving a recurring affiliate means the owner does not get compensated anymore.`,
        },
        on: {
          ok: _vm.doArchive,
          cancel: function ($event) {
            _vm.archiveModalVisible = false
          },
        },
      }),
      _c("iframe", {
        staticStyle: { display: "none" },
        attrs: { id: "export-affiliates-frame" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }