<template>
  <div :id="id" class="labels">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <span class="keyword" v-for="label in labels" :key="label">
          <b-badge variant="secondary">
            {{ label }}
          </b-badge>
        </span>
      </div>
      <div class="edit-labels-button ml-auto">
        <b-button size="" variant="primary" @click.stop="showEditor">
          <Icon icon="edit"/>
           Edit
        </b-button>
      </div>
    </div>
    <EditLabels :labels="labels" @update="$emit('update', $event)" ref="editLabels" :allow-new="allowNew" :label-type="labelType"/>
  </div>
</template>

<script>
import EditLabels from "./EditLabels.vue"

export default {
  name: 'LabelsInput',

  components: {
    EditLabels
  },

  data() {
    return {}
  },

  props: {
    id: String,
    language: String,
    labels: Array,
    allowNew: {type: Boolean, default: true},
    labelType: {type: String, default: "label"}
  },

  methods: {
    showEditor() {
      this.$refs.editLabels.show();
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .keyword {
    margin-left: 4px;
  }

  .labels {
    text-align: left;
  }

</style>
