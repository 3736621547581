 <template>
 <div>
    <b-form-file
      id="select-file"
      v-model="files"
      multiple
      size="md"
      :state="files.length > 0"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
      :accept="acceptedFileTypes"
      :file-name-formatter="fileNameFormatter">
    </b-form-file>
    <div class="invisible">
      {{objectName}}
    </div>
 </div>

</template>

<script>
import AWS from 'aws-sdk/global'
import S3 from 'aws-sdk/clients/s3'

export default {
  name: 'S3Uploader',

  data: function() {
    return {
      files: [],
      fileEvents: [],
      uploadState: "ready",
      progressMax: 1,
      progressValue: 0,
      awsConf: null
    };
  },

  props: {
    itemId: String,
    name: String,
    format: String,
    multiple: Boolean,
    nameAsPath: Boolean
  },

  watch: {
    files(val) {
      if(this.multiple) {
        const res = val.reduce((acc, file) => {
          const parts = file.name.split('.')
          if(parts.length > 2) {
              const t = parts[1]
              if(acc.name != t) {
                if(acc.state == 'init') {
                  acc.state = 'set'
                  acc.name = t
                }
                else {
                  acc.state == 'error'
                }
              }
          }

          return acc
        }, {name: '', state: 'init'})

        if(res.state == 'set') {
          this.$emit('file-change', res)
        }
      }
      else if(val.length > 0) {
        this.$emit('file-change', val[0])
      }

      this.uploadState = 'ready'
      this.$emit('upload-state', this.uploadState)
      this.$emit('file-count', val.length)
    },

    fileEvents(events) {
      if(events.length > 0) {
        this.progressMax = events.reduce((acc, ev) => acc + ev.total, 0)
        this.progressValue = events.reduce((acc, ev) => acc + ev.loaded, 0)
        this.$emit('progress', {max: this.progressMax, value: this.progressValue})
      }
      else {
          this.progressMax = 1
          this.progressValue = 0
          this.$emit('progress', {max: this.progressMax, value: this.progressValue})
      }
    }
  },

  computed: {
    acceptedFileTypes() {
      switch(this.format) {
        case "video":
          return ".avi, .mov, .mp4, .m4v, .mpg, .mpeg, .mxf"

        case "print":
          return ".pdf"

        case "audio":
          return ".mov, .mp3, .m4a, .mp4, .wav"

        case "image":
          return ".pdf, .zip, .jpg, .jpeg, .png"

        case "thumbnail":
          return ".jpg, .jpeg, .png, .gif"

        case "cover":
          return ".jpg, .jpeg, .png, .gif"

        case "slide":
          return ".jpg, .jpeg, .png"

        case "subtitle":
          return ".srt, .vtt"

        case "doc":
          return ".csv, .tsv, .txt"

        default:
          return null
      }
    },

    objectName() {
      if(this.files.length == 1 && !this.multiple)
        var objectName = this.nameAsPath ? this.name : this.name + this.getExtension(this.files[0].name)
        console.log(objectName)
        this.$emit('object-name', objectName)
        return objectName
    }
  },

  methods: {
    startUpload() {
      let func = () => {
        if(this.files.length > 0 && this.awsConf) {
          AWS.config.credentials = new AWS.Credentials(this.awsConf.access_key_id, this.awsConf.secret_access_key, this.awsConf.session_token);
          let bucket = new S3({params: {Bucket: this.awsConf.bucket}})
          this.files.forEach((file, ndx) => {
            let key = this.multiple ? this.getPrefix(file.name) + this.name : this.name
            key = this.nameAsPath ? key : key + this.getExtension(file.name)

            const params = {Key: this.awsConf.key_prefix + key, ContentType: file.type, Body: file};
            const options = {partSize: 10 * 1024 * 1024}
            this.fileEvents[ndx] = {total: 0, loaded: 0}

            bucket.upload(params, options)
            .on('httpUploadProgress', (ev) => {
              if(ev) {
                if(ndx >= 0) {
                  let f = this.fileEvents[ndx]
                  f.total = ev.total
                  f.loaded = ev.loaded

                  this.fileEvents = this.fileEvents.map((ff, n) => {
                    if(n == ndx) return f
                    else return ff
                  })
                }
              }
            })
            .send((err) => {
              this.handleFinished(!!err, ndx)
            });
          })

          this.uploadState = "uploading"
          this.$emit('upload-state', this.uploadState)
        }
        else {
          this.uploadState = "ready"
          this.$emit('upload-state', this.uploadState)
          this.$root.app.showAlert("No file selected to upload", "danger")
        }
      }

      this.$emit('start-upload-request', {handler: func, conf: this.awsConf})
    },

    getExtension(path) {
      if(path) {
          const fileParts = path.split('.')
          return fileParts.length >= 2 ? '.' + fileParts[fileParts.length - 1] : ''
      }
      else {
        return ''
      }
    },

    getPrefix(path) {
      if(path) {
        const pathParts = path.split('/')
        const fileName = pathParts[pathParts.length - 1]
        const fileParts = fileName.split('.')
        const range = fileParts.length >= 2 ? fileParts.length - 1 : fileParts.length

        let prefix = ''
        for(var i = 0; i < range; i++) {
          prefix += fileParts[i]
        }

        return prefix + '.'
      }
      else {
        return ''
      }
    },

    show() {
      this.reset()

      let url = this.format == "doc" ? "/admin/api/v2/admin/temp-s3-access/doc" : `/admin/api/v2/admin/temp-s3-access/asset/${this.itemId}`
      console.log(url)
      fetch(url)
      .then(resp => {
        if(resp.status == 200) return resp.json()
        else throw Error()
      })
      .then(resp => {
        this.awsConf = resp.data
        this.$emit('upload-state', this.uploadState)
        this.$emit('progress', {max: this.progressMax, value: this.progressValue})
        this.$emit('aws-conf-received', resp.data)
      })
      .catch(() => {
        this.$root.app.showAlert("Error retrieving AWS S3 creddentials from server", "danger")
        this.reset()
      });
    },


    close() {

    },

    reset() {
      this.uploadState = 'ready'
      this.$emit('upload-state', this.uploadState)
      this.files = []
      this.fileEvents = []
      this.progressMax = 1
      this.progressValue = 0
      this.$emit('progress', {max: this.progressMax, value: this.progressValue})
    },

    handleFinished(isError, fileNdx) {
      this.fileEvents[fileNdx] = this.fileEvents[fileNdx] || {}
      this.fileEvents[fileNdx].finished = true
      const allFinished = this.fileEvents.length == 0 ? false : this.fileEvents.reduce((acc, ev) => {
        if(!ev.finished) { return false }
        else { return acc }
      }, true)

      if(isError) {
        this.$root.app.showAlert(`There was an error uploading file '${this.files[fileNdx].name}'.`, 'danger')
      }

      if(allFinished) {
        this.$emit('finished')

        if(this.multiple) {
          this.$root.app.showAlert(isError ? 'There was an error uploading one of the files' : 'All files successfully uploaded.', isError ? 'danger' : 'success')
        }
        else if(!isError) {
          this.$root.app.showAlert(`'${this.files[0].name}' successfully uploaded.`, 'success')
        }

        this.reset()
      }

    },

    fileNameFormatter(files) {
      if(files.length == 1) {
        return files[0].name
      }
      else {
        return `${files.length} Files selected...`
      }
    }

  }

}
</script>

<style>
</style>
