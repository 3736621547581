var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.uploadState == "ready" && _vm.modalState == "closed"
        ? [
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "float-right",
                attrs: { title: `Upload new ${_vm.format}`, variant: "link" },
                on: { click: _vm.show },
              },
              [
                _c("Icon", { attrs: { icon: "Add" } }),
                _vm.buttonText
                  ? _c("span", { staticClass: "ml-2" }, [
                      _vm._v(_vm._s(_vm.buttonText)),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm.uploadState == "uploading" && _vm.modalState == "closed"
        ? [
            _c("b-progress", {
              staticStyle: { width: "200px" },
              attrs: {
                value: _vm.progressValue,
                max: _vm.progressMax,
                "show-progress": "",
                animated: "",
                height: "40px",
              },
            }),
          ]
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            title: _vm.modalTitle,
            "hide-footer": "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.close },
        },
        [
          _c(
            "b-form",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "overflow-hidden",
                  attrs: { label: "Select file", "label-for": "select-file" },
                },
                [
                  _c("S3Uploader", {
                    ref: "uploader",
                    attrs: {
                      itemId: _vm.itemId,
                      name: _vm.name,
                      format: _vm.format,
                      multiple: _vm.multiple,
                      nameAsPath: _vm.nameAsPath,
                    },
                    on: {
                      "object-name": function ($event) {
                        _vm.objectName = $event
                      },
                      progress: _vm.handleProgress,
                      "upload-state": _vm.handleUploadState,
                      finished: _vm.handleFinished,
                      "aws-conf-received": _vm.handleAwsConfReceived,
                      "file-change": _vm.handleFileChange,
                      "start-upload-request": _vm.handleStartUploadRequest,
                      "file-count": _vm.handleFileCount,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "my-2" }, [_vm._t("default")], 2),
              !_vm.hideObjectName && !_vm.multiple
                ? [
                    _c(
                      "b-form-group",
                      { attrs: { label: "S3 Object Name" } },
                      [
                        _c("b-form-input", {
                          attrs: { readonly: "", value: _vm.objectName },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.uploadState == "ready"
                ? _c(
                    "b-button",
                    {
                      attrs: {
                        disabled:
                          _vm.fileCount == 0 || _vm.uploadState != "ready",
                        variant: "primary",
                      },
                      on: { click: _vm.startUpload },
                    },
                    [_vm._v("Upload")]
                  )
                : _c("b-progress", {
                    attrs: {
                      value: _vm.progressValue,
                      max: _vm.progressMax,
                      "show-progress": "",
                      animated: "",
                    },
                  }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }