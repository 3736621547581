export default {
  getJSON(endpoint, success, error) {
    var xhr = new XMLHttpRequest();

    xhr.open('GET', endpoint);

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if(xhr.status == 200) {
          let data = JSON.parse(xhr.responseText);
          success(data);
        }
        else {
          if(error) {
            error(xhr.status, xhr.responseText);
          }
        }
      }
    };

  },

  postJSON(endpoint, data, success, error) {
    var xhr = new XMLHttpRequest();
    var body = JSON.stringify(data);

    xhr.open('POST', endpoint, true);

    xhr.setRequestHeader('Content-type', 'application/json');

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if(xhr.status == 200) {
          let data = JSON.parse(xhr.responseText);
          success(data);
        }
        else {
          if(error) {
            error(xhr.status, xhr.responseText);
          }
        }
      }
    };

    xhr.send(body);
  }
}