var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-top": "1.8rem", height: "600px" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-card",
                [
                  _c(
                    "b-input-group",
                    { staticClass: "mb-3" },
                    [
                      _c("b-form-input", {
                        attrs: { placeholder: "Type to Search" },
                        model: {
                          value: _vm.searchInput,
                          callback: function ($$v) {
                            _vm.searchInput = $$v
                          },
                          expression: "searchInput",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: { disabled: !_vm.searchInput },
                              on: {
                                click: function ($event) {
                                  _vm.searchInput = ""
                                },
                              },
                            },
                            [
                              _c("Icon", {
                                attrs: { icon: "Search_backspace" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "draggable",
                    {
                      staticClass: "list-group scroller",
                      attrs: {
                        list: _vm.srcItems,
                        options: { sort: false },
                        group: { name: "mediaItems", pull: true, put: false },
                      },
                    },
                    _vm._l(_vm.srcItems, function (item) {
                      return _c(
                        "a",
                        {
                          key: item.id,
                          staticClass:
                            "list-group-item list-group-item-action d-flex justify-content-between align-items-center",
                          attrs: { href: "#" },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(item.meta.title))]),
                          _c("b-badge", [_vm._v(_vm._s(item.type))]),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-card",
                { attrs: { title: "Category" } },
                [
                  _c(
                    "draggable",
                    {
                      staticClass: "dragArea list-group scroller",
                      attrs: { list: _vm.dstItems, group: "mediaItems" },
                      on: { change: _vm.log },
                    },
                    _vm._l(_vm.dstItems, function (item) {
                      return _c(
                        "a",
                        {
                          key: item.id,
                          staticClass:
                            "list-group-item list-group-item-action d-flex justify-content-between align-items-center",
                          attrs: { href: "#" },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(item.meta.title))]),
                          _c(
                            "span",
                            [
                              _c("b-badge", [_vm._v(_vm._s(item.type))]),
                              _c(
                                "b-badge",
                                {
                                  staticClass: "ml-2",
                                  attrs: { variant: "danger" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeItem(item.id)
                                    },
                                  },
                                },
                                [_vm._v("x")]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }