<template>
  <div class="home">
    <iframe id="download-all-content-frame" style="display:none;"></iframe>
  </div>
</template>

<script>
export default {
  name: "Home",


  methods: {
    exportContent() {
      this.$http.postJSON(
        "/admin/api/v2/media/download-all-content-export",
        {},
        (resp) => {
          let file_name = "/admin/api/v2/media/download-export/" + resp.data;
          document.getElementById('download-all-content-frame').src = file_name;
        },
        (status, html) => {
          this.$root.app.showErrorDialog(html, status.toString())
        }
      )
    }
  },


  mounted() {
    this.$root.app.setActivePageTitle("Home", true)
    this.$root.$emit('set-toolbar', 'home-toolbar')
  },

  created() {
    this.$root.$on('home-content-export', this.exportContent)
  },

  beforeDestroy() {
    this.$root.$off('home-content-export', this.exportContent)
  }
};
</script>
