<template>
  <div class="manage-media-properties">
    <b-modal  ref="modal"
              :visible="visible"
              :title="`Manage media item properties (${count})`"
              no-close-on-backdrop
              @ok="handleUpdate"
              @hidden="clear"
              auto-focus-button="ok">
      <div class="form-content">
        <div class="info mb-4 py-2 px-2">
          <span class="font-weight-bold text-warning">Info</span>
          <b-form-checkbox disabled inline class="ml-4" :checked="true"/> <span style="margin-left: -1rem">Enable</span>
          <b-form-checkbox disabled inline class="ml-4" :checked="false"/> <span style="margin-left: -1rem">Disable</span>
          <b-form-checkbox disabled inline class="ml-4" :checked="false" :indeterminate="true"/> <span style="margin-left: -1rem">Do not change</span>
        </div>

        <b-form id="manage-media-properties-form">
          <b-form-group label="Shopping" label-for="mp-enable-shopping" label-cols="6">
            <b-form-checkbox id="mp-enable-shopping" v-model="shopping_enabled" :indeterminate.sync="shopping_enabled_im"/>
          </b-form-group>

          <b-form-group label="Kids Content" label-for="mp-enable-is-kids-content" label-cols="6">
            <b-form-checkbox id="mp-enable-is-kids-content" v-model="is_kids_content" :indeterminate.sync="is_kids_content_im"/>
          </b-form-group>

          <b-form-group label="Quality Stamp" label-for="mp-enable-quality-stamp" label-cols="6">
            <b-form-checkbox id="mp-enable-quality-stamp" v-model="quality_stamp" :indeterminate.sync="quality_stamp_im"/>
          </b-form-group>

          <b-form-group label="Call To Action" label-for="mp-enable-call-to-action" label-cols="6">
            <b-form-checkbox id="mp-enable-call-to-action" v-model="call_to_action_enabled" :indeterminate.sync="call_to_action_enabled_im"/>
          </b-form-group>

          <b-form-group label="Revshare TOA" label-for="mp-enable-no-rev-share" label-cols="6">
            <b-form-checkbox id="mp-enable-no-rev-share" v-model="no_rev_share" :indeterminate.sync="no_rev_share_im"/>
          </b-form-group>

          <b-form-group label="Exclude From Recently Added" label-for="mp-enable-recently-added-exclude" label-cols="6">
            <b-form-checkbox id="mp-enable-recently-added-exclude" v-model="recently_added_exclude" :indeterminate.sync="recently_added_exclude_im"/>
          </b-form-group>

          <b-form-group label="Show Slide" label-for="mp-enable-slide" label-cols="6">
            <b-form-checkbox id="mp-enable-slide" v-model="slide_enabled" :indeterminate.sync="slide_enabled_im"/>
          </b-form-group>

          <b-form-group label="Show Cover" label-for="mp-enable-cover" label-cols="6">
            <b-form-checkbox id="mp-enable-cover" v-model="cover_enabled" :indeterminate.sync="cover_enabled_im"/>
          </b-form-group>

          <b-form-group label="Show Meta Text" label-for="mp-show-meta-text" label-cols="6">
            <b-form-checkbox id="mp-show-meta-text" v-model="show_meta_text" :indeterminate.sync="show_meta_text_im"/>
          </b-form-group>

          <b-form-group label="Plan" label-for="mp-plan-select" horizontal label-cols="6">
            <b-form-select id="mp-plan-select" v-model="Plan" :options="planOptions"/>
          </b-form-group>

          <b-form-group v-if="Plan != 'free'" label="Premium Value" label-for="mp-premium-value-select" horizontal label-cols="6">
            <b-form-select id="mp-premium-value-select" v-model="PremiumValue" :options="premiumValueOptions"/>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ManageProperties',

  data: function() {
    return {
      visible: false,

      shopping_enabled: false,
      is_kids_content: false,
      quality_stamp: false,
      slide_enabled: false,
      cover_enabled: false,
      show_meta_text: false,
      call_to_action_enabled: false,
      no_rev_share: false,
      recently_added_exclude: false,
      plan: this.$store.state.media.defaultPlan,
      premium_value: 100,

      shopping_enabled_im: true,
      is_kids_content_im: true,
      quality_stamp_im: true,
      slide_enabled_im: true,
      cover_enabled_im: true,
      show_meta_text_im: true,
      call_to_action_enabled_im: true,
      no_rev_share_im: true,
      recently_added_exclude_im: true,
      plan_im: true,
      premium_value_im: true,

      shopping_enabled_cnt: 0,
      is_kids_content_cnt: 0,
      quality_stamp_cnt: 0,
      slide_enabled_cnt: 0,
      cover_enabled_cnt: 0,
      show_meta_text_cnt: 0,
      call_to_action_enabled_cnt: 0,
      no_rev_share_cnt: 0,
      recently_added_exclude_cnt: 0
    };
  },

  props: {
    value: Boolean,
    count: Number
  },

  watch: {
    value(v) {
      this.visible = v
    },

    shopping_enabled(v) {
      if(v && ++this.shopping_enabled_cnt > 1) {
        this.shopping_enabled_im = true
        this.shopping_enabled_cnt = 0
      }
    },

    is_kids_content(v) {
      if(v && ++this.is_kids_content_cnt > 1) {
        this.is_kids_content_im = true
        this.is_kids_content_cnt = 0
      }
    },

    quality_stamp(v) {
      if(v && ++this.quality_stamp_cnt > 1) {
        this.quality_stamp_im = true
        this.quality_stamp_cnt = 0
      }
    },

    slide_enabled(v) {
      if(v && ++this.slide_enabled_cnt > 1) {
        this.slide_enabled_im = true
        this.slide_enabled_cnt = 0
      }
    },

    cover_enabled(v) {
      if(v && ++this.cover_enabled_cnt > 1) {
        this.cover_enabled_im = true
        this.cover_enabled_cnt = 0
      }
    },

    show_meta_text(v) {
      if(v && ++this.show_meta_text_cnt > 1) {
        this.show_meta_text_im = true
        this.show_meta_text_cnt = 0
      }
    },
    call_to_action_enabled(v) {
      if(v && ++this.call_to_action_enabled_cnt > 1) {
        this.call_to_action_enabled_im = true
        this.call_to_action_enabled_cnt = 0
      }
    },

    no_rev_share(v) {
      if(v && ++this.no_rev_share_cnt > 1) {
        this.no_rev_share_im = true
        this.no_rev_share_cnt = 0
      }
    },

    recently_added_exclude(v) {
      if(v && ++this.recently_added_exclude_cnt > 1) {
        this.recently_added_exclude_im = true
        this.recently_added_exclude_cnt = 0
      }
    }
  },

  computed: {
    planOptions() {
      return [{value: "nochange", text: "Do not change"}].concat(this.$store.state.media.options.plan)
    },

    Plan: {
      get() {
        return this.plan_im ? "nochange" : this.plan
      },

      set(p) {
        if(p == "nochange") {
          this.plan_im = true
        }
        else {
          this.plan = p
          this.plan_im = false
        }
      }
    },

    premiumValueOptions() {
      return [{value: "nochange", text: "Do not change"}].concat(this.$store.state.media.options.premiumValue)
    },

    PremiumValue: {
      get() {
        return this.premium_value_im ? "nochange" : this.premium_value
      },

      set(p) {
        if(p == "nochange") {
          this.premium_value_im = true
        }
        else {
          this.premium_value = parseInt(p)
          this.premium_value_im = false
        }
      }
    }
  },

  methods: {
    handleUpdate() {
      const properties = {
        shopping_enabled: this.shopping_enabled_im ? null : this.shopping_enabled,
        is_kids_content: this.is_kids_content_im ? null : this.is_kids_content,
        quality_stamp: this.quality_stamp_im ? null : this.quality_stamp,
        slide_enabled: this.slide_enabled_im ? null : this.slide_enabled,
        cover_enabled: this.cover_enabled_im ? null : this.cover_enabled,
        show_meta_text: this.show_meta_text_im ? null : this.show_meta_text,
        call_to_action_enabled: this.call_to_action_enabled_im ? null : this.call_to_action_enabled,
        no_rev_share: this.no_rev_share_im ? null : this.no_rev_share,
        recently_added_exclude: this.recently_added_exclude_im ? null : this.recently_added_exclude,
        plan: this.plan_im ? null : this.plan,
        premium_value: this.premium_value_im ? null : this.premium_value
      }

      this.$emit('update', properties)
    },

    clear() {
      this.shopping_enabled = false
      this.is_kids_content= false
      this.quality_stamp = false
      this.slide_enabled = false
      this.cover_enabled = false
      this.show_meta_text = false
      this.call_to_action_enabled = false
      this.no_rev_share = false
      this.recently_added_exclude = false
      this.plan = this.$store.state.media.defaultPlan
      this.premium_value = 100

      this.shopping_enabled_im = true
      this.is_kids_content_im = true
      this.quality_stamp_im = true
      this.slide_enabled_im = true
      this.cover_enabled_im = true
      this.show_meta_text_im = true
      this.call_to_action_enabled_im = true
      this.no_rev_share_im = true
      this.recently_added_exclude_im = true
      this.plan_im = true
      this.premium_value_im = true

      this.shopping_enabled_cnt = 0
      this.is_kids_content_cnt = 0
      this.quality_stamp_cnt = 0
      this.slide_enabled_cnt = 0
      this.cover_enabled_cnt = 0
      this.show_meta_text_cnt = 0
      this.call_to_action_enabled_cnt = 0
      this.no_rev_share_cnt = 0
      this.recently_added_exclude_cnt = 0

      this.$emit('input', false)
    }
  },

  created() {
    this.visible = this.value
  }
}


</script>

<style scoped>
.info {
  background-color: #0D1B2B;
}
</style>
