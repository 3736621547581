<template>
  <b-modal title="Generate Preview" :visible="value" :size="size" @ok="handleOk" @cancel="handleCancel" :ok-disabled="!(fromState && toState)" hide-header-close auto-focus-button="ok">
    <b-form>
      <b-form-group label="From" label-for="from-input" type="time" horizontal>
        <b-form-input id="from-input" :value="fromStr" @blur="setFrom" :state="fromState"></b-form-input>
      </b-form-group>
      <b-form-group label="To" label-for="to-input" type="time" horizontal>
        <b-form-input id="to-input" :value="toStr" @blur="setTo" :state="toState"></b-form-input>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  name: 'GeneratePreview',

  data() {
    return {
      from: 15,
      to: 45,

      fromState: true,
      toState: true
    }
  },

  props: {
    value: Boolean,
    assetId: String,
    size: {type: String, default: ""}
  },

  computed: {
    fromStr() { return this.$helpers.durationToString(this.from) },
    toStr() { return this.$helpers.durationToString(this.to) }
  },

  methods: {
    setFrom(ev) {
      this.from = this.$helpers.stringToDuration(ev.target.value)
      this.fromState = !isNaN(this.from)
    },

    setTo(ev) {
      this.to = this.$helpers.stringToDuration(ev.target.value)
      this.toState = !isNaN(this.to)
    },

    handleOk() {
      let payload = JSON.stringify({
        data: {
          asset_id: this.assetId,
          offset: this.from,
          length: this.to - this.from
        }
      })

      fetch(
        'admin/api/v2/media/generate-preview',
        {method: 'POST', body: payload}
      )
      .then(resp => resp.json())
      .then(resp => {
        if(resp.status == "ok") {
          this.$root.app.showAlert("Video preview transcoding has started", "success")
        }
        else {
          console.log(resp)
          this.$root.app.showAlert(`Generate Preview Error: ${this.$helpers.prettify(resp.type)}`, "danger")
        }
      })
      .catch(e => {
        console.log(e)
        this.$root.app.showAlert(`Generate Preview Error: ${e.text}`, "danger")
      })

      this.clear()
    },

    handleCancel() {
      this.clear()
    },

    clear() {
      this.$emit('input', false)
    }
  }
}
</script>


<style scoped>
</style>

