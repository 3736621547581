var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ml-auto py-2 pl-2 bg-dark" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-end" },
      [
        _c(
          "b-button",
          {
            staticClass: "mr-2",
            attrs: { id: "add-button", variant: "link", text: "Add" },
            on: {
              click: function ($event) {
                return _vm.$root.$emit("user-add")
              },
            },
          },
          [
            _c("Icon", { attrs: { icon: "Add" } }),
            _c("span", { staticClass: "ml-2" }, [_vm._v("Add")]),
          ],
          1
        ),
        _vm.userType == "user" ? _c("AddUserBatchImport") : _vm._e(),
        _vm.userType == "user"
          ? _c(
              "div",
              { staticClass: "mx-1", staticStyle: { "margin-top": "2px" } },
              [_c("Icon", { attrs: { icon: "Scheiding", size: 24 } })],
              1
            )
          : _vm._e(),
        _c(
          "b-form",
          { attrs: { inline: "" } },
          [
            _vm.userType == "user"
              ? [
                  _c("b-form-select", {
                    attrs: {
                      id: "users-select-type",
                      options: _vm.typeOptions,
                    },
                    model: {
                      value: _vm.selectedType,
                      callback: function ($$v) {
                        _vm.selectedType = $$v
                      },
                      expression: "selectedType",
                    },
                  }),
                  _c("b-form-select", {
                    staticClass: "ml-2",
                    attrs: {
                      id: "users-select-status",
                      options: _vm.statusOptions,
                    },
                    model: {
                      value: _vm.selectedStatus,
                      callback: function ($$v) {
                        _vm.selectedStatus = $$v
                      },
                      expression: "selectedStatus",
                    },
                  }),
                  _c("b-form-select", {
                    staticClass: "ml-2",
                    attrs: {
                      id: "users-select-country",
                      options: _vm.geoOptions,
                    },
                    model: {
                      value: _vm.selectedGeo,
                      callback: function ($$v) {
                        _vm.selectedGeo = $$v
                      },
                      expression: "selectedGeo",
                    },
                  }),
                ]
              : _vm._e(),
            _c(
              "div",
              { staticClass: "mx-1", staticStyle: { "margin-top": "1px" } },
              [_c("Icon", { attrs: { icon: "Scheiding", size: 24 } })],
              1
            ),
            _c(
              "b-button-group",
              [
                _c(
                  "b-input-group",
                  [
                    _c("b-form-input", {
                      ref: "search",
                      attrs: {
                        autocomplete: "false",
                        placeholder: "Type to Search",
                      },
                      model: {
                        value: _vm.filterInput,
                        callback: function ($$v) {
                          _vm.filterInput = $$v
                        },
                        expression: "filterInput",
                      },
                    }),
                    _c(
                      "b-input-group-append",
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "btn-outline-light",
                              disabled: !_vm.filterInput,
                            },
                            on: {
                              click: function ($event) {
                                _vm.filterInput = ""
                              },
                            },
                          },
                          [_c("Icon", { attrs: { icon: "Search_backspace" } })],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }