var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-img", {
    attrs: { src: _vm.path, width: _vm.size + "px", height: _vm.size + "px" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }