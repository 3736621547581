import { render, staticRenderFns } from "./AssetList.vue?vue&type=template&id=6f7588ae&scoped=true&"
import script from "./AssetList.vue?vue&type=script&lang=js&"
export * from "./AssetList.vue?vue&type=script&lang=js&"
import style0 from "./AssetList.vue?vue&type=style&index=0&id=6f7588ae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f7588ae",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/zambal/dev/ott_cms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6f7588ae')) {
      api.createRecord('6f7588ae', component.options)
    } else {
      api.reload('6f7588ae', component.options)
    }
    module.hot.accept("./AssetList.vue?vue&type=template&id=6f7588ae&scoped=true&", function () {
      api.rerender('6f7588ae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/AssetList.vue"
export default component.exports