<template>
  <div :id="id">
    <b-container fluid class="">
      <b-row>
        <b-col v-if="firstImageUrl">
          <b-img class="img-preview" center thumbnail :src="firstImageUrl"/>
        </b-col>
        <b-col class="d-flex align-items-center flex-row-reverse" style="padding-right: 0px">
          <b-button v-if="(images && images.length > 0) || !allowCopyIfEmpty" id="edit-button" size="" :variant="showEditor ? 'warning' : 'primary'" @click.stop="showEditor = !showEditor" class="ml-2">
            <Icon icon="edit"/>
            Edit
          </b-button>
          <b-dropdown v-else-if="allowCopyIfEmpty && (type == 'cover' || type == 'slide')" id="copy-button" size="" variant="primary" @click.stop="copyFrom" class="ml-2">
            <template slot="button-content">
              <font-awesome-icon icon="copy"/>
              Copy from
            </template>
            <b-dropdown-item v-for="(lang, code) in $store.state.app.languages" :key="code" @click.stop="copyFrom(code)">
              {{lang}}
            </b-dropdown-item>
          </b-dropdown>
          <AddImage :item-id="itemId" :format="type" :language="language" @finished="$emit('reload')"></AddImage>
        </b-col>
      </b-row>
    </b-container>
    <ImageEdit v-if="showEditor" :item-id="itemId" :item-type="itemType" :language="language" :value="images" @input="update($event)" :type="type" :allow-link-to="allowLinkTo"></ImageEdit>
  </div>
</template>

<script>
import ImageEdit from "./ImageEdit.vue"
import AddImage from "./AddImage.vue"

export default {
  name: 'ImageList',

  components: {
    ImageEdit,
    AddImage
  },

  data: function() {
    return {
      images: [],
      showEditor: false
    };
  },

  props: {
    id: String,
    value: Array,
    allowCopyIfEmpty: Boolean,
    allowLinkTo: Boolean,
    itemId: String,
    itemType: String,
    language: String,
    type: String
  },

  computed: {
    firstImageUrl() {
      let hasImage = this.images && this.images.length > 0 && this.images[0].img_set.length > 0
      return hasImage ? this.$helpers.resizeImageUrl(this.images[0].img_set[0].url, 140) : ""
    }

  },

  watch: {
    value(val) {
      this.images = this.$_.clone(val)
    }
  },

  methods: {
    copyFrom(langCode) {
      this.$emit('copy-from', langCode)
    },

    update(images) {
      this.images = images
      this.$emit('input', images)
    }
  },

  created() {
    this.images = this.$_.clone(this.value)
   // this.$log.info(this.images)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.img-preview {
  height: 150px;
}

</style>
