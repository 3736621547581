var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        size: "lg",
        title: "Publish Check Results",
        scrollable: "",
        "ok-only": !_vm.data.isSaveResult,
        "auto-focus-button": "ok",
      },
      on: { ok: _vm.handleOk },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _vm.data.status == "ok"
        ? [
            _c("h5", { staticClass: "text-success" }, [
              _vm._v("All items are ready to Publish"),
            ]),
            _c("p", [
              _vm._v(
                "Click OK to publish the selected items on the live environment."
              ),
            ]),
          ]
        : [
            _c("h5", { staticClass: "text-danger" }, [
              _vm._v(_vm._s(_vm.data.headerText)),
            ]),
            _c("p", { staticClass: "mb-4" }, [
              _vm._v(_vm._s(_vm.data.subHeaderText)),
            ]),
            _vm._l(_vm.data.results, function (r) {
              return _c(
                "b-card",
                {
                  key: r.id,
                  staticClass: "mb-2",
                  attrs: { title: r.title, "title-tag": "h5", tag: "article" },
                },
                [
                  _c(
                    "b-card-text",
                    [
                      _c("b-badge", { staticClass: "mb-4" }, [
                        _vm._v(_vm._s(`Item ID: ${r.id}`)),
                      ]),
                      _c(
                        "ul",
                        _vm._l(r.results, function (rr, index) {
                          return _c("li", { key: index }, [
                            _c(
                              "span",
                              {
                                class:
                                  rr.level == "warning"
                                    ? "text-warning"
                                    : "text-danger",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      rr.level == "warning"
                                        ? "Warning: "
                                        : "Error: "
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    rr.language
                                      ? `${_vm.$helpers.getLanguageName(
                                          rr.language
                                        )} ${rr.message}`
                                      : rr.message
                                  ) +
                                  " "
                              ),
                            ]),
                          ])
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }