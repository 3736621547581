var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-form-input", {
    attrs: { value: _vm.v },
    on: { input: _vm.update },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }