export default {
  name: "Qisum",

  menuTitle: "TEST PLATFORM",

  defaultLanguage: "nl",

  use2FA: true,

  usePremiumValue: true,

  useExternalIDs: true,

  slideEnabled: true,

  enableAds: true,

  enableExactExport: true,

  enableShopping: true,

  enableAffiliates: false,

  enablePromoCodes: false,

  enableArtistsEverywhere: true,

  enableGenres: true,

  enableCounters: true,

  hasPayingCustomers: true,

  allowInsertFromExcel: false,

  allowPartnerContractUpload: true,

  hasAdultCheck: true,

  defaultCoverSize: "lg",

  enableDdex: true,
  ddexUrl: 'https://qisum-ddex-test.usendes.com/',

  logo: "platform/qisum_logo.png",

  useBunnyResize: true,
  thumbor_server: "qisum-test-img.usendes.com",

  enableSentry: true,
  sentryDsn: "https://0cabae97f99ac03c499f356221d98cd9@o4506574792884224.ingest.sentry.io/4506654704074752",
  sentryDomains: ['qisum-test-admin.usendes.com']
}
