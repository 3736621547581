<template>
  <div class="ml-auto py-2 pl-2 bg-dark">
    <div class="d-flex align-items-center justify-content-end">
          <b-button class="mr-2" id="add-button" variant="link" text="Add" @click="$root.$emit('user-add')">
            <Icon icon="Add"/>
            <span class="ml-2">Add</span>
          </b-button>
          <AddUserBatchImport v-if="userType == 'user'"/>

        <div v-if="userType == 'user'" class="mx-1" style="margin-top: 2px;">
          <Icon icon="Scheiding" :size="24"/>
        </div>


        <b-form inline>
          <template v-if="userType == 'user'">
            <b-form-select id="users-select-type" v-model="selectedType" :options="typeOptions"></b-form-select>
            <b-form-select class="ml-2" id="users-select-status" v-model="selectedStatus" :options="statusOptions"></b-form-select>
            <b-form-select class="ml-2" id="users-select-country" v-model="selectedGeo" :options="geoOptions"></b-form-select>
          </template>

          <div class="mx-1" style="margin-top: 1px;">
            <Icon icon="Scheiding" :size="24"/>
          </div>

          <b-button-group>
            <b-input-group>
              <b-form-input ref="search" autocomplete="false" v-model="filterInput" placeholder="Type to Search" />
              <b-input-group-append>
                <b-button variant="btn-outline-light" :disabled="!filterInput" @click="filterInput = ''"><Icon icon="Search_backspace"/></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-button-group>
        </b-form>
    </div>
  </div>
</template>

<script>
import AddUserBatchImport from './AddUsersBatchImport.vue'

export default {
  name: 'UserToolbar',

  components: {
    AddUserBatchImport
  },

  data: function() {
    return {    
      filterInput: null,

      selectedType: 'customer',
      typeOptions: [],

      selectedStatus: 'subscribed',
      statusOptions: [],

      selectedGeo: '*',
      geoOptions: []
    };
  },

  props: {
    userType: String
  },

  watch: {
    filterInput(val) {
      this.$root.$emit('user-filter', val);
    },

    selectedType(val) {
      this.$root.$emit('user-type-filter', val);
    },

    selectedStatus(val) {
      this.$root.$emit('user-status-filter', val);
    },

    selectedGeo(val) {
      this.$root.$emit('user-geo-filter', val);
    }

  },

  methods: {
    load() {
      fetch('/admin/api/v2/users/geo-options')
      .then(function(resp) {
        return resp.json();
      })
      .then(resp => {
        this.geoOptions = [{value: '*', text: 'Any Geo'}].concat(resp.data)
        fetch('/admin/api/v2/users/status-options')
        .then(function(resp) {
          return resp.json();
        })
        .then(resp => {
          this.statusOptions = [{value: '*', text: 'Any Status'}].concat(resp.data)
          fetch('/admin/api/v2/users/type-options')
          .then(function(resp) {
            return resp.json();
          })
          .then(resp => {
            this.typeOptions = [{value: '*', text: 'Any Type'}].concat(resp.data)
          })
        })
      })
    },

    focusSearch() {
      this.$refs.search.focus()
    }
  },

  mounted() {
    this.load()
  },

  created() {
    this.$root.$on('user-focus-search', this.focusSearch)
  },

  beforeDestroy() {
    this.$root.$off('user-focus-search', this.focusSearch)
  }

}
</script>
