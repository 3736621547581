var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rev-share-billing", staticStyle: { "margin-top": "2rem" } },
    [
      _c(
        "div",
        { staticClass: "d-flex mb-4" },
        [
          _c("h5", [_vm._v("Costs")]),
          _vm._l(
            _vm.$store.state.finance.totalCostShares,
            function (share, tier) {
              return _c(
                "h5",
                { key: tier, staticClass: "ml-2" },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$helpers.prettify(tier)) + ": "),
                  ]),
                  _c("b-badge", [
                    _vm._v(
                      _vm._s(_vm.$helpers.percentageFormatter(share)) + "%"
                    ),
                  ]),
                ],
                1
              )
            }
          ),
        ],
        2
      ),
      _vm.$config.enableAds && _vm.globalAmounts["ad_supported"]
        ? _c(
            "div",
            { staticClass: "d-flex", staticStyle: { "margin-bottom": "3rem" } },
            [
              _c(
                "h5",
                [
                  _c("span", { staticClass: "font-weight-bold mr-4" }, [
                    _vm._v("Ad Revenue"),
                  ]),
                  _c(
                    "b-badge",
                    {
                      on: {
                        click: function ($event) {
                          _vm.enableAdEdit = !_vm.enableAdEdit
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            cursor: "pointer",
                            "user-select": "none",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$helpers.currencyFormatter(
                                _vm.globalAmounts["ad_supported"].amount
                              )
                            )
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              !_vm.isPublished && _vm.enableAdEdit
                ? [
                    _c(
                      "b-button",
                      {
                        staticClass: "ml-2",
                        attrs: { size: "sm", variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.updateAdRevenue()
                          },
                        },
                      },
                      [_c("Icon", { attrs: { icon: "edit" } })],
                      1
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "ml-2",
                        attrs: { size: "sm", variant: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.removeAdRevenue()
                          },
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "trash-alt" },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.revenueItems.length > 0
        ? [
            _vm._m(0),
            _c("b-table", {
              staticClass: "my-3",
              attrs: {
                items: _vm.revenueItems,
                fields: _vm.revenueFields,
                striped: "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "cell(edit_revenue)",
                    fn: function (row) {
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-center" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mr-2",
                                attrs: { size: "sm", variant: "primary" },
                                on: { click: row.toggleDetails },
                              },
                              [_c("Icon", { attrs: { icon: "edit" } })],
                              1
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "mr-2",
                                attrs: { size: "sm", variant: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeGeoRevenue(row)
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "trash-alt" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "row-details",
                    fn: function (row) {
                      return [
                        _c(
                          "b-container",
                          [
                            _c(
                              "b-form",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Country",
                                      "label-for": "add-geo-country",
                                      horizontal: "",
                                    },
                                  },
                                  [
                                    _c("b-input", {
                                      attrs: {
                                        id: "add-geo-country",
                                        readonly: "",
                                      },
                                      model: {
                                        value: row.item.geo,
                                        callback: function ($$v) {
                                          _vm.$set(row.item, "geo", $$v)
                                        },
                                        expression: "row.item.geo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Revenue",
                                      "label-for": "add-geo-revenue",
                                      horizontal: "",
                                    },
                                  },
                                  [
                                    _c("CurrencyInput", {
                                      model: {
                                        value: row.item.amount,
                                        callback: function ($$v) {
                                          _vm.$set(row.item, "amount", $$v)
                                        },
                                        expression: "row.item.amount",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-row-reverse mb-4" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mx-1",
                                    attrs: { variant: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateGeoRevenue(row)
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", { attrs: { icon: "Save" } }),
                                    _vm._v(" Save "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                452775035
              ),
            }),
          ]
        : _vm._e(),
      _c("h5", { staticClass: "my-4" }, [_vm._v("Billing")]),
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _vm.isPublished && !_vm.invoicesSent
            ? _c(
                "b-form",
                { attrs: { inline: "" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "warning" },
                      on: { click: _vm.confirmSendInvoices },
                    },
                    [_vm._v(" Send Invoices ")]
                  ),
                  _c(
                    "b-form-group",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "ml-4",
                      attrs: {
                        label: "TEST",
                        "label-for": "invoices-to-self",
                        title:
                          "Send invoices to the email address of the currently logged in user instead of content partners.",
                        horizontal: "",
                        "label-cols": 2,
                      },
                    },
                    [
                      _c("b-form-checkbox", {
                        staticClass: "ml-2",
                        attrs: { id: "invoices-to-self", switch: "" },
                        model: {
                          value: _vm.invoicesToSelf,
                          callback: function ($$v) {
                            _vm.invoicesToSelf = $$v
                          },
                          expression: "invoicesToSelf",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.isPublished && _vm.invoicesSent
            ? [
                _c("b-badge", { staticClass: "ml-4" }, [
                  _vm._v(
                    "Invoices sent at " +
                      _vm._s(_vm.$helpers.dtFormatter(_vm.invoicesSentAt))
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "a",
        {
          ref: "excelDownload",
          staticStyle: { "margin-top": "8rem" },
          attrs: { href: _vm.excelExportHrefData, target: "_blank" },
        },
        [_vm._v("Download export")]
      ),
      _c("b-table", {
        staticClass: "mt-3",
        attrs: {
          items: _vm.billingItems,
          fields: _vm.billingFields,
          striped: "",
        },
        scopedSlots: _vm._u([
          {
            key: "cell(provider)",
            fn: function (data) {
              return [
                _c("span", { domProps: { innerHTML: _vm._s(data.value) } }),
              ]
            },
          },
        ]),
      }),
      _c("AddAdRevenue", {
        ref: "addAdRevenue",
        attrs: { year: _vm.selectedYear, month: _vm.selectedMonth },
      }),
      _c("AddGeoRevenue", {
        ref: "addGeoRevenue",
        attrs: { year: _vm.selectedYear, month: _vm.selectedMonth },
      }),
      _c("SimpleModal", {
        attrs: {
          value: !!_vm.removeRevenueParams,
          msg: "Are you sure you want to delete the selected revenue?",
        },
        on: {
          ok: function ($event) {
            return _vm.doRemoveRevenue(_vm.removeRevenueParams)
          },
          cancel: function ($event) {
            _vm.removeRevenueParams = null
          },
        },
      }),
      _c("SimpleModal", {
        attrs: { msg: _vm.sendInvoicesConfirmText },
        on: {
          ok: _vm.handleSendInvoices,
          cancel: function ($event) {
            _vm.sendInvoicesModalVisible = false
          },
        },
        model: {
          value: _vm.sendInvoicesModalVisible,
          callback: function ($$v) {
            _vm.sendInvoicesModalVisible = $$v
          },
          expression: "sendInvoicesModalVisible",
        },
      }),
      _c("iframe", {
        staticStyle: { display: "none" },
        attrs: { id: "download-frame" },
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "d-flex align-items-center justify-content-between",
        staticStyle: { "margin-bottom": "1.5rem" },
      },
      [_c("h5", [_vm._v("Premium Geo Revenue")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }