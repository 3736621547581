<template>
  <AutoComplete :auto-select-one-item="false" :input-attrs="{class: 'form-control'}" :value="item" :items="filteredItems" @update-items="updateItems" @item-clicked="updateItems" @blur="onBlur" @focus="onFocus" :min-len="0"></AutoComplete>
</template>

<script>
import AutoComplete from "v-autocomplete"

export default {
  name: 'SimpleAutoComplete',

  components: {
    AutoComplete
  },

  data() {
    return {
      item: "",
      filter: null,
      focused: false
    }
  },

  props: {
    value: String,
    items: {type: Array, default: () => []}
  },

  watch: {
    value(v) {
      if(!this.focused) {
        this.item = v
      }
    }
  },

  computed: {
    filteredItems() {
      if(this.filter) {
        return this.items.filter(item => {
          return (new RegExp(this.filter)).test(item.toLowerCase())
        })
      }
      else {
        return this.items
      }

    }
  },

  methods: {
    updateItems(text) {
      if(text && text.length > 0) {
       this.filter = text.trim().toLowerCase()
      }
      else {
        this.filter = null
      }

      this.$emit('input', text)
    },

    onBlur() {
      this.focused = false
    },

    onFocus() {
      this.focused = true
    }

  },

  created() {
    this.item = this.value
  }
}
</script>

<style lang="scss">
</style>