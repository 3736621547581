var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        title: "Transcode Assets",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: { ok: _vm.transcodeAssets, hidden: _vm.clear },
    },
    [
      _c("b-form", [
        _vm.format == "video"
          ? _c(
              "div",
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: "Profile",
                      "label-for": "profile-select",
                      horizontal: "",
                    },
                  },
                  [
                    _c("b-form-select", {
                      staticClass: "mb-3",
                      attrs: {
                        id: "profile-select",
                        options: _vm.videoProfileOptions,
                      },
                      model: {
                        value: _vm.profile,
                        callback: function ($$v) {
                          _vm.profile = $$v
                        },
                        expression: "profile",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm.format == "print"
          ? _c(
              "div",
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: "Profile",
                      "label-for": "profile-select",
                      horizontal: "",
                    },
                  },
                  [
                    _c("b-form-select", {
                      staticClass: "mb-3",
                      attrs: {
                        id: "profile-select",
                        options: _vm.printProfileOptions,
                      },
                      model: {
                        value: _vm.profile,
                        callback: function ($$v) {
                          _vm.profile = $$v
                        },
                        expression: "profile",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm.format == "image"
          ? _c(
              "div",
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: "Profile",
                      "label-for": "profile-select",
                      horizontal: "",
                    },
                  },
                  [
                    _c("b-form-select", {
                      staticClass: "mb-3",
                      attrs: {
                        id: "profile-select",
                        options: _vm.printProfileOptions,
                      },
                      model: {
                        value: _vm.profile,
                        callback: function ($$v) {
                          _vm.profile = $$v
                        },
                        expression: "profile",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm.format == "audio"
          ? _c(
              "div",
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: "Profile",
                      "label-for": "profile-select",
                      horizontal: "",
                    },
                  },
                  [
                    _c("b-form-select", {
                      staticClass: "mb-3",
                      attrs: {
                        id: "profile-select",
                        options: _vm.audioProfileOptions,
                      },
                      model: {
                        value: _vm.profile,
                        callback: function ($$v) {
                          _vm.profile = $$v
                        },
                        expression: "profile",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _c(
            "b-alert",
            {
              attrs: {
                show: _vm.alert.dismissCountdown,
                dismissible: "",
                variant: _vm.alert.variant,
              },
              on: {
                dismissed: function ($event) {
                  _vm.alert.dismissCountdown = 0
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.alert.msg) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }