<template>
  <b-modal  ref="modal"
            :size="size || 'xl'"
            :title="title"
            scrollable
            no-close-on-backdrop
            @shown="handleFocus"
            @ok="updateAndClose"
            @cancel="cancelAndClose">

    <div slot="modal-header"  style="width: 100%">
      <b-row>
        <b-col :cols="columnCount">
          <b-button-group>
            <b-input-group>
              <b-form-input ref="filterInput" v-model="filterInput" placeholder="Type to Search" />
              <b-input-group-append>
                <b-button variant="outline-light" :disabled="!filterInput" @click="filterInput = ''"><Icon icon="Search_backspace"/></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-button-group>
          <b-button v-if="showNewButton" class="ml-2" variant="link" @click="addNew">
            <font-awesome-icon icon="plus-circle"/>
            <span class="ml-2">New</span>
          </b-button>

        </b-col>

        <b-col>
          <div class="selected-items d-flex flex-row-reverse flex-wrap" style="width: 100%">
            <span class="item" v-for="v in valuesCopy" :key="v">
              <b-badge variant="primary" class="mx-1">
                {{ getValueText(v) }}
                <b-badge href="#" @click.stop="removeItem(v)">&#x2716;</b-badge>
              </b-badge>
            </span>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-row>
      <b-col v-for="c in itemColumnsView" :key="c.columnId">
        <b-list-group>
          <b-list-group-item v-for="item in c.items" :key="item.value" :active="item.selected" href="#" @click="selectItem(item)">
            {{ item.text }}
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>

  </b-modal>
</template>

<script>
export default {
  name: 'SelectBox',

  data: function() {
    return {
      columnCount: 4,
      valuesCopy: [],
      itemsCopy: [],
      filterInput: "",
    };
  },

  props: {
    title: String,
    size: String,
    values: Array,
    items: Array,
    allowNew: Boolean
  },

  watch: {
    items() {
      this.itemsCopy = this.$_.clone(this.items)
    }
  },

  computed: {
    showNewButton() {
      return this.allowNew &&
        this.filterInput &&
        this.filterInput.length > 0 &&
        this.filteredItems.findIndex(item => item.value == this.filterInput) == -1
    },

    filter() {
      if(this.filterInput) return new RegExp(this.filterInput, "gi");
      else return null;
    },

    filteredItems() {
      if(this.filter) {
        return this.itemsCopy.filter(item => {
          if(item.value && item.value.match(this.filter)) return true;
          else return false;
        })
      }
      else {
        return this.itemsCopy;
      }
    },

    itemColumnsView() {
      const itemList = this.filteredItems
      let itemColumns = []

      const c = this.columnCount
      const length = itemList.length
      const lengthC = (length % c) == 0 ? length / c : Math.floor(length / c) + 1

      for(let i = 0; i < c - 1; ++i) {
        itemColumns[i] = {
          columnId: i,
          items: this.$_.slice(itemList, i * lengthC, (i + 1) * lengthC)
        }
      }

      itemColumns[c - 1] = {
        columnId: c - 1,
        items: this.$_.slice(itemList, (c - 1) * lengthC, length)
      }

      return itemColumns;
    }
  },

  methods: {
    show() {
      this.valuesCopy = this.$_.clone(this.values)
      this.itemsCopy = this.$_.clone(this.items)
      this.$refs.modal.show()
    },

    selectItem(item) {
      item.selected = !item.selected

      if(item.selected) {
        this.valuesCopy.push(item.value)
      }
      else {
        const ndx = this.valuesCopy.indexOf(item.value)
        if(ndx >= 0) this.$delete(this.valuesCopy, ndx)
      }
    },

    removeItem(value) {
      const ndx = this.valuesCopy.indexOf(value)
      if(ndx >= 0) {
        this.$delete(this.valuesCopy, ndx)
        this.itemsCopy = this.itemsCopy.map(item => {
          if(item.value == value) item.selected = false
          return item
        })
      }
    },

    close() {
      this.itemColumns = [];
      if(this.itemsCopy && this.itemsCopy.length > 0) {
        this.itemsCopy = this.itemsCopy.map(i => {
          i.selected = false

          return i
        })
      }

      let state = this.$store.state.media.modals.labels
      state.visible = false
      this.$store.commit('media/updateModal', {labels: state})
    },

    cancelAndClose() {
      this.$emit('update')
      this.close()
    },

    updateAndClose() {
      this.close()
      this.$emit('update', this.valuesCopy)
    },

    handleFocus() {
      this.$refs.filterInput.focus()
    },

    getValueText(value) {
      for(var i = 0; i < this.itemsCopy.length; ++i) {
        if(this.itemsCopy[i].value == value) return this.itemsCopy[i].text
      }

      return ''
    },

    addNew() {
      this.itemsCopy.push({value: this.filterInput, text: this.filterInput, selected: true})
      this.valuesCopy.push(this.filterInput)
    }
  },

  created() {
    switch(this.size) {
      case "sm":
        this.columnCount = 1
        break

      case "md":
        this.columnCount = 2
        break

      case "lg":
        this.columnCount = 3
        break

      default:
        this.columnCount = 4
        break
    }
  }

}
</script>

<style scoped>
  .selected-items {
    margin-top: 6px;
    margin-left: -16px;
  }
</style>
