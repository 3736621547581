<template>
  <div class="my-4" style="margin-left: 30px">

    <EmbeddedMedia media-type="playlist_page" :value="itemPlaylists" :show-controls="true" :parent="item" @save="$emit('save', $event)"/>
  </div>
</template>

<script>
import EmbeddedMedia from "./EmbeddedMedia.vue"

export default {
  name: 'EmbeddedPlaylists',

  components: {
    EmbeddedMedia
  },


  props: {
    item: Object,
  },

  computed: {
    itemPlaylists() {
      const item = this.item
      return (item && this.$helpers.isArray(item.playlists)) ? item.playlists : []
    }
  }

}
</script>

<style scoped>
</style>
