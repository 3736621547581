var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    {
      staticClass:
        "bg-ott-gradient d-flex h-100 align-items-center flex-column justify-content-center",
      attrs: { fluid: "" },
    },
    [
      _c("b-img", {
        staticClass: "mb-4",
        attrs: { src: _vm.$config.logo, width: "350px" },
      }),
      _c(
        "b-jumbotron",
        {
          staticClass:
            "bg-dark d-flex align-items-center flex-column justify-content-center py-4 my-1",
        },
        [
          _c(
            "b-form",
            { attrs: { inline: "" } },
            [
              _c(
                "b-input-group",
                { staticClass: "mx-2", attrs: { size: "lg", prepend: "@" } },
                [
                  _c("b-input", {
                    ref: "usernameInput",
                    attrs: {
                      id: "login-username",
                      type: "email",
                      placeholder: "Username",
                    },
                    model: {
                      value: _vm.username,
                      callback: function ($$v) {
                        _vm.username = $$v
                      },
                      expression: "username",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-input-group",
                { staticClass: "mx-2", attrs: { size: "lg" } },
                [
                  _c(
                    "b-input-group-prepend",
                    { attrs: { "is-text": "" } },
                    [_c("font-awesome-icon", { attrs: { icon: "key" } })],
                    1
                  ),
                  _c("b-input", {
                    attrs: {
                      id: "login-password",
                      type: "password",
                      placeholder: "Password",
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleLogin.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                ],
                1
              ),
              _vm.$config.use2FA
                ? _c(
                    "b-input-group",
                    { staticClass: "mx-2", attrs: { size: "lg" } },
                    [
                      _c(
                        "b-input-group-prepend",
                        { attrs: { "is-text": "" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "user-secret" },
                          }),
                        ],
                        1
                      ),
                      _c("b-input", {
                        attrs: {
                          id: "login-token",
                          type: "text",
                          placeholder: "2-step verification token",
                        },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleLogin.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.token,
                          callback: function ($$v) {
                            _vm.token = $$v
                          },
                          expression: "token",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "my-4",
              attrs: { size: "lg", variant: "primary" },
              on: { click: _vm.handleLogin },
            },
            [
              _c("font-awesome-icon", { attrs: { icon: "sign-in-alt" } }),
              _c("span", [_vm._v(" Login")]),
            ],
            1
          ),
          _vm.$config.use2FA
            ? [
                _c(
                  "b-button",
                  { attrs: { id: "request-2fa-info", variant: "secondary" } },
                  [_vm._v("Request 2-step verification info")]
                ),
                _c(
                  "b-popover",
                  {
                    attrs: {
                      target: "request-2fa-info",
                      placement: "bottom",
                      show: _vm.showPopover,
                    },
                    on: {
                      "update:show": function ($event) {
                        _vm.showPopover = $event
                      },
                    },
                  },
                  [
                    _c(
                      "b-form",
                      { attrs: { inline: "" } },
                      [
                        _c(
                          "b-input-group",
                          {
                            staticClass: "mr-4",
                            attrs: { size: "lg", prepend: "@" },
                          },
                          [
                            _c("b-input", {
                              ref: "emailInput",
                              attrs: {
                                id: "login-email",
                                type: "email",
                                placeholder: "Username",
                              },
                              model: {
                                value: _vm.email,
                                callback: function ($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { size: "lg", variant: "primary" },
                            on: { click: _vm.handle2FARequest },
                          },
                          [_c("span", [_vm._v("Request")])]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }