<template>
  <div class="ml-auto py-2 pl-2 bg-dark">
    <div class="d-flex align-items-center justify-content-end">
      <b-button class="mr-2" id="add-button" variant="link" text="Add" @click="$root.$emit('promo-code-add')">
        <Icon icon="Add"/>
        <span class="ml-2">Add</span>
      </b-button>

      <b-button @click="$root.$emit('promo-code-mutate')" variant="link">
        <font-awesome-icon icon="fire"/>
        <span class="ml-2">Mutate</span>
      </b-button>

      <div class="mx-1" style="margin-top: 2px;">
        <Icon icon="Scheiding" :size="24"/>
      </div>

      <b-button class="mr-2" @click="$root.$emit('promo-code-activate')" variant="link">
        <font-awesome-icon icon="box-open"/>
        <span class="ml-2">Activate</span>
      </b-button>

      <b-button @click="$root.$emit('promo-code-deactivate')" variant="link">
        <Icon icon="Archive"/>
        <span class="ml-2">Deactivate</span>
      </b-button>

      <div class="mx-1" style="margin-top: 2px;">
        <Icon icon="Scheiding" :size="24"/>
      </div>

      <b-button-group>
        <b-form-select class="mr-2" style="max-width: 250px" :value="selectedPartner" @input="updatePartner($event)" :options="partnerOptions"></b-form-select>        
        <b-form-select class="mr-2" style="max-width: 150px" :value="selectedStatus" @input="updateStatus($event)" :options="statusOptions"></b-form-select>        
        <b-form-select style="max-width: 180px" :value="selectedSortOrder" @input="updateSortOrder($event)" :options="sortOrderOptions"></b-form-select>        
      </b-button-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromoCodeToolbar',

  data() {
    return {
      selectedPartner: "__all__",
      partnerOptions: [],
      selectedStatus: "active",
      statusOptions: [],
      selectedSortOrder: "date-asc",
      sortOrderOptions: [
        {value: "code-asc", text: "Promo code ascending"},
        {value: "code-desc", text: "Promo code descending"},
        {value: "partner-asc", text: "Partner ascending"},
        {value: "partner-desc", text: "Partner descending"},
        {value: "date-asc", text: "Date ascending"},
        {value: "date-desc", text: "Date descending"}
      ]
    }
  },

  methods: {
    getPartnerOptions() {
      fetch("/admin/api/v2/partners/select-options?include-all")
      .then(this.$helpers.JSONHandler)
      .then(resp => {
        this.partnerOptions = resp.data
      });
    },

    getStatusOptions() {
      fetch("/admin/api/v2/promo-codes/status-options")
      .then(this.$helpers.JSONHandler)
      .then(resp => {
        this.statusOptions = resp.data
        this.statusOptions.splice(0, 0, {value: '*', text: 'Any Status'})
      });
    },

    updatePartner(partner) {
      this.selectedPartner = partner
      this.$store.dispatch('media/setPartner', partner)
      this.$root.$emit('promo-code-partner-filter', partner);
    },

    updateSortOrder(sortOrder) {
      this.$root.$emit('promo-code-sort-order', sortOrder);
    },

    updateStatus(status) {
      this.$root.$emit('promo-code-status-filter', status);
    }
  },

  mounted() {
    this.selectedPartner = this.$store.state.media.partnerFilter
    this.$root.$emit('promo-code-partner', this.selectedPartner);
  },

  created() {
    this.getPartnerOptions()
    this.getStatusOptions()
  }
}
</script>
