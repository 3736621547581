<template>
  <div>
    <template v-if="uploadState == 'ready' && modalState == 'closed'">
      <b-button v-b-tooltip.hover :title="`Upload new ${format}`" class="float-right" variant="link" @click="show">
        <Icon icon="Add"/>
        <span v-if="buttonText" class="ml-2">{{buttonText}}</span>
      </b-button>
    </template>
    <template v-else-if="uploadState == 'uploading' && modalState == 'closed'">
      <b-progress :value="progressValue" :max="progressMax" show-progress animated style="width: 200px" height="40px"></b-progress>
    </template>

    <b-modal ref="modal" :title="modalTitle" hide-footer @ok="close" no-close-on-esc no-close-on-backdrop>
      <b-form>
          <b-form-group label="Select file" label-for="select-file" class="overflow-hidden">
            <S3Uploader
              ref="uploader"
              :itemId="itemId"
              :name="name"
              :format="format"
              :multiple="multiple"
              :nameAsPath="nameAsPath"
              @object-name="objectName = $event"
              @progress="handleProgress"
              @upload-state="handleUploadState"
              @finished="handleFinished"
              @aws-conf-received="handleAwsConfReceived"
              @file-change="handleFileChange"
              @start-upload-request="handleStartUploadRequest"
              @file-count="handleFileCount"
            />
          </b-form-group>

          <div class="my-2">
            <slot></slot>
          </div>

          <template v-if="!hideObjectName && !multiple">
            <b-form-group label="S3 Object Name">
              <b-form-input readonly :value="objectName"></b-form-input>
            </b-form-group>
          </template>

          <b-button v-if="uploadState=='ready'" @click="startUpload" :disabled="(fileCount == 0) || uploadState != 'ready'" variant="primary">Upload</b-button>
          <b-progress v-else :value="progressValue" :max="progressMax" show-progress animated></b-progress>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import S3Uploader from "./S3Uploader.vue"

export default {
  name: 'S3UploadModal',

  components: {
    S3Uploader
  },

  data: function() {
    return {
      uploadState: "ready",
      modalState: "closed",
      progressMax: 1,
      progressValue: 0,
      fileCount: 0,
      objectName: ""
    };
  },

  props: {
    itemId: String,
    name: String,
    buttonText: String,
    modalTitle: String,
    format: String,
    hideObjectName: Boolean,
    multiple: Boolean,
    nameAsPath: Boolean
  },

  methods: {
    startUpload() {
      this.$refs.uploader.startUpload()
    },

    show() {
      this.$emit('show')
      this.$refs.modal.show()
      // This hack is needed to ensure S3Uploader is available, since it gets created after the this.$refs.modal.show() call
      setTimeout(() => {
        this.$refs.uploader.show()
      }, 500)
    },

    close() {

    },

    handleProgress({max, value}) {
      this.progressMax = max
      this.progressValue = value
    },

    handleUploadState(state) {
      this.uploadState = state
    },

    handleFinished() {
      this.$emit('finished')
      this.$refs.modal.hide()
    },

    handleAwsConfReceived(conf) {
      this.$emit('aws-conf-received', conf)
    },

    handleFileChange(val) {
      this.$emit('file-change', val)
    },

    handleStartUploadRequest(obj) {
      this.$emit('start-upload-request', obj)
    },

    handleFileCount(count) {
      this.fileCount = count
    }
  }
}
</script>

<style>
</style>
