<template>
  <div :id="id" class="d-flex justify-content-between align-items-center h-100">
    <div>
      <span v-for="item in listSubscriptions" :key="item" class="mr-2">
        <b-badge variant="primary">
          {{ item }}
          <b-badge href="#" @click.stop="removeItem(item)">&#x2716;</b-badge>
        </b-badge>          
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserListSubscriptions',

  data: function() {
    return {
      listSubscriptions: []
    };
  },

  watch: {
    value(v) {
      if(v != this.listSubscriptions) {
        this.listSubscriptions = v
      }
    }
  },

  props: {
    value: Array,
    user: Object
  },

  methods: {
    removeItem(item) {
      fetch(`/admin/api/v2/users/${this.user.id}/list-subscriptions/${item}`, {method: 'DELETE'})
      .then(resp => {
        if(resp.status == 200) {
          this.listSubscriptions = this.listSubscriptions.filter(s => s != item)
          this.$emit('update', this.listSubscriptions)
          this.$root.app.showAlert(`User unsubscribed from '${item}' list.`, "success")
        }
        else {
          this.$root.app.showAlert(`An error occured while unsubscribing user from '${item}' list.`, "danger")
        }
      })
    }
  },

  created() {
    this.listSubscriptions = this.value
  }
}
</script>

<style scoped>
</style>
