<template>
  <div>
    <b-table :items="items" :fields="fields" :filter="filter" :filter-function="filterItem" ref="table" striped>
      <template v-slot:cell(actions)="row">
        <!-- we use @click.stop here to prevent emitting of a 'row-clicked' event  -->
        <div class="d-flex justify-content-center">
          <b-button :id="'actions-' + row.item.id" @click.stop="row.item._actionsVisible = !row.item._actionsVisible" size="sm">
            &#x2699;
          </b-button>
        </div>
        <b-popover v-if="row.item._actionsVisible" :target="'actions-' + row.item.id" :show.sync="row.item._actionsVisible" placement="left">
          <b-button-group>
            <b-button @click.stop="archiveItem = row.item" variant="danger">
              <font-awesome-icon icon="trash-alt"/>
              Remove
            </b-button>
            <b-button @click.stop="editItem(row.item)" variant="primary">
              <Icon icon="edit"/>
              Edit
            </b-button>
          </b-button-group>
        </b-popover>
      </template>
      <template v-slot:row-details="row">
        <b-card>
          <div class="d-flex flex-row-reverse mb-4">
            <b-button :id="'save-button-' + row.item.id" class="mx-1" @click="saveEdit(row.item.id)" variant="primary">
              <Icon icon="Save"/>
              Save
            </b-button>
            <b-button class="mx-1" :id="'close-button-' + row.item.id" variant="primary" @click.stop="closeEditor(row)">
              <font-awesome-icon icon="times"/>
              Close
            </b-button>
          </div>

          <form autocomplete="off">
            <b-form-group label="ID" label-for="id-input" horizontal>
              <b-input id="id-input" :value="editableItems[row.item.id].id" readonly></b-input>
            </b-form-group>
            <b-form-group label="Exernal ID" label-for="external-id-input" horizontal>
              <b-input id="external-id-input" :value="editableItems[row.item.id].external_id" readonly></b-input>
            </b-form-group>
            <b-form-group label="Enable Affiliates" label-for="can-view-affiliates-checkbox" horizontal>
              <b-form-checkbox id="can-view-affiliates-checkbox" v-model="editableItems[row.item.id].can_view_affiliates" v-b-tooltip.hover title="Note that disabling affiliates for a partner stops all running recurring affiliate compensations." switch />
            </b-form-group>
            <b-form-group label="Revshare TOA Default" label-for="no-rev-share-checkbox" horizontal>
              <b-form-checkbox id="no-rev-share-checkbox" v-model="editableItems[row.item.id].no_rev_share" switch />
            </b-form-group>
            <b-form-group v-if="$config.usePremiumValue" label="Default Premium Value" label-for="default-premium-value-select" horizontal>
              <b-form-select id="default-premium-value-select" :value="getDefaultPremiumValue(row.item.id)" @change="setDefaultPremiumValue(row.item.id, $event)" :options="$store.state.media.options.premiumValue"/>
            </b-form-group>
            <b-form-group label="Name" label-for="name-input" horizontal>
              <b-form-input id="name-input" v-model="editableItems[row.item.id].name" ></b-form-input>
            </b-form-group>
            <b-form-group label="Contact Person" label-for="contact-person-input" horizontal>
              <b-form-input id="contact-person-input" v-model="editableItems[row.item.id].contact_person" ></b-form-input>
            </b-form-group>
            <b-form-group label="Email" label-for="email-input" horizontal>
              <b-form-input id="email-input" v-model="editableItems[row.item.id].email" ></b-form-input>
            </b-form-group>
            <b-form-group label="Telephone" label-for="telephone-input" horizontal>
              <b-form-input id="telephone-input" v-model="editableItems[row.item.id].telephone" ></b-form-input>
            </b-form-group>
            <b-form-group label="Notes" label-for="notes-input" horizontal :label-cols="2">
              <b-form-textarea id="notes-input" v-model="editableItems[row.item.id].notes" ></b-form-textarea>
            </b-form-group>

            <b-form-group v-if="$config.allowPartnerContractUpload" label="Contract" label-for="contract-input" horizontal>
              <b-input-group>
                <b-form-input id="contract-input" readonly v-model="editableItems[row.item.id].contract_url" ></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!editableItems[row.item.id].contract_url" :href="contractDownloadUrl(row.item.id)" target="_blank" v-b-tooltip.hover title="Download contract">
                    <Icon icon="export"/>
                  </b-button>
                  <b-button @click="editContract = !editContract" v-b-tooltip.hover title="Upload new contract">
                    <Icon icon="edit"/>
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <div v-if="editContract" class="mt-2 d-flex">
                <div class="example-simple">
                    <div class="upload">
                      <div class="example-btn">
                        <file-upload
                          class="btn btn-primary"
                          :post-action="`/admin/upload/partner-contract/${editableItems[row.item.id].id}`"
                          extensions="pdf"
                          accept="application/pdf"
                          :multiple="false"
                          :size="1024 * 1024 * 25"
                          v-model="files"
                          @input-filter="inputFilter"
                          @input-file="inputFile"
                          ref="upload">
                          <Icon icon="Add"/>
                          Select file
                        </file-upload>
                        <button type="button" class="btn btn-success ml-2" v-if="!$refs.upload || !$refs.upload.active" @click.prevent="$refs.upload.active = true">
                          <font-awesome-icon icon="arrow-up"/>
                          Start Upload
                        </button>
                        <button type="button" class="btn btn-danger mr-2"  v-else @click.prevent="$refs.upload.active = false">
                          <font-awesome-icon icon="stop"/>
                          Stop Upload
                        </button>

                        <span class="ml-2">{{file.name}}</span> -
                        <span>{{file.size | formatSize}}</span> -
                        <span v-if="file.error">{{file.error}}</span>
                        <span v-else-if="file.success">success</span>
                        <span v-else-if="file.active">active</span>
                        <span v-else></span>
                        <div v-if="file.error">
                          <b-alert variant="danger" show>
                            <ul>
                              <li v-for="error in file.response" :key="error">{{ error }}</li>
                            </ul>
                          </b-alert>
                        </div>

                      </div>
                    </div>
                </div>
              </div>
            </b-form-group>
          </form>
        </b-card>
      </template>
    </b-table>
    <AddPartner ref="addPartnerModal"/>

    <SimpleModal :value='archiveItem != null' @ok="removeItem(archiveItem)" @cancel="archiveItem = null" msg="Are you sure you want to archive this item? This action can not be reversed from the CMS and will lock all associated users."/>
    <SimpleModal :value='showAffiliatesDisabledWarning' @cancel="enableAffiliates" msg="Are you sure you want to disable affiliates for this partner? Disabling affiliates stops all running recurring affiliate compensations."/>
  </div>
</template>

<script>
import AddPartner from "../components/AddPartner.vue"
import SimpleModal from "../components/SimpleModal.vue"
import FileUpload from 'vue-upload-component'


export default {
  components: {
    FileUpload,
    AddPartner,
    SimpleModal
  },

  data() {
    return {
      items: [],
      fields: [
        {key: "name", sortable: true},
        {key: "contact_person", sortable: true},
        {key: "email", sortable: true},
        {key: "telephone", sortable: true},
        {key: "can_view_affiliates", label: "Affiliates Enabled", sortable: true, formatter: this.$helpers.booleanFormatter},
        "actions"
      ],
      editableItems: [],
      archiveItem: null,
      filter: null,
      editContract: false,
      files: [],
      didUpload: false,
      prevAffiliatesEnabled: null,
      showAffiliatesDisabledWarning: false
    }
  },

  computed: {

    file() {
      return this.files && this.files.length > 0 ? this.files[0] : {name: '', size: 0}
    }
  },

  watch: {
    files(val) {
      if(!this.didUpload && val.length > 0 && val[0].success) {
        this.didUpload = true
        this.editableItems.forEach(item => this.load(item.id))
      }
      else {
        this.didUpload = false
      }
    },

    affiliatesEnabled(val) {
      if(this.prevAffiliatesEnabled == true && val == false) {
        this.showAffiliatesDisabledWarning = true
      }

      this.prevAffiliatesEnabled = val
    }
  },



  methods: {
    load(keepOpenId) {
      fetch('/admin/api/v2/partners')
      .then(function(resp) {
        return resp.json();
      })
      .then(resp => {
        this.items = this.processItems(resp.data, keepOpenId);
      });
    },

    contractDownloadUrl(id) {
      return `/admin/api/v2/partners/${id}/contract`
    },

    getDefaultPremiumValue(id) {
      return this.editableItems[id].default_premium_value
    },

    setDefaultPremiumValue(id, value) {
      this.editableItems[id].default_premium_value = parseInt(value)
    },

    affiliatesEnabled(id) {
      return this.editableItems[id] ? this.editableItems[id].can_view_affiliates : null
    },
    
    processItems(items, keepOpenId) {
      return items.map((item) => {
        item["_actionsVisible"] = false
        item["_showDetails"] = item.id == keepOpenId

        if(item.id == keepOpenId) {
          this.editableItems[item.id] = item
        }

        return item
      })
    },

    addItem() {
      this.$refs.addPartnerModal.show()
    },

    editItem(item) {
      item._actionsVisible = false
      this.editableItems[item.id] = this.$_.clone(item)

      item._showDetails = true
    },

    removeItem() {
      fetch(`admin/api/v2/partners/${this.archiveItem.id}`, {method: 'DELETE'})
      .then(this.$helpers.JSONHandler)
      .then(json => {
        this.load()
        this.archiveItem._actionsVisible = false
        this.archiveItem = null

        switch(json.data) {
          case "ok":
            this.$root.app.showAlert("Remove successful", "success")
            break

          default:
            this.$root.app.showErrorDialog("Unknown error encountered while removing Content Provider", "Error")
        }
      })
    },

    filterItem(item) {
      this.$log.info("filterItem: " + item.name)
      if(!this.filter) return true

      if(item.name && item.name.match(this.filter)) return true
      if(item.contact_person && item.contact_person.match(this.filter)) return true

      return false
    },

    setFilter(filter) {
      this.$log.info("setFilter: " + filter)
      if(filter) this.filter = new RegExp(filter, "gi")
      else this.filter = null
    },

    saveEdit(id) {
      this.items = this.items.map(item => {
        if(item.id == id) {
          this.editableItems[id]["_actionsVisible"] = false
          this.editableItems[id]["_showDetails"] = true

          return this.editableItems[id]
        }
        else return item
      })

      this.$http.postJSON(
        `/admin/api/v2/partners`,
        {data: this.editableItems[id]},
        () => {
          this.$root.app.showAlert("Save successful", "success")
        },
        (status, html) => {
          this.$root.app.showErrorDialog(html, status.toString())
        }
      )
    },

    closeEditor(row) {
      row.item._showDetails = false
      this.editableItems[row.item.id] = null
    },

    enableAffiliates() {
      Object.keys(this.editableItems).map(id => {
        this.editableItems[id].can_view_affiliates = true
        this.showAffiliatesDisabledWarning = false
      })
    },

    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },

    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
      }
      if (newFile && oldFile) {
        // update
      }

      if (!newFile && oldFile) {
        // remove
      }
    },


  },

  mounted() {
    this.$root.app.setActivePageTitle("Partners", false)
    this.$root.$emit('set-toolbar', 'user-toolbar', 'partner')

    this.load()

    this.$root.$on('user-add', this.addItem)
    this.$root.$on('user-filter', this.setFilter)
    this.$root.$on('user-refresh', this.load)

  },

  beforeDestroy() {
    this.$root.$off('user-add', this.addItem)
    this.$root.$off('user-filter', this.setFilter)
    this.$root.$off('user-refresh', this.load)
  }

};
</script>

