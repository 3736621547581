var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "manage-media-properties" },
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            visible: _vm.visible,
            title: `Manage media item properties (${_vm.count})`,
            "no-close-on-backdrop": "",
            "auto-focus-button": "ok",
          },
          on: { ok: _vm.handleUpdate, hidden: _vm.clear },
        },
        [
          _c(
            "div",
            { staticClass: "form-content" },
            [
              _c(
                "div",
                { staticClass: "info mb-4 py-2 px-2" },
                [
                  _c("span", { staticClass: "font-weight-bold text-warning" }, [
                    _vm._v("Info"),
                  ]),
                  _c("b-form-checkbox", {
                    staticClass: "ml-4",
                    attrs: { disabled: "", inline: "", checked: true },
                  }),
                  _vm._v(" "),
                  _c("span", { staticStyle: { "margin-left": "-1rem" } }, [
                    _vm._v("Enable"),
                  ]),
                  _c("b-form-checkbox", {
                    staticClass: "ml-4",
                    attrs: { disabled: "", inline: "", checked: false },
                  }),
                  _vm._v(" "),
                  _c("span", { staticStyle: { "margin-left": "-1rem" } }, [
                    _vm._v("Disable"),
                  ]),
                  _c("b-form-checkbox", {
                    staticClass: "ml-4",
                    attrs: {
                      disabled: "",
                      inline: "",
                      checked: false,
                      indeterminate: true,
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticStyle: { "margin-left": "-1rem" } }, [
                    _vm._v("Do not change"),
                  ]),
                ],
                1
              ),
              _c(
                "b-form",
                { attrs: { id: "manage-media-properties-form" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Shopping",
                        "label-for": "mp-enable-shopping",
                        "label-cols": "6",
                      },
                    },
                    [
                      _c("b-form-checkbox", {
                        attrs: {
                          id: "mp-enable-shopping",
                          indeterminate: _vm.shopping_enabled_im,
                        },
                        on: {
                          "update:indeterminate": function ($event) {
                            _vm.shopping_enabled_im = $event
                          },
                        },
                        model: {
                          value: _vm.shopping_enabled,
                          callback: function ($$v) {
                            _vm.shopping_enabled = $$v
                          },
                          expression: "shopping_enabled",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Kids Content",
                        "label-for": "mp-enable-is-kids-content",
                        "label-cols": "6",
                      },
                    },
                    [
                      _c("b-form-checkbox", {
                        attrs: {
                          id: "mp-enable-is-kids-content",
                          indeterminate: _vm.is_kids_content_im,
                        },
                        on: {
                          "update:indeterminate": function ($event) {
                            _vm.is_kids_content_im = $event
                          },
                        },
                        model: {
                          value: _vm.is_kids_content,
                          callback: function ($$v) {
                            _vm.is_kids_content = $$v
                          },
                          expression: "is_kids_content",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Quality Stamp",
                        "label-for": "mp-enable-quality-stamp",
                        "label-cols": "6",
                      },
                    },
                    [
                      _c("b-form-checkbox", {
                        attrs: {
                          id: "mp-enable-quality-stamp",
                          indeterminate: _vm.quality_stamp_im,
                        },
                        on: {
                          "update:indeterminate": function ($event) {
                            _vm.quality_stamp_im = $event
                          },
                        },
                        model: {
                          value: _vm.quality_stamp,
                          callback: function ($$v) {
                            _vm.quality_stamp = $$v
                          },
                          expression: "quality_stamp",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Call To Action",
                        "label-for": "mp-enable-call-to-action",
                        "label-cols": "6",
                      },
                    },
                    [
                      _c("b-form-checkbox", {
                        attrs: {
                          id: "mp-enable-call-to-action",
                          indeterminate: _vm.call_to_action_enabled_im,
                        },
                        on: {
                          "update:indeterminate": function ($event) {
                            _vm.call_to_action_enabled_im = $event
                          },
                        },
                        model: {
                          value: _vm.call_to_action_enabled,
                          callback: function ($$v) {
                            _vm.call_to_action_enabled = $$v
                          },
                          expression: "call_to_action_enabled",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Revshare TOA",
                        "label-for": "mp-enable-no-rev-share",
                        "label-cols": "6",
                      },
                    },
                    [
                      _c("b-form-checkbox", {
                        attrs: {
                          id: "mp-enable-no-rev-share",
                          indeterminate: _vm.no_rev_share_im,
                        },
                        on: {
                          "update:indeterminate": function ($event) {
                            _vm.no_rev_share_im = $event
                          },
                        },
                        model: {
                          value: _vm.no_rev_share,
                          callback: function ($$v) {
                            _vm.no_rev_share = $$v
                          },
                          expression: "no_rev_share",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Exclude From Recently Added",
                        "label-for": "mp-enable-recently-added-exclude",
                        "label-cols": "6",
                      },
                    },
                    [
                      _c("b-form-checkbox", {
                        attrs: {
                          id: "mp-enable-recently-added-exclude",
                          indeterminate: _vm.recently_added_exclude_im,
                        },
                        on: {
                          "update:indeterminate": function ($event) {
                            _vm.recently_added_exclude_im = $event
                          },
                        },
                        model: {
                          value: _vm.recently_added_exclude,
                          callback: function ($$v) {
                            _vm.recently_added_exclude = $$v
                          },
                          expression: "recently_added_exclude",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Show Slide",
                        "label-for": "mp-enable-slide",
                        "label-cols": "6",
                      },
                    },
                    [
                      _c("b-form-checkbox", {
                        attrs: {
                          id: "mp-enable-slide",
                          indeterminate: _vm.slide_enabled_im,
                        },
                        on: {
                          "update:indeterminate": function ($event) {
                            _vm.slide_enabled_im = $event
                          },
                        },
                        model: {
                          value: _vm.slide_enabled,
                          callback: function ($$v) {
                            _vm.slide_enabled = $$v
                          },
                          expression: "slide_enabled",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Show Cover",
                        "label-for": "mp-enable-cover",
                        "label-cols": "6",
                      },
                    },
                    [
                      _c("b-form-checkbox", {
                        attrs: {
                          id: "mp-enable-cover",
                          indeterminate: _vm.cover_enabled_im,
                        },
                        on: {
                          "update:indeterminate": function ($event) {
                            _vm.cover_enabled_im = $event
                          },
                        },
                        model: {
                          value: _vm.cover_enabled,
                          callback: function ($$v) {
                            _vm.cover_enabled = $$v
                          },
                          expression: "cover_enabled",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Show Meta Text",
                        "label-for": "mp-show-meta-text",
                        "label-cols": "6",
                      },
                    },
                    [
                      _c("b-form-checkbox", {
                        attrs: {
                          id: "mp-show-meta-text",
                          indeterminate: _vm.show_meta_text_im,
                        },
                        on: {
                          "update:indeterminate": function ($event) {
                            _vm.show_meta_text_im = $event
                          },
                        },
                        model: {
                          value: _vm.show_meta_text,
                          callback: function ($$v) {
                            _vm.show_meta_text = $$v
                          },
                          expression: "show_meta_text",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Plan",
                        "label-for": "mp-plan-select",
                        horizontal: "",
                        "label-cols": "6",
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "mp-plan-select",
                          options: _vm.planOptions,
                        },
                        model: {
                          value: _vm.Plan,
                          callback: function ($$v) {
                            _vm.Plan = $$v
                          },
                          expression: "Plan",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.Plan != "free"
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Premium Value",
                            "label-for": "mp-premium-value-select",
                            horizontal: "",
                            "label-cols": "6",
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "mp-premium-value-select",
                              options: _vm.premiumValueOptions,
                            },
                            model: {
                              value: _vm.PremiumValue,
                              callback: function ($$v) {
                                _vm.PremiumValue = $$v
                              },
                              expression: "PremiumValue",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }