var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SelectBox", {
    ref: "selectBox",
    attrs: {
      title: "Edit Labels",
      size: "xl",
      values: _vm.allowManage ? _vm.managedLabels : _vm.labelsCopy,
      items: _vm.labelSelectList,
      "allow-new": _vm.allowNew,
    },
    on: {
      update: function ($event) {
        return _vm.handleUpdate($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }