var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("DdexAssetModal", {
        attrs: { value: _vm.infoModalVisible, data: _vm.infoModalData },
        on: {
          close: function ($event) {
            _vm.infoModalVisible = false
          },
        },
      }),
      _c(
        "b-container",
        { staticClass: "mt-2", attrs: { fluid: "" } },
        [
          _c("b-table", {
            ref: "table",
            attrs: {
              selectable: "",
              selectedVariant: "secondary",
              "select-mode": "multi",
              items: _vm.items,
              fields: _vm.fields,
              filter: _vm.filter,
              "per-page": _vm.perPage,
              "current-page": _vm.currentPage,
            },
            on: { "row-selected": _vm.rowSelected },
            scopedSlots: _vm._u([
              {
                key: "cell(info)",
                fn: function (data) {
                  return [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "info", size: "sm" },
                        on: {
                          click: function ($event) {
                            return _vm.showInfo(data.item)
                          },
                        },
                      },
                      [_vm._v("Info")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-pagination", {
            attrs: {
              "total-rows": _vm.totalRows,
              "per-page": _vm.perPage,
              "aria-controls": "my-table",
            },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
          _c("SimpleModal", {
            attrs: {
              value: _vm.deleteModalVisible,
              msg: "Are you sure you want to delete the selected DDEX message?",
            },
            on: {
              ok: _vm.doRemove,
              cancel: function ($event) {
                _vm.deleteModalVisible = false
              },
            },
          }),
          _c("SimpleModal", {
            attrs: {
              value: _vm.flagModalVisible,
              msg: "Are you sure you want to flag the selected DDEX message?",
            },
            on: {
              ok: _vm.doFlag,
              cancel: function ($event) {
                _vm.flagModalVisible = false
              },
            },
          }),
          _c("SimpleModal", {
            attrs: {
              value: _vm.unflagModalVisible,
              msg: "Are you sure you want to unflag the selected DDEX message?",
            },
            on: {
              ok: _vm.doUnflag,
              cancel: function ($event) {
                _vm.unflagModalVisible = false
              },
            },
          }),
        ],
        1
      ),
      _vm.isLoading
        ? _c("div", { staticClass: "overlay" }, [
            _c(
              "div",
              [
                _c("b-progress", {
                  staticStyle: {
                    width: "80%",
                    height: "30px",
                    "margin-bottom": "1rem",
                  },
                  attrs: { value: 100, max: 100, animated: "" },
                }),
                _c("div", { staticClass: "text-light" }, [
                  _vm._v("Processing, please wait..."),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }