<template>
  <div style="margin-top: 2rem">

    <div>
      <a ref="aprDownload" :href="aprHrefData" download="providers-aggregated.xlsx" target="_blank">Download Aggregated Partner Report</a>
    </div>
    <div>
      <a ref="dprDownload" :href="dprHrefData" download="providers-detailed.xlsx" target="_blank">Download Detailed Partner Report</a>
    </div>
    <div>
      <a ref="crDownload" :href="crHrefData" download="countries.xlsx" target="_blank">Download Country Report</a>
    </div>
    <div class="mb-4">
      <a ref="prDownload" :href="prHrefData" download="providers-totals.xlsx" target="_blank">Download Partners Totals Report</a>
    </div>

    <div class="d-flex">
      <h5>
        <b-badge class="mr-4" :variant="isPublished ? 'primary' : 'danger'">
            <span>{{ isPublished ? 'Published' : 'Unpublished' }}</span>
          </b-badge>

        <span>TOA Total: </span>
        <b-badge>€ {{$helpers.currencyFormatter(noRevShareTotalAmount)}}</b-badge>
      </h5>
      <h5 v-for="(share, tier) in $store.state.finance.totalLengthShares" :key="tier" class="ml-4">
        <span>{{ $helpers.prettify(tier) }}: </span>
        <b-badge>{{$helpers.percentageFormatter(share)}}%</b-badge>
      </h5>
    </div>


    <b-row class="mr-0 mt-4">
      <b-col>
        <h5 style="margin-bottom: 2rem; height: 2rem;">Partners</h5>

        <b-table class="mt-3" :items="providerItems" :fields="providerFields" ref="table" striped>
        </b-table>

      </b-col>
      <b-col>
      </b-col>
    </b-row>


    <h5 style="margin-top: 4rem">Data</h5>
    <b-table class="mt-3" :items="contentItems" :fields="contentFields" ref="table"  @row-clicked="handleSelectedRow" striped hover small :per-page="perPage" :current-page="currentPage"/>

    <div v-if="perPage" class="d-flex justify-content-center">
      <b-pagination :total-rows="contentItems.length" :per-page="perPage" v-model="currentPage"/>
    </div>

    <RevShareContentDetailsModal ref="contentDetailsModal"/>
  </div>
</template>

<script>
import RevShareContentDetailsModal from './RevShareContentDetailsModal.vue'

/*global ExcelBuilder */

export default {
  name: 'RevShareContentOverview',

  components: {
    RevShareContentDetailsModal
  },

  data: function() {
    let providerFields = [];

    if(this.$config.enableAds) {
      providerFields = [
         {key: 'provider', label: 'Partner', sortable: true},
         {key: 'points.premium', label: 'Premium Points', sortable: true, formatter: this.$helpers.premiumPointsFormatter, tdClass: "text-right"},
         {key: 'points.ad_supported', label: 'Ad Supported Points', sortable: true, formatter: this.$helpers.premiumPointsFormatter, tdClass: "text-right"},
         {key: 'billable_amount', label: 'Billable Amount (€)', sortable: true, formatter: this.$helpers.fractionalCurrencyFormatter, tdClass: "text-right"},
         {key: 'no_rev_share_amount', label: 'TOA Amount (€)', sortable: true, formatter: this.$helpers.fractionalCurrencyFormatter, tdClass: "text-right"},
      ]
    }
    else {
      providerFields = [
         {key: 'provider', label: 'Partner', sortable: true},
         {key: 'points.premium', label: 'Points', sortable: true, formatter: this.$helpers.premiumPointsFormatter, tdClass: "text-right"},
         {key: 'billable_amount', label: 'Billable Amount (€)', sortable: true, formatter: this.$helpers.fractionalCurrencyFormatter, tdClass: "text-right"},
         {key: 'no_rev_share_amount', label: 'TOA Amount (€)', sortable: true, formatter: this.$helpers.fractionalCurrencyFormatter, tdClass: "text-right"},
      ]
    }
    
        
    return {
      providerFields: providerFields,

      contentFields: [
         {key: 'title', label: 'Title', sortable: true},
         {key: 'series', label: 'Series', sortable: true},
         {key: 'season', label: 'Season', sortable: true},
         {key: 'upc', label: 'UPC', sortable: true},
         {key: 'isrc', label: 'ISRC', formatter: this.$helpers.arrayFormatter, sortable: true},
         {key: 'reporting_code', label: 'Reporting Code', sortable: true},
         {key: 'provider', label: 'Partner', sortable: true},
         {key: 'media_type', label: 'Type', sortable: true, formatter: this.$helpers.prettify},
         {key: 'no_rev_share', label: 'TOA', formatter: this.$helpers.booleanFormatter, sortable: true},
         {key: 'tier', label: 'Tier', sortable: true, formatter: this.$helpers.prettify},
         {key: 'total_count', label: 'Views', sortable: true, tdClass: "text-right"},
         {key: 'total_length', label: 'Duration', sortable: true, formatter: this.$helpers.durationToString, tdClass: "text-right"},
         {key: 'total_points', label: 'Points', sortable: true, tdClass: "text-right", formatter: this.$helpers.premiumPointsFormatter},
         {key: 'total_cost', label: 'Cost', sortable: true, tdClass: "text-right"},
         {key: 'total_amount', label: 'Amount (€)', sortable: true, formatter: this.$helpers.fractionalCurrencyFormatter, tdClass: "text-right"}
      ],




      aprExcel: null,
      dprExcel: null,
      crExcel: null,
      prExcel: null,


      currentPage: 1,
      perPage: 20

    };
  },

  computed: {
    items() {
      return []
    },

    aprHrefData() {
      return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + this.aprExcel
    },

    dprHrefData() {
      return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + this.dprExcel
    },

    crHrefData() {
      return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + this.crExcel
    },

    prHrefData() {
      return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + this.prExcel
    },

    contentItems() {
      return this.$store.state.finance.data
    },

    providerItems() {
      return this.$store.state.finance.providerItems
    },

    noRevShareTotalAmount() {
      return this.$store.state.finance.noRevShareTotalAmount
    },

    isPublished() {
      return this.$store.state.finance.isPublished
    }
  },

  watch: {
    contentItems(v) {
      this.buildExcelExport(v)
    },

    providerItems(v) {
      this.buildProviderTotalsExcelExport(v)
    }
  },

  methods: {
    handleSelectedRow(row) {
      let items = this.$_.values(row.geos)
      this.$refs.contentDetailsModal.show(row.media_id, row.title, items)
    },

    buildProviderTotalsExcelExport(data) {
      let workbook = ExcelBuilder.createWorkbook();
      let sheet = workbook.createWorksheet();
      let stylesheet = workbook.getStyleSheet();
      let rows = data.map(row => [row.provider, row.billable_amount, row.no_rev_share_amount])
      let boldFormatter = stylesheet.createFormat({
        font: {
          bold: true,
          size: 11
        }
      });
      rows.unshift([
        {value: 'Partner', metadata: {style: boldFormatter.id}},
        {value: 'Billable Amount', metadata: {style: boldFormatter.id}},
        {value: 'TOA Amount', metadata: {style: boldFormatter.id}}
      ]);
      sheet.setData(rows);
      workbook.addWorksheet(sheet);

      this.prExcel = ExcelBuilder.createFile(workbook);
    },


    buildExcelExport(content) {
      const aggregatedProvidersWorkbook = this.buildWorkbook(this.buildAggregatedProviders(content), true);
      const detailedProvidersWorkbook = this.buildWorkbook(this.buildDetailedProviders(content));
      const countriesWorkbook = this.buildWorkbook(this.buildCountries(content));

      this.aprExcel = ExcelBuilder.createFile(aggregatedProvidersWorkbook),
      this.dprExcel = ExcelBuilder.createFile(detailedProvidersWorkbook),
      this.crExcel = ExcelBuilder.createFile(countriesWorkbook)
    },

    buildWorkbook(data, isAggregated) {
      let workbook = ExcelBuilder.createWorkbook();
      this.$_.each(data, (rows, sheetName) => {
        let sheet = workbook.createWorksheet({name: sheetName});
        let stylesheet = workbook.getStyleSheet();
        let boldFormatter = stylesheet.createFormat({
          font: {
            bold: true,
            size: 11
          }
        });
        if(isAggregated) {
          rows.unshift([
            {value: 'Provider', metadata: {style: boldFormatter.id}},
            {value: 'Country', metadata: {style: boldFormatter.id}},
            {value: 'Views', metadata: {style: boldFormatter.id}},
            {value: 'Points', metadata: {style: boldFormatter.id}},
            {value: 'Total', metadata: {style: boldFormatter.id}},
            {value: 'Share', metadata: {style: boldFormatter.id}}
          ]);
        } else {
          rows.unshift([
            {value: 'Provider', metadata: {style: boldFormatter.id}},
            {value: 'Country', metadata: {style: boldFormatter.id}},
            {value: 'Title', metadata: {style: boldFormatter.id}},
            {value: 'Series', metadata: {style: boldFormatter.id}},
            {value: 'Season', metadata: {style: boldFormatter.id}},
            {value: 'Serial Nr.', metadata: {style: boldFormatter.id}},
            {value: 'Views', metadata: {style: boldFormatter.id}},
            {value: 'Points', metadata: {style: boldFormatter.id}}
          ]);
        }
        sheet.setData(rows);
        workbook.addWorksheet(sheet);
      });

      return workbook;
    },

    buildAggregatedProviders(content) {
      var aggregated = this.$_.reduce(content, (acc, row) => {
        let provider = acc[row.provider];
        if(!provider) {
          provider = {};
        }
        this.$_.each(row.geos, (geoRow) => {
          if(provider[geoRow.geo]) {
            provider[geoRow.geo]['count'] += geoRow.count;
            provider[geoRow.geo]['premium_points'] += geoRow.premium_points;
            provider[geoRow.geo]['share'] = provider[geoRow.geo]['premium_points'] / provider[geoRow.geo]['total'];
          } else {
            provider[geoRow.geo] = {};
            provider[geoRow.geo]['count'] = geoRow.count;
            provider[geoRow.geo]['premium_points'] = geoRow.premium_points;
            provider[geoRow.geo]['total'] = this.$store.state.finance.geoTotals[geoRow.geo];
            provider[geoRow.geo]['share'] = provider[geoRow.geo]['premium_points'] / provider[geoRow.geo]['total'];
          }
        });
        acc[row.provider] = provider;
        return acc;
      }, {});

      return this.$_.reduce(aggregated, (acc, row, provider) => {
        acc[provider] = this.$_.map(row, (stats, geo) => {
          return [provider, geo, stats.count, stats.premium_points, stats.total, stats.share];
        });

        return acc;
      }, {});
    },

    buildDetailedProviders(content) {
      return this.$_.reduce(content, (acc, row) => {
        let provider = acc[row.provider];
        if(!provider) {
          provider = [];
        }
        this.$_.each(row.geos, (geoRow) => {
          provider.push([row.provider, geoRow.geo, row.title, row.series, row.season, row.serial_nr, geoRow.count, geoRow.premium_points]);
        });
        acc[row.provider] = provider;
        return acc;
      }, {});
    },

    buildCountries(content) {
      return this.$_.reduce(content, (acc, row) => {
        return this.$_.reduce(row.geos, (acc, geoRow) => {
          let country = acc[geoRow.geo];
          if(!country) {
            country = [];
          }
          country.push([row.provider, geoRow.geo, row.title, row.series, row.season, row.serial_nr, geoRow.count, geoRow.premium_points]);
          acc[geoRow.geo] = country;
          return acc;
        }, acc);
      }, {});
    },


  }
}
</script>

<style scoped>
</style>
