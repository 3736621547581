var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex justify-content-between align-items-center h-100",
      attrs: { id: _vm.id },
    },
    [
      _c(
        "div",
        _vm._l(_vm.listSubscriptions, function (item) {
          return _c(
            "span",
            { key: item, staticClass: "mr-2" },
            [
              _c(
                "b-badge",
                { attrs: { variant: "primary" } },
                [
                  _vm._v(" " + _vm._s(item) + " "),
                  _c(
                    "b-badge",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.removeItem(item)
                        },
                      },
                    },
                    [_vm._v("✖")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }