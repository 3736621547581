var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.mediaType },
    [
      _vm.mediaType != "category" || _vm.$store.state.media.searchMode
        ? _c(
            "div",
            { staticClass: "d-flex" },
            _vm._l(_vm.filterLabels, function (label) {
              return _c(
                "b-badge",
                {
                  key: label.text,
                  staticClass: "mx-1 mt-3",
                  staticStyle: { opacity: "80%", "font-size": "0.9rem" },
                  attrs: { variant: "dark" },
                },
                [
                  label.icon
                    ? _c("Icon", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: {
                          title: _vm.$helpers.prettify(label.type),
                          icon: label.icon,
                        },
                      })
                    : _vm._e(),
                  _c("span", { staticClass: "mx-1" }, [
                    _vm._v(_vm._s(label.text)),
                  ]),
                  label.type != "stats"
                    ? _c(
                        "b-badge",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.removeMediaLabelFilter(label)
                            },
                          },
                        },
                        [_vm._v("✖")]
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "mb-4",
          class: { "no-text-select": _vm.disableTextSelect },
          attrs: { id: "content" },
        },
        _vm._l(_vm.mediaPageSelection, function (m) {
          return _c("TreeNode", {
            key: m.id,
            ref: m.id,
            refInFor: true,
            attrs: { media: m, partner: _vm.partnerName(m.partner_id) },
            on: {
              "node-selected": _vm.selectNode,
              save: function ($event) {
                return _vm.saveMedia($event)
              },
            },
          })
        }),
        1
      ),
      _vm.perPage
        ? _c(
            "div",
            {
              staticClass: "d-flex justify-content-center mt-4",
              staticStyle: { opacity: "0.7" },
            },
            [
              _c("b-pagination", {
                attrs: {
                  "total-rows": _vm.mediaSelection.length,
                  "per-page": _vm.perPage,
                },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("SimpleModal", {
        attrs: { msg: "Are you sure you want to archive this item?" },
        on: {
          ok: _vm.archiveMedia,
          cancel: function ($event) {
            return _vm.$store.commit("media/updateModal", {
              archive: { visible: false },
            })
          },
        },
        model: {
          value: _vm.archiveModalVisible,
          callback: function ($$v) {
            _vm.archiveModalVisible = $$v
          },
          expression: "archiveModalVisible",
        },
      }),
      _c("AddMedia"),
      _c("ImportMedia"),
      _c("DuplicateMedia", {
        ref: "duplicateMediaModal",
        model: {
          value: _vm.duplicateModalVisible,
          callback: function ($$v) {
            _vm.duplicateModalVisible = $$v
          },
          expression: "duplicateModalVisible",
        },
      }),
      _c("PublishCheckResults", {
        ref: "PublishModal",
        on: {
          save: function ($event) {
            return _vm.saveMedia($event)
          },
        },
      }),
      _c("EditLabels", {
        attrs: {
          labels: _vm.$store.state.media.managedLabels,
          "allow-new": true,
          "allow-manage": true,
        },
        on: { update: _vm.handleLabelsUpdate },
        model: {
          value: _vm.labelsModalVisible,
          callback: function ($$v) {
            _vm.labelsModalVisible = $$v
          },
          expression: "labelsModalVisible",
        },
      }),
      _c("EditLabels", {
        attrs: {
          "label-type": "artist",
          labels: _vm.$store.state.media.managedArtists,
          "allow-new": true,
          "allow-manage": true,
        },
        on: { update: _vm.handleArtistsUpdate },
        model: {
          value: _vm.artistsModalVisible,
          callback: function ($$v) {
            _vm.artistsModalVisible = $$v
          },
          expression: "artistsModalVisible",
        },
      }),
      _c("EditLabels", {
        attrs: {
          "label-type": "genre",
          labels: _vm.$store.state.media.managedGenres,
          "allow-new": true,
          "allow-manage": true,
        },
        on: { update: _vm.handleGenresUpdate },
        model: {
          value: _vm.genresModalVisible,
          callback: function ($$v) {
            _vm.genresModalVisible = $$v
          },
          expression: "genresModalVisible",
        },
      }),
      _c("ManageProperties", {
        attrs: { count: _vm.$store.state.media.selectedNodes.length },
        on: { update: _vm.handlePropertiesUpdate },
        model: {
          value: _vm.propertiesModalVisible,
          callback: function ($$v) {
            _vm.propertiesModalVisible = $$v
          },
          expression: "propertiesModalVisible",
        },
      }),
      _c("iframe", {
        staticStyle: { display: "none" },
        attrs: { id: "download-frame" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }