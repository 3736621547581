var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "S3UploadModal",
    {
      attrs: {
        "item-id": _vm.itemId,
        name: _vm.assetObjectName,
        format: _vm.format,
        "modal-title": "Add Asset",
      },
      on: {
        "file-change": _vm.setAssetFileName,
        finished: function ($event) {
          return _vm.$emit("finished")
        },
        "start-upload-request": function ($event) {
          return $event.handler()
        },
      },
    },
    [
      _c(
        "b-form-group",
        { attrs: { label: "Title" } },
        [
          _c("b-form-input", {
            model: {
              value: _vm.assetTitle,
              callback: function ($$v) {
                _vm.assetTitle = $$v
              },
              expression: "assetTitle",
            },
          }),
        ],
        1
      ),
      this.format == "video" || this.format == "audio"
        ? _c(
            "b-form-group",
            { attrs: { label: "Quality" } },
            [
              _c("b-form-select", {
                attrs: { options: _vm.assetQualityOptions },
                model: {
                  value: _vm.assetQuality,
                  callback: function ($$v) {
                    _vm.assetQuality = $$v
                  },
                  expression: "assetQuality",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-form-group",
        { attrs: { label: "Type" } },
        [
          _c("b-form-select", {
            attrs: { options: _vm.assetTypeOptions },
            model: {
              value: _vm.assetType,
              callback: function ($$v) {
                _vm.assetType = $$v
              },
              expression: "assetType",
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { label: "Language" } },
        [
          _c("b-form-select", {
            attrs: { options: _vm.assetLanguageOptions },
            model: {
              value: _vm.assetLanguage,
              callback: function ($$v) {
                _vm.assetLanguage = $$v
              },
              expression: "assetLanguage",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }