var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        title: "Add User",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "ok-disabled": !_vm.isValid(),
      },
      on: { ok: _vm.createUser, hidden: _vm.clear },
    },
    [
      _c(
        "form",
        { attrs: { autocomplete: "off" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Username",
                "label-for": "name-input",
                horizontal: "",
              },
            },
            [
              _c("b-form-input", {
                attrs: { id: "name-input", state: _vm.usernameState },
                model: {
                  value: _vm.username,
                  callback: function ($$v) {
                    _vm.username = $$v
                  },
                  expression: "username",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Password",
                "label-for": "password-input",
                horizontal: "",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "password-input",
                      autocomplete: "new-password",
                      "data-lpignore": "true",
                      type: _vm.showPassword ? "text" : "password",
                    },
                    model: {
                      value: _vm.item.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "password", $$v)
                      },
                      expression: "item.password",
                    },
                  }),
                  _c(
                    "b-input-group-append",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { pressed: _vm.showPassword },
                          on: {
                            "update:pressed": function ($event) {
                              _vm.showPassword = $event
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: {
                              icon: _vm.showPassword ? "eye-slash" : "eye",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Role",
                "label-for": "users-select-type",
                horizontal: "",
              },
            },
            [
              _c("b-form-select", {
                attrs: { id: "users-select-type", options: _vm.typeOptions },
                model: {
                  value: _vm.item.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "type", $$v)
                  },
                  expression: "item.type",
                },
              }),
            ],
            1
          ),
          _vm.item.type == "partner"
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Partner",
                    "label-for": "users-select-partner",
                    horizontal: "",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "users-select-partner",
                      options: _vm.partnerOptions,
                    },
                    model: {
                      value: _vm.item.partner,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "partner", $$v)
                      },
                      expression: "item.partner",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.item.type == "partner"
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Allow Dashboard login",
                    "label-for": "users-allow-dashboard",
                    horizontal: "",
                  },
                },
                [
                  _c("b-form-checkbox", {
                    attrs: { id: "users-allow-dashboard" },
                    model: {
                      value: _vm.allowPartnerDashboard,
                      callback: function ($$v) {
                        _vm.allowPartnerDashboard = $$v
                      },
                      expression: "allowPartnerDashboard",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Country",
                "label-for": "users-select-geo",
                horizontal: "",
              },
            },
            [
              _c("b-form-select", {
                attrs: { id: "users-select-geo", options: _vm.geoOptions },
                model: {
                  value: _vm.item.country,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "country", $$v)
                  },
                  expression: "item.country",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Language",
                "label-for": "users-select-lang",
                horizontal: "",
              },
            },
            [
              _c("b-form-select", {
                attrs: { id: "users-select-lang", options: _vm.langOptions },
                model: {
                  value: _vm.selectedLang,
                  callback: function ($$v) {
                    _vm.selectedLang = $$v
                  },
                  expression: "selectedLang",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _c(
            "b-alert",
            {
              attrs: {
                show: _vm.alert.dismissCountdown,
                dismissible: "",
                variant: _vm.alert.variant,
              },
              on: {
                dismissed: function ($event) {
                  _vm.alert.dismissCountdown = 0
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.alert.msg) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }