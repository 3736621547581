<template>
<b-form-input :value="v" @input="update"></b-form-input>
</template>

<script>
export default {
  name: 'CurrencyInput',

  data: function() {
    return {
      v: null
    };
  },

  props: {
    value: Number
  },


  methods: {
    update(x) {
      this.$emit('input', parseFloat(x.replace(",", ".")) * 100)
    }
  },

  created() {
    this.v = this.$helpers.simpleCurrencyFormatter(this.value)
  }
}
</script>

<style scoped>
</style>
