<template>
  <div>
    <b-input-group>
      <b-form-input :id="id" v-model="item.title" readonly></b-form-input>
      <b-input-group-append>
        <b-button v-if="status == 'view'" variant="danger" @click="handleDelete">
          <font-awesome-icon icon="trash-alt"/>
          Delete
        </b-button>
        <b-button :variant="buttonVariant" @click="handleButton">
          <font-awesome-icon :icon="buttonIcon"/>
          {{buttonText}}
        </b-button>
      </b-input-group-append>
    </b-input-group>

    <b-modal  ref="modal"
              title="Create Embedded Asset"
              size="lg"
              no-close-on-backdrop
              auto-focus-button="ok"
              @ok="handleEditorOk"
              @cancel="handleEditorCancel">
      <b-form>
        <b-form-group label="ID" label-for="id-input" horizontal>
          <b-input id="id-input" :value="editItem.id" readonly></b-input>
        </b-form-group>
        <b-form-group label="Platform" label-for="platform-select" horizontal>
          <b-form-select id="platform-select" v-model="editItem.platform" :options="platformOptions"/>
        </b-form-group>
        <b-form-group label="Language" label-for="language-select" horizontal>
          <b-form-select id="language-select" v-model="editItem.languages" :options="langOptions"/>
        </b-form-group>
        <b-form-group label="Name" label-for="title-input" horizontal>
          <b-form-input id="title-input" v-model="editItem.title" :state="nameState"></b-form-input>
        </b-form-group>
        <b-form-group label="Duration" label-for="duration-input" type="time" horizontal>
          <b-form-input id="duration-input" :value="durationString" @blur="setDuration" :state="durationState"></b-form-input>
        </b-form-group>
        <b-form-group label="Year" label-for="year-input" type="number" horizontal>
          <b-form-input id="year-input" v-model.number="editItem.year" :state="yearState"></b-form-input>
        </b-form-group>
        <b-form-group v-if="editItem.platform == 'streamable'" label="Embed code" label-for="embed-code-input" horizontal>
          <b-form-input id="embed-code-input" v-model="editItem.embed_code" :state="linkState"></b-form-input>
        </b-form-group>
        <b-form-group v-else label="Link" label-for="link-input" horizontal>
          <b-form-input id="link-input" v-model="editItem.link" :state="linkState"></b-form-input>
        </b-form-group>

      </b-form>

      <transition name="fade">
        <b-alert :show="alert.dismissCountdown" dismissible :variant="alert.variant" @dismissed="alert.dismissCountdown = 0">
          {{ alert.msg }}
        </b-alert>
      </transition>

    </b-modal>

  </div>
</template>

<script>
export default {
  name: 'EmbeddedAsset',

  data: function() {
    return {
      status: "empty", // ["empty", "view", "edit"]

      item: {
        title: null,
        platform: "youtube",
        embed_code: null,
        duration: null,
        length: null,
        year: null,
        format: "external_video",
        type: "primary",
        id: null,
        status: "ready",
        languages: this.$config.defaultLanguage
      },

      editItem: {},


      platformOptions: [
        {value: "youtube", text: "Youtube"},
        {value: "vimeo", text: "Vimeo"},
        {value: "streamable", text: "Streamable.com"},
        {value: "livestream", text: "Live Stream"}
      ],

      langOptions: [],

      alert: {
        dismissCountdown: 0,
        variant: "danger",
        msg: ""
      },

      nameState: null,
      linkState: null,
      durationState: null,
      yearState: null
    };
  },

  props: {
    id: String,
    mediaItemId: String,
    assets: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  computed: {
    buttonVariant() {
      switch(this.status) {
        case "empty": return "success"
        case "view": return "primary"
        case "edit": return "primary"
      }
      return null
    },

    buttonIcon() {
      switch(this.status) {
        case "empty": return "plus-circle"
        case "view": return "edit"
        case "edit": return "edit"
      }
      return null
    },

    buttonText() {
      switch(this.status) {
        case "empty": return " Add"
        case "view": return " Edit"
        case "edit": return " Edit"
      }
      return null
    },

    durationString() {
      return this.$helpers.durationToString(this.editItem.duration)
    }
  },

  watch: {
    assets: {
      immediate: true,
      handler(val) {
        this.$log.debug(val)
        for(var i = 0; i < val.length; ++i) {
          if(val[i].type == "primary") {
            this.item = this.$_.clone(val[i])
            this.item.languages = [val[i].languages[0]]

            if(this.status == "empty") {
              this.status = "view"
            }

            break
          }
        }
      }
    }
  },

  methods: {
    clear(all) {
      this.editItem = {
        title: null,
        platform: "youtube",
        link: null,
        embed_code: null,
        duration: null,
        length: null,
        year: null,
        format: "external_video",
        type: "primary",
        id: null,
        status: "ready",
        languages: []
      }

      if(all) {
        this.item = {
          title: null,
          platform: "youtube",
          link: null,
          embed_code: null,
          duration: null,
          length: null,
          year: null,
          format: "external_video",
          type: "primary",
          id: null,
          status: "ready",
          languages: []
        }
      }

      this.nameState = this.linkState = this.durationState = this.yearState = null
    },

    handleButton() {
      switch(this.status) {
        case "empty":
          this.showEditor()
          this.status = "edit"
          break

        case "view":
          this.showEditor()
          this.status = "edit"
          break

        case "edit":
          this.status = "view"
          break
      }
    },

    handleDelete() {
      fetch(
        `admin/api/v2/assets/delete`,
        {method: 'POST', body: JSON.stringify({data: {id: this.item.id}})}
      )
      .then(this.$helpers.JSONHandler)
      .then(resp => {
        if(resp.status == "ok") {
          this.$emit('delete-asset', this.item.id)
          this.$root.app.showAlert("Asset deleted", "success")
          this.clear(true)
          this.status = "empty"
        }
        else {
          this.$root.app.showErrorDialog(resp.type, "Asset delete Error")
        }
      })

    },

    showEditor() {
      this.editItem = this.$_.clone(this.item)

      if(this.status == "empty") {
        fetch("/admin/api/v2/assets/generate-id")
        .then(this.$helpers.JSONHandler)
        .then(resp => {
          this.editItem.id = resp.data
          this.$refs.modal.show()
        });
      }
      else {
        this.$refs.modal.show()
      }
    },

    handleEditorOk(ev) {
      if(this.validAsset()) {
        this.createEmbeddedAsset()
      }
      else {
        this.alert.msg = "One or more fields are invalid"
        this.alert.dismissCountdown = 4

        ev.preventDefault()
      }
    },

    handleEditorCancel() {
      this.clear()

      if(this.item.id == null) {
        this.status = "empty"
      }
      else {
        this.status = "view"
      }
    },

    validAsset() {
      this.nameState = (this.editItem.title && this.editItem.title.length > 0) ? true : false

      switch(this.editItem.platform) {
        case "youtube":
          this.linkState = (this.editItem.link && this.editItem.link.startsWith("https://youtu.be") || this.editItem.link.startsWith("https://youtube.com/live")) ? true : false
          break

        case "vimeo":
          this.linkState = (this.editItem.link && this.editItem.link.startsWith("https://vimeo.com")) ? true : false
          break

        case "streamable":
          this.linkState = (this.editItem.embed_code && this.editItem.embed_code.startsWith("<iframe src=\"https://")) ? true : false
          break

        case "livestream":
          this.linkState = (this.editItem.link && this.editItem.link.startsWith("http") && this.editItem.link.endsWith("m3u8")) ? true : false
          break

        default:
          this.linkState = null
      }

      this.durationState = (isNaN(this.editItem.duration) || this.editItem.duration == true) && this.editItem.platform != 'livestream' && !this.editItem.link.startsWith("https://www.youtube.com/live") ? false : true

      this.yearState = (this.editItem.year && this.editItem.year > 1900 && this.editItem.year < (new Date().getFullYear() + 1)) ? true : false

      return this.nameState && this.linkState && this.durationState && this.yearState
    },

    createEmbeddedAsset() {
      this.editItem["inserted_at"] = new Date().toISOString()
      this.editItem["media_id"] = this.mediaItemId

      if(this.editItem.platform == "streamable") {
        this.editItem.embed_code = this.editItem.embed_code.trim()
      }
      else {
        this.editItem.link = this.editItem.link.trim()
      }

      if(typeof this.editItem.languages === 'string') {
        this.editItem.languages = [this.editItem.languages]
      }

      this.$http.postJSON(
        `admin/api/v2/assets/${this.mediaItemId}`,
        {data: this.editItem},
        () => {
          this.item = this.$_.clone(this.editItem)
          this.$root.app.showAlert("Save successful", "success")
          this.status = "view"
          this.clear()
        },
        (status, html) => {
          this.$root.app.showErrorDialog(html, status.toString())
          this.status = "view"
          this.clear()
        }
      )
    },

    setDuration(ev) {
      this.editItem.duration = this.$helpers.stringToDuration(ev.target.value)
    }
  },

  mounted() {
    this.langOptions = this.$store.getters['app/languageCodes'].map(code => {
      return {value: code, text: this.$helpers.getLanguageName(code)}
    })
  }
}
</script>

<style scoped>
</style>
