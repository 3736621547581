import helpers from '../Helpers'

export default {
  namespaced: true,

  state: () => ({
    values: {}
  }),

  mutations: {
    putPartners(state, partners) {
      state.values = partners
    }
  },

  actions: {
    async fetchPartners({commit}) {
      const json = await fetch("/admin/api/v2/partners")
      const resp = await helpers.JSONHandler(json)

      let partners = resp.data
      let partnerMap = {}

      for (var i = 0; i < partners.length; ++i) {
        partnerMap[partners[i].id] = partners[i]
      }

      commit('putPartners', partnerMap)
      // Return the partner map for convenience:
      return partnerMap;
    },
  }
}