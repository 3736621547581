<template>
  <div>

    <b-button-group v-if="reorder" class="mt-2">
      <b-button v-b-tooltip.hover title="Save Reordering" @click.stop="saveReordering" variant="primary">
        <Icon icon="Save"/>
      </b-button>
      <b-button v-b-tooltip.hover title="Restore original order" @click.stop="restoreReordering" variant="primary">
        <font-awesome-icon icon="undo-alt"/>
      </b-button>
    </b-button-group>

    <b-list-group class="img-list">
      <draggable v-model="images" @update="updateOrder">
        <transition-group>
          <b-list-group-item v-for="image in images" :key="image.key">
            <b-container>
              <b-row>
                <b-col>
                  <b-img left thumbnail width="200px" :src="image.img_set && image.img_set[0].url"/>
                </b-col>
                <b-col cols="9">
                  <div v-if="images.length > 0" class="d-flex flex-row-reverse">
                    <b-button variant="danger" @click="deleteImage(image.key)">
                      <font-awesome-icon icon="trash-alt"/>
                      Delete
                    </b-button>
                  </div>

                  <div v-for="img in image.img_set" :key="img.url">
                    <div class="img-list-prop">
                      <span class="img-list-label">Source: </span>
                      <span>{{ img.source }}</span>
                    </div>
                    <div class="img-list-prop">
                      <span class="img-list-label">URL: </span>
                      <a :href="img.url" target="__blank">Open in new tab</a>
                    </div>
                    <div class="img-list-prop">
                      <span class="img-list-label">Width: </span>
                      <span>{{ img.width }}</span>
                    </div>
                    <div class="img-list-prop">
                      <span class="img-list-label">Height: </span>
                      <span>{{ img.height }}</span>
                    </div>
                  </div>

                  <div v-if="allowLinkTo && $helpers.isTopLevel(itemType) && type == 'slide'" class="img-list-prop">
                    <span class="img-list-label">Link To: </span>
                    <b-input-group>
                      <b-form-input v-model="image.link_to" placeholder="URL (starting with https://)" />
                      <b-input-group-append>
                        <b-btn @click="saveImages">Save</b-btn>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-list-group-item>
        </transition-group>
      </draggable>
    </b-list-group>
    <SimpleModal :value='deleteModalVisible' @ok="doDeleteImage" @cancel="deleteModalVisible = false" msg="Are you sure you want to delete this cover?"/>
  </div>
</template>

<script>
import SimpleModal from "./SimpleModal.vue"
import Draggable from "vuedraggable"

export default {
  name: 'ImageEdit',

  components: {
    SimpleModal,
    Draggable
  },

  data: function() {
    return {
      images: [],
      deleteModalVisible: false,
      imageToDelete: null,
      reorder: false
    };
  },
  props: {
    itemId: String,
    itemType: String,
    language: String,
    value: Array,
    type: String,
    allowLinkTo: Boolean
  },

  watch: {
    value(val) {
      this.images = this.$_.clone(val)
    }
  },

  methods: {
    deleteImage(key) {
      this.imageToDelete = key
      this.deleteModalVisible = true
    },

    doDeleteImage() {
      this.deleteModalVisible = false

      this.$http.postJSON(
        `admin/api/v2/media/${this.itemId}/delete-${this.type}`,
        {data: {key: this.imageToDelete, language: this.language}},
        () => {
          this.images = this.images.filter(image => image.key != this.imageToDelete)
          this.$emit('input', this.images)
          this.imageToDelete = null
        },
        (status, html) => this.$root.app.showErrorDialog(html, status.toString())
      )
    },

    updateOrder() {
      this.reorder = true
    },

    saveReordering() {
      let images = this.images.map((img, ndx) => {
        img.serial_nr = ndx + 1
        return img
      })
      let update = {}
      update[`${this.type}s`] = images
      update.language = this.language

      this.$http.postJSON(
        `/admin/api/v2/media/${this.itemId}/put-${this.type}s`,
        {data: update},
        () => {
          this.images = images
          this.$emit('input', this.images)
          this.reorder = false
          this.$root.app.showAlert("Reordering successfuly saved", "success")
        },
        (status, html) => {
          this.$root.app.showErrorDialog(html, status.toString())
          this.restoreReordering()
        }
      )
    },

    saveImages() {
      let data = {}
      data[`${this.type}s`] = this.images
      data.language = this.language

      this.$http.postJSON(
        `/admin/api/v2/media/${this.itemId}/put-${this.type}s`,
        {data: data},
        () => {
          this.$emit('input', this.images)
          this.$root.app.showAlert("Images successfuly saved", "success")
        },
        (status, html) => {
          this.$root.app.showErrorDialog(html, status.toString())
        }
      )
    },

    restoreReordering() {
      this.images.sort((a, b) => {
        if (a.serial_nr < b.serial_nr)
          return -1

        if (a.serial_nr > b.serial_nr)
          return 1

        return 0
      })
      this.$emit('input', this.images)
      this.reorder = false
    }


  },

  created() {
    this.images = this.$_.clone(this.value)
    //this.$log.info(this.images)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.img-list-prop {
  text-align: left;
  margin-bottom: 5px;
}

.img-list-label {
  font-weight: bold;
}

.img-list {
  margin-top: 10px;
}


</style>
