<template>
  <SelectBox title="Select Offers" size="lg" :values="offers" :items="offerList" ref="selectBox" @update="$emit('update', $event)"></SelectBox>
</template>

<script>
import SelectBox from './SelectBox.vue'

export default {
  name: 'EditAffiliateOffers',

  components: {
    SelectBox
  },

  data() {
    return {}
  },

  props: {
    offers: Array,
    offerList: Array
  },

  methods: {
    show() {
      this.$refs.selectBox.show()
    }
  }
  
}
</script>

<style>
</style>
