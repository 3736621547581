<template>
  <Media media-type="category"/>
</template>

<script>
import Media from "../components/Media.vue";

export default {
  name: "Categories",

  components: {
    Media
  },

  mounted() {
    this.$root.app.setActivePageTitle("Categories", false)
    this.$root.$emit('set-toolbar', 'media-toolbar', "category")
  }

};
</script>
