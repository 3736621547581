var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-table", {
        attrs: {
          hover: "",
          items: _vm.items,
          fields: _vm.fields,
          "per-page": _vm.perPage,
          "current-page": _vm.currentPage,
        },
      }),
      _vm.perPage
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-center" },
            [
              _c("b-pagination", {
                attrs: {
                  "total-rows": _vm.items.length,
                  "per-page": _vm.perPage,
                },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }