var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "home" }, [
      _c("iframe", {
        staticStyle: { display: "none" },
        attrs: { id: "download-all-content-frame" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }