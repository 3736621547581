<template>
    <b-modal  ref="modal"
              title="Mutate Affiliates"
              no-close-on-backdrop
              no-close-on-esc
              @ok="mutateAffiliate"
              @hidden="clear">
      <form autocomplete="off">
        <b-form-group label="Schedule" label-for="publication-comp" horizontal>
          <Publication id="publication-comp" from-field="active_from" to-field="active_to" v-model="item"/>
        </b-form-group>
        <b-form-group v-if="item.type == 'recurring'" label="Recurring compensation amount" label-for="amount-input" horizontal>
          <b-form-input id="amount-input" v-model="recurringCompensation"></b-form-input>
        </b-form-group>
        <b-form-group label="Offers" label-for="affiliate-offer-editor" horizontal>
          <AffiliateOffers id="affiliate-offer-editor" :type="item.type" :affiliate-compensations="item.affiliate_compensations" @update="updateAffiliateCompensations"></AffiliateOffers>
        </b-form-group>
      </form>
      <transition name="fade">
        <b-alert :show="alert.dismissCountdown" dismissible :variant="alert.variant" @dismissed="alert.dismissCountdown = 0">
          {{ alert.msg }}
        </b-alert>
      </transition>
    </b-modal>
</template>

<script>
import Publication from "./Publication.vue"
import AffiliateOffers from "./AffiliateOffers.vue"

export default {
  name: 'MutateAffiliate',

  components: {
    Publication,
    AffiliateOffers
  },

  props: {
  },

  data() {
    return {
      item: {
        type: null,
        active_from: null,
        active_to: null,
        affiliate_compensations: []
      },
      alert: {
        dismissCountdown: 0,
        variant: "warning",
        msg: ""
      },

      recurringCompensation: 0,
      offers: [],
      selectedAffiliates: []
    }
  },

  methods: {
    show(affiliates, type) {
      this.item.type = type
      this.selectedAffiliates = affiliates
      this.$refs.modal.show();
    },

    clear() {
      this.item = {
        active_from: null,
        active_to: null,
        affiliate_compensations: []
      }

      this.offers = []
    },

    mutateAffiliate() {
      this.updateAffiliateCompensations(this.offers)
      const mutatedAffiliates = this.processMutations()

      this.$http.postJSON(
          'admin/api/v2/affiliates/mutate',
          {data: mutatedAffiliates},
          () => {
          this.$root.app.showAlert("Mutation successful", "success")
          this.$root.$emit('affiliate-refresh')
          },
          (status, html) => {
            this.$root.app.showErrorDialog(html, status.toString())
          }
      )
    },

    updateAffiliateCompensations(offers) {
      this.offers = offers

      let getAmount = (offerId) => {
        if(this.item.type == 'recurring') {
          return parseInt(this.$helpers.currencyParser(this.recurringCompensation) * 100 + 0.00001)
        }
        else {
          let items = this.item.affiliate_compensations

          for(var i = 0; i < items.length; ++i) {
            if(items[i].offer_id == offerId) {
              return parseInt(this.$helpers.currencyParser(items[i].euro_amount) * 100 + 0.00001)
            }
          }
          
          return 0;
        }
      }
     
      let getEuroAmount = (offerId) => {
        if(this.item.type == 'recurring') {
          return this.recurringCompensation
        }
        else {
          let items = this.item.affiliate_compensations

          for(var i = 0; i < items.length; ++i) {
            if(items[i].offer_id == offerId) {
              return items[i].euro_amount
            }
          }
          
          return 0;
        }
      }

      this.item.affiliate_compensations = offers.map(o => {
        return {
          offer_id: o,
          amount: getAmount(o),
          euro_amount: getEuroAmount(o)
        }
      })
    },

    processMutations() {
      return this.selectedAffiliates.map(a => {
        if(this.item.active_from) {
          a.active_from = this.item.active_from
        }

        if(this.item.active_to) {
          a.active_to = this.item.active_to
        }

        if(this.item.affiliate_compensations && this.item.affiliate_compensations.length > 0) {
          a.affiliate_compensations = []
          this.item.affiliate_compensations.forEach(ac => {
            let clone = this.$_.clone(ac)
            clone.affiliate_id = a.id
            delete clone.euro_amount
            a.affiliate_compensations.push(clone)
          })
        }
        else {
          a.affiliate_compensations = []
        }
        return a
      })
    }
  },

  created() {
    this.clear()
  }
}
</script>


<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
