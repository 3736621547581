var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "labels", attrs: { id: _vm.id } },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _c(
            "div",
            _vm._l(_vm.labels, function (label) {
              return _c(
                "span",
                { key: label, staticClass: "keyword" },
                [
                  _c("b-badge", { attrs: { variant: "secondary" } }, [
                    _vm._v(" " + _vm._s(label) + " "),
                  ]),
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "edit-labels-button ml-auto" },
            [
              _c(
                "b-button",
                {
                  attrs: { size: "", variant: "primary" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.showEditor.apply(null, arguments)
                    },
                  },
                },
                [_c("Icon", { attrs: { icon: "edit" } }), _vm._v(" Edit ")],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("EditLabels", {
        ref: "editLabels",
        attrs: {
          labels: _vm.labels,
          "allow-new": _vm.allowNew,
          "label-type": _vm.labelType,
        },
        on: {
          update: function ($event) {
            return _vm.$emit("update", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }