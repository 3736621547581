var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.reorder
        ? _c(
            "b-button-group",
            { staticClass: "mt-2" },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  attrs: { title: "Save Reordering", variant: "primary" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.saveReordering.apply(null, arguments)
                    },
                  },
                },
                [_c("Icon", { attrs: { icon: "Save" } })],
                1
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  attrs: {
                    title: "Restore original order",
                    variant: "primary",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.restoreReordering.apply(null, arguments)
                    },
                  },
                },
                [_c("font-awesome-icon", { attrs: { icon: "undo-alt" } })],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-list-group",
        { staticClass: "img-list" },
        [
          _c(
            "draggable",
            {
              on: { update: _vm.updateOrder },
              model: {
                value: _vm.images,
                callback: function ($$v) {
                  _vm.images = $$v
                },
                expression: "images",
              },
            },
            [
              _c(
                "transition-group",
                _vm._l(_vm.images, function (image) {
                  return _c(
                    "b-list-group-item",
                    { key: image.key },
                    [
                      _c(
                        "b-container",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("b-img", {
                                    attrs: {
                                      left: "",
                                      thumbnail: "",
                                      width: "200px",
                                      src:
                                        image.img_set && image.img_set[0].url,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "9" } },
                                [
                                  _vm.images.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-row-reverse",
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: { variant: "danger" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteImage(
                                                    image.key
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: { icon: "trash-alt" },
                                              }),
                                              _vm._v(" Delete "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._l(image.img_set, function (img) {
                                    return _c("div", { key: img.url }, [
                                      _c(
                                        "div",
                                        { staticClass: "img-list-prop" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "img-list-label" },
                                            [_vm._v("Source: ")]
                                          ),
                                          _c("span", [
                                            _vm._v(_vm._s(img.source)),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "img-list-prop" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "img-list-label" },
                                            [_vm._v("URL: ")]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: img.url,
                                                target: "__blank",
                                              },
                                            },
                                            [_vm._v("Open in new tab")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "img-list-prop" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "img-list-label" },
                                            [_vm._v("Width: ")]
                                          ),
                                          _c("span", [
                                            _vm._v(_vm._s(img.width)),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "img-list-prop" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "img-list-label" },
                                            [_vm._v("Height: ")]
                                          ),
                                          _c("span", [
                                            _vm._v(_vm._s(img.height)),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  }),
                                  _vm.allowLinkTo &&
                                  _vm.$helpers.isTopLevel(_vm.itemType) &&
                                  _vm.type == "slide"
                                    ? _c(
                                        "div",
                                        { staticClass: "img-list-prop" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "img-list-label" },
                                            [_vm._v("Link To: ")]
                                          ),
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  placeholder:
                                                    "URL (starting with https://)",
                                                },
                                                model: {
                                                  value: image.link_to,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      image,
                                                      "link_to",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "image.link_to",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-btn",
                                                    {
                                                      on: {
                                                        click: _vm.saveImages,
                                                      },
                                                    },
                                                    [_vm._v("Save")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SimpleModal", {
        attrs: {
          value: _vm.deleteModalVisible,
          msg: "Are you sure you want to delete this cover?",
        },
        on: {
          ok: _vm.doDeleteImage,
          cancel: function ($event) {
            _vm.deleteModalVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }