<template>
  <b-container fluid class="mt-2">
    <b-table selectable selectedVariant="secondary" select-mode="multi" @row-selected="rowSelected" :items="items" :fields="fields" :filter="filter" ref="table">
    </b-table>
    <TranscodeAssets ref="transcodeAssetsModal"/>
    <SimpleModal :value='deleteModalVisible' @ok="doRemove" @cancel="deleteModalVisible = false" msg="Are you sure you want to delete the selected assets?"/>
  </b-container>
</template>

<script>
import SimpleModal from "./SimpleModal.vue"
import TranscodeAssets from "./TranscodeAssets.vue"

export default {
  name: 'AssetInbox',

  components: {
    TranscodeAssets,
    SimpleModal
  },

  data: function() {
    return {
      items: [],
      fields: ["path", "media_type", "type", "status"],
      filter: null,
      selectedItems: [],
      deleteModalVisible: false
    };
  },

  props: {

  },

  methods: {
    load() {
      fetch('/admin/api/v2/assets/inbox')
      .then(function(resp) {
        return resp.json();
      })
      .then(resp => {
        this.items = resp.data;
      });      
    },

    filterItem(item) {
      this.$log.info("filterItem: " + item.name)
      if(!this.filter) return true

      if(item.path && item.path.match(this.filter)) return true

      return false
    },

    setFilter(filter) {
      if(filter) this.filter = new RegExp(filter, "gi")
      else this.filter = null
    },

    remove() {
      if(this.selectedItems.length > 0) {
        this.deleteModalVisible = true
      }
    },

    doRemove() {
      this.deleteModalVisible = false
      const data = this.selectedItems.map(a => { return {id: a.id, path: a.path}})
      fetch(
        `admin/api/v2/assets/delete-assets`,
        {method: 'POST', body: JSON.stringify({data: data})}
      )
      .then(() => {
        this.assetToDelete = null
        this.$root.app.showAlert("Assets successfully deleted", "success")
        this.load()
      })
    },

    rowSelected(items) {
      this.$log.info(items)
      this.selectedItems = items;
    },

    requestTranscode() {
      if(this.selectedItems.length == 0) {
        this.$root.app.showAlert("No assets selected to transcode", "warning")
      }
      else if(this.selectedItemsValid()) {
        this.transcodeSelectedItems()
      }
      else {
        this.$root.app.showAlert("Can only batch transcode assets of the same format", "danger")
      }
    },

    selectedItemsValid() {
      let valid = true
      let format = null;

      this.selectedItems.forEach((item) => {
        if(item.format != format && format != null) {
          valid = false;
        }
        format = item.format
      })

      return valid;
    },

    transcodeSelectedItems() {
      this.$refs.transcodeAssetsModal.show(this.selectedItems)
    }

  },

  created() {
    this.$root.app.setActivePageTitle("Inbox | Assets")
    this.$root.$emit('set-toolbar', 'inbox-toolbar', 'asset')

    this.load()

    this.$root.$on('inbox-refresh', this.load)
    this.$root.$on('inbox-filter', this.setFilter)
    this.$root.$on('inbox-remove', this.remove)
    this.$root.$on('inbox-transcode', this.requestTranscode)
  },

  beforeDestroy() {
    this.$root.$off('inbox-refresh', this.load)
    this.$root.$off('inbox-filter', this.setFilter)
    this.$root.$off('inbox-remove', this.remove)
    this.$root.$off('inbox-transcode', this.requestTranscode)
  }
}
</script>

<style scoped>
</style>
