<template>
    <b-modal  ref="modal"
              title="Transcode Assets"
              no-close-on-backdrop
              no-close-on-esc
              @ok="transcodeAssets"
              @hidden="clear">
      <b-form>
        <div v-if="format == 'video'">
          <b-form-group label="Profile" label-for="profile-select" horizontal>
            <b-form-select id="profile-select" v-model="profile" :options="videoProfileOptions" class="mb-3" />
          </b-form-group>
        </div>
        <div v-else-if="format == 'print'">
          <b-form-group label="Profile" label-for="profile-select" horizontal>
            <b-form-select id="profile-select" v-model="profile" :options="printProfileOptions" class="mb-3" />
          </b-form-group>
        </div>
        <div v-else-if="format == 'image'">
          <b-form-group label="Profile" label-for="profile-select" horizontal>
            <b-form-select id="profile-select" v-model="profile" :options="printProfileOptions" class="mb-3" />
          </b-form-group>
        </div>
        <div v-else-if="format == 'audio'">
          <b-form-group label="Profile" label-for="profile-select" horizontal>
            <b-form-select id="profile-select" v-model="profile" :options="audioProfileOptions" class="mb-3" />
          </b-form-group>
        </div>
      </b-form>
      <transition name="fade">
        <b-alert :show="alert.dismissCountdown" dismissible :variant="alert.variant" @dismissed="alert.dismissCountdown = 0">
          {{ alert.msg }}
        </b-alert>
      </transition>
    </b-modal>
</template>

<script>
export default {
  name: 'TranscodeAssets',

  props: {
  },

  data() {
    return {
      items: [],
      format: null,

      printProfileOptions: [
        {value: "pdf", text: "PDF"},
        {value: "zip", text: "ZIP"}
      ],

      profile: null,

      alert: {
        dismissCountdown: 0,
        variant: "warning",
        msg: ""
      }
    }
  },

  computed: {
    videoProfileOptions() {
      return this.$store.state.media.options.videoTranscodePresets
    },

    audioProfileOptions() {
      return this.$store.state.media.options.audioTranscodePresets
    }
  },

  methods: {
    show(items) {
      this.$log.info(items)
      this.items = items
      this.getFormat()
      this.$refs.modal.show();
    },

    getFormat() {
      if(this.items && this.items.length > 0) {
        this.format = this.items[0].format
        if(this.format == "video") this.profile = this.$store.state.media.defaultVideoTranscodePreset
        else if(this.format == "print") this.profile = "pdf"
        else if(this.format == "image") this.profile = "zip"
        else if(this.format == "audio") this.profile = this.$store.state.media.defaultAudioTranscodePreset
      }
    },

    clear() {
      this.items = []
      this.format = null
    },

    getID() {
      fetch("/admin/api/v2/assets/generate-id")
      .then(this.$helpers.JSONHandler)
      .then(resp => {
        this.item.id = resp.data;
      });
    },

    transcodeAssets() {
      if(this.format && this.profile) {
        let ids = this.items.map(item => item.id)
        this.$http.postJSON(
          'admin/api/v2/assets/request-transcode',
          {data: {ids: ids, profile: this.profile}},
          () => {
            this.$root.app.showAlert("Transcoding started", "success")
            this.$root.$emit('inbox-refresh')
          },
          (status, html) => this.$root.app.showErrorDialog(html, status.toString())
        )
      }
      else {
        this.$root.app.showAlert("No assets to transcode", "danger")
      }
    }

  },

  created() {
    this.clear()
  }
}
</script>


<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
