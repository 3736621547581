<template>
  <div class="ml-auto py-2 pl-2 bg-dark">
    <div class="d-flex align-items-center justify-content-end">

      <template v-if="financeType == 'billing'">
        <b-button v-if="!isPublished && $config.enableAds && !$store.state.finance.globalAmounts['ad_supported']" variant="link" class="ml-2" @click="$root.$emit('rev-share-add-ad-revenue')">
          <Icon icon="Add"/>
          <span class="ml-2">Add Ad Revenue</span>
        </b-button>

        <b-button v-if="!isPublished" variant="link" class="ml-2" @click="$root.$emit('rev-share-add-geo-revenue')">
          <Icon icon="Add"/>
          <span class="ml-2">Add Geo Revenue</span>
        </b-button>

        <div class="mx-1" style="margin-top: 2px;">
          <Icon icon="Scheiding" :size="24"/>
        </div>

        <b-button :disabled="!canPublish && !isPublished" :variant="isPublished ? 'danger' : 'primary'" class="mr-3" @click="$root.$emit('rev-share-publish-month', !isPublished)">
          <span>{{ isPublished ? 'Depublish' : 'Publish' }}</span>
        </b-button>
      </template>

      <b-form inline>
        <b-form-select id="finance-select-month" v-model="selectedMonth" :options="monthOptions"></b-form-select>
        <b-form-select id="finance-select-year" v-model="selectedYear" :options="yearOptions"></b-form-select>

        <template v-if="financeType == 'content'">
          <div class="mx-1" style="margin-top: 2px;">
            <Icon icon="Scheiding" :size="24"/>
          </div>

          <b-form-select id="finance-select-country" v-model="selectedGeo" :options="geoOptions"></b-form-select>

          <div class="mx-1" style="margin-top: 2px;">
            <Icon icon="Scheiding" :size="24"/>
          </div>

          <b-button-group>
            <b-input-group>
              <b-form-input ref="search" v-model="filterInput" placeholder="Type to Search" />
              <b-input-group-append>
                <b-btn :disabled="!filterInput" @click="filterInput = ''"><Icon icon="Search_backspace"/></b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-button-group>
        </template>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinanceToolbar',

  data() {
    return {
      monthOptions: [
        {value: 1, text: 'Januari'},
        {value: 2, text: 'Februari'},
        {value: 3, text: 'March'},
        {value: 4, text: 'April'},
        {value: 5, text: 'May'},
        {value: 6, text: 'June'},
        {value: 7, text: 'July'},
        {value: 8, text: 'August'},
        {value: 9, text: 'September'},
        {value: 10, text: 'October'},
        {value: 11, text: 'November'},
        {value: 12, text: 'December'},
      ],

      yearOptions: [
        {value: 2019, text: '2019'},
        {value: 2020, text: '2020'},
        {value: 2021, text: '2021'},
        {value: 2022, text: '2022'},
        {value: 2023, text: '2023'},
        {value: 2024, text: '2024'},
        {value: 2025, text: '2025'},
        {value: 2026, text: '2026'},
        {value: 2027, text: '2027'},
        {value: 2028, text: '2028'},
      ],

      geoOptions: []
    }
  },

  props: {
    financeType: String
  },

  computed: {
    filterInput: {
      get() {
        return this.$store.state.finance.filterInput
      },

      set(v) {
        this.$store.commit('finance/setFilterInput', v)
      }
    },

    selectedGeo: {
      get() {
        return this.$store.state.finance.selectedGeo
      },

      set(v) {
        this.$store.commit('finance/setSelectedGeo', v)
      }
    },

    selectedMonth: {
      get() {
        return this.$store.state.finance.selectedMonth
      },

      set(v) {
        this.$store.commit('finance/setSelectedMonth', v)
      }
    },

    selectedYear: {
      get() {
        return this.$store.state.finance.selectedYear
      },

      set(v) {
        this.$store.commit('finance/setSelectedYear', v)
      }
    },

    isPublished() {
      return this.$store.state.finance.isPublished
    },

    canPublish() {
      return this.$store.getters['finance/canPublish']
    }
  },

  watch: {
    filterInput(v) {
      this.$root.$emit('rev-share-filter', v);
    },

    selectedGeo() {
      this.update()
    },

    selectedMonth() {
      this.update()
    },

    selectedYear() {
      this.update()
    },

    financeType() {
      this.update()
    }

  },

  methods: {
    update() {
      this.$store.dispatch('finance/fetchAll')
    },

    getGeos() {
      fetch('/admin/api/v2/rev-share/geo-options?include-world')
      .then(function(resp) {
        return resp.json();
      })
      .then(resp => {
        this.geoOptions = resp.data;
        this.update()
      });
    },

    focusSearch() {
      this.$refs.search.focus()
    }

  },

  created() {
    this.$root.$on('rev-share-focus-search', this.focusSearch)
    this.getGeos()
  },

  beforeDestroy() {
    this.$root.$off('rev-share-focus-search', this.focusSearch)
  }

}
</script>
