<template>
    <b-input-group>
      <b-form-input :id="id" :value="prettyString" type="text" readonly></b-form-input>
      <b-input-group-append>
        <b-button size="" class="" variant="primary" @click="edit">
          <Icon icon="edit"/>
           {{ editText || 'Edit' }}
        </b-button>
      </b-input-group-append>

      <b-modal  ref="modal"
                :title="titleText || 'Edit Date/Time'"
                size="lg"
                @ok="updateSchedule">

        <div class="d-flex justify-content-center mt-3">
          <b-card title="">
            <div class="d-flex align-items-center">
              <b-calendar v-model="date" class="mr-2" hide-header></b-calendar>
              <b-time v-model="time" :hour12=false hide-header></b-time>
            </div>
          </b-card>
        </div>
      </b-modal>

    </b-input-group>
</template>

<script>
export default {
  name: 'GenericDateTime',

  data() {
    return {
      datetime: '',
      date: '',
      time: '',
    }
  },

  props: {
    id: String,
    value: String,
    editText: String,
    titleText: String
  },

  watch: {
    value(val) {
      this.setDateTimeFromValue(val)
    }
  },

  computed: {
    prettyString() {
      const local = this.value ? this.$dt.fromISO(this.value, {zone: 'utc'}).setZone('local') : null

      if(!local) {
        return "No Date/Time"
      }
      else {
        return local.toLocaleString(this.$dt.DATETIME_SHORT)
      }
    }
  },

  methods: {

    edit() {
      this.setDateTimeFromValue(this.value)
      this.$refs.modal.show()
    },

    updateSchedule() {
      if(this.date != '' && this.time != '') {
        let dt = this.$dt.fromISO(`${this.date}T${this.time}`, {zone: 'local'}).setZone('utc')
        this.datetime = `${dt.toFormat('yyyy-MM-dd')}T${dt.toFormat('TT')}Z`
      }
      else {
        this.datetime = null
      }

      this.$emit('input', this.datetime)
    },

    splitDateTimeString(dtString) {
      if(dtString) {
        let dt = this.$dt.fromISO(dtString, {zone: 'utc'})
        let [date, time] = dt.setZone('local').toISO().split('T')
        time = time.substr(0, 8)
        return [date, time]
      }
      else{
        return ['', '']
      }
    },

    setDateTimeFromValue(value) {
      let [date, time] = this.splitDateTimeString(value)
      this.date = date
      this.time = time
    }

  }
}
</script>

<style scoped>
</style>