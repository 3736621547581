var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "S3UploadModal",
    {
      attrs: {
        multiple: "",
        "item-id": _vm.itemId,
        name: _vm.imageObjectName,
        format: _vm.format,
        "modal-title": "Add Image",
      },
      on: {
        "file-change": _vm.setImageFileName,
        finished: function ($event) {
          return _vm.$emit("finished")
        },
        "start-upload-request": function ($event) {
          return _vm.startUpload($event)
        },
      },
    },
    [
      _c(
        "b-form-group",
        { attrs: { label: "Image set" } },
        [
          _c("b-form-checkbox", {
            model: {
              value: _vm.isImageSet,
              callback: function ($$v) {
                _vm.isImageSet = $$v
              },
              expression: "isImageSet",
            },
          }),
        ],
        1
      ),
      _vm.isImageSet
        ? _c(
            "b-form-group",
            { attrs: { label: "Image set prefix" } },
            [
              _c("b-form-input", {
                model: {
                  value: _vm.imageTitle,
                  callback: function ($$v) {
                    _vm.imageTitle = $$v
                  },
                  expression: "imageTitle",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.format != "thumbnail"
        ? _c(
            "b-form-group",
            { attrs: { label: "Language" } },
            [
              _c("b-form-select", {
                attrs: { options: _vm.imageLanguageOptions },
                model: {
                  value: _vm.imageLanguage,
                  callback: function ($$v) {
                    _vm.imageLanguage = $$v
                  },
                  expression: "imageLanguage",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }