<template>
  <b-input-group>
    <b-input :id="id" :value="value" readonly></b-input>
    <b-input-group-append>
      <b-button variant="btn-outline-light" v-b-tooltip.hover title="Copy ID to Clipboard" @click.stop="copyTextToClipboard">
        <Icon icon="Copy_id"/>
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
export default {
  name: 'IdInput',

  data: function() {
    return {

    };
  },
  props: {
    id: String,
    value: String
  },

  methods: {
    copyTextToClipboard() {
      const input = document.querySelector(`#${this.id}`);
      input.focus()
      input.select()
      document.execCommand("copy")
    }
  }
}
</script>

<style scoped>
.btn-outline-light {
  border-color: #8B91A0 !important;
  background-color: #0D1B2B !important;
}

</style>
