<template>
  <div>
    <b-table hover :items="items" :fields="fields" :per-page="perPage" :current-page="currentPage"></b-table>
    <div v-if="perPage" class="d-flex justify-content-center">
      <b-pagination :total-rows="items.length" :per-page="perPage" v-model="currentPage"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WatchedItemsTable',

  data: function() {
    return {
      items: [],
      fields: [
        {key: "id"},
        {key: "title"},
        {key: "type"},
        {key: "plan"},
        {key: "watch_date", formatter: this.$helpers.dtFormatter}
      ],
      currentPage: 1
    };
  },

  props: {
    url: String,
    perPage: Number
  },

  watch: {
    url(val) {
      this.load(val)
    }
  },

  methods: {
    load(url) {
      if(url) {
        fetch(url).then(this.$helpers.JSONHandler).then(json => {
          this.items = json.data.map(item => {
            item.title = item.meta.title
            item.watch_date = item.watched_media.last_accessed
            delete item.watched_media
            delete item.meta
            return item
          })
        })
      }
      else {
        this.items = []
      }
    }
  },

  created() {
    this.load(this.url)
  }
}
</script>

