 <template>
  <div class="ml-auto py-2 pl-2 bg-dark">
    <div class="d-flex align-items-center justify-content-end">
      <b-button-group>
        <b-button id="add-button" variant="primary" text="Add" @click="$root.$emit('keyword-add')">
          <Icon icon="Add"/>
          <span class="ml-2">Add</span>
        </b-button>
      </b-button-group>

      <div style="margin-top: 2px;" class="mx-1">
        <Icon icon="Scheiding" :size="24"/>
      </div>

      <b-button-group>
        <b-input-group>
          <b-form-input ref="search" v-model="filterInput" placeholder="Type to Search" />
          <b-input-group-append>
            <b-button variant="btn-outline-light" :disabled="!filterInput" @click="filterInput = ''"><Icon icon="Search_backspace"/></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-button-group>    
    </div>
  </div>
</template>

<script>
export default {
  name: 'KeywordToolbar',

  data: function() {
    return {    
      filterInput: null
    };
  },

  props: {
    keywordType: String
  },

  watch: {
    filterInput(val) {
      this.$root.$emit('keyword-filter', val);
    }
  },

  methods: {
    focusSearch() {
      this.$refs.search.focus()
    }
  },

  created() {
    this.$root.$on('keyword-focus-search', this.focusSearch)
  },

  beforeDestroy() {
    this.$root.$off('keyword-focus-search', this.focusSearch)
  }

}
</script>
