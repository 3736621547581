import helpers from '../Helpers'
import config from '../config'
import map from "lodash/map"
import values from "lodash/values"

const util = {
  calcNoRevShareTotal(providerItems) {
    return providerItems.reduce((acc, item) => acc + item.no_rev_share_amount, 0);
  },

  getCurrentMonth() {
    return (new Date()).getMonth() + 1
  },

  getCurrentYear() {
    return (new Date()).getYear() + 1900
  },

  getPeriodPayload(state) {
    return JSON.stringify({
      data: {
        year: state.selectedYear,
        month: state.selectedMonth
      }
    })
  }
}

export default {
  namespaced: true,

  state: () => ({
    filterInput: null,
    selectedMonth: util.getCurrentMonth(),
    selectedYear: util.getCurrentYear(),
    selectedGeo: '',

    isPublished: false,
    invoicesSent: false,
    invoicesSentAt: null,

    revenueItems: [],
    billingItems: [],
    providerItems: [],
    data: [],
    shoppingItems: [],
    shoppingPartnerItems: [],
    affiliateItems: [],
    affiliatePartnerItems: [],
    promoCodeItems: [],
    promoCodePartnerItems: [],

    geoTotals: {},
    geoAmounts: {},
    globalAmounts: {},
    totalLengthShares: {},
    totalCostShares: {},
    noRevShareTotalAmount: 0,
    billingItemsTotal: {}
  }),

  getters: {
    canPublish: state => {
      // Can only publish if there's geo revenue or ad revenue, or only geo revenue when ads are disabled. 
      const hasGeoRevenue = state.revenueItems.length > 0
      const hasAdRevenue = !!state.globalAmounts['ad_supported']
      return hasGeoRevenue || (hasAdRevenue && config.enableAds)
    }
  },

  mutations: {
    setFilterInput(state, input) {
      state.filterInput = input
    },

    setSelectedMonth(state, month) {
      state.selectedMonth = month
    },

    setSelectedYear(state, year) {
      state.selectedYear = year
    },

    setSelectedGeo(state, geo) {
      state.selectedGeo = geo
    },

    setIsPublished(state, isPublished) {
      state.isPublished = isPublished
    },

    setInvoicesSent(state, invoicesSent) {
      state.invoicesSent = invoicesSent
    },

    setInvoicesSentAt(state, invoicesSentAt) {
      state.invoicesSentAt = invoicesSentAt
    },

    setRevenueItems(state, {geo_amounts, global_amounts}) {
      state.revenueItems = values(geo_amounts['premium'])
      state.geoAmounts = geo_amounts['premium']
      state.globalAmounts = global_amounts
    },

    setBillingItems(state, billingItems) {
      state.billingItems = billingItems.map(r => {
        r.payout_amount = r.billable_amount + r.affiliate_amount + r.shopping_partner_amount

        return r
      })

      // Calc totals

      let total_billable_amount = 0;
      let total_no_rev_share_amount = 0;
      let total_affiliate_amount = 0;
      let total_payout_amount = 0;

      state.billingItems.forEach(r => {
        if(total_billable_amount == "-" || r.billable_amount == "-") {
          total_billable_amount = "-";
        } else {
          total_billable_amount += r.billable_amount;
        }

        if(total_no_rev_share_amount == "-" || r.no_rev_share_amount == "-") {
          total_no_rev_share_amount = "-";
        } else {
          total_no_rev_share_amount += r.no_rev_share_amount;
        }

        if(total_affiliate_amount == "-" || r.affiliate_amount == "-") {
          total_affiliate_amount = "-";
        } else {
          total_affiliate_amount += r.affiliate_amount;
        }

        if(total_payout_amount == "-" || r.payout_amount == "-") {
          total_payout_amount = "-";
        } else {
          total_payout_amount += r.payout_amount;
        }
      });

      state.billingItemsTotal = {
        provider: "<strong>TOTAL</strong>",
        billable_amount: total_billable_amount,
        no_rev_share_amount: total_no_rev_share_amount,
        affiliate_amount: total_affiliate_amount,
        payout_amount: total_payout_amount
      }

    },

    setProviderItems(state, {provider_points, provider_amounts}) {
      state.providerItems = map(provider_amounts, (obj, provider) => {
        obj["points"] = provider_points[provider]
        return obj
      });

      state.noRevShareTotalAmount = util.calcNoRevShareTotal(state.providerItems)
    },

    setData(state, data) {
      state.data = data
    },

    setGeoTotals(state, geoTotals) {
      state.geoTotals = geoTotals
    },

    setShareTotals(state, {total_length_shares, total_cost_shares}) {
      state.totalLengthShares = total_length_shares
      state.totalCostShares = total_cost_shares
    },

    setShopping(state, {items, partnerItems}) {
      state.shoppingItems = items
      state.shoppingPartnerItems = partnerItems
    },

    setAffiliates(state, {items, partnerItems}) {
      state.affiliateItems = items
      state.affiliatePartnerItems = partnerItems
    },

    setPromoCodes(state, {items, partnerItems}) {
      state.promoCodeItems = items
      state.promoCodePartnerItems = partnerItems
    }

  },

  actions: {
    async fetchRevenue({state, commit}) {
      const payload = util.getPeriodPayload(state)
      const json = await fetch('/admin/api/v2/rev-share/billing', {method: 'POST', body: payload})
      const resp = await helpers.JSONHandler(json)

      commit('setIsPublished', resp.data.published)
      commit('setRevenueItems', resp.data)
    },

    async fetchBilling({state, commit}) {
      const json = await fetch(`/admin/api/v2/rev-share/billing/${state.selectedYear}/${state.selectedMonth}`)
      const resp = await helpers.JSONHandler(json)

      commit('setIsPublished', resp.data.published)
      commit('setInvoicesSent', resp.data.invoices_sent)
      commit('setInvoicesSentAt', resp.data.invoices_sent_at)
      commit('setBillingItems', resp.data.totals)

    },

    async fetchData({state, commit}) {
      const payload = util.getPeriodPayload(state)
      const json = await fetch(`/admin/api/v2/rev-share?geo=${state.selectedGeo}`, {method: 'POST', body: payload})
      const resp = await helpers.JSONHandler(json)

      commit('setGeoTotals', resp.data.geo_points)
      commit('setShareTotals', resp.data)
      commit('setData', resp.data.data)
      commit('setProviderItems', resp.data)
    },

    async fetchShopping({state, commit}) {
      const payload = util.getPeriodPayload(state)
      const json = await fetch('/admin/api/v2/shopping-purchases', {method: 'POST', body: payload})
      const resp = await helpers.JSONHandler(json)

      commit('setShopping', {items: resp.data.purchases, partnerItems: resp.data.partner_totals})
    },

    async fetchAffiliates({state, commit}) {
      const payload = util.getPeriodPayload(state)
      const json = await fetch('/admin/api/v2/affiliates/overview', {method: 'POST', body: payload})
      const resp = await helpers.JSONHandler(json)

      commit('setAffiliates', {items: resp.data.records, partnerItems: resp.data.totals})
    },

    async fetchPromoCodes({state, commit}) {
      const payload = util.getPeriodPayload(state)
      const json = await fetch('/admin/api/v2/promo-codes/overview', {method: 'POST', body: payload})
      const resp = await helpers.JSONHandler(json)

      commit('setPromoCodes', {items: resp.data.records, partnerItems: resp.data.totals})
    },


    async fetchAll({dispatch}) {
      await dispatch('fetchRevenue')
      await dispatch('fetchBilling')
      await dispatch('fetchData')
      await dispatch('fetchShopping')
      await dispatch('fetchAffiliates')
      await dispatch('fetchPromoCodes')
    }

  }
}
