<template>
  <b-container fluid class="bg-ott-gradient d-flex h-100 align-items-center flex-column justify-content-center">

    <b-img class="mb-4" :src="$config.logo" width="350px"></b-img>
    <b-jumbotron class="bg-dark d-flex align-items-center flex-column justify-content-center py-4 my-1">
      <b-form inline>
        <b-input-group size="lg" prepend="@" class="mx-2">
          <b-input v-model="username" id="login-username" type="email" ref=usernameInput placeholder="Username"></b-input>
        </b-input-group>
        <b-input-group size="lg" class="mx-2">
          <b-input-group-prepend is-text>
            <font-awesome-icon icon="key"/>
          </b-input-group-prepend>
          <b-input v-model="password" id="login-password" type="password" placeholder="Password" @keyup.enter="handleLogin"></b-input>
        </b-input-group>
        <b-input-group v-if="$config.use2FA" size="lg" class="mx-2">
          <b-input-group-prepend is-text>
            <font-awesome-icon icon="user-secret"/>
          </b-input-group-prepend>
          <b-input v-model="token" id="login-token" type="text" placeholder="2-step verification token" @keyup.enter="handleLogin"></b-input>
        </b-input-group>
      </b-form>
      <b-button size="lg" variant="primary" @click="handleLogin" class="my-4">
        <font-awesome-icon icon="sign-in-alt"/>
        <span> Login</span>
      </b-button>
      <template v-if="$config.use2FA">
        <b-button id="request-2fa-info" variant="secondary">Request 2-step verification info</b-button>
        <b-popover target="request-2fa-info" placement="bottom" :show.sync="showPopover">
          <b-form inline>
            <b-input-group size="lg" prepend="@" class="mr-4">
              <b-input v-model="email" id="login-email" type="email" ref="emailInput" placeholder="Username"></b-input>
            </b-input-group>
            <b-button size="lg" variant="primary" @click="handle2FARequest">
              <span>Request</span>
            </b-button>
          </b-form>
        </b-popover>
      </template>
    </b-jumbotron>

  </b-container>
</template>

<script>
export default {
  name: 'Login',

  data: function() {
    return {
      email: "",
      username: "",
      password: "",
      token: "",
      showPopover: false
    };
  },

  methods: {
    handleLogin() {
        this.$store.dispatch('session/handleLogin', {
          username: this.username,
          password: this.password,
          token: this.token
        })
        .then(() => {
          this.username = ''
          this.password = ''
          this.token = ''
          this.email = ''
        })
        .catch(() => {
         this.$root.app.showAlert("Error logging in", "danger")
         this.$refs.usernameInput.focus()
        })
    },

    handle2FARequest() {
      this.$store.dispatch('session/handle2FARequest', this.email)
        .then(() => {
          this.showPopover = false
          this.email = ''
          this.$root.app.showAlert("2-step verification instructions sent by email", "success")
        })
        .catch(() => {
          this.$root.app.showAlert("User not found", "danger")
          this.$refs.emailInput.focus()
        })
    }
  },

  mounted() {
    this.$refs.usernameInput.focus()
    this.showPopover = false
  }
}
</script>

<style scoped>

.bg-ott-gradient {
  background: transparent radial-gradient(closest-side at 47% 48%, #1B3B5B 0%, #072034 100%) 0% 0% no-repeat padding-box;
  box-shadow: -3px 3px 10px #00000033;
}
</style>
