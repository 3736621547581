import config from '../config'

export default {
  namespaced: true,

  state: () => ({
    loggedIn: false,
    user: null,
    loggedInChecked: false,
  }),

  mutations: {
    create(state, user) {
      state.loggedIn = true;
      state.user = user;
      state.loggedInChecked = true;
    },

    clear(state) {
      state.loggedIn = false;
      state.user = null;
      state.loggedInChecked = true;
    }
  },

  actions: {
    handleLogin({commit}, credentials) {
      let opts = {
        user: credentials.username,
        password: credentials.password
      }

      if(config.use2FA) {
        opts.token = credentials.token
      }

      let payload = JSON.stringify(opts)

      return fetch('/admin/api/v2/login', {method: 'POST', body: payload})
        .then(resp => {
          if(resp.status == 200) commit('create')
          else { commit('clear'); throw Error() }
        })
    },

    handleLogout({commit}) {
      return fetch('admin/api/v2/logout').then(() => commit('clear'))
    },


    handle2FARequest(context, email) {
      let payload = JSON.stringify({
        email: email,
      })

      return fetch('/admin/api/v2/admin/2fa-request', {method: 'POST', body: payload})
        .then(resp => {
          if(resp.status != 200) throw Error()
        })
    },

    fetchUser({commit}) {
      return fetch('/admin/api/v2/admin/me')
        .then(resp => {
          if(resp.status == 200) return resp.json()
          else throw Error(resp.statusText)
        })
        .then(resp => commit('create', resp.data))
        .catch(() => commit('clear'))
    },
  }
}