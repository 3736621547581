var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-3", attrs: { id: "main-menu" } },
    [
      _c(
        "h4",
        {
          staticClass: "w-100 text-center pr-3 mb-3",
          staticStyle: { "font-stretch": "expanded" },
        },
        [_vm._v(_vm._s(_vm.$config.menuTitle))]
      ),
      _c(
        "b-nav",
        { attrs: { vertical: "" } },
        [
          _vm.userType == "editor" ||
          _vm.userType == "editor_in_chief" ||
          _vm.userType == "finance" ||
          _vm.userType == "admin" ||
          _vm.userType == "support"
            ? [
                _c(
                  "b-nav-item",
                  {
                    attrs: {
                      "exact-active-class": "active-item",
                      to: "/",
                      title: "Home",
                    },
                  },
                  [
                    _c("Icon", { attrs: { icon: "Menu_Dashboard" } }),
                    _c("span", { staticClass: "ml-3" }, [_vm._v("Home")]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.userType == "editor" ||
          _vm.userType == "editor_in_chief" ||
          _vm.userType == "admin"
            ? [
                _c(
                  "b-nav-item",
                  {
                    attrs: {
                      "exact-active-class": "active-item",
                      to: "/inbox",
                      title: "Inbox",
                    },
                  },
                  [
                    _c("Icon", { attrs: { icon: "Menu_inbox" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-3" }, [_vm._v("Inbox")]),
                  ],
                  1
                ),
                _c(
                  "b-nav-item",
                  {
                    attrs: {
                      "exact-active-class": "active-item",
                      to: "/categories",
                      title: "Categories",
                    },
                  },
                  [
                    _c("Icon", { attrs: { icon: "Menu_Categories" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-3" }, [_vm._v("Categories")]),
                  ],
                  1
                ),
                _c(
                  "b-nav-item",
                  {
                    attrs: {
                      "exact-active-class": "active-item",
                      to: "/media",
                      title: "Media",
                    },
                  },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "photo-video" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-3" }, [_vm._v("Media")]),
                  ],
                  1
                ),
                _vm.config.enableDdex === true
                  ? _c(
                      "b-nav-item",
                      {
                        attrs: {
                          "exact-active-class": "active-item",
                          to: "/ddex-import",
                          title: "DDEX",
                        },
                      },
                      [
                        _c("Icon", { attrs: { icon: "Menu_inbox" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml-3" }, [_vm._v("DDEX")]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.userType == "support" || _vm.userType == "admin"
            ? _c(
                "b-nav-item",
                {
                  attrs: {
                    "exact-active-class": "active-item",
                    to: "/users",
                    title: "Users",
                  },
                },
                [
                  _c("Icon", { attrs: { icon: "Menu_Users" } }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-3" }, [_vm._v("Users")]),
                ],
                1
              )
            : _vm._e(),
          _vm.userType == "finance" || _vm.userType == "admin"
            ? [
                _vm.$config.enableAffiliates
                  ? _c(
                      "b-nav-item",
                      {
                        attrs: {
                          "exact-active-class": "active-item",
                          to: "/affiliates",
                          title: "Affiliates",
                        },
                      },
                      [
                        _c("Icon", { attrs: { icon: "Menu_Affiliates" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml-3" }, [
                          _vm._v("Affiliates"),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$config.enablePromoCodes
                  ? _c(
                      "b-nav-item",
                      {
                        attrs: {
                          "exact-active-class": "active-item",
                          to: "/promo-codes",
                          title: "Promo codes",
                        },
                      },
                      [
                        _c("Icon", { attrs: { icon: "Menu_Promo" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml-3" }, [
                          _vm._v("Promo codes"),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-nav-item",
                  {
                    attrs: {
                      "exact-active-class": "active-item",
                      to: "/partners",
                      title: "Partners",
                    },
                  },
                  [
                    _c("Icon", { attrs: { icon: "Menu_Partners" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-3" }, [_vm._v("Partners")]),
                  ],
                  1
                ),
                _c(
                  "b-nav-item",
                  {
                    attrs: {
                      "exact-active-class": "active-item",
                      to: "/finance",
                      title: "Finance",
                    },
                  },
                  [
                    _c("Icon", { attrs: { icon: "Menu_Revenue" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-3" }, [_vm._v("Finance")]),
                  ],
                  1
                ),
                _c(
                  "b-nav-item",
                  {
                    attrs: {
                      "link-classes": "last-nav-item",
                      "exact-active-class": "active-item",
                      to: "/stats",
                      title: "Stats",
                    },
                  },
                  [
                    _c("Icon", { attrs: { icon: "Menu_Stats" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-3" }, [_vm._v("Stats")]),
                  ],
                  1
                ),
              ]
            : _vm.userType == "editor" || _vm.userType == "editor_in_chief"
            ? [
                _c(
                  "b-nav-item",
                  {
                    attrs: {
                      "exact-active-class": "active-item",
                      to: "/affiliates",
                      title: "Affiliates",
                    },
                  },
                  [
                    _c("Icon", { attrs: { icon: "Menu_Affiliates" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-3" }, [_vm._v("Affiliates")]),
                  ],
                  1
                ),
                _c(
                  "b-nav-item",
                  {
                    attrs: {
                      "exact-active-class": "active-item",
                      to: "/promo-codes",
                      title: "Promo codes",
                    },
                  },
                  [
                    _c("Icon", { attrs: { icon: "Menu_Promo" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v("Promo codes"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-nav-item",
                  {
                    attrs: {
                      "exact-active-class": "active-item",
                      to: "/partners",
                      title: "Partners",
                    },
                  },
                  [
                    _c("Icon", { attrs: { icon: "Menu_Partners" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-3" }, [_vm._v("Partners")]),
                  ],
                  1
                ),
                _c(
                  "b-nav-item",
                  {
                    attrs: {
                      "link-classes": "last-nav-item",
                      "exact-active-class": "active-item",
                      to: "/stats",
                      title: "Stats",
                    },
                  },
                  [
                    _c("Icon", { attrs: { icon: "Menu_Stats" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-3" }, [_vm._v("Stats")]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }