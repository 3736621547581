var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ml-auto py-2 pl-2 bg-dark" }, [
    _c(
      "div",
      { staticClass: "w-100 d-flex justify-content-end" },
      [
        _vm.mediaType != "category"
          ? [
              _c(
                "b-dropdown",
                { attrs: { variant: "link" } },
                [
                  _c("template", { slot: "button-content" }, [
                    _c("span", { staticClass: "mr-2" }, [
                      _c("span", { staticClass: "mx-2" }, [_vm._v("File")]),
                    ]),
                  ]),
                  _c("b-dropdown-text", [_vm._v("Add new...")]),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.$store.dispatch("media/addNewItem", {
                            type: "artist_page",
                          })
                        },
                      },
                    },
                    [
                      _c("Icon", { attrs: { icon: "Menu_Categories" } }),
                      _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                        _vm._v("Artist Page"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.$store.dispatch("media/addNewItem", {
                            type: "audio",
                          })
                        },
                      },
                    },
                    [
                      _c("Icon", { attrs: { icon: "Menu_audio" } }),
                      _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                        _vm._v("Audio"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.$store.dispatch("media/addNewItem", {
                            type: "book",
                          })
                        },
                      },
                    },
                    [
                      _c("Icon", { attrs: { icon: "Menu_books" } }),
                      _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                        _vm._v("Book"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.$store.dispatch("media/addNewItem", {
                            type: "brand",
                          })
                        },
                      },
                    },
                    [
                      _c("Icon", { attrs: { icon: "Menu_Brands" } }),
                      _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                        _vm._v("Brand"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.$store.dispatch("media/addNewItem", {
                            type: "external",
                          })
                        },
                      },
                    },
                    [
                      _c("Icon", { attrs: { icon: "Menu_externals" } }),
                      _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                        _vm._v("External"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.$store.dispatch("media/addNewItem", {
                            type: "magazine",
                          })
                        },
                      },
                    },
                    [
                      _c("Icon", { attrs: { icon: "Menu_Magazines" } }),
                      _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                        _vm._v("Magazine"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.$store.dispatch("media/addNewItem", {
                            type: "page",
                          })
                        },
                      },
                    },
                    [
                      _c("Icon", { attrs: { icon: "Menu_Categories" } }),
                      _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                        _vm._v("Micropage"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.$store.dispatch("media/addNewItem", {
                            type: "music_album",
                          })
                        },
                      },
                    },
                    [
                      _c("Icon", { attrs: { icon: "Menu_music" } }),
                      _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                        _vm._v("Music Album"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.$store.dispatch("media/addNewItem", {
                            type: "photo_package",
                          })
                        },
                      },
                    },
                    [
                      _c("Icon", { attrs: { icon: "Menu_camera" } }),
                      _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                        _vm._v("Photo Package"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.$store.dispatch("media/addNewItem", {
                            type: "playlist_page",
                          })
                        },
                      },
                    },
                    [
                      _c("Icon", { attrs: { icon: "Menu_Categories" } }),
                      _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                        _vm._v("Playlist Page"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.$store.dispatch("media/addNewItem", {
                            type: "podcast",
                          })
                        },
                      },
                    },
                    [
                      _c("Icon", { attrs: { icon: "Menu_podcast" } }),
                      _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                        _vm._v("Podcast"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.$store.dispatch("media/addNewItem", {
                            type: "series",
                          })
                        },
                      },
                    },
                    [
                      _c("Icon", { attrs: { icon: "Menu_Series" } }),
                      _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                        _vm._v("Series"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.$store.dispatch("media/addNewItem", {
                            type: "video",
                          })
                        },
                      },
                    },
                    [
                      _c("Icon", { attrs: { icon: "Menu_Videos" } }),
                      _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                        _vm._v("Video"),
                      ]),
                    ],
                    1
                  ),
                  _c("b-dropdown-divider"),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { id: "import-button", text: "Import" },
                      on: {
                        click: function ($event) {
                          return _vm.$store.commit("media/updateModal", {
                            import: { visible: true, insert: false },
                          })
                        },
                      },
                    },
                    [
                      _c("Icon", { attrs: { icon: "import" } }),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v("Import from Excel"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { id: "export-button", text: "Export" },
                      on: {
                        click: function ($event) {
                          return _vm.$root.$emit("media-export", true)
                        },
                      },
                    },
                    [
                      _c("Icon", { attrs: { icon: "export" } }),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v("Export to Excel"),
                      ]),
                    ],
                    1
                  ),
                  _c("b-dropdown-divider"),
                  _vm.$config.allowInsertFromExcel
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            id: "import-new-button",
                            text: "Import New",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$store.commit("media/updateModal", {
                                import: { visible: true, insert: true },
                              })
                            },
                          },
                        },
                        [
                          _c("Icon", { attrs: { icon: "import" } }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v("Import new content from Excel"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "b-dropdown",
                { attrs: { variant: "link" } },
                [
                  _c("template", { slot: "button-content" }, [
                    _c("span", { staticClass: "mr-2" }, [
                      _c("span", { staticClass: "mx-2" }, [_vm._v("Edit")]),
                    ]),
                  ]),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { id: "duplicate-button", text: "Duplicate" },
                      on: { click: _vm.handleDuplicate },
                    },
                    [
                      _c("Icon", { attrs: { icon: "Copy_id" } }),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v("Duplicate"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    { on: { click: _vm.handleArchive } },
                    [
                      _c("Icon", { attrs: { icon: "Archive" } }),
                      _c("span", { staticClass: "ml-2" }, [_vm._v("Archive")]),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    { on: { click: _vm.manageMediaLabels } },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "tags" } }),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v("Manage Labels"),
                      ]),
                    ],
                    1
                  ),
                  _vm.$config.enableArtistsEverywhere
                    ? _c(
                        "b-dropdown-item",
                        { on: { click: _vm.manageMediaArtists } },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "user" } }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v("Manage Artists"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$config.enableGenres
                    ? _c(
                        "b-dropdown-item",
                        { on: { click: _vm.manageMediaGenres } },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "tags" } }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v("Manage Genres"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-dropdown-item",
                    { on: { click: _vm.manageMediaProperties } },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "square-check" },
                      }),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v("Manage Properties"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    { on: { click: _vm.handlePublishCheck } },
                    [
                      _c("Icon", { attrs: { icon: "globe" } }),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v("Publish check"),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "mx-1", staticStyle: { "margin-top": "10px" } },
                [_c("Icon", { attrs: { icon: "Scheiding", size: 24 } })],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "btn dropdown-toggle btn-link",
                  attrs: { "aria-haspopup": "true", "aria-expanded": "false" },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.mediaLabelFilter.show()
                    },
                  },
                },
                [
                  _c("font-awesome-icon", { attrs: { icon: "tags" } }),
                  _c("span", { staticClass: "mx-2" }, [_vm._v("Labels")]),
                ],
                1
              ),
              _c("EditLabels", {
                ref: "mediaLabelFilter",
                attrs: {
                  labels: _vm.$store.state.media.labelFilter,
                  "allow-new": false,
                  "allow-manage": false,
                },
                on: {
                  update: function ($event) {
                    return _vm.$store.dispatch("media/setLabelFilter", $event)
                  },
                },
              }),
              _vm.$store.state.media.searchMode
                ? _c("MediaSearchTypeFilter", { ref: "mediaTypeFilter" })
                : _c("MediaTypeFilter", { ref: "mediaTypeFilter" }),
            ]
          : _vm.$store.state.media.selectedNodes.length == 1 &&
            _vm.$store.state.media.selectedNodes[0].media.category_type !=
              "root"
          ? [
              _vm.$store.state.media.categoryIdToCopy == null
                ? [
                    _c(
                      "b-button",
                      { on: { click: _vm.handleCopyCategory } },
                      [
                        _c("Icon", { attrs: { icon: "Copy_id" } }),
                        _vm._v(" Copy "),
                      ],
                      1
                    ),
                  ]
                : _c(
                    "b-button-group",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "warning" },
                          on: {
                            click: function ($event) {
                              return _vm.$store.commit(
                                "media/resetCategoryIdToCopy"
                              )
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "times" } }),
                          _vm._v(" Clear "),
                        ],
                        1
                      ),
                      _vm.$store.state.media.categoryIdToCopy != null
                        ? _c(
                            "b-button",
                            { on: { click: _vm.handlePasteCategory } },
                            [
                              _c("Icon", { attrs: { icon: "Copy_id" } }),
                              _vm._v(" Paste "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
            ]
          : _vm._e(),
        _vm.mediaType != "category" || _vm.searchMode
          ? [
              _c("PublishStatusFilter", { ref: "publishStatusFilter" }),
              _c(
                "b-button-group",
                { staticClass: "ml-3" },
                [
                  _vm.mediaType != "category" && !_vm.searchMode
                    ? [
                        _c("b-form-select", {
                          staticClass: "mr-3",
                          staticStyle: { "max-width": "250px" },
                          attrs: {
                            id: "stats-select-partner",
                            value: _vm.$store.state.media.partnerFilter,
                            options:
                              _vm.$store.state.media.options.partnerFilter,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$store.dispatch(
                                "media/setPartner",
                                $event
                              )
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                  _c("b-form-select", {
                    staticStyle: { "max-width": "180px" },
                    attrs: {
                      value:
                        _vm.mediaType == "category"
                          ? _vm.$store.state.media.categorySortOrder
                          : _vm.$store.state.media.sortOrder,
                      options:
                        _vm.mediaType == "category"
                          ? _vm.$store.state.media.options.categorySortOrder
                          : _vm.$store.state.media.options.sortOrder,
                    },
                    on: {
                      input: function ($event) {
                        _vm.mediaType == "category"
                          ? _vm.$store.commit(
                              "media/putCategorySortOrder",
                              $event
                            )
                          : _vm.$store.commit("media/putSortOrder", $event)
                      },
                    },
                  }),
                  _vm.searchMode
                    ? [
                        _c(
                          "b-input-group",
                          {
                            staticClass: "ml-3",
                            staticStyle: { "min-width": "250px" },
                          },
                          [
                            _c("b-form-input", {
                              ref: "search",
                              attrs: { placeholder: "Type to Search" },
                              model: {
                                value: _vm.searchInput,
                                callback: function ($$v) {
                                  _vm.searchInput = $$v
                                },
                                expression: "searchInput",
                              },
                            }),
                            _c("b-input-group-append", [
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "-0.07rem" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: "outline-light",
                                        disabled: !_vm.searchInput,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.searchInput = ""
                                        },
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        attrs: { icon: "Search_backspace" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "b-input-group",
                          {
                            staticClass: "ml-3",
                            staticStyle: { "min-width": "250px" },
                          },
                          [
                            _c("b-form-input", {
                              ref: "search",
                              attrs: { placeholder: "Type to Filter" },
                              model: {
                                value: _vm.filterInput,
                                callback: function ($$v) {
                                  _vm.filterInput = $$v
                                },
                                expression: "filterInput",
                              },
                            }),
                            _c("b-input-group-append", [
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "-0.07rem" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: "outline-light",
                                        disabled: !_vm.filterInput,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.filterInput = ""
                                        },
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        attrs: { icon: "Search_backspace" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                ],
                2
              ),
            ]
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "ml-1",
            staticStyle: { "margin-right": "-5px", "margin-top": "5px" },
          },
          [_c("Icon", { attrs: { icon: "Scheiding", size: 24 } })],
          1
        ),
        _c(
          "b-form-checkbox",
          {
            staticClass: "mt-1 ml-1",
            attrs: { name: "check-button", switch: "" },
            model: {
              value: _vm.searchMode,
              callback: function ($$v) {
                _vm.searchMode = $$v
              },
              expression: "searchMode",
            },
          },
          [_vm._v(" Search Mode ")]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }