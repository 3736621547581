import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Inbox from './views/Inbox.vue'
import Categories from './views/Categories.vue'
import MediaView from './views/MediaView.vue'
import Affiliates from './views/Affiliates.vue'
import PromoCodes from './views/PromoCodes.vue'
import Partners from './views/Partners.vue'
import Finance from './views/Finance.vue'
import Stats from './views/Stats.vue'
import Users from './views/Users.vue'
import Ddex from './views/Ddex.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/inbox',
      name: 'inbox',
      component: Inbox
    },
    {
      path: '/categories',
      name: 'categories',
      component: Categories
    },
    {
      path: '/media',
      name: 'media',
      component: MediaView
    },
    {
      path: '/affiliates',
      name: 'affiliates',
      component: Affiliates
    },
    {
      path: '/promo-codes',
      name: 'promo-codes',
      component: PromoCodes
    },
    {
      path: '/partners',
      name: 'partners',
      component: Partners
    },
    {
      path: '/finance',
      name: 'finance',
      component: Finance
    },
    {
      path: '/stats',
      name: 'stats',
      component: Stats
    },
    {
      path: '/users',
      name: 'users',
      component: Users
    },
    {
      path: '/ddex-import',
      name: 'ddex-import',
      component: Ddex
    },
  ]
})
