var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        "margin-top": "0.14rem",
        "min-width": "10rem",
        "max-width": "13rem",
      },
    },
    [
      _c(
        "b-dropdown",
        { staticStyle: { width: "13rem" }, attrs: { variant: "link" } },
        [
          _c(
            "template",
            { slot: "button-content" },
            [
              _c("font-awesome-icon", { attrs: { icon: "photo-video" } }),
              _c("span", { staticClass: "mx-2" }, [_vm._v("Media Type")]),
            ],
            1
          ),
          _c(
            "b-dropdown-form",
            [
              _c(
                "b-form-checkbox",
                {
                  staticClass: "my-1",
                  model: {
                    value: _vm.showAll,
                    callback: function ($$v) {
                      _vm.showAll = $$v
                    },
                    expression: "showAll",
                  },
                },
                [
                  _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
                  _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                    _vm._v("Toggle All"),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "my-1",
                  attrs: { checked: _vm.typeFilter.artist_page },
                  on: {
                    input: function ($event) {
                      return _vm.updateFilter("artist_page", $event)
                    },
                  },
                },
                [
                  _c("Icon", { attrs: { icon: "Menu_Categories" } }),
                  _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                    _vm._v("Artist Pages"),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "my-1",
                  attrs: { checked: _vm.typeFilter.audio },
                  on: {
                    input: function ($event) {
                      return _vm.updateFilter("audio", $event)
                    },
                  },
                },
                [
                  _c("Icon", { attrs: { icon: "Menu_audio" } }),
                  _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                    _vm._v("Audio"),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "my-1",
                  attrs: { checked: _vm.typeFilter.book },
                  on: {
                    input: function ($event) {
                      return _vm.updateFilter("book", $event)
                    },
                  },
                },
                [
                  _c("Icon", { attrs: { icon: "Menu_books" } }),
                  _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                    _vm._v("Books"),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "my-1",
                  attrs: { checked: _vm.typeFilter.brand },
                  on: {
                    input: function ($event) {
                      return _vm.updateFilter("brand", $event)
                    },
                  },
                },
                [
                  _c("Icon", { attrs: { icon: "Menu_Brands" } }),
                  _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                    _vm._v("Brands"),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "my-1",
                  attrs: { checked: _vm.typeFilter.external },
                  on: {
                    input: function ($event) {
                      return _vm.updateFilter("external", $event)
                    },
                  },
                },
                [
                  _c("Icon", { attrs: { icon: "Menu_externals" } }),
                  _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                    _vm._v("Externals"),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "my-1",
                  attrs: { checked: _vm.typeFilter.magazine },
                  on: {
                    input: function ($event) {
                      return _vm.updateFilter("magazine", $event)
                    },
                  },
                },
                [
                  _c("Icon", { attrs: { icon: "Menu_Magazines" } }),
                  _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                    _vm._v("Magazines"),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "my-1",
                  attrs: { checked: _vm.typeFilter.page },
                  on: {
                    input: function ($event) {
                      return _vm.updateFilter("page", $event)
                    },
                  },
                },
                [
                  _c("Icon", { attrs: { icon: "Menu_Categories" } }),
                  _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                    _vm._v("Micropages"),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "my-1",
                  attrs: { checked: _vm.typeFilter.music_album },
                  on: {
                    input: function ($event) {
                      return _vm.updateFilter("music_album", $event)
                    },
                  },
                },
                [
                  _c("Icon", { attrs: { icon: "Menu_music" } }),
                  _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                    _vm._v("Music Albums"),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "my-1",
                  attrs: { checked: _vm.typeFilter.photo_package },
                  on: {
                    input: function ($event) {
                      return _vm.updateFilter("photo_package", $event)
                    },
                  },
                },
                [
                  _c("Icon", { attrs: { icon: "Menu_camera" } }),
                  _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                    _vm._v("Photo Packages"),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "my-1",
                  attrs: { checked: _vm.typeFilter.playlist_page },
                  on: {
                    input: function ($event) {
                      return _vm.updateFilter("playlist_page", $event)
                    },
                  },
                },
                [
                  _c("Icon", { attrs: { icon: "Menu_Categories" } }),
                  _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                    _vm._v("Playlist Pages"),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "my-1",
                  attrs: { checked: _vm.typeFilter.podcast },
                  on: {
                    input: function ($event) {
                      return _vm.updateFilter("podcast", $event)
                    },
                  },
                },
                [
                  _c("Icon", { attrs: { icon: "Menu_podcast" } }),
                  _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                    _vm._v("Podcasts"),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "my-1",
                  attrs: { checked: _vm.typeFilter.series },
                  on: {
                    input: function ($event) {
                      return _vm.updateFilter("series", $event)
                    },
                  },
                },
                [
                  _c("Icon", { attrs: { icon: "Menu_Series" } }),
                  _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                    _vm._v("Series"),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "my-1",
                  attrs: { checked: _vm.typeFilter.video },
                  on: {
                    input: function ($event) {
                      return _vm.updateFilter("video", $event)
                    },
                  },
                },
                [
                  _c("Icon", { attrs: { icon: "Menu_Videos" } }),
                  _c("span", { staticClass: "ml-1 px-2 py-1" }, [
                    _vm._v("Videos"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }