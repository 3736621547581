<template>
  <b-modal ref="modal" :title="title" size="xl" hide-footer>
    <b-table class="mt-1" :items="items" :fields="fields" striped small borderless></b-table>
  </b-modal>
</template>

<script>
export default {
  name: 'ShoppingDetailsModal',

  data: function() {
    return {
      title: "",
      items: [],
      fields: [
        {key: 'country', label: 'Country', sortable: true},
        {key: 'sku', label: 'SKU', sortable: true},
        {key: 'price', label: 'Price', sortable: true, formatter: this.$helpers.currencyFormatter},
        {key: 'quantity', label: 'Quantity', sortable: true},
        {key: 'partner_amount', label: 'Billable Amount (€)', sortable: true, formatter: this.$helpers.currencyFormatter, tdClass: "text-right"},
        {key: 'platform_amount', label: 'TOA Amount (€)', sortable: true, formatter: this.$helpers.currencyFormatter, tdClass: "text-right"},
        {key: 'total_amount', label: 'Total Amount (€)', sortable: true, formatter: this.$helpers.currencyFormatter, tdClass: "text-right"}
      ]
    };
  },

  methods: {
    show(title, items) {
      this.title = title
      this.items = items

      this.$refs.modal.show();
    },

  }
}
</script>

<style scoped>
</style>
