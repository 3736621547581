<template>
  <b-table hover :items="processedItems" :fields="fields">
    <template v-slot:cell(publish_status)="row">
      <span :class="publishClass(row.value)" class="rounded">{{row.value}}</span>
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'ContentTable',

  data: function() {
    return {
      fields: [{key: "title", label: "Title"}, "type", "plan", {key: "publish_status", label: "Status"}],

    };
  },

  props: {
    items: Array
  },

  computed: {
    processedItems() {
      return this.items.map(item => {
        if(item.current_title) {
          item.title = item.current_title
        }
        else {
          item.title = item.meta[0].title
        }
        return item
      })
    }
  },

  methods: {

    publishClass(status) {
      return {
        'bg-live': status == 'live',
        'bg-incomplete': status == 'incomplete',
        'bg-complete': status == 'complete',
        'bg-overdue': status == 'overdue',
        'bg-upcoming': status == 'upcoming',
        'bg-done': status == 'done',
        'bg-invalid': status == 'invalid'
        }
    },
  }
}
</script>

<style scoped>
</style>
