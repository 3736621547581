<template>
  <b-modal ref="modal" :title="title" @ok="addRevenue" @hidden="clear">
    <b-form>
      <b-form-group label="Revenue" label-for="add-geo-revenue" horizontal>
        <b-input v-model="revenue"></b-input>
      </b-form-group>
    </b-form>
    <transition name="fade">
      <b-alert :show="alert.dismissCountdown" dismissible :variant="alert.variant" @dismissed="alert.dismissCountdown = 0">
        {{ alert.msg }}
      </b-alert>
    </transition>
  </b-modal>
</template>

<script>
export default {
  name: "AddAdRevenue",

  props: {
    year: Number,
    month: Number
  },

  data() {
    return {
      revenue: 0.00,
      update: false,

      alert: {
        dismissCountdown: 0,
        variant: "warning",
        msg: ""
      }
    };
  },

  computed: {
    title() {
      return `${this.update ? 'Update' : 'Add'} ${this.$helpers.toMonthName(this.month)} ${this.year} Revenue`
    },

    revShareId() {
      const addRevenue = this.$store.state.finance.globalAmounts['ad_supported']
      return addRevenue ? addRevenue.rev_share_id : null
    }
  },

  methods: {
    show(value) {
      const r = Number.parseFloat(value)
      if(Number.isNaN(r)) {
        this.update = false
      }
      else {
        this.revenue = r
        this.update = true
      }

      this.$refs.modal.show()
    },

    clear() {
    },


    addRevenue(ev) {
      if(this.revenue && this.revenue > 0 && this.revShareId || !this.update) {
        let data, method
        console.log(parseFloat(this.revenue) * 100)

        if(this.update) {
          data = {
            rev_share_id: this.revShareId,
            geo: null,
            tier: "ad_supported",
            amount: parseFloat(this.revenue) * 100
          }
          method = 'PUT'
        }
        else {
          data = {
            year: this.year,
            month: this.month,
            geo: null,
            tier: "ad_supported",
            amount: parseFloat(this.revenue) * 100
          }
          method = 'POST'
        }
        const payload = JSON.stringify({
          data: data
        })

        fetch('/admin/api/v2/rev-share/billing/revenue', {method: method, body: payload})
        .then(function(resp) {
          if(resp.status == 400) {
            throw Error()
          }
          return resp.json()
        })
        .then(() => {
          this.$store.dispatch('finance/fetchAll')
          this.$root.app.showAlert(`Revenue ${this.update ? 'updated' : 'saved'}`, "success")
        })
        .catch(() => {
          this.$root.app.showAlert(`Error ${this.update ? 'updating' : 'saving'} revenue`, "danger")
        })
      }
      else {
        if(!this.revenue || this.revenue == 0) {
          this.alert.msg = "Please provide revenue"
        }
        else if(this.update && !this.revShareId) {
          this.alert.msg = "Can not update because rev_share_id for revenue is missing"
        }

        this.alert.dismissCountdown = 4
        ev.preventDefault()
      }
    }
  },

  created() {
    this.clear();
  }
};
</script>


<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

