var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        visible: _vm.value,
        size: "lg",
        title: _vm.title,
        "ok-only": "",
        "hide-header-close": "",
        "ok-disabled": !_vm.selectedItem,
      },
      on: {
        ok: _vm.handleOk,
        shown: function ($event) {
          return _vm.$refs.searchInput.focus()
        },
        hidden: function ($event) {
          return _vm.$emit("input", false)
        },
      },
    },
    [
      _c(
        "b-card",
        [
          _c(
            "b-input-group",
            { staticClass: "mb-3" },
            [
              _c("b-form-input", {
                ref: "searchInput",
                attrs: { placeholder: "Type to Search" },
                model: {
                  value: _vm.searchInput,
                  callback: function ($$v) {
                    _vm.searchInput = $$v
                  },
                  expression: "searchInput",
                },
              }),
              _c(
                "b-input-group-append",
                [
                  _c(
                    "b-btn",
                    {
                      attrs: { disabled: !_vm.searchInput },
                      on: {
                        click: function ($event) {
                          _vm.searchInput = ""
                        },
                      },
                    },
                    [_c("Icon", { attrs: { icon: "Search_backspace" } })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-list-group",
            _vm._l(_vm.items, function (item) {
              return _c(
                "b-list-group-item",
                {
                  key: item.id,
                  attrs: {
                    href: "#",
                    active: _vm.selectedItem && item.id == _vm.selectedItem.id,
                  },
                  on: {
                    click: function ($event) {
                      _vm.selectedItem = item
                    },
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(item.meta.title))]),
                  item.meta.secondary_title || (item.artists || []).join(", ")
                    ? _c("span", { staticClass: "mx-1" }, [
                        _vm._v(
                          "- " +
                            _vm._s(
                              item.meta.secondary_title ||
                                (item.artists || []).join(", ")
                            )
                        ),
                      ])
                    : _vm._e(),
                  _c("b-badge", [_vm._v(_vm._s(item.type))]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }