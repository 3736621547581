var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ml-auto py-2 pl-2 bg-dark" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-end" },
      [
        _c(
          "b-button",
          {
            staticClass: "mr-2",
            attrs: { variant: "danger" },
            on: {
              click: function ($event) {
                return _vm.$root.$emit("ddex-remove")
              },
            },
          },
          [
            _c("font-awesome-icon", { attrs: { icon: "trash-alt" } }),
            _c("span", { staticClass: "ml-2" }, [_vm._v("Remove")]),
          ],
          1
        ),
        _vm.selectedStatus !== "Flagged"
          ? _c(
              "b-button",
              {
                staticClass: "mr-2",
                attrs: { variant: "secondary" },
                on: {
                  click: function ($event) {
                    return _vm.$root.$emit("ddex-flag")
                  },
                },
              },
              [_c("span", { staticClass: "ml-2" }, [_vm._v("Flag")])]
            )
          : _vm._e(),
        _vm.selectedStatus === "Flagged"
          ? _c(
              "b-button",
              {
                staticClass: "mr-2",
                attrs: { variant: "secondary" },
                on: {
                  click: function ($event) {
                    return _vm.$root.$emit("ddex-unflag")
                  },
                },
              },
              [_c("span", { staticClass: "ml-2" }, [_vm._v("Unflag")])]
            )
          : _vm._e(),
        _c(
          "b-button",
          {
            attrs: { variant: "primary" },
            on: {
              click: function ($event) {
                return _vm.$root.$emit("ddex-import")
              },
            },
          },
          [
            _c("font-awesome-icon", { attrs: { icon: "cogs" } }),
            _c("span", { staticClass: "ml-2" }, [_vm._v("DDEX Import")]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mx-1", staticStyle: { "margin-top": "2px" } },
          [_c("Icon", { attrs: { icon: "Scheiding", size: 24 } })],
          1
        ),
        _c(
          "b-button-group",
          [
            _c("b-form-select", {
              staticClass: "mr-2",
              staticStyle: { "max-width": "150px" },
              attrs: { value: _vm.selectedStatus, options: _vm.statusOptions },
              on: {
                input: function ($event) {
                  return _vm.updateStatus($event)
                },
              },
            }),
            _c(
              "b-input-group",
              [
                _c("b-form-input", {
                  ref: "search",
                  attrs: { placeholder: "Type to Search" },
                  model: {
                    value: _vm.filterInput,
                    callback: function ($$v) {
                      _vm.filterInput = $$v
                    },
                    expression: "filterInput",
                  },
                }),
                _c(
                  "b-input-group-append",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "btn-outline-light",
                          disabled: !_vm.filterInput,
                        },
                        on: {
                          click: function ($event) {
                            _vm.filterInput = ""
                          },
                        },
                      },
                      [_c("Icon", { attrs: { icon: "Search_backspace" } })],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }