var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-12" },
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: "collapse-" + _vm._uid,
              expression: "'collapse-' + _uid",
            },
          ],
          staticClass: "text-left ddex-expandable-button",
          attrs: { variant: "outline-dark", block: "" },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.title))]),
          _c(
            "span",
            {
              staticClass: "chevron float-right",
              class: { "chevron-up": _vm.isCollapsed },
            },
            [_vm._v("▼")]
          ),
        ]
      ),
      _c(
        "b-collapse",
        {
          staticClass: "mt-2",
          attrs: { id: "collapse-" + _vm._uid },
          on: { show: _vm.toggleCollapse, hidden: _vm.toggleCollapse },
        },
        [
          _c(
            "b-card",
            {
              attrs: {
                "header-bg-variant": "dark",
                "header-text-variant": "white",
                "text-variant": "dark",
              },
            },
            [
              _c(
                "dl",
                { staticClass: "mb-0" },
                [
                  _vm._l(_vm.content, function (value, key) {
                    return [
                      _c("dt", { key: key + "-title" }, [_vm._v(_vm._s(key))]),
                      _c("dd", { key: key + "-value" }, [
                        _c("pre", [_vm._v(_vm._s(value))]),
                      ]),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }