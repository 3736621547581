var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-input-group",
    [
      _c("b-form-input", {
        attrs: {
          id: _vm.id,
          value: _vm.prettyString,
          type: "text",
          readonly: "",
        },
      }),
      _c(
        "b-input-group-append",
        [
          _c(
            "b-button",
            {
              attrs: { size: "", variant: "primary" },
              on: { click: _vm.edit },
            },
            [
              _c("Icon", { attrs: { icon: "edit" } }),
              _vm._v(" " + _vm._s(_vm.editText || "Edit") + " "),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: { title: _vm.titleText || "Edit Date/Time", size: "lg" },
          on: { ok: _vm.updateSchedule },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-center mt-3" },
            [
              _c("b-card", { attrs: { title: "" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _c("b-calendar", {
                      staticClass: "mr-2",
                      attrs: { "hide-header": "" },
                      model: {
                        value: _vm.date,
                        callback: function ($$v) {
                          _vm.date = $$v
                        },
                        expression: "date",
                      },
                    }),
                    _c("b-time", {
                      attrs: { hour12: false, "hide-header": "" },
                      model: {
                        value: _vm.time,
                        callback: function ($$v) {
                          _vm.time = $$v
                        },
                        expression: "time",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }