<template>
    <b-modal  ref="modal"
              title="Mutate Promo codes"
              no-close-on-backdrop
              no-close-on-esc
              @ok="mutatePromoCode"
              @hidden="clear">
      <form autocomplete="off">
        <b-form-group label="Schedule" label-for="publication-comp" horizontal>
          <Publication id="publication-comp" from-field="active_from" to-field="active_to" v-model="item"/>
        </b-form-group>
        <b-form-group label="Offers" label-for="promo-code-offer-editor" horizontal>
          <PromoCodeOffers id="promo-code-offer-editor" :discounts="item.discounts" @update="updateDiscounts"></PromoCodeOffers>
        </b-form-group>
      </form>
      <transition name="fade">
        <b-alert :show="alert.dismissCountdown" dismissible :variant="alert.variant" @dismissed="alert.dismissCountdown = 0">
          {{ alert.msg }}
        </b-alert>
      </transition>
    </b-modal>
</template>

<script>
import Publication from "./Publication.vue"
import PromoCodeOffers from "./PromoCodeOffers.vue"

export default {
  name: 'MutatePromoCode',

  components: {
    Publication,
    PromoCodeOffers
  },

  props: {
  },

  data() {
    return {
      item: {
        active_from: null,
        active_to: null,
        discounts: []
      },
      alert: {
        dismissCountdown: 0,
        variant: "warning",
        msg: ""
      },

      recurringCompensation: 0,
      offers: [],
      selectedPromoCodes: []
    }
  },

  methods: {
    show(promoCodes) {
      this.selectedPromoCodes = promoCodes
      this.$refs.modal.show();
    },

    clear() {
      this.item = {
        active_from: null,
        active_to: null,
        discounts: []
      }

      this.offers = []
    },

    mutatePromoCode() {
      this.updateDiscounts(this.offers)
      const mutatedPromoCodes = this.processMutations()

      this.$http.postJSON(
          'admin/api/v2/promo-codes/mutate',
          {data: mutatedPromoCodes},
          () => {
          this.$root.app.showAlert("Mutation successful", "success")
          this.$root.$emit('affiliate-refresh')
          },
          (status, html) => {
            this.$root.app.showErrorDialog(html, status.toString())
          }
      )
    },

    updateDiscounts(offers) {
      this.offers = offers

      let getAmount = (offerId) => {
        let items = this.item.discounts

        for(var i = 0; i < items.length; ++i) {
          if(items[i].offer_id == offerId) {
            return parseInt(this.$helpers.currencyParser(items[i].euro_amount) * 100 + 0.00001)
          }
        }

        return 0;
      }

      let getEuroAmount = (offerId) => {
        let items = this.item.discounts

        for(var i = 0; i < items.length; ++i) {
          if(items[i].offer_id == offerId) {
            return items[i].euro_amount
          }
        }

        return 0;
      }

      this.item.discounts = offers.map(o => {
        return {
          offer_id: o,
          amount: getAmount(o),
          euro_amount: getEuroAmount(o)
        }
      })
    },

    processMutations() {
      return this.selectedPromoCodes.map(a => {
        if(this.item.active_from) {
          a.active_from = this.item.active_from
        }

        if(this.item.active_to) {
          a.active_to = this.item.active_to
        }

        if(this.item.discounts && this.item.discounts.length > 0) {
          a.discounts = []
          this.item.discounts.forEach(ac => {
            let clone = this.$_.clone(ac)
            clone.promo_code_id = a.id
            // not sure if needed, but this line casued issues
            // after mutating promo codes 2023-05-05
            //delete clone.euro_amount
            a.discounts.push(clone)
          })
        }
        else {
          a.discounts = []
        }
        return a
      })
    }
  },

  created() {
    this.clear()
  }
}
</script>


<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
