var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: "Generate Preview",
        visible: _vm.value,
        size: _vm.size,
        "ok-disabled": !(_vm.fromState && _vm.toState),
        "hide-header-close": "",
        "auto-focus-button": "ok",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "b-form",
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: "From",
                "label-for": "from-input",
                type: "time",
                horizontal: "",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "from-input",
                  value: _vm.fromStr,
                  state: _vm.fromState,
                },
                on: { blur: _vm.setFrom },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "To",
                "label-for": "to-input",
                type: "time",
                horizontal: "",
              },
            },
            [
              _c("b-form-input", {
                attrs: { id: "to-input", value: _vm.toStr, state: _vm.toState },
                on: { blur: _vm.setTo },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }