<template>
  <b-modal :visible="value" :size="size" @ok="handleOk" @cancel="handleCancel" no-close-on-backdrop hide-header-close auto-focus-button="ok">
    {{msg}}
  </b-modal>
</template>

<script>
export default {
  name: 'SimpleModal',

  props: {
    msg: String,
    value: Boolean,
    size: {type: String, default: ""}
  },

  methods: {
    handleOk() {
      this.$emit('ok')
      this.$emit('input', false)
    },

    handleCancel() {
      this.$emit('cancel')
      this.$emit('input', false)
    }

  }
}
</script>


<style scoped>
</style>
