var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "errorModal",
      attrs: {
        "hide-footer": "",
        "header-bg-variant": "danger",
        "header-text-variant": "dark",
        title: _vm.header,
      },
    },
    [
      _c("div", { staticClass: "d-block text-center" }, [
        _c("p", {
          attrs: { id: "error-body" },
          domProps: { innerHTML: _vm._s(_vm.body) },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }