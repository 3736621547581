var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        size: _vm.size || "xl",
        title: _vm.title,
        scrollable: "",
        "no-close-on-backdrop": "",
      },
      on: {
        shown: _vm.handleFocus,
        ok: _vm.updateAndClose,
        cancel: _vm.cancelAndClose,
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: { width: "100%" },
          attrs: { slot: "modal-header" },
          slot: "modal-header",
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: _vm.columnCount } },
                [
                  _c(
                    "b-button-group",
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            ref: "filterInput",
                            attrs: { placeholder: "Type to Search" },
                            model: {
                              value: _vm.filterInput,
                              callback: function ($$v) {
                                _vm.filterInput = $$v
                              },
                              expression: "filterInput",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "outline-light",
                                    disabled: !_vm.filterInput,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.filterInput = ""
                                    },
                                  },
                                },
                                [
                                  _c("Icon", {
                                    attrs: { icon: "Search_backspace" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.showNewButton
                    ? _c(
                        "b-button",
                        {
                          staticClass: "ml-2",
                          attrs: { variant: "link" },
                          on: { click: _vm.addNew },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "plus-circle" },
                          }),
                          _c("span", { staticClass: "ml-2" }, [_vm._v("New")]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("b-col", [
                _c(
                  "div",
                  {
                    staticClass:
                      "selected-items d-flex flex-row-reverse flex-wrap",
                    staticStyle: { width: "100%" },
                  },
                  _vm._l(_vm.valuesCopy, function (v) {
                    return _c(
                      "span",
                      { key: v, staticClass: "item" },
                      [
                        _c(
                          "b-badge",
                          {
                            staticClass: "mx-1",
                            attrs: { variant: "primary" },
                          },
                          [
                            _vm._v(" " + _vm._s(_vm.getValueText(v)) + " "),
                            _c(
                              "b-badge",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.removeItem(v)
                                  },
                                },
                              },
                              [_vm._v("✖")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        _vm._l(_vm.itemColumnsView, function (c) {
          return _c(
            "b-col",
            { key: c.columnId },
            [
              _c(
                "b-list-group",
                _vm._l(c.items, function (item) {
                  return _c(
                    "b-list-group-item",
                    {
                      key: item.value,
                      attrs: { active: item.selected, href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.selectItem(item)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.text) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }