var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    { attrs: { variant: "link" } },
    [
      _c(
        "template",
        { slot: "button-content" },
        [
          _c("font-awesome-icon", { attrs: { icon: "globe" } }),
          _c("span", { staticClass: "mx-2" }, [_vm._v("Publish Status")]),
        ],
        1
      ),
      _c(
        "b-dropdown-form",
        [
          _c(
            "b-form-checkbox",
            {
              staticClass: "my-1",
              model: {
                value: _vm.showAll,
                callback: function ($$v) {
                  _vm.showAll = $$v
                },
                expression: "showAll",
              },
            },
            [_c("span", { staticClass: "px-2 py-1" }, [_vm._v("Toggle All")])]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "my-1",
              attrs: { checked: _vm.publishStatusFilter.incomplete },
              on: {
                input: function ($event) {
                  return _vm.updateFilter("incomplete", $event)
                },
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "fg-incomplete rounded px-2 py-1",
                  attrs: {
                    title:
                      "Item is missing meta data and should not be published yet",
                  },
                },
                [_vm._v("Incomplete")]
              ),
            ]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "my-1",
              attrs: { checked: _vm.publishStatusFilter.complete },
              on: {
                input: function ($event) {
                  return _vm.updateFilter("complete", $event)
                },
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "fg-complete rounded px-2 py-1",
                  attrs: { title: "Item is ready to be published" },
                },
                [_vm._v("Complete")]
              ),
            ]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "my-1",
              attrs: { checked: _vm.publishStatusFilter.overdue },
              on: {
                input: function ($event) {
                  return _vm.updateFilter("overdue", $event)
                },
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "fg-overdue rounded px-2 py-1",
                  attrs: {
                    title:
                      "Item is complete and according to its schedule should be live already, but isn't published yet",
                  },
                },
                [_vm._v("Overdue")]
              ),
            ]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "my-1",
              attrs: { checked: _vm.publishStatusFilter.upcoming },
              on: {
                input: function ($event) {
                  return _vm.updateFilter("upcoming", $event)
                },
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "fg-upcoming rounded px-2 py-1",
                  attrs: {
                    title:
                      "Item is published, but its start schedule is still in the future",
                  },
                },
                [_vm._v("Upcoming")]
              ),
            ]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "my-1",
              attrs: { checked: _vm.publishStatusFilter.live },
              on: {
                input: function ($event) {
                  return _vm.updateFilter("live", $event)
                },
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "fg-live rounded px-2 py-1",
                  attrs: {
                    title: "Item is published and accessible for customers",
                  },
                },
                [_vm._v("Live")]
              ),
            ]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "my-1",
              attrs: { checked: _vm.publishStatusFilter.done },
              on: {
                input: function ($event) {
                  return _vm.updateFilter("done", $event)
                },
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "fg-done rounded px-2 py-1",
                  attrs: {
                    title:
                      "Item is published, but its end schedule has been reached",
                  },
                },
                [_vm._v("Done")]
              ),
            ]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "my-1",
              attrs: { checked: _vm.publishStatusFilter.invalid },
              on: {
                input: function ($event) {
                  return _vm.updateFilter("invalid", $event)
                },
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "fg-invalid rounded px-2 py-1",
                  attrs: {
                    title:
                      "Item is published and accessible for customers, but meta data is still missing",
                  },
                },
                [_vm._v("Invalid")]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }