var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "duplicate-media" },
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            visible: _vm.value,
            title: "Duplicate media item",
            "no-close-on-backdrop": "",
            "auto-focus-button": "ok",
          },
          on: { ok: _vm.duplicateMedia, cancel: _vm.handleCancel },
        },
        [
          _c(
            "div",
            { staticClass: "form-content" },
            [
              _c(
                "b-form",
                { attrs: { id: "duplicate-form" } },
                [
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    attrs: {
                      title: "Number of duplicates",
                      id: "duplicate-count-input",
                      type: "number",
                    },
                    model: {
                      value: _vm.duplicateCount,
                      callback: function ($$v) {
                        _vm.duplicateCount = _vm._n($$v)
                      },
                      expression: "duplicateCount",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }