var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        title: _vm.title,
        visible: _vm.value,
        size: "xl",
        centered: true,
        "content-class": "full-width-modal",
      },
      on: { hide: _vm.onHide },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-button",
                { attrs: { variant: "secondary" }, on: { click: _vm.onClose } },
                [_vm._v("Close")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.data
        ? _c(
            "div",
            { staticClass: "info-modal-content" },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c("h4", [_vm._v("Header")]),
                          _c(
                            "b-table-simple",
                            { attrs: { hover: "", responsive: "" } },
                            [
                              _c(
                                "b-thead",
                                [
                                  _c(
                                    "b-tr",
                                    [
                                      _c("b-th", [_vm._v("Category")]),
                                      _c("b-th", [_vm._v("Key")]),
                                      _c("b-th", [_vm._v("Value")]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-tbody",
                                [
                                  _vm._l(
                                    _vm.sections,
                                    function (section, sectionName) {
                                      return _vm._l(
                                        section,
                                        function (value, key) {
                                          return _c(
                                            "b-tr",
                                            { key: `${sectionName}-${key}` },
                                            [
                                              _c("b-td", [
                                                _vm._v(_vm._s(sectionName)),
                                              ]),
                                              _c("b-td", [_vm._v(_vm._s(key))]),
                                              _c("b-td", [
                                                _c(
                                                  "pre",
                                                  {
                                                    staticClass:
                                                      "d-inline mb-0",
                                                  },
                                                  [_vm._v(_vm._s(value))]
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        }
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("h4", [_vm._v("Recordings")]),
                              _vm._l(
                                _vm.data.originalData.resources.SoundRecordings,
                                function (release, index) {
                                  return _c("collapsible-card", {
                                    key: index,
                                    attrs: {
                                      title: release.DisplayTitle,
                                      content: release,
                                    },
                                  })
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("h4", [_vm._v("Releases")]),
                              _vm._l(
                                _vm.data.originalData.releases.Releases,
                                function (release, index) {
                                  return _c("collapsible-card", {
                                    key: index,
                                    attrs: {
                                      title: release.ReferenceTitle,
                                      content: release,
                                    },
                                  })
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("h4", [_vm._v("Deals")]),
                              _vm._l(
                                _vm.data.originalData.deals.Deals,
                                function (release, index) {
                                  return _c("collapsible-card", {
                                    key: index,
                                    attrs: {
                                      title: release.CommercialModelType,
                                      content: release,
                                    },
                                  })
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("div", [_vm._v(" Loading... ")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }