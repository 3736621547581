<template>
  <S3UploadModal :item-id="itemId" :name="assetObjectName" :format="format" @file-change="setAssetFileName" modal-title="Add Asset" @finished="$emit('finished')" @start-upload-request="$event.handler()">
    <b-form-group label="Title">
      <b-form-input v-model="assetTitle"></b-form-input>
    </b-form-group>
    <b-form-group v-if="this.format == 'video' || this.format == 'audio'" label="Quality">
      <b-form-select v-model="assetQuality" :options="assetQualityOptions"></b-form-select>
    </b-form-group>
    <b-form-group label="Type">
      <b-form-select v-model="assetType" :options="assetTypeOptions"></b-form-select>
    </b-form-group>
    <b-form-group label="Language">
      <b-form-select v-model="assetLanguage" :options="assetLanguageOptions"></b-form-select>
    </b-form-group>
  </S3UploadModal>
</template>

<script>
import S3UploadModal from "./S3UploadModal.vue"

export default {
  name: "AddAsset",

  components: {
    S3UploadModal
  },

  props: {
    format: String,
    itemId: String,
    language: String
  },

  data() {
    return {
      assetFileName: "",
      assetTitle: "",
      assetQuality: "720p",
      assetQualityOptions: [],
      assetType: "primary",
      assetTypeOptions: [
        {value: "primary", text: "Primary"},
        {value: "preview", text: "Preview"},
        {value: "ad", text: "Ad"}
      ],
      assetLanguage: this.$config.defaultLanguage,
      assetLanguageOptions: []
    };
  },

  watch: {
    format(val) {
      this.setFormatDefault(val)
   },

    assetFileName(val) {
      if(val) {
        const fileParts = val.split('.')
        this.assetTitle = fileParts[0]
      }
    },

    language(val) {
      if(val) {
        this.assetLanguage = val
      }
    }
  },
  computed: {
    assetObjectName() {
      let name = this.assetTitle ? `${this.$helpers.slugify(this.assetTitle)}.${this.format}` : this.format

      switch(this.format) {
        case "video":
          name = name + `.${this.assetQuality}.${this.assetType}.${this.assetLanguage}`
          break

        case "print":
          name = name + `.${this.assetType}.${this.assetLanguage}`
          break

        case "image":
          name = name + `.${this.assetType}`
          break

        case "audio":
          name = name + `.${this.assetQuality}.${this.assetType}`
          break

        default:
          name = name + `.${this.assetType}`
      }

      return name
    }
  },

  methods: {
    setFormatDefault(format) {
      switch(format) {
        case "video":
          this.assetQuality = this.$store.state.media.defaultVideoTranscodePreset
          this.assetQualityOptions = this.$store.state.media.options.videoTranscodePresets
          break

        case "audio":
          this.assetQuality = this.$store.state.media.defaultAudioTranscodePreset
          this.assetQualityOptions = this.$store.state.media.options.audioTranscodePresets
          break

        default:
          return
      }
    },

    setAssetFileName(ev) {
      if(ev && ev.name) {
        this.assetFileName = ev.name
      }
    }
  },

  mounted() {
    this.assetQuality = this.$store.state.media.defaultVideoTranscodePreset
    this.assetQualityOptions = this.$store.state.media.options.videoTranscodePresets
    this.assetLanguage = this.language
    this.setFormatDefault(this.format)

    this.assetLanguageOptions = this.$store.getters['app/languageCodes'].map(code => {
      return {value: code, text: this.$helpers.getLanguageName(code)}
    })

  }
};
</script>


<style scoped>
</style>

