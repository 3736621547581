var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: { title: _vm.title, size: "xl", "hide-footer": "" },
    },
    [
      _c("b-table", {
        staticClass: "mt-1",
        attrs: {
          items: _vm.items,
          fields: _vm.fields,
          striped: "",
          small: "",
          borderless: "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }