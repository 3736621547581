<template>
    <b-modal  ref="modal"
              title="Add Partner"
              no-close-on-backdrop
              no-close-on-esc
              @ok="createPartner"
              @hidden="clear">
      <form autocomplete="off">
        <b-form-group label="ID" label-for="id-input" horizontal>
          <b-input id="id-input" :value="item.id" readonly></b-input>
        </b-form-group>
        <b-form-group label="Can View Affiliates" label-for="can-view-affiliates-checkbox" horizontal>
          <b-form-checkbox id="can-view-affiliates-checkbox" v-model="item.can_view_affiliates" switch />
        </b-form-group>
        <b-form-group label="Revshare TOA Default" label-for="no-rev-share-checkbox" horizontal>
          <b-form-checkbox id="no-rev-share-checkbox" v-model="item.no_rev_share" switch />
        </b-form-group>
        <b-form-group v-if="$config.usePremiumValue" label="Default Premium Value" label-for="default-premium-value-select" horizontal>
          <b-form-select id="default-premium-value-select" v-model.number="defaultPremiumValue" :options="$store.state.media.options.premiumValue"/>
        </b-form-group>
        <b-form-group label="Name" label-for="name-input" horizontal>
          <b-form-input id="name-input" v-model="item.name" ></b-form-input>
        </b-form-group>
        <b-form-group label="Contact Person" label-for="contact-person-input" horizontal>
          <b-form-input id="contact-person-input" v-model="item.contact_person" ></b-form-input>
        </b-form-group>
        <b-form-group label="Email" label-for="email-input" horizontal>
          <b-form-input id="email-input" v-model="item.email" ></b-form-input>
        </b-form-group>
        <b-form-group label="Telephone" label-for="telephone-input" horizontal>
          <b-form-input id="telephone-input" v-model="item.telephone" ></b-form-input>
        </b-form-group>
        <b-form-group label="Notes" label-for="notes-input" horizontal>
          <b-form-textarea id="notes-input" v-model="item.notes" ></b-form-textarea>
        </b-form-group>
      </form>
      <transition name="fade">
        <b-alert :show="alert.dismissCountdown" dismissible :variant="alert.variant" @dismissed="alert.dismissCountdown = 0">
          {{ alert.msg }}
        </b-alert>
      </transition>
    </b-modal>
</template>

<script>
export default {
  name: 'AddPartner',

  props: {
  },

  data() {
    return {
      item: {
        id: null,
        name: null,
        contact_person: null,
        email: null,
        telephone: null,
        no_rev_share: false,
        default_premium_value: 100,
        can_view_affiliates: false,
        notes: null
      },
      alert: {
        dismissCountdown: 0,
        variant: "warning",
        msg: ""
      }
    }
  },

  computed: {
    defaultPremiumValue: {
      get() { return this.item.default_premium_value },
      set(value) {
        this.item.default_premium_value = parseInt(value)
      }
    }
  },

  methods: {
    show() {
      this.$refs.modal.show();
      this.getID();
    },

    clear() {
      this.item = {
        id: null,
        name: null,
        contact_person: null,
        email: null,
        telephone: null,
        no_rev_share: false,
        can_view_affiliates: false,
        notes: null
      }
    },

    getID() {
      fetch("/admin/api/v2/partners/generate-id")
      .then(this.$helpers.JSONHandler)
      .then(resp => {
        this.item.id = resp.data;
      });
    },

    createPartner(ev) {
      if(this.itemIsValid()) {
        this.$http.postJSON(
          `admin/api/v2/partners`,
          {data: this.item},
          () => {
            this.$root.app.showAlert("Save successful", "success")
            this.$root.$emit('user-refresh')
            this.$store.dispatch('media/fetchOptions')
          },
          (status, html) => this.$root.app.showErrorDialog(html, status.toString())
        )
      }
      else {
        this.alert.msg = "Please fill in at least one language before saving"
        this.alert.dismissCountdown = 4
        ev.preventDefault()
      }
    },

    itemIsValid() {
      return true
    }
  },

  created() {
    this.clear()
  }
}
</script>


<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
