<template>
  <div :class="mediaType">
    <div class="d-flex align-items-center p-2 shadow-sm rounded bg-light justify-content-between" style="width: 33%;">
      <div class="d-flex align-items-center">
        <h5 class="m-0">{{mediaType == 'playlist_page' ? 'Embedded Playlists' : 'Embedded Dashboard'}}</h5>

        <b-button-group v-if="showControls" class="ml-3">
          <b-button :id="`add-${mediaType}-button`" variant="primary" text="Add" @click="addItem">
            <Icon icon="Add"/>
            <span> Add</span>
          </b-button>

        </b-button-group>
      </div>

      <b-button-group v-if="reorderMode">
        <b-button v-b-tooltip.hover title="Save Reordering" @click.stop="saveReordering" variant="primary">
          <Icon icon="Save"/>
        </b-button>
        <b-button v-b-tooltip.hover title="Restore original order" @click.stop="restoreReordering" variant="primary">
          <font-awesome-icon icon="undo-alt"/>
        </b-button>
      </b-button-group>


    </div>

    <draggable class="embedded-content" v-model="content" @update="updatedOrder">
      <TreeNode v-for="m in content" :key="m.id" :media="m" :ref="m.id" @save="$emit('save', $event)"/>
    </draggable>
  </div>
</template>

<script>
import Draggable from "vuedraggable"


export default {
  name: 'EmbeddedMedia',

  components: {
    Draggable
  },

  data() {
    return {
      content: [],
      selectedNodes: new Set(),
      selectedIds: [],
      editorOpen: 0,
      reorderMode: false
    };
  },

  props: {
      mediaType: String,
      value: Array,
      showControls: Boolean,
      parent: Object
  },

  watch: {
    value(val) {
      this.content = val ? this.$_.clone(val) : []
    }
  },

  methods: {
    selectNode(id, mod) {
      let node = this.getNode(id)

      if(!mod && this.selectedNodes.size > 0) {
        this.selectedNodes.forEach(node => node.highlight(false))
        this.selectedNodes.clear()
        return
      }

      if (this.selectedNodes.has(node)) {
        node.highlight(false);
        this.selectedNodes.delete(node)
      }
      else if(mod == "shift") {
        this.selectRange(id)
      }
      else {
        node.highlight(true);
        this.selectedNodes.add(node)
      }

      this.selectionChanged()
    },

    selectRange(id) {
      let selectedNdx = this.$helpers.indexOfById(this.media, id)
      let ndxs = []
      this.selectedNodes.forEach(node => ndxs.push(this.$helpers.indexOfById(this.media, node.getId())))
      ndxs.sort((a, b) => a < b ? -1 : a > b ? 1 : 0)

      if(selectedNdx < ndxs[0]) {
        for(let i = selectedNdx; i < ndxs[0]; ++i) {
          let node = this.getNode(this.media[i].id)
          node.highlight(true);
          this.selectedNodes.add(node)
        }
      }
      else if(selectedNdx > ndxs[ndxs.length - 1]) {
        for(let i = selectedNdx; i > ndxs[ndxs.length - 1]; --i) {
          let node = this.getNode(this.media[i].id)
          node.highlight(true);
          this.selectedNodes.add(node)
        }
      }
      else {
        let node = this.getNode(this.media[selectedNdx].id)
        node.highlight(true);
        this.selectedNodes.add(node)
      }

      this.selectionChanged()
    },

    selectionChanged() {
      this.selectedIds = []
      this.selectedNodes.forEach(n => n.media ? this.selectedIds.push(n.media.id) : null)
      //this.$emit('selection-change', ids)
    },

    getNode(id) {
      let node = this.$refs[id]
      if(node) return node[0]
      else return undefined
    },

    setEditorState(visible) {
      this.editorOpen += (visible ? 1 : -1)
    },

    updatedOrder() {
      this.reorderMode = true
    },

   addItem() {
      this.$store.dispatch('media/addNewItem', {type: this.mediaType, parent: this.parent, is_embedded: true})
    },

    saveReordering() {
      const update = this.content.map((m, ndx) => {
        m.serial_nr = ndx + 1
        return {
          id: m.id,
          type: m.type,
          default_language: m.default_language,
          serial_nr: ndx + 1
        };
      })

      const payload = JSON.stringify({data: update})

      fetch("/admin/api/v2/media?skip_publish_check", { method: 'POST', body: payload })
      .then(this.$helpers.JSONHandler)
      .then(() => {
          this.content = this.content.map((m, ndx) => {
            m.serial_nr = ndx + 1
            return m
          })
          this.$emit('input', this.content)
          this.reorderMode = false
          this.$root.app.showAlert("Reordering successfuly saved", "success")
      })
      .catch((error) => {
        this.$root.app.showAlert(`Error storing reordering: ${error}`, 'danger')
        this.restoreReordering()
      })
    },

    restoreReordering() {
      if(this.content) {
        this.content.sort((a, b) => {
          if (a.serial_nr < b.serial_nr)
            return -1

          if (a.serial_nr > b.serial_nr)
            return 1

          return 0
        })
      }
      this.$emit('input', this.content)
      this.reorderMode = false
    }


  },

  created() {
    this.content = this.value ? this.$_.clone(this.value) : []
  }

};
</script>

<style>
#embedded-content {
  margin-top: 20px;
}
</style>
