<template>
    <b-input-group>
      <b-form-input :id="id" :value="prettyString" type="text" readonly></b-form-input>
      <b-input-group-append>
        <b-button v-if="!readonly" size="" class="" variant="primary" @click="edit">
          <Icon icon="edit"/>
           Edit
        </b-button>
      </b-input-group-append>

      <b-modal  ref="modal"
                title="Edit Schedule"
                :size="hasEndDate ? 'xl' : 'lg'"
                @ok="updateSchedule">

        <b-button size="" class="" :variant="isScheduled ? 'danger' : 'warning'" :pressed.sync="isScheduled">{{ isScheduled ? 'Unschedule' : 'Schedule' }}</b-button>
        <b-button v-if="isScheduled" size="" class="ml-2" :variant="hasEndDate ? 'warning' : 'primary'" :pressed.sync="hasEndDate">
          {{hasEndDate ? 'Disable End Date' : 'Set End Date'}}
        </b-button>

        <div class="d-flex mt-3" :class="hasEndDate ? 'justify-content-between' : 'justify-content-center'">
          <b-card title="From" v-if="isScheduled">
            <div class="d-flex align-items-center">
              <b-calendar v-model="fromDate" class="mr-2" :max="toDate" hide-header></b-calendar>
              <b-time v-model="fromTime" :hour12=false hide-header></b-time>
            </div>
          </b-card>
          <b-card title="To" v-if="isScheduled && hasEndDate">
            <div class="d-flex align-items-center">
              <b-calendar v-model="toDate" class="mr-2" :min="fromDate" hide-header></b-calendar>
              <b-time v-model="toTime" :hour12=false hide-header></b-time>
            </div>
          </b-card>
        </div>
      </b-modal>

    </b-input-group>
</template>

<script>
export default {
  name: 'Publication',

  data() {
    return {
      publication: {},
      fromDate: '',
      fromTime: '',
      toDate: '',
      toTime: ''
    }
  },

  props: {
    id: String,
    value: Object,
    fromField: {type: String, default: "schedule_from"},
    toField: {type: String, default: "schedule_to"},
    readonly: {type: Boolean, default: false}
  },

  watch: {
    value(val) {
      this.publication = this.$_.clone(val)
    }
  },

  computed: {
    hasEndDate: {
      get() {
        return !!this.toDate
      },

      set(val) {
        if(val) {
          const now = this.$dt.local()
          this.toDate = now.toFormat('yyyy-MM-dd')
          this.toTime = now.toFormat('hh:mm:ss')
        }
        else {
          this.toDate = ''
          this.toTime = ''
        }
      }
    },

    isScheduled: {
      get() {
        return this.fromDate != ''
      },

      set(val) {
        if(val) {
          const now = this.$dt.local()
          this.fromDate = now.toFormat('yyyy-MM-dd')
          this.fromTime = now.toFormat('hh:mm:ss')

          this.toDate = ''
          this.toTime = ''
        }
        else {
          this.fromDate = ''
          this.fromTime = ''

          this.toDate = ''
          this.toTime = ''
        }
      }
    },


    prettyString() {
      const from = this.publication[this.fromField] ? this.$dt.fromISO(this.publication[this.fromField], {zone: 'utc'}).setZone('local') : null
      const to = this.publication[this.toField] ? this.$dt.fromISO(this.publication[this.toField], {zone: 'utc'}).setZone('local') : null

      if(!from) {
        return "No Schedule"
      }
      else if(!to) {
        return `From: ${from.toLocaleString(this.$dt.DATETIME_SHORT)}`
      }
      else {
        return `From: ${from.toLocaleString(this.$dt.DATETIME_SHORT)}, To: ${to.toLocaleString(this.$dt.DATETIME_SHORT)}`
      }
    }
  },

  methods: {

    edit() {
      let [date, time] = this.splitDateTimeString(this.publication[this.fromField])
      this.fromDate = date
      this.fromTime = time;

      [date, time] = this.splitDateTimeString(this.publication[this.toField])
      this.toDate = date
      this.toTime = time

      this.$refs.modal.show()
    },

    updateSchedule() {
      if(this.fromDate != '' && this.fromTime != '') {
        let dt = this.$dt.fromISO(`${this.fromDate}T${this.fromTime}`, {zone: 'local'}).setZone('utc')
        this.publication[this.fromField] = `${dt.toFormat('yyyy-MM-dd')}T${dt.toFormat('TT')}Z`
      }
      else {
        this.publication[this.fromField] = null
      }

      if(this.toDate != '' && this.toTime != '') {
        let dt = this.$dt.fromISO(`${this.toDate}T${this.toTime}`, {zone: 'local'}).setZone('utc')
        this.publication[this.toField] = `${dt.toFormat('yyyy-MM-dd')}T${dt.toFormat('TT')}Z`
      }
      else {
        this.publication[this.toField] = null
      }

      let updateItem = this.$_.clone(this.value)
      updateItem["propertyUpdate"] = true
      updateItem[this.fromField] = this.publication[this.fromField]
      updateItem[this.toField] = this.publication[this.toField]
      this.$emit('input', updateItem)
    },

    splitDateTimeString(dtString) {
      if(dtString) {
        let dt = this.$dt.fromISO(dtString, {zone: 'utc'})
        let [date, time] = dt.setZone('local').toISO().split('T')
        time = time.substr(0, 8)
        return [date, time]
      }
      else{
        return ['', '']
      }
    }

  },

  created() {
    this.publication = this.$_.clone(this.value)
  }
}
</script>

<style scoped>

</style>