<template>
  <div class="promo_codes">
<!--
    <div id="promo-code-count-stats" class="d-flex justify-content-end align-items-center my-4">
      <b-button variant="primary" @click="exportPromoCodes">
        <Icon icon="export"/>
        Export PromoCodes
      </b-button>
    </div>
-->


    <div class="mt-4">
      <b-table hover striped selectable select-mode="multi" selected-variant="info" @row-selected="rowSelected" :items="items" :fields="fields" :per-page="perPage" :current-page="currentPage">

        <template v-slot:cell(show_details)="row">
          <div class="d-flex justify-content-center">
            <b-button size="sm" variant="primary" @click="row.toggleDetails" class="mr-2">
              <font-awesome-icon icon="info"/>
            </b-button>
          </div>
        </template>

        <template v-slot:row-details="row">
          <b-container>
            <form autocomplete="off">
              <b-form-group label="ID" label-for="id-input" horizontal>
                <b-form-input id="id-input" :value="row.item.id" readonly></b-form-input>
              </b-form-group>
              <b-form-group label="Code" label-for="promo-codes-input-type" horizontal>
                <id-input id="promo-codes-input-type" :value="row.item.code"></id-input>
              </b-form-group>
              <b-form-group label="Partner" label-for="promo-codes-input-partner" horizontal>
                <b-form-input id="promo-codes-input-partner" :value="getPartnerName(row.item.partner)" readonly></b-form-input>
              </b-form-group>
              <b-form-group label="Schedule" label-for="publication-comp" horizontal>
                <Publication id="publication-comp" from-field="active_from" to-field="active_to" :value="row.item" readonly/>
              </b-form-group>
              <b-form-group label="Description" label-for="description-input" horizontal>
                <b-form-input id="description-input" :value="row.item.description" readonly></b-form-input>
              </b-form-group>
              <b-form-group label="Offers" label-for="promo-code-offer-editor" horizontal>
                <PromoCodeOffers id="promo-code-offer-editor" readonly :discounts="row.item.discounts"></PromoCodeOffers>
              </b-form-group>
            </form>
          </b-container>

        </template>

      </b-table>
    </div>

    <div v-if="perPage" class="d-flex justify-content-center">
      <b-pagination :total-rows="items.length" :per-page="perPage" v-model="currentPage"/>
    </div>

    <AddPromoCode ref="addPromoCode"/>
    <MutatePromoCode ref="mutatePromoCode"/>

    <iframe id="export-promo-code-frame" style="display:none;"></iframe>

  </div>
</template>

<script>
import IdInput from "../components/IdInput.vue"
import AddPromoCode from '../components/AddPromoCode.vue'
import MutatePromoCode from '../components/MutatePromoCode.vue'
import Publication from "../components/Publication.vue"
import PromoCodeOffers from "../components/PromoCodeOffers.vue"

export default {
  name: 'PromoCodes',

  components: {
    IdInput,
    AddPromoCode,
    MutatePromoCode,
    Publication,
    PromoCodeOffers
  },

  data() {
    return {
      currentPage: 1,
      perPage: 20,
      partnerFilter: '__all__',
      typeFilter: '*',
      statusFilter: 'active',
      partnerOptions: [],
      sortOrder: 'date-asc',
      sorter: null,

      showContentModal: false,

      promoCodes: [],

      selectedPromoCodes: [],

      fields: [
        {key: 'partner', label: 'Partner', sortable: true, formatter: this.getPartnerName},
        {key: 'code', sortable: true},
        {key: 'description', sortable: true},
        {key: 'status', sortable: true},
        {key: 'active_from', label: 'From', sortable: true, formatter: this.$helpers.dtFormatter},
        {key: 'active_to', label: 'To', sortable: true, formatter: this.$helpers.dtFormatter},
        {key: 'show_details', label: 'Details'}
      ],


    };
  },

  props: {

  },

  computed: {
    items() {
      return this.promoCodes.filter(item => {
        return (this.statusFilter == '*' || item.status == this.statusFilter) &&
          (this.partnerFilter == "__all__" || item.partner && item.partner.match(this.partnerFilter))
      }).sort(this.sorter)
    }
  },

  methods: {
    load() {
        fetch('/admin/api/v2/partners/select-options')
        .then(function(resp) {
          return resp.json();
        })
        .then(resp => {
          this.partnerOptions = resp.data

          fetch('/admin/api/v2/promo-codes')
          .then(resp => {
            if(resp.status == 200) return resp.json()
            else throw Error(resp.statusText)
          })
          .then(resp => {
            this.promoCodes = resp.data.map(a => {
              if(a.discounts) {
                a.discounts.map(ac => {
                  ac.euro_amount = this.$helpers.simpleCurrencyFormatter(ac.amount)

                  return ac
                })
              }

              return a
            })
          })
          .catch(() => {
            this.$root.app.showAlert('Error loading promo codes', 'danger')
          })
        })
    },

    rowSelected(ev) {
      this.selectedPromoCodes = ev
    },

    setStatusFilter(status) {
      this.statusFilter = status
    },

    setPartnerFilter(partner) {
      this.partnerFilter = partner
    },

    setSorter(sortOrder) {
      this.sorter = this.getSortFunction(sortOrder)
    },

    addPromoCode() {
      this.$refs.addPromoCode.show()
    },

    mutatePromoCode() {
      if(this.selectedPromoCodes && this.selectedPromoCodes.length > 0) {
        this.$refs.mutatePromoCode.show(this.selectedPromoCodes)
      }
    },

    activate() {
      let ids = this.selectedPromoCodes.map(a => a.id)
      const length = ids.length

      this.$http.postJSON(
        'admin/api/v2/promo-codes/activate',
        {data: ids},
        () => {
          if(length > 1 || (length == 1 && this.selectedPromoCodes[0].status != 'active')) {
            this.$root.app.showAlert("Promo code activation successful", "success")
            this.$root.$emit('promo-code-refresh')
          }
        },
        (status, html) => {
          if(status == 400 && JSON.parse(html).type == "promo_code_not_unique") {
            this.$root.app.showAlert("There's already a promo code active with the same code.", "danger")
          }
          else {
            this.$root.app.showErrorDialog(html, status.toString())
          }
        }
      )

    },

    deactivate() {
      let ids = this.selectedPromoCodes.map(a => a.id)
      const length = ids.length

      this.$http.postJSON(
        'admin/api/v2/promo-codes/deactivate',
        {data: ids},
        () => {
          if(length > 1 || (length == 1 && this.selectedPromoCodes[0].status == 'active')) {
            this.$root.app.showAlert("Promo code deactivation successful", "success")
            this.$root.$emit('promo-code-refresh')
          }
        },
        (status, html) => {
          this.$root.app.showErrorDialog(html, status.toString())
        }
      )

    },

    handleFiltered(items) {
      this.currentPage = 1
      this.totalRows = items.length
    },

    getPartnerName(partnerId) {
      const ps = this.partnerOptions.filter(p => p.value == partnerId)

      if(ps.length == 1) { return ps[0].text }
      else { return "" }
    },

    exportPromoCodes() {
      this.$http.postJSON(
        "/admin/api/v2/promo-codes/build-export",
        {},
        (resp) => {
          let file_name = "/admin/api/v2/promo-codes/download-export/" + resp.data;
          document.getElementById('export-promo-codes-frame').src = file_name;
        },
        (status, html) => {
          this.clear()
          this.$root.app.showErrorDialog(html, status.toString())
        }
      )
    },

    getSortFunction(sortOrder) {
      switch(sortOrder) {
        case "code-asc":
          return (a, b) => {
            let at = a.lowercase_code
            let bt = b.lowercase_code
            return at < bt ? -1 : at > bt ? 1 : 0

          }

        case "code-desc":
          return (a, b) => {
            let at = a.lowercase_code
            let bt = b.lowercase_code
            return at < bt ? 1 : at > bt ? -1 : 0

          }

        case "partner-asc":
          return (a, b) => {
            let at = this.getPartnerName(a.partner)
            let bt = this.getPartnerName(b.partner)
            return at < bt ? -1 : at > bt ? 1 : 0

          }

        case "partner-desc":
          return (a, b) => {
            let at = this.getPartnerName(a.partner)
            let bt = this.getPartnerName(b.partner)
            return at < bt ? 1 : at > bt ? -1 : 0

          }

        case "date-asc":
          return (a, b) => {
            let at = a.active_from
            let bt = b.active_from
            return at < bt ? -1 : at > bt ? 1 : 0

          }

        case "date-desc":
          return (a, b) => {
            let at = a.active_from
            let bt = b.active_from
            return at < bt ? 1 : at > bt ? -1 : 0

          }
      }
    },

    getDiscount(ds) {
      if(ds && ds.length > 0) {
        return this.$helpers.simpleCurrencyFormatter(ds[0].amount)
      }
      else {
        return 0
      }
    }
  },

  mounted() {
    this.$root.app.setActivePageTitle("Promo Codes", false)
    this.$root.$emit('set-toolbar', 'promo-code-toolbar')

    this.load()
  },

  created() {
    this.sorter = this.getSortFunction(this.sortOrder)
    this.partnerFilter = this.$store.state.media.partnerFilter


    this.$root.$on('promo-code-sort-order', this.setSorter)
    this.$root.$on('promo-code-status-filter', this.setStatusFilter)
    this.$root.$on('promo-code-partner-filter', this.setPartnerFilter)
    this.$root.$on('promo-code-add', this.addPromoCode)
    this.$root.$on('promo-code-refresh', this.load)
    this.$root.$on('promo-code-activate', this.activate)
    this.$root.$on('promo-code-deactivate', this.deactivate)
    this.$root.$on('promo-code-mutate', this.mutatePromoCode)
  },

  beforeDestroy() {
    this.$root.$off('promo-code-sort-order', this.setSorter)
    this.$root.$off('promo-code-status-filter', this.setStatusFilter)
    this.$root.$off('promo-code-partner-filter', this.setPartnerFilter)
    this.$root.$off('promo-code-add', this.addPromoCode)
    this.$root.$off('promo-code-refresh', this.load)
    this.$root.$off('promo-code-activate', this.activate)
    this.$root.$off('promo-code-deactivate', this.deactivate)
    this.$root.$off('promo-code-mutate', this.mutatePromoCode)
  }
}
</script>

<style scoped>

.no-head {
  display: none;
}

</style>