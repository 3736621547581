<template>
  <div :id="id" class="affiliate-offers">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <span class="offer" v-for="ac in discounts" :key="ac.offer_id">
          <b-badge tag="form" variant="primary" class="m-1">
            <b-form-group label="Offer" label-for="offer-input" horizontal>
              <b-form-input id="offer-input" :value="getOfferName(ac.offer_id)" readonly></b-form-input>
            </b-form-group>
            <b-form-group label="Discount" label-for="amount-input" horizontal>
              <b-form-input id="amount-input" v-model="ac.euro_amount" :readonly="readonly"> </b-form-input>
            </b-form-group>
          </b-badge>          
        </span>
      </div>
      <div v-if="!readonly" class="edit-keywords-button ml-auto">
        <b-button size="" variant="primary" @click.stop="showEditor">
          <Icon icon="edit"/>
           Edit
        </b-button>
      </div>
    </div>
    <EditPromoCodeOffers :offers="offers" :offerList="offerList" @update="$emit('update', $event)" ref="editKeywords"/>
  </div>
</template>

<script>
import EditPromoCodeOffers from "./EditPromoCodeOffers.vue"

export default {
  name: 'PromoCodeOffers',

  components: {
    EditPromoCodeOffers
  },

  data() {
    return {
      offerList: []
    }
  },
  
  props: {
    id: String,
    discounts: {type: Array, default: () => []},
    readonly: {type: Boolean, default: false}
  },

  computed: {
    offers() {
      return this.discounts.map(ac => ac.offer_id)
    }
  },

  methods: {
    load() {
      fetch("/admin/api/v2/promo-codes/available-offers")
      .then(function(resp) {
        return resp.json();
      })
      .then(resp => {
        this.offerList = this.buildOfferList(resp.data);
      });
    },

    buildOfferList(offers) {
      return offers
        .sort((a, b) => {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })
        .map(offer => { return {value: offer.id, text: offer.name, selected: this.isMember(offer.id)} })
    },

    isMember(k) {
      return this.offers.indexOf(k) >= 0
    },

    getOfferName(offerId) {
      for(var i = 0; i < this.offerList.length; ++i) {
        if(this.offerList[i].value == offerId) return this.offerList[i].text
      }

      return ''
    },

    showEditor() {
      this.$refs.editKeywords.show();
    },

    formatAmount(amount) {
      Math.round(amount / 100.0 + 0.00001)
    },

    updateAmount(ac, amount) {
      ac.amount = parseInt(amount * 100 + 0.00001)
    }
  },

  created() {
    this.load()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .keyword {
    margin-left: 4px;
  }

  .keywords {
    text-align: left;
  }

</style>
