var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-table", {
    attrs: { hover: "", items: _vm.processedItems, fields: _vm.fields },
    scopedSlots: _vm._u([
      {
        key: "cell(publish_status)",
        fn: function (row) {
          return [
            _c(
              "span",
              { staticClass: "rounded", class: _vm.publishClass(row.value) },
              [_vm._v(_vm._s(row.value))]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }