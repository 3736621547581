var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        title: "Add Partner",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: { ok: _vm.createPartner, hidden: _vm.clear },
    },
    [
      _c(
        "form",
        { attrs: { autocomplete: "off" } },
        [
          _c(
            "b-form-group",
            { attrs: { label: "ID", "label-for": "id-input", horizontal: "" } },
            [
              _c("b-input", {
                attrs: { id: "id-input", value: _vm.item.id, readonly: "" },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Can View Affiliates",
                "label-for": "can-view-affiliates-checkbox",
                horizontal: "",
              },
            },
            [
              _c("b-form-checkbox", {
                attrs: { id: "can-view-affiliates-checkbox", switch: "" },
                model: {
                  value: _vm.item.can_view_affiliates,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "can_view_affiliates", $$v)
                  },
                  expression: "item.can_view_affiliates",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Revshare TOA Default",
                "label-for": "no-rev-share-checkbox",
                horizontal: "",
              },
            },
            [
              _c("b-form-checkbox", {
                attrs: { id: "no-rev-share-checkbox", switch: "" },
                model: {
                  value: _vm.item.no_rev_share,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "no_rev_share", $$v)
                  },
                  expression: "item.no_rev_share",
                },
              }),
            ],
            1
          ),
          _vm.$config.usePremiumValue
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Default Premium Value",
                    "label-for": "default-premium-value-select",
                    horizontal: "",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "default-premium-value-select",
                      options: _vm.$store.state.media.options.premiumValue,
                    },
                    model: {
                      value: _vm.defaultPremiumValue,
                      callback: function ($$v) {
                        _vm.defaultPremiumValue = _vm._n($$v)
                      },
                      expression: "defaultPremiumValue",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Name",
                "label-for": "name-input",
                horizontal: "",
              },
            },
            [
              _c("b-form-input", {
                attrs: { id: "name-input" },
                model: {
                  value: _vm.item.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "name", $$v)
                  },
                  expression: "item.name",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Contact Person",
                "label-for": "contact-person-input",
                horizontal: "",
              },
            },
            [
              _c("b-form-input", {
                attrs: { id: "contact-person-input" },
                model: {
                  value: _vm.item.contact_person,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "contact_person", $$v)
                  },
                  expression: "item.contact_person",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Email",
                "label-for": "email-input",
                horizontal: "",
              },
            },
            [
              _c("b-form-input", {
                attrs: { id: "email-input" },
                model: {
                  value: _vm.item.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "email", $$v)
                  },
                  expression: "item.email",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Telephone",
                "label-for": "telephone-input",
                horizontal: "",
              },
            },
            [
              _c("b-form-input", {
                attrs: { id: "telephone-input" },
                model: {
                  value: _vm.item.telephone,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "telephone", $$v)
                  },
                  expression: "item.telephone",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Notes",
                "label-for": "notes-input",
                horizontal: "",
              },
            },
            [
              _c("b-form-textarea", {
                attrs: { id: "notes-input" },
                model: {
                  value: _vm.item.notes,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "notes", $$v)
                  },
                  expression: "item.notes",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _c(
            "b-alert",
            {
              attrs: {
                show: _vm.alert.dismissCountdown,
                dismissible: "",
                variant: _vm.alert.variant,
              },
              on: {
                dismissed: function ($event) {
                  _vm.alert.dismissCountdown = 0
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.alert.msg) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }