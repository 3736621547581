<template>
  <S3UploadModal multiple :item-id="itemId" :name="imageObjectName" :format= "format" @file-change="setImageFileName" modal-title="Add Image" @finished="$emit('finished')" @start-upload-request="startUpload($event)">
    <b-form-group label="Image set">
      <b-form-checkbox v-model="isImageSet"></b-form-checkbox>
    </b-form-group>
    <b-form-group v-if="isImageSet" label="Image set prefix">
      <b-form-input v-model="imageTitle"></b-form-input>
    </b-form-group>
    <b-form-group v-if="format != 'thumbnail'" label="Language">
      <b-form-select v-model="imageLanguage" :options="imageLanguageOptions"></b-form-select>
    </b-form-group>
  </S3UploadModal>
</template>

<script>
import S3UploadModal from "./S3UploadModal.vue"

export default {
  name: "AddImage",

  components: {
    S3UploadModal
  },

  props: {
    format: String,
    itemId: String,
    language: String
  },

  data() {
    return {
      imageFileName: "",
      imageTitle: "",
      imageLanguage: this.$config.defaultLanguage,
      imageLanguageOptions: [],

      isImageSet: false
    };
  },

  watch: {
    language(val) {
      if(val) {
        this.imageLanguage = val
      }
    }
  },
  computed: {
    imageObjectName() {
      let name = this.imageTitle && this.isImageSet ? `${this.$helpers.slugify(this.imageTitle)}.${this.format}` : this.format

      if(this.format == "slide" || this.format == "cover") {
        name = name + `.${this.imageLanguage}`
      }

      return name
    }
  },

  methods: {
    setImageFileName(ev) {
      if(ev && ev.name) {
        this.imageFileName = ev.name
      }
    },

    startUpload(ev) {
      if(this.isImageSet && (!this.imageTitle || this.imageTitle.length == 0)) {
        this.$root.app.showAlert("Image set prefix is missing!", "danger")
      }
      else {
        ev.handler()
      }
    }
  },

  mounted() {
    this.imageLanguage = this.language
    this.imageLanguageOptions = this.$store.getters['app/languageCodes'].map(code => {
      return {value: code, text: this.$helpers.getLanguageName(code)}
    })

    this.imageLanguageOptions.push({value: "all", text: "All"})

  }
};
</script>


<style scoped>
</style>

