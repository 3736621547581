var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: _vm.id } },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _c(
            "div",
            { staticClass: "geos" },
            _vm._l(_vm.value, function (g) {
              return _c(
                "span",
                { key: g, staticClass: "geo" },
                [
                  _c(
                    "b-badge",
                    { attrs: { variant: "secondary" } },
                    [
                      _vm._v(" " + _vm._s(_vm.codeToName(g)) + " "),
                      _vm.showEditor
                        ? _c(
                            "b-badge",
                            {
                              attrs: { href: "#", "data-value": g },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.removeGeo.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("✖")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "edit-geos-button ml-auto" },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    size: "",
                    variant: _vm.showEditor ? "warning" : "primary",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.toggleEditor.apply(null, arguments)
                    },
                  },
                },
                [_c("Icon", { attrs: { icon: "edit" } }), _vm._v(" Edit ")],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.showEditor
        ? _c(
            "b-input-group",
            { staticClass: "geo-editor" },
            [
              _c("AutoComplete", {
                attrs: {
                  "input-attrs": { class: "form-control" },
                  items: _vm.filteredGeos,
                  "get-label": _vm.getName,
                  "component-item": _vm.template,
                  "min-len": 0,
                },
                on: { "update-items": _vm.updateGeos },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.addGeo.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.geo,
                  callback: function ($$v) {
                    _vm.geo = $$v
                  },
                  expression: "geo",
                },
              }),
              _c(
                "b-button",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.addGeo.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Add")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }