<template>
  <SelectBox title="Edit Labels" size="xl" :values="allowManage ? managedLabels : labelsCopy" :items="labelSelectList" :allow-new="allowNew" ref="selectBox" @update="handleUpdate($event)"></SelectBox>
</template>

<script>
import SelectBox from './SelectBox.vue'

export default {
  name: 'EditLabels',

  components: {
    SelectBox
  },

  data() {
    return {
      labelSelectList: [],
      labelsCopy: [],
      labelsMut: [],
    };
  },

  props: {
    labels: Array,
    allowNew: {type: Boolean, default: false},
    allowManage: {type: Boolean, default: false},
    value: Boolean,
    labelType: {type: String, default: "label"}
  },

  computed: {
    url() {
      switch(this.labelType) {
        case "artist":
          return "/admin/api/v2/artists"

        case "genre":
          return "/admin/api/v2/genres"

        default:
          return "/admin/api/v2/labels"
      }
    },

    mutationName() {
      switch(this.labelType) {
        case "artist":
          return 'media/mutateMediaArtists'

        case "genre":
          return 'media/mutateMediaGenres'

        default:
          return 'media/mutateMediaLabels'
      }
    },

    managedLabels() {
      switch(this.labelType) {
        case "artist":
          return this.$store.state.media.managedArtists

        case "genre":
          return this.$store.state.media.managedGenres

        default:
          return this.$store.state.media.managedLabels
      }
    }
  },

  watch: {
    labels(x) {
      this.labelsCopy = x || []
    },
    value(value) {
      if(value) this.show()
    }
  },

  methods: {
    show() {
      fetch(this.url)
      .then(function(resp) {
        return resp.json();
      })
      .then(resp => {
        let allLabels = resp.data
        const values = this.allowManage ? this.labelsMut : this.labelsCopy

        if(values) {
          values.forEach(label => {
            if(!this.$_.includes(allLabels, label)) {
              allLabels.push(label)
            }
          })
        }
        this.labelSelectList = this.toLabelList(allLabels);
        this.$refs.selectBox.show()
      });
    },

    toLabelList(labels) {
      return labels
        .filter(label => label != null)
        .sort((a, b) => {
          return a < b ? -1 : a > b ? 1 : 0;
        })
        .map(label => { return {value: label, text: label, selected: this.isMember(label)} })
    },

    isMember(label) {
      const values = this.allowManage ? this.managedLabels : this.labelsCopy
      return values.indexOf(label) >= 0
    },

    updateValues(newLabels) {
      this.labelsCopy = newLabels
    },

    handleUpdate(newValues) {
      if(this.allowManage) {
        if(newValues) {
          const toRemove = this.$_.difference(this.managedLabels, newValues)
          const toAdd = this.$_.difference(newValues, this.managedLabels)
          this.$store.dispatch(this.mutationName, {toAdd: toAdd, toRemove: toRemove})
            .then(() => this.$root.app.showAlert(`${this.labelType == 'artist' ? 'Artists' : this.labelType == 'genre' ? 'Genres' : 'Labels'} updated`, 'success'))
           .catch(() => this.$root.app.showAlert(`There was an error while updating ${this.labelType == 'artist' ? 'artists' : this.labelType == 'genre' ? 'genres' : 'labels'}`, 'danger'))
        }
      }
      else if(newValues) {
        this.$emit('update', newValues)
      }
      this.$emit('input', false)
    }
  },

  created() {
    if(this.value) {
      this.show()
    }

    this.labelsCopy = this.labels
    this.$root.$on('media-label-filter-update', this.updateValues)
  },

  beforeDestroy() {
    this.$root.$off('media-label-filter-update', this.updateValues)
  }
}
</script>

<style>
</style>
