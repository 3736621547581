var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-button-toolbar",
        {
          attrs: { "key-nav": "", "aria-label": "Toolbar with button groups" },
        },
        [
          _c(
            "b-button-group",
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$root.$emit("home-content-export")
                    },
                  },
                },
                [
                  _c("Icon", { attrs: { icon: "export" } }),
                  _c("span", { staticClass: "ml-2" }, [
                    _vm._v("Content Overview Export"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }