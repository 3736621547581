<template>
  <div class="rev-share-billing" style="margin-top: 2rem">
    <div class="d-flex mb-4">
      <h5>Costs</h5>
      <h5 v-for="(share, tier) in $store.state.finance.totalCostShares" :key="tier" class="ml-2">
        <span>{{ $helpers.prettify(tier) }}: </span>
        <b-badge>{{$helpers.percentageFormatter(share)}}%</b-badge>
      </h5>
    </div>

    <div v-if="$config.enableAds && globalAmounts['ad_supported']" class="d-flex" style="margin-bottom: 3rem">
      <h5>
        <span class="font-weight-bold mr-4">Ad Revenue</span>
        <b-badge @click="enableAdEdit = !enableAdEdit">
          <span style="cursor: pointer; user-select: none;">{{ $helpers.currencyFormatter(globalAmounts['ad_supported'].amount) }}</span>
        </b-badge>
      </h5>
      <template v-if="!isPublished && enableAdEdit">
        <b-button size="sm" variant="primary" @click="updateAdRevenue()" class="ml-2">
          <Icon icon="edit"/>
        </b-button>
        <b-button size="sm" variant="danger" @click="removeAdRevenue()" class="ml-2">
          <font-awesome-icon icon="trash-alt"/>
        </b-button>
      </template>
    </div>

    <template v-if="revenueItems.length > 0">
      <div class="d-flex align-items-center justify-content-between" style="margin-bottom: 1.5rem">
        <h5 >Premium Geo Revenue</h5>
      </div>
      <b-table class="my-3" :items="revenueItems" :fields="revenueFields" striped>
        <template v-slot:cell(edit_revenue)="row">
          <div class="d-flex justify-content-center">
            <b-button size="sm" variant="primary" @click="row.toggleDetails" class="mr-2">
              <Icon icon="edit"/>
            </b-button>
            <b-button size="sm" variant="danger" @click="removeGeoRevenue(row)" class="mr-2">
              <font-awesome-icon icon="trash-alt"/>
            </b-button>
          </div>
        </template>

        <template v-slot:row-details="row">
          <b-container>
            <b-form>
              <b-form-group label="Country" label-for="add-geo-country" horizontal>
                <b-input id="add-geo-country" v-model="row.item.geo" readonly></b-input>
              </b-form-group>
              <b-form-group label="Revenue" label-for="add-geo-revenue" horizontal>
                <CurrencyInput v-model="row.item.amount"></CurrencyInput>
              </b-form-group>
            </b-form>

            <div class="d-flex flex-row-reverse mb-4">
              <b-button class="mx-1" @click="updateGeoRevenue(row)" variant="primary">
                <Icon icon="Save"/>
                Save
              </b-button>
            </div>

          </b-container>
        </template>
      </b-table>
    </template>

    <h5 class="my-4">Billing</h5>

    <div class="mb-4">
      <b-form inline v-if="isPublished && !invoicesSent">
        <b-button variant="warning" @click="confirmSendInvoices">
          Send Invoices
        </b-button>
        <b-form-group class="ml-4" label="TEST" label-for="invoices-to-self" v-b-tooltip.hover title="Send invoices to the email address of the currently logged in user instead of content partners." horizontal :label-cols="2">
          <b-form-checkbox class="ml-2" id="invoices-to-self" v-model="invoicesToSelf" switch />
        </b-form-group>
      </b-form>
      <template v-else-if="isPublished && invoicesSent">
        <b-badge class="ml-4">Invoices sent at {{$helpers.dtFormatter(invoicesSentAt)}}</b-badge>
      </template> 
    </div>

    <a ref="excelDownload" :href="excelExportHrefData" target="_blank" style="margin-top: 8rem;">Download export</a>

    <b-table class="mt-3" :items="billingItems" :fields="billingFields" striped>
      <template v-slot:cell(provider)="data">
        <span v-html="data.value"></span>
      </template>
    </b-table>

    <AddAdRevenue ref="addAdRevenue" :year="selectedYear" :month="selectedMonth"/>
    <AddGeoRevenue ref="addGeoRevenue" :year="selectedYear" :month="selectedMonth"/>
    <SimpleModal :value='!!removeRevenueParams' @ok="doRemoveRevenue(removeRevenueParams)" @cancel="removeRevenueParams = null" msg="Are you sure you want to delete the selected revenue?"/>
    <SimpleModal v-model='sendInvoicesModalVisible' @ok="handleSendInvoices" @cancel="sendInvoicesModalVisible = false" :msg="sendInvoicesConfirmText"/>
    <iframe id="download-frame" style="display:none;"></iframe>
  </div>
</template>

<script>
/*global ExcelBuilder */
import AddAdRevenue from '../components/AddAdRevenue.vue'
import AddGeoRevenue from '../components/AddGeoRevenue.vue'
import CurrencyInput from '../components/CurrencyInput.vue'
import SimpleModal from '../components/SimpleModal.vue'

export default {
  name: 'RevShareBilling',

  components: {
    AddAdRevenue,
    AddGeoRevenue,
    CurrencyInput,
    SimpleModal
  },

  data: function() {
    let billingFields = [
      {key: 'provider', label: 'Partner', sortable: true},
      {key: 'billable_amount', label: 'Content Amount (€)', formatter: this.$helpers.currencyFormatter, tdClass: ["text-right", "pr-4"], sortable: true}
    ]
 
    if(this.$config.enableShopping) {
      billingFields.push({key: 'shopping_partner_amount', label: 'Shopping Amount (€)', formatter: this.$helpers.currencyFormatter, tdClass: ["text-right", "pr-4"], sortable: true})
    }

    if(this.$config.enableShopping) {
      billingFields.push({key: 'affiliate_amount', label: 'Affiliate Amount (€)', formatter: this.$helpers.currencyFormatter, tdClass: ["text-right", "pr-4"], sortable: true})
    }

    billingFields.push({key: 'payout_amount', label: 'Payout Amount (€)', formatter: this.$helpers.currencyFormatter, tdClass: ["text-right", "pr-4"], sortable: true})

    return {
      revenueFields: [
        {key: 'geo', label: 'Country'},
        {key: 'amount', label: 'Revenue (€)', formatter: this.$helpers.currencyFormatter, tdClass: "text-right"},
        {key: 'edit_revenue', label: 'Edit'}
      ],

      billingFields: billingFields,

      excelExport: null,
      exportFileName: "",

      idMap: {},

      removeRevenueParams: null,
      enableAdEdit: false,
      sendInvoicesModalVisible: false,
      invoicesToSelf: false
    };
  },

  computed: {
    excelExportHrefData() {
      return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + this.excelExport
    },

    billingItems() {
      return this.$store.state.finance.billingItems
    },

    revenueItems() {
      return this.$store.state.finance.revenueItems
    },

    globalAmounts() {
      return this.$store.state.finance.globalAmounts
    },

    isPublished() {
      return this.$store.state.finance.isPublished
    },

    invoicesSent() {
      return this.$store.state.finance.invoicesSent
    },

    invoicesSentAt() {
      return this.$store.state.finance.invoicesSentAt
    },

    selectedYear() {
      return this.$store.state.finance.selectedYear
    },

    selectedMonth() {
      return this.$store.state.finance.selectedMonth
    },

    sendInvoicesConfirmText() {
      const monthName = this.$helpers.toMonthName(this.selectedMonth)
      const toSelf = `Are you sure you want to send ${monthName} ${this.selectedYear} invoices to yourself?`
      const toPartners = `Are you sure you want to send ${monthName} ${this.selectedYear} invoices to all content partners`

      return this.invoicesToSelf ? toSelf : toPartners
    }
  },

  watch: {
    billingItems() {
      this.load()
    },

    isPublished(v) {
      if(v) {
        this.revenueFields = [
          {key: 'geo', label: 'Country'},
          {key: 'amount', label: 'Revenue (€)', formatter: this.$helpers.currencyFormatter, tdClass: "text-right"},
        ]
      }
      else {
        this.revenueFields = [
          {key: 'geo', label: 'Country'},
          {key: 'amount', label: 'Revenue (€)', formatter: this.$helpers.currencyFormatter, tdClass: "text-right"},
          {key: 'edit_revenue', label: 'Edit'}
        ]
      }
    }

  },

  methods: {
    load() {
      fetch('/admin/api/v2/partners/external-id-map')
      .then(resp => resp.json())
      .then(json => {
        this.idMap = json.data
        this.excelExport = this.buildExcelExport(this.$store.state.finance.billingItems);
      });
    },

    buildExcelExport(data) {
      let workbook = ExcelBuilder.createWorkbook();
      let sheetName = `${this.selectedYear}${this.selectedMonth}`
      let lastDayOfMonth = new Date(this.selectedYear, this.selectedMonth, 0).getDate()
      this.setExportFilename(sheetName);
      let sheet = workbook.createWorksheet({name: sheetName});
      let stylesheet = workbook.getStyleSheet();
      let rows = data.map(row => {
        if(this.$config.enableExactExport) {
          return [this.idMap[row.provider_id], row.provider, '', `Uitbetaling ${this.selectedYear}-${this.selectedMonth}`, this.$helpers.fractionalCurrencyFormatter(row.billable_amount), this.$helpers.fractionalCurrencyFormatter(row.shopping_partner_amount), this.$helpers.fractionalCurrencyFormatter(row.payout_amount), '1', '', `${this.selectedYear}-${this.selectedMonth}-1`, `${this.selectedYear}-${this.selectedMonth}-${lastDayOfMonth}`, 'Self Billing Invoice']
        }
        else if(this.$config.enableShopping && this.$config.enableAffiliates) {
          return [this.idMap[row.provider_id], row.provider, this.$helpers.fractionalCurrencyFormatter(row.billable_amount), this.$helpers.fractionalCurrencyFormatter(row.shopping_partner_amount), this.$helpers.fractionalCurrencyFormatter(row.affiliate_amount), this.$helpers.fractionalCurrencyFormatter(row.payout_amount)]  
        }
        else if(this.$config.enableShopping) {
          return [this.idMap[row.provider_id], row.provider, this.$helpers.fractionalCurrencyFormatter(row.billable_amount), this.$helpers.fractionalCurrencyFormatter(row.shopping_partner_amount), this.$helpers.fractionalCurrencyFormatter(row.payout_amount)]
        }
        else if(this.$config.enableAffiliates) {
          return [this.idMap[row.provider_id], row.provider, this.$helpers.fractionalCurrencyFormatter(row.billable_amount), this.$helpers.fractionalCurrencyFormatter(row.affiliate_amount), this.$helpers.fractionalCurrencyFormatter(row.payout_amount)]
        }
        else {
          return [this.idMap[row.provider_id], row.provider, this.$helpers.fractionalCurrencyFormatter(row.billable_amount), this.$helpers.fractionalCurrencyFormatter(row.payout_amount)]
        }
      })
      let boldFormatter = stylesheet.createFormat({
        font: {
          bold: true,
          size: 11
        }
      });

      let unshift = [
        {value: 'External ID', metadata: {style: boldFormatter.id}},
        {value: 'Provider', metadata: {style: boldFormatter.id}},
        {value: 'Content Amount', metadata: {style: boldFormatter.id}}
      ]
      
      if(this.$config.enableExactExport) {
        unshift = [
          {value: 'External ID', metadata: {style: boldFormatter.id}},
          {value: 'Provider', metadata: {style: boldFormatter.id}},
          {value: 'Article Code', metadata: {style: boldFormatter.id}},
          {value: 'Invoice Line Description', metadata: {style: boldFormatter.id}},
          {value: 'Content Amount', metadata: {style: boldFormatter.id}},
          {value: 'Shopping Amount', metadata: {style: boldFormatter.id}},
          {value: 'Payout Amount', metadata: {style: boldFormatter.id}},
          {value: 'Count', metadata: {style: boldFormatter.id}},
          {value: 'Tax Code', metadata: {style: boldFormatter.id}},
          {value: 'Start Date', metadata: {style: boldFormatter.id}},
          {value: 'End Date', metadata: {style: boldFormatter.id}},
          {value: 'Description', metadata: {style: boldFormatter.id}}
        ]

      }
      else {
        if(this.$config.enableShopping) {
          unshift.push({value: 'Shopping Amount', metadata: {style: boldFormatter.id}})
        }

        if(this.$config.enableAffiliates) {
          unshift.push({value: 'Affiliate Amount', metadata: {style: boldFormatter.id}})
        }

        unshift.push({value: 'Payout Amount', metadata: {style: boldFormatter.id}})
      }
      
      rows.unshift(unshift);
      sheet.setData(rows);
      workbook.addWorksheet(sheet);
      return ExcelBuilder.createFile(workbook);
    },

    setExportFilename(name) {
      this.exportFileName = "billing-report-" + name + ".xlsx"
      this.$refs.excelDownload.download = this.exportFileName
    },

    confirmSendInvoices() {
      this.sendInvoicesModalVisible = true;
    },

    handleSendInvoices() {
      const payload = JSON.stringify({
        year: this.selectedYear,
        month: this.selectedMonth,
        send_to_self: this.invoicesToSelf
      })

      fetch('/admin/api/v2/rev-share/billing/send-invoices', {method: 'POST', body: payload})
      .then((resp) => {
        if(resp.status == 200) {
          this.$store.commit('finance/setInvoicesSent', true)
          this.$root.app.showAlert("Successfully sent revenue share invoices", "success")
        }
        else {
          this.$root.app.showAlert("Error sending revenue share invoices", "danger")
        }
      })
      this.sendInvoicesModalVisible = false;
    },

    handlePublish(publish) {
      const payload = JSON.stringify({
        publish: publish,
        year: this.selectedYear,
        month: this.selectedMonth
      })

      fetch('/admin/api/v2/rev-share/billing/publish', {method: 'POST', body: payload})
      .then((resp) => {
        if(resp.status == 200) {
          this.$store.commit('finance/setIsPublished', !this.isPublished)
        }
        else {
          this.$root.app.showAlert("Error changing publishing status of revenue", "danger")
        }
      })
    },

    addAdRevenue() {
      this.$refs.addAdRevenue.show()
    },

    updateAdRevenue() {
      const addRevenue = this.$store.state.finance.globalAmounts['ad_supported']
      this.$refs.addAdRevenue.show(addRevenue ? addRevenue.amount / 100.0 : null)
    },

    removeAdRevenue() {
      const addRevenue = this.$store.state.finance.globalAmounts['ad_supported']
      if(addRevenue) {
        this.removeRevenueParams = {
          rev_share_id: addRevenue.rev_share_id,
          tier: addRevenue.tier,
          geo: null
        }
      }
    },

    addGeoRevenue() {
      this.$refs.addGeoRevenue.show()
    },

    updateGeoRevenue(row) {
      if(row.item.amount) {
        let payload = JSON.stringify({
          data: {
            rev_share_id: row.item.rev_share_id,
            tier: row.item.tier,
            geo: row.item.geo,
            amount: parseInt(row.item.amount)
          }
        })

        fetch('/admin/api/v2/rev-share/billing/revenue', {method: 'PUT', body: payload})
        .then(function(resp) {
          if(resp.status != 200) {
            throw Error(resp.statusText)
          }
          else  {
            return resp.json()
          }
        })
        .then(() => {
          this.$store.dispatch('finance/fetchAll')
          this.$root.app.showAlert("Revenue successfully updated", "success")
        })
        .catch(() => {
          this.$root.app.showErrorDialog("Revenue update failed", "Error")
        })
      }
      else {
        this.$root.app.showAlert("Please provide revenue", "danger")
      }
    },

    removeGeoRevenue(row) {
      this.removeRevenueParams = {
        rev_share_id: row.item.rev_share_id,
        geo: row.item.geo,
        tier: row.item.tier
      }
    },

    doRemoveRevenue(params) {
      let payload = JSON.stringify({
        data: params
      })

      fetch('/admin/api/v2/rev-share/billing/revenue', {method: 'DELETE', body: payload})
      .then(resp => {
        this.removeRevenueId = null

        if(resp.status != 200) {
          throw Error(resp.statusText)
        }
        else  {
          return resp.json()
        }
      })
      .then(() => {
        this.$store.dispatch('finance/fetchAll')
        this.$root.app.showAlert("Revenue successfully removed", "success")
      })
      .catch(() => {
        this.$root.app.showErrorDialog("Revenue remove failed", "Error")
      })
    },

    closeRevenueEditor(row) {
      row.item._showDetails = false
    },
  },

  created() {
    this.$root.$on('rev-share-add-ad-revenue', this.addAdRevenue)
    this.$root.$on('rev-share-add-geo-revenue', this.addGeoRevenue)
    this.$root.$on('rev-share-publish-month', this.handlePublish)
  },

  beforeDestroy() {
    this.$root.$off('rev-share-add-ad-revenue', this.addAdRevenue)
    this.$root.$off('rev-share-add-geo-revenue', this.addGeoRevenue)
    this.$root.$off('rev-share-publish-month', this.handlePublish)
  }
}
</script>

<style scoped>

.header-hide {
  display: none;
}
</style>
