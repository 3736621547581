<template>
  <div class="ddex-import mt-2">
    <DdexImport/>
  </div>
</template>

<script>
import DdexImport from "../components/DdexImport.vue"

export default {
  components: {
    DdexImport
  }
}
</script>

<style scoped>

</style>
