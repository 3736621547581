var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-top": "2rem" } },
    [
      _c("div", [
        _c(
          "a",
          {
            ref: "aprDownload",
            attrs: {
              href: _vm.aprHrefData,
              download: "providers-aggregated.xlsx",
              target: "_blank",
            },
          },
          [_vm._v("Download Aggregated Partner Report")]
        ),
      ]),
      _c("div", [
        _c(
          "a",
          {
            ref: "dprDownload",
            attrs: {
              href: _vm.dprHrefData,
              download: "providers-detailed.xlsx",
              target: "_blank",
            },
          },
          [_vm._v("Download Detailed Partner Report")]
        ),
      ]),
      _c("div", [
        _c(
          "a",
          {
            ref: "crDownload",
            attrs: {
              href: _vm.crHrefData,
              download: "countries.xlsx",
              target: "_blank",
            },
          },
          [_vm._v("Download Country Report")]
        ),
      ]),
      _c("div", { staticClass: "mb-4" }, [
        _c(
          "a",
          {
            ref: "prDownload",
            attrs: {
              href: _vm.prHrefData,
              download: "providers-totals.xlsx",
              target: "_blank",
            },
          },
          [_vm._v("Download Partners Totals Report")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c(
            "h5",
            [
              _c(
                "b-badge",
                {
                  staticClass: "mr-4",
                  attrs: { variant: _vm.isPublished ? "primary" : "danger" },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.isPublished ? "Published" : "Unpublished")
                    ),
                  ]),
                ]
              ),
              _c("span", [_vm._v("TOA Total: ")]),
              _c("b-badge", [
                _vm._v(
                  "€ " +
                    _vm._s(
                      _vm.$helpers.currencyFormatter(_vm.noRevShareTotalAmount)
                    )
                ),
              ]),
            ],
            1
          ),
          _vm._l(
            _vm.$store.state.finance.totalLengthShares,
            function (share, tier) {
              return _c(
                "h5",
                { key: tier, staticClass: "ml-4" },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$helpers.prettify(tier)) + ": "),
                  ]),
                  _c("b-badge", [
                    _vm._v(
                      _vm._s(_vm.$helpers.percentageFormatter(share)) + "%"
                    ),
                  ]),
                ],
                1
              )
            }
          ),
        ],
        2
      ),
      _c(
        "b-row",
        { staticClass: "mr-0 mt-4" },
        [
          _c(
            "b-col",
            [
              _c(
                "h5",
                { staticStyle: { "margin-bottom": "2rem", height: "2rem" } },
                [_vm._v("Partners")]
              ),
              _c("b-table", {
                ref: "table",
                staticClass: "mt-3",
                attrs: {
                  items: _vm.providerItems,
                  fields: _vm.providerFields,
                  striped: "",
                },
              }),
            ],
            1
          ),
          _c("b-col"),
        ],
        1
      ),
      _c("h5", { staticStyle: { "margin-top": "4rem" } }, [_vm._v("Data")]),
      _c("b-table", {
        ref: "table",
        staticClass: "mt-3",
        attrs: {
          items: _vm.contentItems,
          fields: _vm.contentFields,
          striped: "",
          hover: "",
          small: "",
          "per-page": _vm.perPage,
          "current-page": _vm.currentPage,
        },
        on: { "row-clicked": _vm.handleSelectedRow },
      }),
      _vm.perPage
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-center" },
            [
              _c("b-pagination", {
                attrs: {
                  "total-rows": _vm.contentItems.length,
                  "per-page": _vm.perPage,
                },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("RevShareContentDetailsModal", { ref: "contentDetailsModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }