<template>
    <div class="mb-12">
        <b-button
            v-b-toggle="'collapse-' + _uid"
            variant="outline-dark"
            block
            class="text-left ddex-expandable-button"
        >
            <span>{{ title }}</span>
            <span class="chevron float-right" :class="{ 'chevron-up': isCollapsed }">&#x25BC;</span>

        </b-button>
        <b-collapse
            :id="'collapse-' + _uid"
            class="mt-2"
            @show="toggleCollapse"
            @hidden="toggleCollapse"
        >
            <b-card header-bg-variant="dark" header-text-variant="white" text-variant="dark">
                <dl class="mb-0">
                    <template v-for="(value, key) in content">
                        <dt :key="key + '-title'">{{ key }}</dt>
                        <dd :key="key + '-value'">
                            <pre>{{ value }}</pre>
                        </dd>
                    </template>
                </dl>
            </b-card>
        </b-collapse>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        content: Object,
    },
    data() {
        return {
            isCollapsed: true,
        };
    },
    methods: {
        toggleCollapse() {
            this.isCollapsed = !this.isCollapsed;
        },
    },
};
</script>
<style>
    pre {
        white-space: pre-wrap;
        color: white;
    }
    .ddex-expandable-button {
        margin-top: 5px;
        border-radius: 5px;
        border-width: 2px;
        font-weight: bold;
        transition: all 0.3s ease;
    }

    .ddex-expandable-button:hover {
        background-color: #f8f9fa;
        color: black;
    }
    .chevron {
        font-weight: bold;
        transition: transform 0.3s ease;
        transform: rotate(180deg);
    }

    .chevron-up {
        transform: rotate(0deg);
    }
</style>