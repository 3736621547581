<template>
  <b-modal ref="modal" :title="title" @ok="addRevenue" @hidden="clear">
    <b-form>
      <b-form-group label="Country" label-for="add-geo-country" horizontal>
        <b-form-select id="add-geo-country" v-model="selectedGeo" :options="geoOptions"></b-form-select>
      </b-form-group>
      <b-form-group label="Revenue" label-for="add-geo-revenue" horizontal>
        <b-input v-model="revenue"></b-input>
      </b-form-group>
    </b-form>
    <transition name="fade">
      <b-alert :show="alert.dismissCountdown" dismissible :variant="alert.variant" @dismissed="alert.dismissCountdown = 0">
        {{ alert.msg }}
      </b-alert>
    </transition>
  </b-modal>
</template>

<script>
export default {
  name: "AddGeoRevenue",

  props: {
    year: Number,
    month: Number
  },

  data() {
    return {
      selectedGeo: null,
      geoOptions: [],

      revenue: 0.00,

      alert: {
        dismissCountdown: 0,
        variant: "warning",
        msg: ""
      }
    };
  },

  computed: {
    title() {
      return `Add ${this.$helpers.toMonthName(this.month)} ${this.year} Revenue`;
    }
  },

  methods: {
    show() {
      fetch('/admin/api/v2/rev-share/geo-options')
      .then(function(resp) {
        return resp.json();
      })
      .then(resp => {
        this.geoOptions = resp.data;
        this.$refs.modal.show();
      });
    },

    clear() {
    },


    addRevenue(ev) {
      if(this.revenue && this.revenue > 0 && this.selectedGeo) {
        let payload = JSON.stringify({
          data: {
            year: this.year,
            month: this.month,
            geo: this.selectedGeo,
            tier: "premium",
            amount: parseFloat(this.revenue) * 100
          }
        })

        fetch('/admin/api/v2/rev-share/billing/revenue', {method: 'POST', body: payload})
        .then(function(resp) {
          if(resp.status == 400) {
            throw Error()
          }
          return resp.json();
        })
        .then(() => {
          this.$store.dispatch('finance/fetchAll')
          this.$root.app.showAlert("Revenue saved", "success")
        })
        .catch(() => {
          this.$root.app.showAlert("Error saving revenue", "danger")
        })
      }
      else {
        if(!this.revenue || this.revenue == 0) {
          this.alert.msg = "Please provide revenue"
        }
        else if(!this.selectedGeo) {
          this.alert.msg = "Please provide a country"
        }

        this.alert.dismissCountdown = 4
        ev.preventDefault()
      }
    },

    itemIsValid() {
      return this.$_.reduce(
        this.item,
        (acc, value, key) =>
          !(this.$helpers.isBlank(value) || key == "group_id") ? true : acc,
        false
      );
    }
  },

  created() {
    this.clear();
  }
};
</script>


<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

