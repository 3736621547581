<template>
  <p>{{ item.name }}</p>
</template>

<script>
export default {
  name: 'GeoTemplate',
  props: {
    item: Object
  }
}
</script>
