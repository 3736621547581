var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ml-auto py-2 pl-2" }, [
    _c(
      "div",
      { staticStyle: { "margin-right": "3em" } },
      [
        _vm.statsType != "current-users"
          ? _c(
              "b-form",
              { attrs: { inline: "" } },
              [
                _vm.selectedUserType == "single_user"
                  ? _c(
                      "b-input-group",
                      { staticClass: "user-search" },
                      [
                        _c("AutoComplete", {
                          attrs: {
                            "input-attrs": { class: "form-control" },
                            items: _vm.matchedUsers,
                            "get-label": _vm.getName,
                            "component-item": _vm.template,
                            "min-len": 0,
                          },
                          on: { "update-items": _vm.updateUserSearch },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.updateSelectedUser.apply(
                                null,
                                arguments
                              )
                            },
                          },
                          model: {
                            value: _vm.selectedUser,
                            callback: function ($$v) {
                              _vm.selectedUser = $$v
                            },
                            expression: "selectedUser",
                          },
                        }),
                        _c(
                          "b-button",
                          {
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.updateSelectedUser.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [_vm._v("Load")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.statsType == "content" || _vm.statsType == "partners"
                  ? [
                      _c(
                        "label",
                        {
                          staticClass: "mr-sm-2 text-secondary",
                          attrs: { for: "stats-select-user-type" },
                        },
                        [_vm._v("User Type")]
                      ),
                      _c("b-form-select", {
                        staticClass: "mr-2",
                        attrs: {
                          id: "stats-select-user-type",
                          value: _vm.selectedUserType,
                          options: _vm.userTypeOptions,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.updateUserType($event)
                          },
                        },
                      }),
                    ]
                  : _vm._e(),
                _c(
                  "label",
                  {
                    staticClass: "mr-sm-2 text-secondary",
                    attrs: { for: "stats-select-from" },
                  },
                  [_vm._v("From")]
                ),
                _c("Datepicker", {
                  staticClass: "mb-2 mr-sm-2 mb-sm-0",
                  attrs: {
                    id: "stats-select-from",
                    value: _vm.from,
                    name: "from-date",
                    "monday-first": "",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.updateFrom($event)
                    },
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "mr-sm-2 text-secondary",
                    attrs: { for: "stats-select-from" },
                  },
                  [_vm._v("To")]
                ),
                _c("Datepicker", {
                  staticClass: "mb-2 mr-sm-2 mb-sm-0",
                  attrs: {
                    id: "stats-select-to",
                    value: _vm.to,
                    "disabled-dates": _vm.disabledToDates,
                    name: "to-date",
                    "monday-first": "",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.updateTo($event)
                    },
                  },
                }),
              ],
              2
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }