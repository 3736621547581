var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add-media" },
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            id: "add-media-modal",
            size:
              _vm.hasParent &&
              (_vm.mediaType != "category" || _vm.mediaType != "playlist_page")
                ? ""
                : "xl",
            title: _vm.title,
            "ok-disabled": !(
              (_vm.mediaType == "category" || _vm.mediaPartner) &&
              _vm.mediaTitle &&
              (_vm.photoFileCount > 0 || _vm.mediaType != "photo_package")
            ),
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
          },
          on: { shown: _vm.handleFocus, ok: _vm.createMedia },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-content" },
            [
              _c(
                "b-form",
                { attrs: { id: "edit-form" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "ID",
                        "label-for": "id-input",
                        horizontal: "",
                        "label-cols": 2,
                      },
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          id: "id-input",
                          value: _vm.mediaId,
                          readonly: "",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.hasParent
                    ? [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Content Provider",
                              "label-for": "partner-select",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                id: "partner-select",
                                options: _vm.$store.state.media.options.partner,
                              },
                              model: {
                                value: _vm.mediaPartner,
                                callback: function ($$v) {
                                  _vm.mediaPartner = $$v
                                },
                                expression: "mediaPartner",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.mediaType == "photo_package"
                    ? _c(
                        "b-form-group",
                        {
                          staticClass: "overflow-hidden",
                          attrs: {
                            label: "Select File",
                            "label-for": "file-input",
                            horizontal: "",
                            "label-cols": 2,
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "text-left" },
                            [
                              _c("S3Uploader", {
                                ref: "uploader",
                                attrs: {
                                  itemId: _vm.mediaId,
                                  name: "image.primary",
                                  format: "image",
                                  multiple: false,
                                  nameAsPath: false,
                                },
                                on: {
                                  finished: _vm.handleFinished,
                                  "upload-state": function ($event) {
                                    _vm.photoUploadState = $event
                                  },
                                  "file-count": _vm.handleFileCount,
                                  "start-upload-request": function ($event) {
                                    return $event.handler()
                                  },
                                  progress: _vm.handleUploadProgress,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.$config.useExternalIDs
                    ? [
                        _vm.$helpers.hasUPC(_vm.mediaType)
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "UPC",
                                  "label-for": "upc-input",
                                  horizontal: "",
                                  "label-cols": 2,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { id: "upc-input" },
                                  model: {
                                    value: _vm.mediaUPC,
                                    callback: function ($$v) {
                                      _vm.mediaUPC = $$v
                                    },
                                    expression: "mediaUPC",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$helpers.hasISRC(_vm.mediaType)
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "ISRC",
                                  "label-for": "isrc-input",
                                  horizontal: "",
                                  "label-cols": 2,
                                },
                              },
                              [
                                _c("b-form-textarea", {
                                  attrs: { id: "isrc-input" },
                                  model: {
                                    value: _vm.mediaISRC,
                                    callback: function ($$v) {
                                      _vm.mediaISRC = $$v
                                    },
                                    expression: "mediaISRC",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$helpers.hasISRC(_vm.mediaType)
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Reporting Code",
                                  "label-for": "reporting-code-input",
                                  horizontal: "",
                                  "label-cols": 2,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { id: "reporting-code-input" },
                                  model: {
                                    value: _vm.mediaReportingCode,
                                    callback: function ($$v) {
                                      _vm.mediaReportingCode = $$v
                                    },
                                    expression: "mediaReportingCode",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  (_vm.mediaType == "music_album" ||
                    _vm.mediaType == "album_track" ||
                    _vm.$config.enableArtistsEverywhere) &&
                  _vm.mediaType != "category"
                    ? [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Artists",
                              "label-for": "artists-comp",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("LabelsInput", {
                              attrs: {
                                id: "artists-comp",
                                language: _vm.mediaDefaultLang,
                                labels: _vm.mediaArtists,
                                "label-type": "artist",
                              },
                              on: { update: _vm.handleArtists },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Title",
                        "label-for": "title-input",
                        horizontal: "",
                        "label-cols": 2,
                      },
                    },
                    [
                      _c("b-form-input", {
                        ref: "titleInput",
                        attrs: { id: "title-input" },
                        model: {
                          value: _vm.mediaTitle,
                          callback: function ($$v) {
                            _vm.mediaTitle = $$v
                          },
                          expression: "mediaTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Secondary Title",
                        "label-for": "sec-title-input",
                        horizontal: "",
                        "label-cols": 2,
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: { id: "sec-title-input" },
                        model: {
                          value: _vm.mediaSecTitle,
                          callback: function ($$v) {
                            _vm.mediaSecTitle = $$v
                          },
                          expression: "mediaSecTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.mediaType == "playlist_page"
                    ? [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Format",
                              "label-for": "format-select",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                id: "format-select",
                                options:
                                  _vm.$store.state.media.options
                                    .playlistAssetFormat,
                              },
                              model: {
                                value: _vm.mediaAssetFormat,
                                callback: function ($$v) {
                                  _vm.mediaAssetFormat = $$v
                                },
                                expression: "mediaAssetFormat",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.$helpers.isPlayable(_vm.mediaType) ||
                  _vm.$helpers.isTopLevel(_vm.mediaType)
                    ? [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Plan",
                              "label-for": "plan-select",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                id: "plan-select",
                                options: _vm.$store.state.media.options.plan,
                              },
                              model: {
                                value: _vm.mediaPlan,
                                callback: function ($$v) {
                                  _vm.mediaPlan = $$v
                                },
                                expression: "mediaPlan",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.$helpers.isPlayable(_vm.mediaType)
                    ? [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Revshare TOA",
                              "label-for": "no-rev-share-checkbox",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("b-form-checkbox", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              attrs: {
                                id: "no-rev-share-checkbox",
                                title: "Puts revenue share to own account",
                                switch: "",
                              },
                              model: {
                                value: _vm.mediaNoRevShare,
                                callback: function ($$v) {
                                  _vm.mediaNoRevShare = $$v
                                },
                                expression: "mediaNoRevShare",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.$config.usePremiumValue &&
                        _vm.mediaPlan != "free" &&
                        _vm.isPremiumType(_vm.mediaType)
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Premium Value",
                                  "label-for": "premium-value-select",
                                  horizontal: "",
                                  "label-cols": 2,
                                },
                              },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    id: "premium-value-select",
                                    options:
                                      _vm.$store.state.media.options
                                        .premiumValue,
                                  },
                                  model: {
                                    value: _vm.mediaPremiumValue,
                                    callback: function ($$v) {
                                      _vm.mediaPremiumValue = _vm._n($$v)
                                    },
                                    expression: "mediaPremiumValue",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm.mediaType == "external"
                    ? [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Link to",
                              "label-for": "link-input",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "link-input" },
                              model: {
                                value: _vm.mediaLinkTo,
                                callback: function ($$v) {
                                  _vm.mediaLinkTo = $$v
                                },
                                expression: "mediaLinkTo",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.$helpers.hasAssets(_vm.mediaType)
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Asset source",
                            "label-for": "asset-source-select",
                            horizontal: "",
                            "label-cols": 2,
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "asset-source-select",
                              options: _vm.$store.state.media.options.asset,
                            },
                            model: {
                              value: _vm.mediaAssetSource,
                              callback: function ($$v) {
                                _vm.mediaAssetSource = $$v
                              },
                              expression: "mediaAssetSource",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$config.enableGenres && _vm.mediaType != "category"
                    ? [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Genres",
                              "label-for": "genres-comp",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("LabelsInput", {
                              attrs: {
                                id: "genres-comp",
                                language: _vm.mediaDefaultLang,
                                labels: _vm.mediaGenres,
                                "label-type": "genre",
                              },
                              on: { update: _vm.handleGenres },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  !_vm.hasParent
                    ? [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Fallback Language",
                              "label-for": "default-language-select",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                id: "default-language-select",
                                options:
                                  _vm.$store.state.media.options.language,
                              },
                              model: {
                                value: _vm.mediaDefaultLang,
                                callback: function ($$v) {
                                  _vm.mediaDefaultLang = $$v
                                },
                                expression: "mediaDefaultLang",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Labels",
                              "label-for": "labels-comp",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("LabelsInput", {
                              attrs: {
                                id: "labels-comp",
                                language: _vm.mediaDefaultLang,
                                labels: _vm.mediaLabels,
                              },
                              on: { update: _vm.handleLabels },
                            }),
                          ],
                          1
                        ),
                        _vm.mediaType == "photo_package"
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Enable Shopping",
                                    "label-for": "shopping-enabled-checkbox",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      id: "shopping-enabled-checkbox",
                                      switch: "",
                                    },
                                    model: {
                                      value: _vm.mediaShoppingEnabled,
                                      callback: function ($$v) {
                                        _vm.mediaShoppingEnabled = $$v
                                      },
                                      expression: "mediaShoppingEnabled",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm.mediaType == "playlist_page"
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Type",
                                    "label-for": "category-type-select",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "category-type-select",
                                      options:
                                        _vm.$store.state.media.options
                                          .playlistPageCategoryType,
                                    },
                                    model: {
                                      value: _vm.mediaCategoryType,
                                      callback: function ($$v) {
                                        _vm.mediaCategoryType = $$v
                                      },
                                      expression: "mediaCategoryType",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : [
                              _vm.$helpers.isPlayableTopLevel(_vm.mediaType)
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Kids Content",
                                        "label-for": "is-kids-content-checkbox",
                                        horizontal: "",
                                        "label-cols": 2,
                                      },
                                    },
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          id: "is-kids-content-checkbox",
                                          switch: "",
                                        },
                                        model: {
                                          value: _vm.mediaIsKidsContent,
                                          callback: function ($$v) {
                                            _vm.mediaIsKidsContent = $$v
                                          },
                                          expression: "mediaIsKidsContent",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Quality Stamp",
                                    "label-for": "quality-stamp-checkbox",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      id: "quality-stamp-checkbox",
                                      switch: "",
                                    },
                                    model: {
                                      value: _vm.mediaQualityStamp,
                                      callback: function ($$v) {
                                        _vm.mediaQualityStamp = $$v
                                      },
                                      expression: "mediaQualityStamp",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Enable Slides",
                                    "label-for": "slide-enabled-checkbox",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      id: "slide-enabled-checkbox",
                                      switch: "",
                                    },
                                    model: {
                                      value: _vm.mediaSlideEnabled,
                                      callback: function ($$v) {
                                        _vm.mediaSlideEnabled = $$v
                                      },
                                      expression: "mediaSlideEnabled",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Year of Release",
                              "label-for": "year-input",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "year-input" },
                              model: {
                                value: _vm.mediaYear,
                                callback: function ($$v) {
                                  _vm.mediaYear = _vm._n($$v)
                                },
                                expression: "mediaYear",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Schedule",
                              "label-for": "publication-comp",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("Publication", {
                              attrs: { id: "publication-comp" },
                              model: {
                                value: _vm.item,
                                callback: function ($$v) {
                                  _vm.item = $$v
                                },
                                expression: "item",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.$helpers.isLivestreamable(_vm.mediaType)
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Is Livestream",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      id: "is-livestream-checkbox",
                                      switch: "",
                                    },
                                    model: {
                                      value: _vm.mediaIsLivestream,
                                      callback: function ($$v) {
                                        _vm.mediaIsLivestream = $$v
                                      },
                                      expression: "mediaIsLivestream",
                                    },
                                  }),
                                  _vm.mediaIsLivestream
                                    ? _c(
                                        "div",
                                        { staticClass: "mt-2" },
                                        [
                                          _c("Publication", {
                                            attrs: {
                                              id: "datetime-livestream-comp",
                                              "from-field":
                                                "datetime_livestream_start",
                                              "to-field":
                                                "datetime_livestream_end",
                                            },
                                            model: {
                                              value: _vm.item,
                                              callback: function ($$v) {
                                                _vm.item = $$v
                                              },
                                              expression: "item",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Geo Whitelist",
                              "label-for": "whitelist-comp",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("Geos", {
                              attrs: { id: "whitelist-comp", url: _vm.geosUrl },
                              model: {
                                value: _vm.mediaGeoWhitelist,
                                callback: function ($$v) {
                                  _vm.mediaGeoWhitelist = $$v
                                },
                                expression: "mediaGeoWhitelist",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Geo Blackist",
                              "label-for": "blacklist-comp",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("Geos", {
                              attrs: { id: "blacklist-comp", url: _vm.geosUrl },
                              model: {
                                value: _vm.mediaGeoBlacklist,
                                callback: function ($$v) {
                                  _vm.mediaGeoBlacklist = $$v
                                },
                                expression: "mediaGeoBlacklist",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm.mediaType == "category"
                    ? [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Enable More Page",
                              "label-for": "page-enabled-checkbox",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("b-form-checkbox", {
                              attrs: {
                                id: "page-enabled-checkbox",
                                switch: "",
                              },
                              model: {
                                value: _vm.mediaPageEnabled,
                                callback: function ($$v) {
                                  _vm.mediaPageEnabled = $$v
                                },
                                expression: "mediaPageEnabled",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Type",
                              "label-for": "category-type-select",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                id: "category-type-select",
                                options:
                                  _vm.$store.state.media.options.categoryType,
                              },
                              model: {
                                value: _vm.mediaCategoryType,
                                callback: function ($$v) {
                                  _vm.mediaCategoryType = $$v
                                },
                                expression: "mediaCategoryType",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Ad Mode",
                              "label-for": "category-ad-mode-select",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                id: "category-ad-mode-select",
                                options:
                                  _vm.$store.state.media.options.categoryAdMode,
                              },
                              model: {
                                value: _vm.mediaCategoryAdMode,
                                callback: function ($$v) {
                                  _vm.mediaCategoryAdMode = $$v
                                },
                                expression: "mediaCategoryAdMode",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Fallback Language",
                              "label-for": "default-language-select",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                id: "default-language-select",
                                options:
                                  _vm.$store.state.media.options.language,
                              },
                              model: {
                                value: _vm.mediaDefaultLang,
                                callback: function ($$v) {
                                  _vm.mediaDefaultLang = $$v
                                },
                                expression: "mediaDefaultLang",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Schedule",
                              "label-for": "publication-comp",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("Publication", {
                              attrs: { id: "publication-comp" },
                              model: {
                                value: _vm.item,
                                callback: function ($$v) {
                                  _vm.item = $$v
                                },
                                expression: "item",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Geo Whitelist",
                              "label-for": "whitelist-comp",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("Geos", {
                              attrs: { id: "whitelist-comp", url: _vm.geosUrl },
                              model: {
                                value: _vm.mediaGeoWhitelist,
                                callback: function ($$v) {
                                  _vm.mediaGeoWhitelist = $$v
                                },
                                expression: "mediaGeoWhitelist",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Geo Blackist",
                              "label-for": "blacklist-comp",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("Geos", {
                              attrs: { id: "blacklist-comp", url: _vm.geosUrl },
                              model: {
                                value: _vm.mediaGeoBlacklist,
                                callback: function ($$v) {
                                  _vm.mediaGeoBlacklist = $$v
                                },
                                expression: "mediaGeoBlacklist",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm.photoUploadState == "uploading"
                ? [
                    _c("b-progress", {
                      staticStyle: { width: "200px" },
                      attrs: {
                        value: _vm.photoUploadProgressValue,
                        max: _vm.photoUploadProgressMax,
                        "show-progress": "",
                        animated: "",
                        height: "40px",
                      },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }