var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SelectBox", {
    ref: "selectBox",
    attrs: {
      title: "Select Offers",
      size: "lg",
      values: _vm.offers,
      items: _vm.offerList,
    },
    on: {
      update: function ($event) {
        return _vm.$emit("update", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }