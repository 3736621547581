var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100vh" }, attrs: { id: "app" } },
    [
      _vm.$store.state.app.initReady
        ? [
            _c(
              "b-container",
              {
                attrs: { id: "main-container", fluid: "" },
                on: {
                  "show-menu": function ($event) {
                    _vm.menuActive = true
                  },
                  "hide-menu": function ($event) {
                    _vm.menuActive = false
                  },
                },
              },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        class: { active: _vm.menuActive },
                        attrs: { id: "menu-col", cols: _vm.menuActive ? 2 : 1 },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center w-100 my-3",
                          },
                          [
                            _c("b-img", {
                              attrs: { src: _vm.$config.logo, height: "27px" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "overflow-y": "auto",
                              "max-height": "calc(100vh - 50px)",
                              "min-width": "175px",
                            },
                          },
                          [
                            _c("Menu", {
                              ref: "menu",
                              staticStyle: { "margin-top": "20px" },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "b-col",
                      { attrs: { id: "content-col" } },
                      [
                        _c("Bar", {
                          ref: "bar",
                          attrs: {
                            "is-media-page": _vm.isMediaPage,
                            "active-page-title": _vm.activePageTitle,
                          },
                        }),
                        _c(
                          "b-overlay",
                          {
                            attrs: {
                              show: _vm.$store.state.app.overlay,
                              rounded: "sm",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mx-4" },
                              [_c("router-view")],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("ErrorDialog", { ref: "appErrorDialog" }),
          ]
        : _vm.loggedInChecked && !_vm.$store.state.session.loggedIn
        ? [_c("Login")]
        : [_vm._m(0)],
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.alert.dismissCountdown > 0
          ? _c(
              "footer",
              { staticClass: "footer" },
              [
                _c(
                  "b-alert",
                  {
                    attrs: {
                      show: _vm.alert.dismissCountdown,
                      dismissible: "",
                      variant: _vm.alert.variant,
                    },
                    on: {
                      dismissed: function ($event) {
                        _vm.alert.dismissCountdown = 0
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.alert.content) + " ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loader-holder" }, [
      _c("div", { staticClass: "lds-dual-ring" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }