<template>
  <div class="ml-auto py-2 pl-2 bg-dark">
    <div class="w-100 d-flex justify-content-end">
      <template v-if="mediaType != 'category'">
        <b-dropdown variant="link" class="">
          <template slot="button-content">
            <span class="mr-2">
              <span class="mx-2">File</span>
            </span>
          </template>

          <b-dropdown-text>Add new...</b-dropdown-text>


          <b-dropdown-item href="#" @click="$store.dispatch('media/addNewItem', {type: 'artist_page'})">
            <Icon icon="Menu_Categories"/>
            <span class="ml-1 px-2 py-1">Artist Page</span>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="$store.dispatch('media/addNewItem', {type: 'audio'})">
            <Icon icon="Menu_audio"/>
            <span class="ml-1 px-2 py-1">Audio</span>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="$store.dispatch('media/addNewItem', {type: 'book'})">
            <Icon icon="Menu_books"/>
            <span class="ml-1 px-2 py-1">Book</span>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="$store.dispatch('media/addNewItem', {type: 'brand'})">
            <Icon icon="Menu_Brands"/>
            <span class="ml-1 px-2 py-1">Brand</span>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="$store.dispatch('media/addNewItem', {type: 'external'})">
            <Icon icon="Menu_externals"/>
            <span class="ml-1 px-2 py-1">External</span>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="$store.dispatch('media/addNewItem', {type: 'magazine'})">
            <Icon icon="Menu_Magazines"/>
            <span class="ml-1 px-2 py-1">Magazine</span>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="$store.dispatch('media/addNewItem', {type: 'page'})">
            <Icon icon="Menu_Categories"/>
            <span class="ml-1 px-2 py-1">Micropage</span>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="$store.dispatch('media/addNewItem', {type: 'music_album'})">
            <Icon icon="Menu_music"/>
            <span class="ml-1 px-2 py-1">Music Album</span>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="$store.dispatch('media/addNewItem', {type: 'photo_package'})">
            <Icon icon="Menu_camera"/>
            <span class="ml-1 px-2 py-1">Photo Package</span>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="$store.dispatch('media/addNewItem', {type: 'playlist_page'})">
            <Icon icon="Menu_Categories"/>
            <span class="ml-1 px-2 py-1">Playlist Page</span>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="$store.dispatch('media/addNewItem', {type: 'podcast'})">
            <Icon icon="Menu_podcast"/>
            <span class="ml-1 px-2 py-1">Podcast</span>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="$store.dispatch('media/addNewItem', {type: 'series'})">
            <Icon icon="Menu_Series"/>
            <span class="ml-1 px-2 py-1">Series</span>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="$store.dispatch('media/addNewItem', {type: 'video'})">
            <Icon icon="Menu_Videos"/>
            <span class="ml-1 px-2 py-1">Video</span>
          </b-dropdown-item>

          <b-dropdown-divider/>

          <b-dropdown-item id="import-button" text="Import" @click="$store.commit('media/updateModal', {import: {visible: true, insert: false}})">
            <Icon icon="import"/>
            <span class="ml-2">Import from Excel</span>
          </b-dropdown-item>

          <b-dropdown-item id="export-button" text="Export" @click="$root.$emit('media-export', true)">
            <Icon icon="export"/>
            <span class="ml-2">Export to Excel</span>
          </b-dropdown-item>

          <b-dropdown-divider/>

          <b-dropdown-item v-if="$config.allowInsertFromExcel" id="import-new-button" text="Import New" @click="$store.commit('media/updateModal', {import: {visible: true, insert: true}})">
            <Icon icon="import"/>
            <span class="ml-2">Import new content from Excel</span>
          </b-dropdown-item>

        </b-dropdown>


        <b-dropdown variant="link" class="">
          <template slot="button-content">
            <span class="mr-2">
              <span class="mx-2">Edit</span>
            </span>
          </template>

          <b-dropdown-item id="duplicate-button" text="Duplicate" @click="handleDuplicate">
            <Icon icon="Copy_id"/>
            <span class="ml-2">Duplicate</span>
          </b-dropdown-item>

          <b-dropdown-item @click="handleArchive">
            <Icon icon="Archive"/>
            <span class="ml-2">Archive</span>
          </b-dropdown-item>

          <b-dropdown-item @click="manageMediaLabels">
            <font-awesome-icon icon="tags"/>
            <span class="ml-2">Manage Labels</span>
          </b-dropdown-item>

          <b-dropdown-item v-if="$config.enableArtistsEverywhere" @click="manageMediaArtists">
            <font-awesome-icon icon="user"/>
            <span class="ml-2">Manage Artists</span>
          </b-dropdown-item>

          <b-dropdown-item v-if="$config.enableGenres" @click="manageMediaGenres">
            <font-awesome-icon icon="tags"/>
            <span class="ml-2">Manage Genres</span>
          </b-dropdown-item>

          <b-dropdown-item @click="manageMediaProperties">
            <font-awesome-icon icon="square-check"/>
            <span class="ml-2">Manage Properties</span>
          </b-dropdown-item>

          <b-dropdown-item @click="handlePublishCheck">
            <Icon icon="globe"/>
            <span class="ml-2">Publish check</span>
          </b-dropdown-item>
        </b-dropdown>

        <div style="margin-top: 10px" class="mx-1">
          <Icon icon="Scheiding" :size="24"/>
        </div>

        <button @click="$refs.mediaLabelFilter.show()" class="btn dropdown-toggle btn-link" aria-haspopup="true" aria-expanded="false">
          <font-awesome-icon icon="tags"/>
          <span class="mx-2">Labels</span>
        </button>

        <EditLabels :labels="$store.state.media.labelFilter" @update="$store.dispatch('media/setLabelFilter', $event)" ref="mediaLabelFilter" :allow-new="false" :allow-manage="false"/>

        <MediaSearchTypeFilter v-if="$store.state.media.searchMode" ref="mediaTypeFilter"/>
        <MediaTypeFilter v-else ref="mediaTypeFilter"/>
      </template>
      <template v-else-if="$store.state.media.selectedNodes.length == 1 && $store.state.media.selectedNodes[0].media.category_type != 'root'">
        <template v-if="$store.state.media.categoryIdToCopy == null">
          <b-button @click="handleCopyCategory">
            <Icon icon="Copy_id"/>
            Copy
          </b-button>
        </template>
        <b-button-group v-else>
          <b-button @click="$store.commit('media/resetCategoryIdToCopy')" variant="warning">
            <font-awesome-icon icon="times"/>
            Clear
          </b-button>
          <b-button v-if="$store.state.media.categoryIdToCopy != null" @click="handlePasteCategory">
            <Icon icon="Copy_id"/>
            Paste
          </b-button>
        </b-button-group>
      </template>
      <template v-if="(mediaType != 'category') || searchMode">
        <PublishStatusFilter ref="publishStatusFilter"/>


        <b-button-group class="ml-3">
          <template v-if="mediaType != 'category' && !searchMode">
            <b-form-select class="mr-3" style="max-width: 250px" id="stats-select-partner" :value="$store.state.media.partnerFilter" @input="$store.dispatch('media/setPartner', $event)" :options="$store.state.media.options.partnerFilter"></b-form-select>
          </template>
          <b-form-select
            style="max-width: 180px"
            :value="mediaType == 'category' ? $store.state.media.categorySortOrder : $store.state.media.sortOrder"
            @input="mediaType == 'category' ? $store.commit('media/putCategorySortOrder', $event) : $store.commit('media/putSortOrder', $event)"
            :options="mediaType == 'category' ? $store.state.media.options.categorySortOrder : $store.state.media.options.sortOrder"
          >
          </b-form-select>

          <template v-if="searchMode">
            <b-input-group class="ml-3" style="min-width: 250px">
              <b-form-input ref="search" v-model="searchInput" placeholder="Type to Search"/>
              <b-input-group-append>
                <div style="margin-top: -0.07rem">
                  <b-button variant="outline-light" :disabled="!searchInput" @click="searchInput = ''"><Icon icon="Search_backspace"/></b-button>
                </div>
              </b-input-group-append>
            </b-input-group>
          </template>
          <template v-else>
            <b-input-group class="ml-3" style="min-width: 250px">
              <b-form-input ref="search" v-model="filterInput" placeholder="Type to Filter"/>
              <b-input-group-append>
                <div style="margin-top: -0.07rem">
                  <b-button variant="outline-light" :disabled="!filterInput" @click="filterInput = ''"><Icon icon="Search_backspace"/></b-button>
                </div>
              </b-input-group-append>
            </b-input-group>
          </template>
        </b-button-group>
      </template>
      <div style="margin-right: -5px; margin-top: 5px;" class="ml-1">
        <Icon icon="Scheiding" :size="24"/>
      </div>

      <b-form-checkbox class="mt-1 ml-1" v-model="searchMode" name="check-button" switch>
        Search Mode
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import PublishStatusFilter from './PublishStatusFilter.vue'
import EditLabels from './EditLabels.vue'
import MediaTypeFilter from './MediaTypeFilter.vue'
import MediaSearchTypeFilter from './MediaSearchTypeFilter.vue'

export default {
  name: "MediaToolbar",

  components: {
    EditLabels,
    PublishStatusFilter,
    MediaTypeFilter,
    MediaSearchTypeFilter,
  },

  props: {
    mediaType: String
  },

  computed: {
    filterInput: {
      get() { return this.$store.state.media.searchFilterString},
      set(value) {
        this.$store.commit('media/updateSearchFilter', value)
      }
    },

    searchInput: {
      get() {
        if(this.mediaType == "category") {
          return this.$store.state.media.searchCategoriesString
        }
        else {
          return this.$store.state.media.searchMediaString
        }
      },
      set(value) {
        if(this.mediaType == "category") {
          this.$store.dispatch('media/searchCategories', value)
        }
        else {
          this.$store.dispatch('media/searchMedia', value)
        }

      }
    },

    searchMode: {
      get() { return this.$store.state.media.searchMode},
      set(value) {
        this.$store.dispatch('media/setSearchMode', value)
      }
    }
  },

  methods: {

    focusSearch() {
      this.$refs.search.focus()
    },

    clearSearch() {
      this.filterInput = ''
    },

    handleArchive() {
      if(this.$store.state.media.selectedNodes.length > 0) {
        this.$store.commit('media/updateModal', {archive: {visible: true}})
      }
    },

    handleDuplicate() {
      if(this.$store.state.media.selectedNodes.length == 0) {
        this.$root.app.showAlert("No item selected to duplicate", "warning")
      }
      else if(this.$store.state.media.selectedNodes.length > 1) {
        this.$root.app.showAlert("Can not duplicate more than one item at a time", "warning")
      }
      else {
        this.$store.dispatch('media/duplicateItem', this.$store.state.media.selectedNodes[0].media.id)
      }
    },

    handlePublishCheck() {
      this.$store.dispatch('media/publishCheck')
        .then(resp => {
          this.$store.dispatch('media/publishCheckResults', {
            status: resp.json.data.status,
            results: resp.json.data.results,
            isSaveResult: false
          })
        })
        .catch(error => this.$root.app.showAlert(error.text, "danger"))

    },

    handleCopyCategory() {
      this.$store.commit('media/copySelectedCategory')
    },

    handlePasteCategory() {
      this.$store.dispatch('media/pasteCategory')
        .catch(() => this.$root.app.showAlert("This action is not allowed", "danger"))
    },

    manageMediaLabels() {
      if(this.$store.state.media.selectedNodes.length > 0) {
        this.$store.dispatch('media/manageMediaLabels')
      }
      else {
        this.$root.app.showAlert("No media items selected", "danger")
      }
    },

    manageMediaArtists() {
      if(this.$store.state.media.selectedNodes.length > 0) {
        this.$store.dispatch('media/manageMediaArtists')
      }
      else {
        this.$root.app.showAlert("No media items selected", "danger")
      }
    },

    manageMediaGenres() {
      if(this.$store.state.media.selectedNodes.length > 0) {
        this.$store.dispatch('media/manageMediaGenres')
      }
      else {
        this.$root.app.showAlert("No media items selected", "danger")
      }
    },

    manageMediaProperties() {
      if(this.$store.state.media.selectedNodes.length > 0) {
        this.$store.dispatch('media/manageMediaProperties')
      }
      else {
        this.$root.app.showAlert("No media items selected", "danger")
      }
    }

  },

  created() {
    this.$root.$on('media-search-focus', this.focusSearch)
    this.$root.$on('media-search-clear-selection', this.clearSearch)
  },

  beforeDestroy() {
    this.$root.$off('media-search-focus', this.focusSearch)
    this.$root.$off('media-search-clear-selection', this.clearSearch)
  }
}
</script>

<style scoped>
</style>
