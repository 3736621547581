var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: { title: _vm.title },
      on: { ok: _vm.addRevenue, hidden: _vm.clear },
    },
    [
      _c(
        "b-form",
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Country",
                "label-for": "add-geo-country",
                horizontal: "",
              },
            },
            [
              _c("b-form-select", {
                attrs: { id: "add-geo-country", options: _vm.geoOptions },
                model: {
                  value: _vm.selectedGeo,
                  callback: function ($$v) {
                    _vm.selectedGeo = $$v
                  },
                  expression: "selectedGeo",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Revenue",
                "label-for": "add-geo-revenue",
                horizontal: "",
              },
            },
            [
              _c("b-input", {
                model: {
                  value: _vm.revenue,
                  callback: function ($$v) {
                    _vm.revenue = $$v
                  },
                  expression: "revenue",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _c(
            "b-alert",
            {
              attrs: {
                show: _vm.alert.dismissCountdown,
                dismissible: "",
                variant: _vm.alert.variant,
              },
              on: {
                dismissed: function ($event) {
                  _vm.alert.dismissCountdown = 0
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.alert.msg) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }