var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-form-file", {
        attrs: {
          id: "select-file",
          multiple: "",
          size: "md",
          state: _vm.files.length > 0,
          placeholder: "Choose a file or drop it here...",
          "drop-placeholder": "Drop file here...",
          accept: _vm.acceptedFileTypes,
          "file-name-formatter": _vm.fileNameFormatter,
        },
        model: {
          value: _vm.files,
          callback: function ($$v) {
            _vm.files = $$v
          },
          expression: "files",
        },
      }),
      _c("div", { staticClass: "invisible" }, [
        _vm._v(" " + _vm._s(_vm.objectName) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }