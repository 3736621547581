<template>
  <div style="margin-top: 1.8rem; height: 600px">

    <b-row>

      <b-col>
        <b-card>

          <b-input-group class="mb-3">
            <b-form-input v-model="searchInput" placeholder="Type to Search" />
            <b-input-group-append>
              <b-btn :disabled="!searchInput" @click="searchInput = ''"><Icon icon="Search_backspace"/></b-btn>
            </b-input-group-append>
          </b-input-group>

          <draggable
            class="list-group scroller"
            :list="srcItems"
            :options="{sort: false}"
            :group="{ name: 'mediaItems', pull: true, put: false }"
          >
            <a href="#" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" v-for="item in srcItems" :key="item.id">
              <span>{{ item.meta.title }}</span>
              <b-badge>{{ item.type }}</b-badge>
            </a>
          </draggable>
        </b-card>
      </b-col>

      <b-col>
        <b-card title="Category">

          <draggable
            class="dragArea list-group scroller"
            :list="dstItems"
            group="mediaItems"
            @change="log"
          >
            <a href="#" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" v-for="item in dstItems" :key="item.id">
              <span>{{ item.meta.title }}</span>
              <span>
                <b-badge>{{ item.type }}</b-badge>
                <b-badge class="ml-2" variant="danger" @click="removeItem(item.id)">x</b-badge>
              </span>
            </a>
          </draggable>
        </b-card>
      </b-col>

    </b-row>


  </div>
</template>

<script>
import Draggable from "vuedraggable"

export default {
  name: 'EditManualCategory',

  components: {
    Draggable
  },

  data: function() {
    return {
      searchInput: '',
      allowedMediaTypes: [],
      srcItems: [],
      dstItems: []
    };
  },
  props: {
    lang: String,
    items: Array,
    format: String,
    assetFormat: String
  },

  watch: {
    assetFormat(val) {
      this.setAllowedMediaTypes(val)
    },
    
    searchInput(val) {
      if(val && val.length) {
        this.search(this.searchInput)
      }
      else {
        this.srcItems = []
      }
    }
  },

  methods: {
    setAllowedMediaTypes(assetFormat) {
      switch(assetFormat) {
        case 'audio':
          this.allowedMediaTypes = ['album_track', 'audio', 'podcast_episode']
          break;

        case 'video':
          this.allowedMediaTypes = ['episode', 'video']
          break;

        default:
          this.allowedMediaTypes = ['audio', 'video', 'series', 'magazin', 'music_album', 'podcast', 'book', 'brand', 'page', 'external', 'photo', 'artist_page', 'playlist_page']
          break;
      }
    },
    
    search(query) {
      fetch(`/admin/api/v2/media/search?q=${query}&language=${this.lang}&status=draft,ready,published&type=${this.allowedMediaTypes.join()}`)
      .then(function(resp) {
        return resp.json()
      })
      .then(resp => {
        if(this.format == 'external') {
          this.srcItems = resp.data.filter(item => item.type == 'external')
        }
        else {
          this.srcItems = resp.data
        }
      })
    },

    getItems(items) {
      let ids = items.map(p => p.id)


      fetch(`/admin/api/v2/media/get-multiple?language=${this.lang}&status=draft,ready,published`, {method: 'POST', body: JSON.stringify({data: ids})})
      .then(function(resp) {
        return resp.json()
      })
      .then(resp => {
        this.dstItems = resp.data
      })
    },

    removeItem(id) {
      this.dstItems = this.dstItems.filter(item => item.id != id)
      this.$emit('update', this.dstItems)
    },

    log() {
      const orgLength = this.dstItems.length;
      this.dstItems = this.$_.uniqBy(this.dstItems, (item) => item.id)

      if(orgLength != this.dstItems.length) {
        this.$root.app.showAlert('Adding duplicate items to a manual category is not allowed', 'danger')
      }

      this.$emit('update', this.dstItems)
    }
  },

  created() {
    this.setAllowedMediaTypes(this.assetFormat)
    this.getItems(this.items)
  }

}
</script>

<style scoped>
.scroller {
  overflow-y: scroll;
  max-height: 600px;
}
</style>
