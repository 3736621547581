<template>
  <div class="process-adyen-report">
    <div class="d-flex flex-row-reverse">
      <b-button variant="" class="mb-2" v-b-toggle.process-ui>
        <font-awesome-icon icon="exchange-alt"/>
        <span> Process Adyen Report</span>
      </b-button>
    </div>
    <b-collapse id="process-ui">
        <div class="example-simple mt-2">
          <div class="">
            <div class="example-btn d-flex flex-row-reverse">
              <b-input-group>
                 <template v-slot:prepend>
                  <file-upload
                    class="btn btn-primary"
                    post-action="/admin/upload/adyen-report"
                    extensions="csv"
                    accept="text/csv"
                    :multiple="false"
                    :size="1024 * 1024 * 10"
                    v-model="files"
                    @input-filter="inputFilter"
                    @input-file="inputFile"
                    ref="upload">
                    <Icon icon="Add"/>
                    Select file
                  </file-upload>
                </template>
              
                <b-input class="" style="width: 400px" readonly :value="file.name"></b-input>

                <template v-slot:append>
                  <button v-if="file.success" type="button" class="btn btn-success" @click="download">
                    <Icon icon="export"/>
                    Download
                  </button>
                  <button v-else-if="file.error" type="button" class="btn btn-danger" @click="clear">
                    <font-awesome-icon icon="exclamation-circle"/>
                    Error
                  </button>
                  <button v-else-if="!$refs.upload || !$refs.upload.active" type="button" class="btn btn-primary" :disabled="!file.name" @click.prevent="$refs.upload.active = true">
                    <font-awesome-icon icon="upload"/>
                    Upload
                  </button>
                  <button v-else-if="$refs.upload && $refs.upload.active" type="button" class="btn btn-primary" disabled>
                    <b-spinner small label="Spinning"></b-spinner>
                    Uploading
                  </button>
                </template>
              </b-input-group>
            </div>
          </div>

        </div>
      </b-collapse>
      <iframe id="download-frame" style="display:none;"></iframe>
    </div>
</template>

<style>
.example-simple label.btn {
  margin-bottom: 0;
  margin-right: 1rem;
}
</style>

<script>
import FileUpload from 'vue-upload-component'
export default {
  name: 'ProcessAdyenReport',

  components: {
    FileUpload,
  },

  data() {
    return {
      files: [],
    }
  },

  computed: {
    reportDownloadUrl() {
      if(this.file.success) {
        return `admin/upload/adyen-report/${this.file.response}`
      }
      else {
        return "#"
      }
    },

    file() {
      return this.files.length > 0 ? this.files[0] : {}
    }
  },

  methods: {
    show() {
      this.$refs.modal.show();
    },

    clear() {
      this.files = [];
    },

    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },

    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
      }
      if (newFile && oldFile) {
        // update
      }

      if (!newFile && oldFile) {
        // remove
      }
    },

    download() {
      document.getElementById('download-frame').src = this.reportDownloadUrl
    }
  }
}
</script>
