<template>
  <div id="main-menu" class="ml-3">
    <h4 class="w-100 text-center pr-3 mb-3" style="font-stretch: expanded">{{ $config.menuTitle }}</h4>
    <b-nav vertical>
      <template v-if="userType == 'editor' || userType == 'editor_in_chief' || userType == 'finance' || userType == 'admin' || userType == 'support'">
        <b-nav-item exact-active-class="active-item" to="/" title="Home"><Icon icon="Menu_Dashboard"/><span class="ml-3">Home</span></b-nav-item>
      </template>
      <template v-if="userType == 'editor' || userType == 'editor_in_chief' || userType == 'admin'">
        <b-nav-item exact-active-class="active-item" to="/inbox" title="Inbox"><Icon icon="Menu_inbox"/> <span class="ml-3">Inbox</span></b-nav-item>
        <b-nav-item exact-active-class="active-item" to="/categories" title="Categories"><Icon icon="Menu_Categories"/> <span class="ml-3">Categories</span></b-nav-item>
        <b-nav-item exact-active-class="active-item" to="/media" title="Media"><font-awesome-icon icon="photo-video"/> <span class="ml-3">Media</span></b-nav-item>
        <b-nav-item v-if="config.enableDdex === true" exact-active-class="active-item" to="/ddex-import" title="DDEX"><Icon icon="Menu_inbox"/> <span class="ml-3">DDEX</span></b-nav-item>
      </template>
      <b-nav-item v-if="userType == 'support' || userType == 'admin'" exact-active-class="active-item" to="/users" title="Users"><Icon icon="Menu_Users"/> <span class="ml-3">Users</span></b-nav-item>
      <template v-if="userType == 'finance' || userType == 'admin'">
        <b-nav-item v-if="$config.enableAffiliates" exact-active-class="active-item" to="/affiliates" title="Affiliates"><Icon icon="Menu_Affiliates"/> <span class="ml-3">Affiliates</span></b-nav-item>
        <b-nav-item v-if="$config.enablePromoCodes" exact-active-class="active-item" to="/promo-codes" title="Promo codes"><Icon icon="Menu_Promo"/> <span class="ml-3">Promo codes</span></b-nav-item>
        <b-nav-item exact-active-class="active-item" to="/partners" title="Partners"><Icon icon="Menu_Partners"/> <span class="ml-3">Partners</span></b-nav-item>
        <b-nav-item exact-active-class="active-item" to="/finance" title="Finance"><Icon icon="Menu_Revenue"/> <span class="ml-3">Finance</span></b-nav-item>
        <b-nav-item link-classes="last-nav-item" exact-active-class="active-item" to="/stats" title="Stats"><Icon icon="Menu_Stats"/> <span class="ml-3">Stats</span></b-nav-item>
      </template>
      <template v-else-if="userType == 'editor' || userType == 'editor_in_chief' ">
        <b-nav-item exact-active-class="active-item" to="/affiliates" title="Affiliates"><Icon icon="Menu_Affiliates"/> <span class="ml-3">Affiliates</span></b-nav-item>
        <b-nav-item exact-active-class="active-item" to="/promo-codes" title="Promo codes"><Icon icon="Menu_Promo"/> <span class="ml-3">Promo codes</span></b-nav-item>
        <b-nav-item exact-active-class="active-item" to="/partners" title="Partners"><Icon icon="Menu_Partners"/> <span class="ml-3">Partners</span></b-nav-item>
        <b-nav-item link-classes="last-nav-item" exact-active-class="active-item" to="/stats" title="Stats"><Icon icon="Menu_Stats"/> <span class="ml-3">Stats</span></b-nav-item>
      </template>
    </b-nav>
  </div>
</template>

<script>
import config from "@/config";

export default {
  name: "Menu",

  computed: {
    config() {
      return config
    },
    userType() {
      if(this.$store.state.session.user) {
        return this.$store.state.session.user.type
      }
      return null
    }
  }

}
</script>

<style>
#main-menu {
  text-align: left;
}

.nav-link {
  font: normal normal normal 14px/29px Roboto;
  color: #8B91A0;
  margin-right: 1rem;
  padding: 0.1rem 1rem !important;
}

.active-item {
  color: #FFFFFF;
}

.last-nav-item {
  margin-bottom: 0px;
}
</style>


