<template>
  <div style="margin-top: 0.14rem; min-width: 10rem; max-width: 13rem">
    <b-dropdown variant="link" style="width: 13rem">
      <template slot="button-content">
        <font-awesome-icon icon="photo-video"/>
        <span class="mx-2">Media Type</span>
      </template>

      <b-dropdown-form>
        <b-form-checkbox v-model="showAll" class="my-1">
          <font-awesome-icon icon="asterisk"/>
          <span class="ml-1 px-2 py-1">Toggle All</span>
        </b-form-checkbox>


        <b-form-checkbox :checked="typeFilter.audio" @input="updateFilter('audio', $event)" class="my-1">
          <Icon icon="Menu_audio"/>
          <span class="ml-1 px-2 py-1">Audio</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.artist_page" @input="updateFilter('artist_page', $event)" class="my-1">
          <Icon icon="Menu_Categories"/>
          <span class="ml-1 px-2 py-1">Artist Pages</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.book" @input="updateFilter('book', $event)" class="my-1">
          <Icon icon="Menu_books"/>
          <span class="ml-1 px-2 py-1">Books</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.brand" @input="updateFilter('brand', $event)" class="my-1">
          <Icon icon="Menu_Brands"/>
          <span class="ml-1 px-2 py-1">Brands</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.external" @input="updateFilter('external', $event)" class="my-1">
          <Icon icon="Menu_externals"/>
          <span class="ml-1 px-2 py-1">Externals</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.magazine" @input="updateFilter('magazine', $event)" class="my-1">
          <Icon icon="Menu_Magazines"/>
          <span class="ml-1 px-2 py-1">Magazines</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.year" @input="updateFilter('year', $event)" class="my-1">
          <Icon icon="Menu_Magazines"/>
          <span class="ml-1 px-2 py-1">Magazine Years</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.issue" @input="updateFilter('issue', $event)" class="my-1">
          <Icon icon="Menu_Magazines"/>
          <span class="ml-1 px-2 py-1">Magazine Issues</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.page" @input="updateFilter('page', $event)" class="my-1">
          <Icon icon="Menu_Categories"/>
          <span class="ml-1 px-2 py-1">Micropages</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.music_album" @input="updateFilter('music_album', $event)" class="my-1">
          <Icon icon="Menu_music"/>
          <span class="ml-1 px-2 py-1">Music Albums</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.album_track" @input="updateFilter('album_track', $event)" class="my-1">
          <Icon icon="Menu_music"/>
          <span class="ml-1 px-2 py-1">Music Tracks</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.photo" @input="updateFilter('photo', $event)" class="my-1">
          <Icon icon="Menu_camera"/>
          <span class="ml-1 px-2 py-1">Photos</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.photo_package" @input="updateFilter('photo_package', $event)" class="my-1">
          <Icon icon="Menu_camera"/>
          <span class="ml-1 px-2 py-1">Photo Packages</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.playlist_page" @input="updateFilter('playlist_page', $event)" class="my-1">
          <Icon icon="Menu_Categories"/>
          <span class="ml-1 px-2 py-1">Playlist Pages</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.podcast" @input="updateFilter('podcast', $event)" class="my-1">
          <Icon icon="Menu_podcast"/>
          <span class="ml-1 px-2 py-1">Podcasts</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.podcast_episode" @input="updateFilter('podcast_episode', $event)" class="my-1">
          <Icon icon="Menu_podcast"/>
          <span class="ml-1 px-2 py-1">Podcast Episodes</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.series" @input="updateFilter('series', $event)" class="my-1">
          <Icon icon="Menu_Series"/>
          <span class="ml-1 px-2 py-1">Series</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.season" @input="updateFilter('season', $event)" class="my-1">
          <Icon icon="Menu_Series"/>
          <span class="ml-1 px-2 py-1">Series Seasons</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.episode" @input="updateFilter('episode', $event)" class="my-1">
          <Icon icon="Menu_Series"/>
          <span class="ml-1 px-2 py-1">Series Episodes</span>
        </b-form-checkbox>
        <b-form-checkbox :checked="typeFilter.video" @input="updateFilter('video', $event)" class="my-1">
          <Icon icon="Menu_Videos"/>
          <span class="ml-1 px-2 py-1">Videos</span>
        </b-form-checkbox>

      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>

<script>
import Icon from './Icon.vue';

export default {
  name: 'MediaTypeFilter',
  components: {
    Icon
  },

  data: function() {
    return {
      showAll: true,
      show: false
    };
  },

  computed: {
    collapseIcon() {
      if(this.show) return "expand_more-24px"
      else return "Expand_collapsed"
    },

    typeFilter() {
      return this.$store.state.media.searchTypeFilter
    }
  },

  watch: {
    showAll(val) {
      this.$store.commit('media/typeFilterSetAll', val)
    }
  },

  methods: {
    toggle() {
      this.show = !this.show
    },

    updateFilter(type, value) {
      this.$store.dispatch('media/updateTypeFilter', {type, value})
    }
  }
}
</script>

<style scoped>
.b-dropdown-form {
  padding-left: 0.5rem !important;
  padding-right: 0.2rem !important;
}
</style>
