<template>
  <Media media-type="all-media"/>
</template>

<script>
import Media from "../components/Media.vue";

export default {
  name: "MediaView",

  components: {
    Media
  },

  mounted() {
    this.$root.app.setActivePageTitle("Media", true)
    this.$root.$emit('set-toolbar', 'media-toolbar', "media")
  }

};
</script>


