var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "import-media" },
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            size: "lg",
            title: _vm.insert ? "Import NEW media" : "Import media",
            "ok-only": "",
          },
          on: {
            hidden: _vm.clear,
            ok: function ($event) {
              return _vm.$root.$emit("media-refresh")
            },
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("div", { staticClass: "example-simple" }, [
            _c("div", { staticClass: "upload" }, [
              _c(
                "ul",
                _vm._l(_vm.files, function (file) {
                  return _c("li", { key: file.id }, [
                    _c("span", [_vm._v(_vm._s(file.name))]),
                    _vm._v(" - "),
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatSize")(file.size))),
                    ]),
                    _vm._v(" - "),
                    file.error
                      ? _c("span", [_vm._v(_vm._s(file.error))])
                      : file.success
                      ? _c("span", [_vm._v("success")])
                      : file.active
                      ? _c("span", [_vm._v("active")])
                      : _c("span"),
                    file.error
                      ? _c(
                          "div",
                          { staticStyle: { "padding-right": "3rem" } },
                          [
                            _c("h4", { staticClass: "my-2" }, [
                              _vm._v(
                                "Import aborted, because there were error(s) found at:"
                              ),
                            ]),
                            _c(
                              "b-alert",
                              { attrs: { variant: "danger", show: "" } },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "text-left" },
                                  _vm._l(file.response, function (error) {
                                    return _c("li", { key: error }, [
                                      _vm._v(_vm._s(error)),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "example-btn" },
                [
                  _c(
                    "file-upload",
                    {
                      ref: "upload",
                      staticClass: "btn btn-primary",
                      attrs: {
                        "post-action": `/admin/upload/media-excel?insert=${_vm.insert}`,
                        extensions: "xlsx",
                        accept:
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        multiple: false,
                        size: 1024 * 1024 * 10,
                      },
                      on: {
                        "input-filter": _vm.inputFilter,
                        "input-file": _vm.inputFile,
                      },
                      model: {
                        value: _vm.files,
                        callback: function ($$v) {
                          _vm.files = $$v
                        },
                        expression: "files",
                      },
                    },
                    [
                      _c("Icon", { attrs: { icon: "Add" } }),
                      _vm._v(" Select file "),
                    ],
                    1
                  ),
                  !_vm.$refs.upload || !_vm.$refs.upload.active
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.$refs.upload.active = true
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "arrow-up" },
                          }),
                          _vm._v(" Start Upload "),
                        ],
                        1
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.$refs.upload.active = false
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "stop" } }),
                          _vm._v(" Stop Upload "),
                        ],
                        1
                      ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }