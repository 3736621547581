<template>
    <b-modal
            :id="id"
            :title="title"
            :visible="value"
            size="xl"
            :centered="true"
            @hide="onHide"
            content-class="full-width-modal"
    >
        <div v-if="data" class="info-modal-content">
            <b-container>

                <b-row>
                    <b-col>
                        <h4>Header</h4>
                        <b-table-simple hover responsive>
                            <b-thead>
                                <b-tr>
                                    <b-th>Category</b-th>
                                    <b-th>Key</b-th>
                                    <b-th>Value</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <template v-for="(section, sectionName) in sections">
                                    <b-tr v-for="(value, key) in section" :key="`${sectionName}-${key}`">
                                        <b-td>{{ sectionName }}</b-td>
                                        <b-td>{{ key }}</b-td>
                                        <b-td><pre class="d-inline mb-0">{{ value }}</pre></b-td>
                                    </b-tr>
                                </template>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
                <div>
                    <b-row>
                        <b-col>
                            <h4>Recordings</h4>
                            <collapsible-card
                                    v-for="(release, index) in data.originalData.resources.SoundRecordings"
                                    :key="index"
                                    :title="release.DisplayTitle"
                                    :content="release"
                            ></collapsible-card>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <h4>Releases</h4>
                            <collapsible-card
                                    v-for="(release, index) in data.originalData.releases.Releases"
                                    :key="index"
                                    :title="release.ReferenceTitle"
                                    :content="release"
                            ></collapsible-card>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <h4>Deals</h4>
                            <collapsible-card
                                    v-for="(release, index) in data.originalData.deals.Deals"
                                    :key="index"
                                    :title="release.CommercialModelType"
                                    :content="release"
                            ></collapsible-card>
                        </b-col>
                    </b-row>
                </div>


            </b-container>
        </div>
        <div v-else>
            Loading...
        </div>

        <template #modal-footer>
            <b-button @click="onClose" variant="secondary">Close</b-button>
        </template>
    </b-modal>
</template>



<script>
import CollapsibleCard from './CollapsibleCard.vue';

export default {
    name: "DdexAssetModal",
    props: {
        id: {
            type: String,
            default: "info-modal",
        },
        title: {
            type: String,
            default: "Info",
        },
        value: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Object,
            default: null,
        },
    },
    methods: {
        onHide() {
            this.$emit("close");
        },
        onClose() {
            this.$emit("input", false);
        },
    },
    components: {
        CollapsibleCard,
    },
    computed: {
        sections() {
            return {
                Summary: this.data.originalData.summary,
                Release: this.data.originalData.deals.Summary,
                Header: this.data.originalData.header,
            };
        },
    },
};
</script>
<style scoped>
.info-modal-content {
    max-height: 80vh;
    overflow-y: auto;
}

pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    color: white;
}
h4 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

</style>