<template>
  <div class="inbox mt-2">
    <AssetInbox/>
  </div>
</template>

<script>
import AssetInbox from "../components/AssetInbox.vue"

export default {
  components: {
    AssetInbox
  }
  
}
</script>

<style scoped>

</style>
