<template>
  <div class="affiliates">
<!--
    <div id="affiliate-count-stats" class="d-flex justify-content-end align-items-center my-4">
      <b-button variant="primary" @click="exportAffiliates">
        <Icon icon="export"/>
        Export Affiliates
      </b-button>
    </div>
-->


    <div class="mt-4">
      <b-table hover striped selectable select-mode="multi" selected-variant="info" @row-selected="rowSelected" :items="items" :fields="fields" :per-page="perPage" :current-page="currentPage">

        <template v-slot:cell(show_details)="row">
          <div class="d-flex justify-content-center">
            <b-button size="sm" variant="primary" @click="row.toggleDetails" class="mr-2">
              <font-awesome-icon icon="info"/>
            </b-button>
          </div>
        </template>

        <template v-slot:row-details="row">
          <b-container>
            <form autocomplete="off">
              <b-form-group label="ID" label-for="id-input" horizontal>
                <b-form-input id="id-input" :value="row.item.id" readonly></b-form-input>
              </b-form-group>
              <b-form-group label="Type" label-for="affiliates-input-type" horizontal>
                <b-form-input id="affiliates-input-type" :value="$_.capitalize(row.item.type)" readonly></b-form-input>
              </b-form-group>
              <b-form-group label="Partner" label-for="affiliates-input-partner" horizontal>
                <b-form-input id="affiliates-input-partner" :value="getPartnerName(row.item.partner)" readonly></b-form-input>
              </b-form-group>
              <b-form-group label="Schedule" label-for="publication-comp" horizontal>
                <Publication id="publication-comp" from-field="active_from" to-field="active_to" :value="row.item" readonly/>
              </b-form-group>
              <b-form-group label="Affiliate link" label-for="link-input" horizontal>
                <id-input id="link-input" :value="row.item.url"></id-input>
              </b-form-group>
              <b-form-group v-if="usePromoCode(row.item)" label="Promo Code" label-for="promo-code-input" horizontal>
                <b-form-input id="promo-code-input" :value="row.item.promo_code.code" readonly></b-form-input>
              </b-form-group>
              <b-form-group v-if="row.item.type == 'recurring'" label="Recurring compensation amount" label-for="amount-input" horizontal>
                <b-form-input id="amount-input" :value="getRecurringCompensation(row.item.affiliate_compensations)" readonly></b-form-input>
              </b-form-group>
              <b-form-group label="Offers" label-for="affiliate-offer-editor" horizontal>
                <AffiliateOffers id="affiliate-offer-editor" readonly :type="row.item.type" :affiliate-compensations="row.item.affiliate_compensations" :use-promo-code='usePromoCode(row.item)'></AffiliateOffers>
              </b-form-group>
            </form>
          </b-container>

        </template>

      </b-table>
    </div>

    <div v-if="perPage" class="d-flex justify-content-center">
      <b-pagination :total-rows="items.length" :per-page="perPage" v-model="currentPage"/>
    </div>

    <AddAffiliate ref="addAffiliate"/>
    <MutateAffiliate ref="mutateAffiliate"/>

    <SimpleModal :value='archiveModalVisible' @ok="doArchive" @cancel="archiveModalVisible = false" :msg="`Are you sure you want to archive ${this.selectedAffiliates.length > 1 ? 'these affiliates' : 'this affiliate'}? Archiving a recurring affiliate means the owner does not get compensated anymore.`"/>


    <iframe id="export-affiliates-frame" style="display:none;"></iframe>

  </div>
</template>

<script>
import IdInput from "../components/IdInput.vue"
import AddAffiliate from '../components/AddAffiliate.vue'
import MutateAffiliate from '../components/MutateAffiliate.vue'
import Publication from "../components/Publication.vue"
import AffiliateOffers from "../components/AffiliateOffers.vue"
import SimpleModal from "../components/SimpleModal.vue"

export default {
  name: 'Affiliates',

  components: {
    IdInput,
    AddAffiliate,
    MutateAffiliate,
    Publication,
    AffiliateOffers,
    SimpleModal
  },

  data() {
    return {
      currentPage: 1,
      perPage: 20,
      partnerFilter: '__all__',
      typeFilter: '*',
      statusFilter: 'active',
      partnerOptions: [],
      sortOrder: 'date-asc',
      sorter: null,

      showContentModal: false,

      affiliates: [],

      selectedAffiliates: [],

      fields: [
        {key: 'partner', label: 'Partner', sortable: true, formatter: this.getPartnerName},
        {key: 'type', sortable: true},
        {key: 'status', sortable: true},
        {key: 'promo_code_id', label: 'Promo Code', sortable: true, formatter: (id) => id ? 'Yes' : 'No'},
        {key: 'active_from', label: 'From', sortable: true, formatter: this.$helpers.dtFormatter},
        {key: 'active_to', label: 'To', sortable: true, formatter: this.$helpers.dtFormatter},
        {key: 'url', label: 'Affiliate URL', sortable: true},
        {key: 'show_details', label: 'Details'}
      ],

      archiveModalVisible: false,
    };
  },

  props: {

  },

  computed: {
    items() {
      return this.affiliates.filter(item => {
        return (this.typeFilter == '*' || item.type == this.typeFilter) &&
          (this.statusFilter == '*' || item.status == this.statusFilter) &&
          (this.partnerFilter == "__all__" || item.partner && item.partner.match(this.partnerFilter))
      }).sort(this.sorter)
    }
  },

  methods: {
    load() {
        fetch('/admin/api/v2/partners/select-options')
        .then(function(resp) {
          return resp.json();
        })
        .then(resp => {
          this.partnerOptions = resp.data

          fetch('/admin/api/v2/affiliates')
          .then(resp => {
            if(resp.status == 200) return resp.json()
            else throw Error(resp.statusText)
          })
          .then(resp => {
            this.affiliates = resp.data.map(a => {
              a.affiliate_compensations.map(ac => {
                ac.euro_amount = this.$helpers.simpleCurrencyFormatter(ac.amount)

                return ac
              })

              if(this.usePromoCode(a) && a.promo_code.discounts) {
                a.affiliate_compensations = a.affiliate_compensations.map(ac => {
                  let discounts = a.promo_code.discounts

                  for(var i = 0; i < discounts.length; ++i) {
                    if(discounts[i].offer_id == ac.offer_id) {
                      ac.discount = discounts[i].amount
                      ac.euro_discount = this.$helpers.simpleCurrencyFormatter(ac.discount)
                    }
                  }

                  return ac
                })
              }

              return a
            })
          })
          .catch(() => {
            this.$root.app.showAlert('Error loading affiliates', 'danger')
          })
        })
    },

    rowSelected(ev) {
      this.selectedAffiliates = ev
    },

    setTypeFilter(type) {
      this.typeFilter = type
    },

    setStatusFilter(status) {
      this.statusFilter = status
    },

    setPartnerFilter(partner) {
      this.partnerFilter = partner
    },

    setSorter(sortOrder) {
      this.sorter = this.getSortFunction(sortOrder)
    },

    addAffiliate() {
      this.$refs.addAffiliate.show()
    },

    mutateAffiliate() {
      if(this.selectedAffiliates && this.selectedAffiliates.length > 0) {
        let type = null
        for(var i = 0; i < this.selectedAffiliates.length; ++i) {
          const t = this.selectedAffiliates[i].type
          if(!type) { type = t}
          else if(type != t) type = 'error'
        }

        if(!type || type == 'error') {
          this.$root.app.showAlert("It's only possible to mutate affiliates of the same type", 'danger')
        }
        else {
          this.$refs.mutateAffiliate.show(this.selectedAffiliates, type)
        }
      }
    },

    activate() {
      let ids = this.selectedAffiliates.map(a => a.id)
      const length = ids.length

      this.$http.postJSON(
        'admin/api/v2/affiliates/activate',
        {data: ids},
        () => {
          if(length > 1 || (length == 1 && this.selectedAffiliates[0].status != 'active')) {
            this.$root.app.showAlert(`${length} affiliate activation${length == 1 ? '' : 's'} successful`, "success")
            this.$root.$emit('affiliate-refresh')
          }
        },
        (status, html) => {
          this.$root.app.showErrorDialog(html, status.toString())
        }
      )

    },

    deactivate() {
      let ids = this.selectedAffiliates.map(a => a.id)
      const length = ids.length

      this.$http.postJSON(
        'admin/api/v2/affiliates/deactivate',
        {data: ids},
        () => {
          if(length > 1 || (length == 1 && this.selectedAffiliates[0].status == 'active')) {
            this.$root.app.showAlert("Affiliate deactivation successful", "success")
            this.$root.$emit('affiliate-refresh')
          }
        },
        (status, html) => {
          this.$root.app.showErrorDialog(html, status.toString())
        }
      )

    },

    archive() {
      this.archiveModalVisible = true
    },

    doArchive() {
      let ids = this.selectedAffiliates.map(a => a.id)
      const length = ids.length

      this.$http.postJSON(
        'admin/api/v2/affiliates/archive',
        {data: ids},
        () => {
          this.archiveModalVisible = false
          if(length > 1 || (length == 1 && this.selectedAffiliates[0].status == 'active')) {
            this.$root.app.showAlert("Affiliate archiving successful", "success")
            this.$root.$emit('affiliate-refresh')
            this.archiveModalVisible = false
          }
        },
        (status, html) => {
          this.archiveModalVisible = false
          this.$root.app.showErrorDialog(html, status.toString())
        }
      )

    },

    handleFiltered(items) {
      this.currentPage = 1
      this.totalRows = items.length
    },

    getPartnerName(partnerId) {
      const ps = this.partnerOptions.filter(p => p.value == partnerId)

      if(ps.length == 1) { return ps[0].text }
      else { return "" }
    },

    exportAffiliates() {
      this.$http.postJSON(
        "/admin/api/v2/affiliates/build-export",
        {},
        (resp) => {
          let file_name = "/admin/api/v2/affiliates/download-export/" + resp.data;
          document.getElementById('export-affiliates-frame').src = file_name;
        },
        (status, html) => {
          this.clear()
          this.$root.app.showErrorDialog(html, status.toString())
        }
      )
    },

    getSortFunction(sortOrder) {
      switch(sortOrder) {
        case "partner-asc":
          return (a, b) => {
            let at = this.getPartnerName(a.partner)
            let bt = this.getPartnerName(b.partner)
            return at < bt ? -1 : at > bt ? 1 : 0

          }

        case "partner-desc":
          return (a, b) => {
            let at = this.getPartnerName(a.partner)
            let bt = this.getPartnerName(b.partner)
            return at < bt ? 1 : at > bt ? -1 : 0

          }

        case "date-asc":
          return (a, b) => {
            let at = a.active_from
            let bt = b.active_from
            return at < bt ? -1 : at > bt ? 1 : 0

          }

        case "date-desc":
          return (a, b) => {
            let at = a.active_from
            let bt = b.active_from
            return at < bt ? 1 : at > bt ? -1 : 0

          }
      }
    },

    getRecurringCompensation(acs) {
      if(acs && acs.length > 0) {
        return this.$helpers.simpleCurrencyFormatter(acs[0].amount)
      }
      else {
        return 0
      }
    },

    usePromoCode(af) {
      return !!af.promo_code_id
    }

  },

  mounted() {
    this.$root.app.setActivePageTitle("Affiliates", false)
    this.$root.$emit('set-toolbar', 'affiliate-toolbar')

    this.load()
  },

  created() {
    this.sorter = this.getSortFunction(this.sortOrder)
    this.partnerFilter = this.$store.state.media.partnerFilter


    this.$root.$on('affiliate-sort-order', this.setSorter)
    this.$root.$on('affiliate-type-filter', this.setTypeFilter)
    this.$root.$on('affiliate-status-filter', this.setStatusFilter)
    this.$root.$on('affiliate-partner-filter', this.setPartnerFilter)
    this.$root.$on('affiliate-add', this.addAffiliate)
    this.$root.$on('affiliate-refresh', this.load)
    this.$root.$on('affiliate-activate', this.activate)
    this.$root.$on('affiliate-deactivate', this.deactivate)
    this.$root.$on('affiliate-archive', this.archive)
    this.$root.$on('affiliate-mutate', this.mutateAffiliate)
  },

  beforeDestroy() {
    this.$root.$off('affiliate-sort-order', this.setSorter)
    this.$root.$off('affiliate-type-filter', this.setTypeFilter)
    this.$root.$off('affiliate-status-filter', this.setStatusFilter)
    this.$root.$off('affiliate-partner-filter', this.setPartnerFilter)
    this.$root.$off('affiliate-add', this.addAffiliate)
    this.$root.$off('affiliate-refresh', this.load)
    this.$root.$off('affiliate-activate', this.activate)
    this.$root.$off('affiliate-deactivate', this.deactivate)
    this.$root.$off('affiliate-archive', this.archive)
    this.$root.$off('affiliate-mutate', this.mutateAffiliate)
  }
}
</script>

<style scoped>

.no-head {
  display: none;
}

</style>