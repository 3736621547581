<template>
  <b-modal ref="modal" :title="title" size="xl" hide-footer>
    <b-table class="mt-1" :items="items" :fields="fields" striped small borderless></b-table>
  </b-modal>
</template>

<script>
export default {
  name: 'ContentDetailsModal',

  data: function() {
    return {
      title: "",
      items: [],
      fields: [
        {key: 'geo', label: 'Country', sortable: true},
        {key: 'count', label: 'Views', sortable: true},
        {key: 'length', label: 'Duration', sortable: true, formatter: this.$helpers.durationToString}
      ]
    };
  },

  props: {
  },

  methods: {
    show(title, items) {
      this.title = title
      this.items = items

      this.$refs.modal.show();
    },

  }
}
</script>

<style scoped>
</style>
