<template>
  <div class="d-flex justify-content-end align-items-center">
    <div v-if="!expanded" class="mr-2 w-100">
      <b-badge variant="primary" v-for="s in subtitles" :key="s.source">
        {{$helpers.getLanguageName(s.language)}}
      </b-badge>
    </div>

    <b-list-group v-if="expanded" class="mr-2" style="width: 100%; text-align: left">
      <b-list-group-item v-for="s in subtitles" :key="s.source">
        <b-row>
          <b-col>
            <b-form>
              <b-form-group label="Language" label-class="font-weight-bold">
                <span>{{ $helpers.getLanguageName(s.language) }}</span>
              </b-form-group>
              <b-form-group label="Source" label-class="font-weight-bold">
                <span>{{ s.source }}</span>
              </b-form-group>

            </b-form>
          </b-col>
          <b-col>
            <b-button-group class="float-right">
              <b-button v-b-tooltip.hover :title="`Delete subtitle`" variant="danger" @click="deleteSubtitle(s.source)">
                <font-awesome-icon icon="trash-alt"/>
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>

    <AddSubtitle :language="$config.defaultLanguage" :item-id="itemId" @finished="$emit('reload')"></AddSubtitle>

    <div class="ml-2" style="max-height: 40px">
      <b-button size="" :variant="expanded ? 'warning' : 'primary'" @click.stop="toggleExpand" style="max-height: 40px; width: 4.8rem;">
        <Icon icon="edit"/>
          Edit
      </b-button>
    </div>
    <SimpleModal :value='mutateModalVisible' @ok="doMutateSubtitle" @cancel="mutateModalVisible = false" :msg="mutateModalMsg"/>
  </div>
</template>

<script>
import SimpleModal from "./SimpleModal.vue"
import AddSubtitle from "./AddSubtitle.vue"

export default {
  name: 'SubtitleList',

  components: {
    SimpleModal,
    AddSubtitle
  },

  data: function() {
    return {
      expanded: false,
      mutateModalVisible: false,
      mutateModalMsg: "",
      mutateModalOp: "",
      subtitleToMutate: null,
    };
  },
  props: {
    subtitles: Array,
    itemId: String
  },

  computed: {
    primarySubtitleSource() {
      this.items.forEach(item => {
        if(item.status == "ready" && item.type == "primary") {
          return item.source;
        }
      });

      this.items.forEach(item => {
        if(item.type == "primary") {
          return item.source;
        }
      });

      if(this.items.length > 0) {
        return this.items[0].source;
      }

      return null;
    }
  },

  methods: {
    toggleExpand() {
      this.expanded = !this.expanded
    },

    deleteSubtitle(subtitleSource) {
      this.subtitleToMutate = subtitleSource
      this.mutateModalOp = "delete"
      this.mutateModalMsg = "Are you sure you want to delete this subtitle?"
      this.mutateModalVisible = true
    },

    doMutateSubtitle() {
      this.mutateModalVisible = false

      let url = ""
      let successMsg = ""

      switch(this.mutateModalOp) {
        case "delete":
          url = `admin/api/v2/media/${this.itemId}/delete-subtitle`
          successMsg = "Subtitle successfully deleted"
          break
      }

      fetch(
        url,
        {method: 'POST', body: JSON.stringify({data: {subtitle_source: this.subtitleToMutate}})}
      )
      .then(this.$helpers.JSONHandler)
      .then(resp => {
        if(resp.status == "ok") {
          this.$emit('reload')
          this.$root.app.showAlert(successMsg, "success")
        }
        else {
          this.$root.app.showErrorDialog(resp.type, "Error deleting subtitle")
        }
        this.subtitleToMutate = null
      })
    }
  }
}
</script>

<style scoped>
</style>
