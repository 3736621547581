<template>
    <b-modal  ref="modal"
              title="Add Promo code"
              @ok="createPromoCode"
              @hidden="clear">
      <form autocomplete="off">
        <b-form-group label="Code" label-for="code-input" horizontal>
          <b-form-input id="code-input" v-model="item.code" ></b-form-input>
        </b-form-group>
        <b-form-group label="Description" label-for="description-input" horizontal>
          <b-form-input id="description-input" v-model="item.description" ></b-form-input>
        </b-form-group>
        <b-form-group label="Partner" label-for="promo-code-select-partner" horizontal>
          <b-form-select id="promo-code-select-partner" v-model="item.partner" :options="partnerOptions"></b-form-select>
        </b-form-group>
        <b-form-group label="Schedule" label-for="publication-comp" horizontal>
          <Publication id="publication-comp" from-field="active_from" to-field="active_to" v-model="item"/>
        </b-form-group>
        <b-form-group label="Offers" label-for="promo-code-offer-editor" horizontal>
          <PromoCodeOffers id="promo-code-offer-editor" :type="item.type" :discounts="item.discounts" @update="updateDiscounts"></PromoCodeOffers>
        </b-form-group>
      </form>
      <transition name="fade">
        <b-alert :show="alert.dismissCountdown" dismissible :variant="alert.variant" @dismissed="alert.dismissCountdown = 0">
          {{ alert.msg }}
        </b-alert>
      </transition>
    </b-modal>
</template>

<script>
import Publication from "./Publication.vue"
import PromoCodeOffers from "./PromoCodeOffers.vue"

export default {
  name: 'AddPromoCode',

  components: {
    Publication,
    PromoCodeOffers
  },

  props: {
  },

  data() {
    return {
      item: {
        code: '',
        description: null,
        partner: null,
        status: 'active',
        active_from: null,
        active_to: null,
        discounts: []
      },
      alert: {
        dismissCountdown: 0,
        variant: "warning",
        msg: ""
      },

      typeOptions: [],
      partnerOptions: [],
      recurringCompensation: 0,
      offers: []
    }
  },

  methods: {
    show() {
      this.item.active_from = new Date().toISOString()

      fetch('/admin/api/v2/partners/select-options')
      .then(function(resp) {
        return resp.json();
      })
      .then(resp => {
        this.partnerOptions = resp.data
        this.$refs.modal.show();
      })
    },

    clear() {
      this.item = {
        code: '',
        description: null,
        partner: null,
        status: 'active',
        active_from: null,
        active_to: null,
        discounts: []
      }

      this.offers = []
    },

    createPromoCode(ev) {
      if(this.item.code && this.item.code.length > 0) {
        this.updateDiscounts(this.offers)
        let item = this.$_.clone(this.item)

        this.$http.postJSON(
            'admin/api/v2/promo-codes',
            {data: item},
            () => {
            this.$root.app.showAlert("Save successful", "success")
            this.$root.$emit('promo-code-refresh')
            },
            (status, html) => {
              this.$root.app.showErrorDialog(html, status.toString())
            }
        )
      }
      else {
        this.alert.msg = "Please provide a promo code"

        this.alert.dismissCountdown = 4
        ev.preventDefault()
      }
    },

    updateDiscounts(offers) {
      this.offers = offers

      let getAmount = (offerId) => {
        let items = this.item.discounts

        for(var i = 0; i < items.length; ++i) {
          if(items[i].offer_id == offerId) {
            return parseInt(this.$helpers.currencyParser(items[i].euro_amount) * 100 + 0.00001)
          }
        }

        return 0;
      }

      let getEuroAmount = (offerId) => {
        let items = this.item.discounts

        for(var i = 0; i < items.length; ++i) {
          if(items[i].offer_id == offerId) {
            return items[i].euro_amount
          }
        }

        return 0;
      }

      this.item.discounts = offers.map(o => {
        return {
          promo_code_id: this.item.id,
          offer_id: o,
          amount: getAmount(o),
          euro_amount: getEuroAmount(o)
        }
      })
    }
  },

  created() {
    this.clear()
  }
}
</script>


<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
