<template>
  <div class="duplicate-media">
    <b-modal  ref="modal"
              :visible="value"
              title="Duplicate media item"
              no-close-on-backdrop
              @ok="duplicateMedia"
              @cancel="handleCancel"
              auto-focus-button="ok">
      <div class="form-content">
        <b-form id="duplicate-form">
          <b-form-input v-b-tooltip.hover title="Number of duplicates" id="duplicate-count-input" v-model.number="duplicateCount" type="number"></b-form-input>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'DuplicateMedia',

  data() {
    return {
      sourceId: null,
      duplicateCount: 1
    }
  },

  props: {
    value: Boolean
  },

  methods: {
    duplicateMedia() {
      this.$emit('input', false)
      this.$store.dispatch('media/persistDuplicates', this.duplicateCount)
        .then(() => {
          this.$root.app.showAlert('Duplicating media item successful', 'success')
        })
        .catch((error) => {
          this.$root.app.showAlert(`Error duplicating media item: ${error}`, 'danger')
        })
    },

    handleCancel() {
      this.$emit('input', false)
      this.$store.commit('media/updateModal', {duplicate: {visible: false, id: null}})
    }
  }

}

</script>