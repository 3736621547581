var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        visible: _vm.value,
        size: _vm.size,
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        "auto-focus-button": "ok",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [_vm._v(" " + _vm._s(_vm.msg) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }