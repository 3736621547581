import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueLogger from 'vuejs-logger'
import lodash from 'lodash'
import { DateTime } from  'luxon'
import 'v-autocomplete/dist/v-autocomplete.css'

import store from './store'

import App from './App.vue'
import router from './router'
import DataSource from './DataSource'
import Helpers from './Helpers'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faHome,
  faGripHorizontal,
  faVideo,
  faBook,
  faBookOpen,
  faImages,
  faCompress,
  faExpand,
  faPlusCircle,
  faTrashAlt,
  faFileImport,
  faTv,
  faStop,
  faDownload,
  faArrowUp,
  faEdit,
  faGrin,
  faSave,
  faFilter,
  faTimes,
  faArchive,
  faSearch,
  faCopy,
  faIndustry,
  faEye,
  faEyeSlash,
  faInbox,
  faCogs,
  faUndoAlt,
  faStore,
  faExternalLinkAlt,
  faGlobe,
  faGripVertical,
  faBan,faAd,
  faFunnelDollar,
  faChartPie,
  faKey,
  faSignInAlt,
  faDoorOpen,
  faAt,
  faExchangeAlt,
  faUpload,
  faExclamationCircle,
  faChartLine,
  faChartArea,
  faLock,
  faLockOpen,
  faPlayCircle,
  faUser,
  faUserSecret,
  faUserGroup,
  faHandsHelping,
  faBoxOpen,
  faFire,
  faInfo,
  faPercent,
  faClone,
  faPhotoVideo,
  faAsterisk,
  faEllipsisV,
  faTags,
  faListOl,
  faSquareCheck} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


// Font Awesome initialization
library.add(
  faHome,
  faGripHorizontal,
  faVideo,
  faBook,
  faBookOpen,
  faImages,
  faCompress,
  faExpand,
  faPlusCircle,
  faTrashAlt,
  faFileImport,
  faDownload,
  faTv,
  faArrowUp,
  faStop,
  faEdit,
  faGrin,
  faSave,
  faFilter,
  faTimes,
  faArchive,
  faSearch,
  faCopy,
  faIndustry,
  faEye,
  faEyeSlash,
  faInbox,
  faCogs,
  faUndoAlt,
  faStore,
  faExternalLinkAlt,
  faGlobe,
  faGripVertical,
  faBan,
  faAd,
  faFunnelDollar,
  faChartPie,
  faKey,
  faSignInAlt,
  faDoorOpen,
  faAt,
  faExchangeAlt,
  faUpload,
  faExclamationCircle,
  faChartLine,
  faChartArea,
  faLock,
  faLockOpen,
  faPlayCircle,
  faUser,
  faUserSecret,
  faUserGroup,
  faHandsHelping,
  faBoxOpen,
  faFire,
  faInfo,
  faPercent,
  faClone,
  faPhotoVideo,
  faAsterisk,
  faEllipsisV,
  faTags,
  faListOl,
  faSquareCheck
);
Vue.component('font-awesome-icon', FontAwesomeIcon)


// Global component imports

// TreeNode is imported so that it can be used recursively
import TreeNode from './components/TreeNode.vue'
Vue.component('TreeNode', TreeNode)

import Icon from './components/Icon.vue'
Vue.component('Icon', Icon)

import MediaIcon from './components/MediaIcon.vue'
Vue.component('MediaIcon', MediaIcon)

Vue.use(BootstrapVue)


// Setup config

const isProduction = process.env.NODE_ENV === 'production';

const logOptions = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : true,
    showLogLevel : true,
    showMethodName : false,
    separator: '|',
    showConsoleColors: true
};
Vue.use(VueLogger, logOptions)

Vue.config.productionTip = false

import appConfig from './config.js'

let OSName="Unknown OS";
let platform = navigator.userAgentData ? navigator.userAgentData.platform : ""
if(platform.length == 0) platform = navigator.platform || ""

if (platform.indexOf("Win")!=-1) OSName="Windows";
if (platform.indexOf("Mac")!=-1) OSName="MacOS";
if (platform.indexOf("X11")!=-1) OSName="UNIX";
if (platform.indexOf("Linux")!=-1) OSName="Linux";


// Set to Vue prototype, so libraries and configuration can be used in every component

Object.defineProperty(Vue.prototype, '$_', { value: lodash });
Object.defineProperty(Vue.prototype, '$helpers', { value: Helpers });
Object.defineProperty(Vue.prototype, '$http', { value: DataSource });
Object.defineProperty(Vue.prototype, '$dt', { value: DateTime });
Object.defineProperty(Vue.prototype, '$config', { value: appConfig });
Object.defineProperty(Vue.prototype, '$os', { value: OSName });


// Define vue template formatters

Vue.filter('formatSize', function (size) {
  if(size) {
    if (size > 1024 * 1024 * 1024 * 1024) {
      return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
    } else if (size > 1024 * 1024 * 1024) {
      return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
    } else if (size > 1024 * 1024) {
      return (size / 1024 / 1024).toFixed(2) + ' MB'
    } else if (size > 1024) {
      return (size / 1024).toFixed(2) + ' KB'
    }
    return size.toString() + ' B'
  }

  return ''
})

import * as Sentry from "@sentry/vue";


if (appConfig.enableSentry) {
  Sentry.init({
    Vue,
    dsn: appConfig.sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: appConfig.sentryDomains,
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}


// instantiate vue

new Vue({
  router,
  store: store,
  render: h => h(App),
  computed: {
    app() {
      return this.$children[0]
    }
  }
}).$mount('#app')
