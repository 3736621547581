var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AutoComplete", {
    attrs: {
      "auto-select-one-item": false,
      "input-attrs": { class: "form-control" },
      value: _vm.item,
      items: _vm.filteredItems,
      "min-len": 0,
    },
    on: {
      "update-items": _vm.updateItems,
      "item-clicked": _vm.updateItems,
      blur: _vm.onBlur,
      focus: _vm.onFocus,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }