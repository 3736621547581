var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "S3UploadModal",
    {
      attrs: {
        name: _vm.batchImportFileName,
        "name-as-path": "",
        format: "doc",
        "button-text": "Bulk Import",
        "modal-title": "Users Bulk Import",
        "hide-object-name": true,
      },
      on: {
        "file-change": _vm.setBatchImportFileName,
        finished: _vm.handleUploadFinished,
        "start-upload-request": _vm.handleStartUpload,
        show: _vm.show,
      },
    },
    [
      _c(
        "b-form-group",
        {
          attrs: {
            label: "Partner",
            "label-for": "batch-import-select-partner",
            horizontal: "",
          },
        },
        [
          _c("b-form-select", {
            attrs: {
              id: "batch-import-select-partner",
              options: _vm.partnerOptions,
            },
            on: {
              change: function ($event) {
                return _vm.loadAffiliates($event)
              },
            },
            model: {
              value: _vm.userPartner,
              callback: function ($$v) {
                _vm.userPartner = $$v
              },
              expression: "userPartner",
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          attrs: {
            label: "Affiliate",
            "label-for": "batch-import-select-partner",
            horizontal: "",
          },
        },
        [
          _c("b-form-select", {
            attrs: {
              id: "batch-import-select-partner",
              options: _vm.affiliateOptions,
            },
            model: {
              value: _vm.userAffiliate,
              callback: function ($$v) {
                _vm.userAffiliate = $$v
              },
              expression: "userAffiliate",
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { label: "Offer" } },
        [
          _c("b-form-select", {
            attrs: { options: _vm.userOfferOptions },
            model: {
              value: _vm.userOffer,
              callback: function ($$v) {
                _vm.userOffer = $$v
              },
              expression: "userOffer",
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { label: "Language" } },
        [
          _c("b-form-select", {
            attrs: { options: _vm.userLanguageOptions },
            model: {
              value: _vm.userLanguage,
              callback: function ($$v) {
                _vm.userLanguage = $$v
              },
              expression: "userLanguage",
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { label: "Country" } },
        [
          _c("b-form-select", {
            attrs: { options: _vm.userCountryOptions },
            model: {
              value: _vm.userCountry,
              callback: function ($$v) {
                _vm.userCountry = $$v
              },
              expression: "userCountry",
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { label: "Start Date" } },
        [
          _c("DateTime", {
            model: {
              value: _vm.userStartDate,
              callback: function ($$v) {
                _vm.userStartDate = $$v
              },
              expression: "userStartDate",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }