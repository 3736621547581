<template>
  <b-modal :visible="value" size="lg" :title="title" @ok="handleOk" ok-only hide-header-close :ok-disabled="!selectedItem" @shown="$refs.searchInput.focus()" @hidden="$emit('input', false)">
    <b-card>

      <b-input-group class="mb-3">
        <b-form-input v-model="searchInput" placeholder="Type to Search" ref="searchInput"/>
        <b-input-group-append>
          <b-btn :disabled="!searchInput" @click="searchInput = ''"><Icon icon="Search_backspace"/></b-btn>
        </b-input-group-append>
      </b-input-group>

      <b-list-group>
        <b-list-group-item href="#" v-for="item in items" :key="item.id" @click="selectedItem = item" :active="selectedItem && item.id == selectedItem.id">
          <span>{{ item.meta.title }}</span>
          <span v-if="item.meta.secondary_title || (item.artists || []).join(', ')" class="mx-1">- {{ item.meta.secondary_title || (item.artists || []).join(', ') }}</span>
          <b-badge>{{ item.type }}</b-badge>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </b-modal>
</template>

<script>
export default {
  name: 'SelectItem',

  components: {
  },

  data: function() {
    return {
      searchInput: '',
      items: [],
      selectedItem: null
    };
  },
  props: {
    title: String,
    value: Boolean,
    lang: String,
    types: String,
    requirePreview: {type: Boolean, default: false}
  },

  watch: {
    searchInput(val) {
      if(val && val.length) {
        this.search(this.searchInput)
      }
      else {
        this.items = []
      }
    }
  },

  methods: {
    search(query) {
      fetch(`/admin/api/v2/media/search?q=${query}&language=${this.lang}&status=draft,ready,published&type=${this.types}&require_preview=${this.requirePreview}`)
      .then(function(resp) {
        return resp.json()
      })
      .then(resp => {
        this.items = resp.data
      })
    },

    handleOk() {
      this.$emit('selected', this.selectedItem)
    },
  },

  
}
</script>

<style scoped>
.scroller {
  overflow-y: scroll;
  max-height: 600px;
}
</style>

