<template>
  <b-dropdown variant="link">
    <template slot="button-content">
      <font-awesome-icon icon="globe"/>
      <span class="mx-2">Publish Status</span>
    </template>
    <b-dropdown-form>
      <b-form-checkbox v-model="showAll" class="my-1">
        <span class="px-2 py-1">Toggle All</span>
      </b-form-checkbox>
      <b-form-checkbox :checked="publishStatusFilter.incomplete" @input="updateFilter('incomplete', $event)" class="my-1">
        <span class="fg-incomplete rounded px-2 py-1" v-b-tooltip.hover title="Item is missing meta data and should not be published yet">Incomplete</span>
      </b-form-checkbox>
      <b-form-checkbox :checked="publishStatusFilter.complete" @input="updateFilter('complete', $event)" class="my-1">
        <span class="fg-complete rounded px-2 py-1" v-b-tooltip.hover title="Item is ready to be published">Complete</span>
      </b-form-checkbox>
      <b-form-checkbox :checked="publishStatusFilter.overdue" @input="updateFilter('overdue', $event)" class="my-1">
        <span class="fg-overdue rounded px-2 py-1" v-b-tooltip.hover title="Item is complete and according to its schedule should be live already, but isn't published yet">Overdue</span>
      </b-form-checkbox>
      <b-form-checkbox :checked="publishStatusFilter.upcoming" @input="updateFilter('upcoming', $event)" class="my-1">
        <span class="fg-upcoming rounded px-2 py-1" v-b-tooltip.hover title="Item is published, but its start schedule is still in the future">Upcoming</span>
      </b-form-checkbox>
      <b-form-checkbox :checked="publishStatusFilter.live" @input="updateFilter('live', $event)" class="my-1">
        <span class="fg-live rounded px-2 py-1" v-b-tooltip.hover title="Item is published and accessible for customers">Live</span>
      </b-form-checkbox>
      <b-form-checkbox :checked="publishStatusFilter.done" @input="updateFilter('done', $event)" class="my-1">
        <span class="fg-done rounded px-2 py-1" v-b-tooltip.hover title="Item is published, but its end schedule has been reached">Done</span>
      </b-form-checkbox>
      <b-form-checkbox :checked="publishStatusFilter.invalid" @input="updateFilter('invalid', $event)" class="my-1">
        <span class="fg-invalid rounded px-2 py-1" v-b-tooltip.hover title="Item is published and accessible for customers, but meta data is still missing">Invalid</span>
      </b-form-checkbox>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'PublishStatusFilter',

  data: function() {
    return {
      showAll: true,
      show: false
    };
  },

  computed: {
    collapseIcon() {
      if(this.show) return "expand_more-24px"
      else return "Expand_collapsed"
    },

    ...mapState('media', {
      publishStatusFilter: 'publishStatusFilter'
    })
  },

  watch: {
    showAll(val) {
      this.$store.commit('media/publishStatusFilterSetAll', val)
    }
  },

  methods: {
    toggle() {
      this.show = !this.show
    },

    updateFilter(publish_status, value) {
      this.$store.commit('media/updatePublishStatusFilter', {publish_status, value})
    }
  }
}
</script>
