<template>
  <div class="d-flex justify-content-between">
    <b-input v-if="!expanded" :id="id" :value="primaryAssetSource" readonly class="mr-2"></b-input>
    <span v-if="!expanded" class="mx-2" style="margin-top: 3px;">{{ $helpers.durationToString(primaryAssetDuration) }}</span>
    <b-list-group v-if="expanded" class="mr-2" style="width: 100%; text-align: left">
      <b-list-group-item v-for="item in items" :key="item.id">
        <b-row>
          <b-col>
            <b-form>
              <b-form-group label="Status" label-class="font-weight-bold">
                <span>{{ item.status }}</span>
              </b-form-group>
              <b-form-group label="Source" label-class="font-weight-bold">
                <span>{{ item.source }}</span>
              </b-form-group>
              <b-form-group label="Profile"  label-class="font-weight-bold" v-if="item.format == 'video' && item.profile">
                <span>{{ item.profile }}</span>
              </b-form-group>
              <b-form-group label="Transcode Job Ready"  label-class="font-weight-bold" v-if="item.status == 'ready' && item.transcode_job_ready">
                <span>{{ $helpers.utcToLocalString(item.transcode_job_ready) }}</span>
              </b-form-group>
              <b-form-group label="Embedded subtitles"  label-class="font-weight-bold" v-if="item.format == 'video'">
                <b-list-group>
                  <b-list-group-item v-for="s in item.subtitles" :key="s.source">
                    <span class="font-weight-bold mr-2">{{ s.language }}</span>
                    <span>{{ s.source }}</span>
                  </b-list-group-item>
                </b-list-group>
              </b-form-group>
            </b-form>
          </b-col>
          <b-col>
            <b-button-group class="float-right">
              <b-button :id="`download-asset-${item.id}`" v-b-tooltip.hover title="Download source file" variant="primary" @click="downloadAsset(item.id)">
                <font-awesome-icon icon="download"/>
              </b-button>
              <b-button v-if="item.status == 'ready' && format == 'video'" v-b-tooltip.hover title="Generate Preview..." variant="primary" @click="generatePreview(item.id)">
                <font-awesome-icon icon="photo-video"/>
              </b-button>
              <b-button v-b-tooltip.hover :title="`Retranscode ${format}`" variant="primary" @click="retranscodeAsset(item.id)">
                <font-awesome-icon icon="undo-alt"/>
              </b-button>
              <b-button v-b-tooltip.hover :title="`Delete ${format}`" variant="danger" @click="deleteAsset(item.id)">
                <font-awesome-icon icon="trash-alt"/>
              </b-button>
            </b-button-group>
            <b-popover :target="`download-asset-${item.id}`" triggers="click blur" placement="bottom">
              <a class="bright-download-url" :href="downloadUrl" target="_blank" download>Download file...</a>
            </b-popover>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>

    <AddAsset :format="format" :language="language" :item-id="itemId" @finished="$emit('update')"></AddAsset>

    <div class="ml-2" style="max-height: 40px">
      <b-button size="" :variant="expanded ? 'warning' : 'primary'" @click.stop="toggleExpand" style="max-height: 40px; width: 4.8rem;">
        <Icon icon="edit"/>
          Edit
      </b-button>
    </div>
    <SimpleModal :value='mutateModalVisible' @ok="doMutateAsset" @cancel="mutateModalVisible = false" :msg="mutateModalMsg"/>
    <GeneratePreview v-model="generatePreviewVisible" :asset-id="previewAssetId"/>
  </div>
</template>

<script>
import SimpleModal from "./SimpleModal.vue"
import AddAsset from "./AddAsset.vue"
import GeneratePreview from "./GeneratePreview.vue"

export default {
  name: 'AssetList',

  components: {
    SimpleModal,
    AddAsset,
    GeneratePreview
  },

  data: function() {
    return {
      expanded: false,
      mutateModalVisible: false,
      mutateModalMsg: "",
      mutateModalOp: "",
      assetToMutate: null,
      generatePreviewVisible: false,
      previewAssetId: null,
      downloadUrl: null
    };
  },
  props: {
    id: String,
    items: Array,
    itemId: String,
    format: String,
    language: String
  },

  computed: {
    primaryAssetSource() {
      this.items.forEach(item => {
        if(item && item.status == "ready" && item.type == "primary") {
          return item.source;
        }
      });

      this.items.forEach(item => {
        if(item && item.type == "primary") {
          return item.source;
        }
      });

      if(this.items.length > 0) {
        return this.items[0].source;
      }

      return null;
    },

    primaryAssetDuration() {
      this.items.forEach(item => {
        if(item && item.status == "ready" && item.type == "primary") {
          return item.duration;
        }
      });

      this.items.forEach(item => {
        if(item && item.type == "primary") {
          return item.duration;
        }
      });

      if(this.items.length > 0) {
        return this.items[0].duration;
      }

      return null;
    }
  },

  methods: {
    toggleExpand() {
      this.expanded = !this.expanded
    },

    deleteAsset(assetId) {
      this.assetToMutate = assetId
      this.mutateModalOp = "delete"
      this.mutateModalMsg = "Are you sure you want to delete this asset?"
      this.mutateModalVisible = true
    },

    retranscodeAsset(assetId) {
      this.assetToMutate = assetId
      this.mutateModalOp = "retranscode"
      this.mutateModalMsg = "Are you sure you want to retranscode this asset?"
      this.mutateModalVisible = true
    },

    doMutateAsset() {
      this.mutateModalVisible = false

      let url = ""
      let ev = ""
      let successMsg = ""

      switch(this.mutateModalOp) {
        case "delete":
          url = `admin/api/v2/assets/delete`
          ev = "delete-asset"
          successMsg = "Asset successfully deleted"
          break

        case "retranscode":
          url = `admin/api/v2/assets/retranscode`
          ev = "retranscode-asset"
          successMsg = "Asset retranscode job started"
          break
      }

      fetch(
        url,
        {method: 'POST', body: JSON.stringify({data: {id: this.assetToMutate}})}
      )
      .then(resp => resp.json())
      .then(resp => {
        if(resp.status == "ok") {
          this.$emit(ev, this.assetToMutate)
          this.$root.app.showAlert(successMsg, "success")
        }
        else {
          console.log(resp)
          if(resp.type == 'item_published') {
            this.$root.app.showErrorDialog(`${this.$helpers.prettify(resp.type)}. Unpublish item to retranscode assets.`, "Retranscode error")
          }
          else {
            this.$root.app.showAlert(`Retranscode Error: ${this.$helpers.prettify(resp.type)}`, "danger")
          }

        }
        this.assetToMutate = null
      })
      .catch(e => {
        console.log(e)
        this.$root.app.showAlert(`Retranscode Error: ${e.text}`, "danger")
      })
    },

    generatePreview(assetId) {
       this.previewAssetId = assetId
      this.generatePreviewVisible = true 
    },

    downloadAsset(assetId) {
      fetch(
        "admin/api/v2/assets/temp-download-url",
        {method: 'POST', body: JSON.stringify({data: {id: assetId}})}
      )
      .then(resp => resp.json())
      .then(resp => {
        if(resp.status == "ok") {
          this.downloadUrl = resp.data
        }
        else {
          console.log(resp)
          this.$root.app.showAlert(`Error getting temporary download url: ${this.$helpers.prettify(resp.type)}`, "danger")
        }
      })
      .catch(e => {
        console.log(e)
        this.$root.app.showAlert(`Error getting temporary download url: ${e.text}`, "danger")
      })
    }
  }
}
</script>

<style scoped>
.bright-download-url {
  text-color: #E5E9EC !important;
  color: #E5E9EC !important;
}

</style>
