<template>
  <S3UploadModal :name="batchImportFileName" name-as-path format="doc" @file-change="setBatchImportFileName" button-text="Bulk Import" modal-title="Users Bulk Import" @finished="handleUploadFinished" @start-upload-request="handleStartUpload" @show="show" :hide-object-name="true">
    <b-form-group label="Partner" label-for="batch-import-select-partner" horizontal>
      <b-form-select id="batch-import-select-partner" v-model="userPartner" :options="partnerOptions" @change="loadAffiliates($event)"></b-form-select>
    </b-form-group>
    <b-form-group label="Affiliate" label-for="batch-import-select-partner" horizontal>
      <b-form-select id="batch-import-select-partner" v-model="userAffiliate" :options="affiliateOptions"></b-form-select>
    </b-form-group>
    <b-form-group label="Offer">
      <b-form-select v-model="userOffer" :options="userOfferOptions"></b-form-select>
    </b-form-group>
    <b-form-group label="Language">
      <b-form-select v-model="userLanguage" :options="userLanguageOptions"></b-form-select>
    </b-form-group>
    <b-form-group label="Country">
      <b-form-select v-model="userCountry" :options="userCountryOptions"></b-form-select>
    </b-form-group>
    <b-form-group label="Start Date">
      <DateTime v-model="userStartDate"></DateTime>
    </b-form-group>


  </S3UploadModal>
</template>

<script>
import S3UploadModal from "./S3UploadModal.vue"
import DateTime from "./GenericDateTime.vue"

export default {
  name: "AddUsersBatchImport",

  components: {
    S3UploadModal,
    DateTime
  },

  data() {
    return {
      batchImportFileName: "",
      batchImportId: null,
      userOrganisation: "",
      userLanguage: this.$config.defaultLanguage,
      userLanguageOptions: [],

      userCountry: "NL",
      userCountryOptions: [],

      userOffer: "",
      userOfferOptions: [],

      userStartDate: "",

      userPartner: null,
      partnerOptions: [],

      userAffiliate: null,
      affiliateOptions: [],


      jobId: null
    };
  },

  methods: {
    setBatchImportFileName(ev) {
      if(ev && ev.name) {
        this.batchImportFileName = ev.name
      }
    },

    handleStartUpload(ev) {
      if(this.userOffer && this.userOffer != "") {
        const payload = JSON.stringify({
          data: {
            bucket: ev.conf.bucket,
            path: ev.conf.key_prefix + this.batchImportFileName,
            country: this.userCountry,
            partner: this.userPartner,
            affiliate_id: this.userAffiliate,
            offer: this.userOffer,
            start_date: this.userStartDate,
            language: this.userLanguage
          }
        })

        fetch('/admin/api/v2/users/batch-import/new', {method: 'POST', body: payload})
        .then((resp) =>{
          if(resp.status == 200) {
            return resp.json()
          }
          else {
            throw Error()
          }
        })
        .then((resp) => {
          this.batchImportId = resp.data
          ev.handler()
        })
        .catch(() => {
          this.$root.app.showAlert("Error Creating Users Batch Import Job", "danger")
        })
      }
      else {
        this.$root.app.showAlert("Please select an offer to use for the bulk import", "danger")
      }
    },

    show() {
      const now = this.$dt.utc()

      this.userStartDate = now.toFormat('yyyy-MM-dd') + 'T' + now.toFormat('hh:mm:ss') + 'Z'
    },

    loadAffiliates(selectedPartner) {
      fetch(`/admin/api/v2/partners/affiliate-select-options/${selectedPartner}?include-empty`)
      .then(function(resp) {
        return resp.json();
      })
      .then(resp => {
        this.userAffiliate = null
        this.affiliateOptions = resp.data
      })
    },

    handleUploadFinished() {
      setTimeout(() => {
        fetch(`/admin/api/v2/users/batch-import/status/${this.batchImportId}`)
        .then(resp => {
          if(resp.status == 200) {
            return resp.json()
          }
          else {
            throw Error()
          }
        })
        .then(json => {
          switch(json.data.status) {
            case "done":
              this.$root.app.showAlert("Users successfully bulk imported", "success")
              break

            case "error":
              if(json.data.msg == "user_already_has_gift" && json.data.data.length > 0) {
                const pre_body = '<p class="font-weight-bold text-left">The following users where excluded from bulk import, because they already have a pending gift.</p>' +
                  '<p class="text-left">This can be caused because they were already part of a bulk import before, or they received a gift from someone else.</p>' +
                  '<ul class="text-left">'
                const body = json.data.data.map(email => `<li>${email}</li>`).join('')
                const post_body ='</ul>'
                this.$root.app.showErrorDialog(pre_body + body + post_body, 'Users excluded from bulk import')
              }
              else {
                this.$root.app.showAlert("An error occured during users bulk import", "danger")
              }
              break

            default:
              this.handleUploadFinished()
          }
        })
        .catch(() => {
          this.$root.app.showAlert("An error occured trying to get users bulk import status", "danger")
        })
      }, 1000)
    }
  },

  created() {
    fetch('/admin/api/v2/users/geo-options')
    .then(function(resp) {
      return resp.json();
    })
    .then(resp => {
      this.userCountryOptions = resp.data
    })

    fetch('/admin/api/v2/users/bulk-offer-options')
    .then(function(resp) {
      return resp.json();
    })
    .then(resp => {
      this.userOfferOptions = resp.data
    })

    fetch('/admin/api/v2/partners/select-options')
    .then(function(resp) {
      return resp.json();
    })
    .then(resp => {
      this.partnerOptions = resp.data
    })

  },

  mounted() {
    this.userLanguageOptions = this.$store.getters['app/languageCodes'].map(code => {
      return {value: code, text: this.$helpers.getLanguageName(code)}
    })
  }
};
</script>
