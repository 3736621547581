<template>
  <b-img :src="path" :width="size + 'px'" :height="size + 'px'"/>
</template>

<script>
export default {
  name: 'Icon',

  data: function() {
    return {

    };
  },

  props: {
    size: {type: Number, default: 16},
    icon: String
  },

  computed: {
    path() {
      return 'icons/' + this.icon + ".svg"
    }
  }
}
</script>

<style scoped>
img {
  margin-bottom: 2px;  
}
</style>
