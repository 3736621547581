<template>
  <div>
    <b-button-toolbar key-nav  aria-label="Toolbar with button groups">

      <b-button-group>
        <b-button @click="$root.$emit('home-content-export')" variant="primary">
            <Icon icon="export"/>
            <span class="ml-2">Content Overview Export</span>
        </b-button>
      </b-button-group>

    </b-button-toolbar>
  </div>
</template>

<script>

export default {
  name: 'HomeToolbar',

  data: function() {
    return {    
    };
  },

}
</script>
