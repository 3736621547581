<template>
  <div style="width: 100%;" class="sticky-top">
    <Keypress :key-code="70" :modifiers="keyModifiers" @success="focusSearch" />

    <div class="w-100 d-flex justify-content-between pl-4 mr-4 bg-dark py-1">
      <span style="margin-top: 0.75rem;" class="page-title">{{ activePageTitle }}</span>
      <HomeToolbar v-if="toolbarType == 'home-toolbar'"/>
      <MediaToolbar v-else-if="toolbarType == 'media-toolbar'" ref="mediaBar" :media-type="contentType"/>
      <KeywordToolbar v-else-if="toolbarType == 'keyword-toolbar'" :keyword-type="contentType"/>
      <UserToolbar v-else-if="toolbarType == 'user-toolbar'" :user-type="contentType"/>
      <AffiliateToolbar v-else-if="toolbarType == 'affiliate-toolbar'"/>
      <PromoCodeToolbar v-else-if="toolbarType == 'promo-code-toolbar'"/>
      <InboxToolbar v-else-if="toolbarType == 'inbox-toolbar'" :inbox-type="contentType"/>
      <DdexToolbar v-else-if="toolbarType == 'ddex-toolbar'" :ddex-type="contentType"/>
      <CampaignToolbar v-else-if="toolbarType == 'campaign-toolbar'"/>
      <FinanceToolbar v-else-if="toolbarType == 'finance-toolbar'" :finance-type="contentType"/>
      <StatsToolbar v-else-if="toolbarType == 'stats-toolbar'" :stats-type="contentType"/>

      <div style="margin-right: -5px; margin-top: 12px" class="ml-1">
        <Icon icon="Scheiding" :size="24"/>
      </div>

      <b-dropdown variant="link" no-caret right>
        <!-- Using button-content slot -->
        <template slot="button-content">
          <div style="margin-top: 1px;">
            <Icon :size="24" icon="menu-24px"/>
          </div>
        </template>
        <b-dropdown-item disabled>{{ user.username }}</b-dropdown-item>
        <b-dropdown-item href="#" @click="handleLogout">Signout</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import HomeToolbar from "./HomeToolbar.vue"
import MediaToolbar from "./MediaToolbar.vue"
import KeywordToolbar from "./KeywordToolbar.vue"
import UserToolbar from "./UserToolbar.vue"
import AffiliateToolbar from "./AffiliateToolbar.vue"
import PromoCodeToolbar from "./PromoCodeToolbar.vue"
import InboxToolbar from "./InboxToolbar.vue"
import FinanceToolbar from "./FinanceToolbar.vue"
import StatsToolbar from "./StatsToolbar.vue"
import Keypress from 'vue-keypress'
import DdexToolbar from "@/components/DdexToolbar.vue";


export default {
  name: "Bar",

  components: {
    DdexToolbar,
    HomeToolbar,
    MediaToolbar,
    KeywordToolbar,
    UserToolbar,
    InboxToolbar,
    FinanceToolbar,
    StatsToolbar,
    AffiliateToolbar,
    PromoCodeToolbar,
    Keypress
  },

  data() {
    return {
      toolbarType: null,
      contentType: null
    }
  },

  props: {
    activePageTitle: String,
    alert: Object,
    isMediaPage: Boolean
  },

  computed: {
    keyModifiers() {
      return this.$os == "MacOS" ? ['metaKey', 'shiftKey'] : ['ctrlKey', 'shiftKey']
    },

    user() {
      return this.$store.state.session.user || {}
    }
  },

  watch: {
    contentType(val) {
      this.$store.commit('app/putContentType', val)
    }
  },

  methods: {
    setToolbar(toolbarType, contentType) {
      this.toolbarType = toolbarType
      this.contentType = contentType
    },


    focusSearch() {
      switch(this.toolbarType) {
        case "media-toolbar":
          this.$root.$emit('media-search-focus')
          break;

        case "keyword-toolbar":
          this.$root.$emit('keyword-focus-search')
          break;

        case "inbox-toolbar":
          this.$root.$emit('inbox-focus-search')
          break;

        case "ddex-toolbar":
          this.$root.$emit('ddex-focus-search')
          break;

        case "user-toolbar":
          this.$root.$emit('user-focus-search')
          break;

        case "finance-toolbar":
          this.$root.$emit('rev-share-focus-search')
          break;
      }

    },

    mediaLabelMatch(item, filter) {
      return this.$refs.mediaBar.mediaLabelMatch(item, filter)
    },

    mediaLabelFilter(item, filter) {
      this.$refs.mediaBar.mediaLabelFilter(item, filter)
    },

    mediaTypeMatch(item, filter) {
      return this.$refs.mediaBar.mediaTypeMatch(item, filter)
    },

    mediaTypeFilter(item, filter) {
      this.$refs.mediaBar.mediaTypeFilter(item, filter)
    },

    publishStatusMatch(item, filter) {
      return this.$refs.mediaBar.publishStatusMatch(item, filter)
    },

    publishStatusFilter(item, filter) {
      this.$refs.mediaBar.publishStatusFilter(item, filter)
    },

    handleLogout() {
      this.$store.dispatch('session/handleLogout').then(() => location.reload())
    }


  },

  created() {
    this.$root.$on('set-toolbar', this.setToolbar)
  },

  beforeDestroy() {
    this.$root.$off('set-toolbar', this.setToolbar)
  }
}
</script>

<style scoped>
  .page-title {
    color: #20B4FF;
    font-size: 1.2rem;
  }
</style>