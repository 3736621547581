var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "S3UploadModal",
    {
      attrs: {
        "item-id": _vm.itemId,
        name: _vm.subtitleObjectName,
        format: "subtitle",
        "modal-title": "Add Subtitle",
      },
      on: {
        "file-change": _vm.setSubtitleFileName,
        finished: function ($event) {
          return _vm.$emit("finished")
        },
        "start-upload-request": function ($event) {
          return $event.handler()
        },
      },
    },
    [
      _c(
        "b-form-group",
        { attrs: { label: "Title" } },
        [
          _c("b-form-input", {
            model: {
              value: _vm.subtitleTitle,
              callback: function ($$v) {
                _vm.subtitleTitle = $$v
              },
              expression: "subtitleTitle",
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { label: "Language" } },
        [
          _c("b-form-select", {
            attrs: { options: _vm.subtitleLanguageOptions },
            model: {
              value: _vm.subtitleLanguage,
              callback: function ($$v) {
                _vm.subtitleLanguage = $$v
              },
              expression: "subtitleLanguage",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }