<template>
  <div class="ml-auto py-2 pl-2 bg-dark">
    <div class="d-flex align-items-center justify-content-end">
      <b-button class="mr-2" @click="$root.$emit('inbox-remove')" variant="danger">
          <font-awesome-icon icon="trash-alt"/>
          <span class="ml-2">Remove</span>
      </b-button>
      <b-button @click="$root.$emit('inbox-transcode')" variant="primary">
          <font-awesome-icon icon="cogs"/>
          <span class="ml-2">Transcode</span>
      </b-button>

      <div class="mx-1" style="margin-top: 2px;">
        <Icon icon="Scheiding" :size="24"/>
      </div>

      <b-button-group>
        <b-input-group>
          <b-form-input ref="search" v-model="filterInput" placeholder="Type to Search" />
          <b-input-group-append>
            <b-button variant="btn-outline-light" :disabled="!filterInput" @click="filterInput = ''"><Icon icon="Search_backspace"/></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-button-group>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InboxToolbar',

  data: function() {
    return {
      filterInput: null
    };
  },

  props: {
    inboxType: String
  },

  watch: {
    filterInput(val) {
      this.$root.$emit('inbox-filter', val);
    }
  },

  methods: {
    focusSearch() {
      this.$refs.search.focus()
    }
  },

  created() {
    this.$root.$on('inbox-focus-search', this.focusSearch)
  },

  beforeDestroy() {
    this.$root.$off('inbox-focus-search', this.focusSearch)
  }

}
</script>
