<template>
    <b-modal  ref="modal"
              title="Add User"
              no-close-on-backdrop
              no-close-on-esc
              :ok-disabled="!isValid()"
              @ok="createUser"
              @hidden="clear">
      <form autocomplete="off">
        <b-form-group label="Username" label-for="name-input" horizontal>
          <b-form-input id="name-input" v-model="username" :state="usernameState"></b-form-input>
        </b-form-group>
        <b-form-group label="Password" label-for="password-input" horizontal>
          <b-input-group>
            <b-form-input id="password-input" autocomplete="new-password" data-lpignore="true" :type="showPassword ? 'text' : 'password'" v-model="item.password" ></b-form-input>
            <b-input-group-append>
              <b-button :pressed.sync="showPassword">
                <font-awesome-icon :icon="showPassword ? 'eye-slash' : 'eye'"/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Role" label-for="users-select-type" horizontal>
          <b-form-select id="users-select-type" v-model="item.type" :options="typeOptions"></b-form-select>
        </b-form-group>
        <b-form-group v-if="item.type == 'partner'" label="Partner" label-for="users-select-partner" horizontal>
          <b-form-select id="users-select-partner" v-model="item.partner" :options="partnerOptions"></b-form-select>
        </b-form-group>
        <b-form-group v-if="item.type == 'partner'" label="Allow Dashboard login" label-for="users-allow-dashboard" horizontal>
          <b-form-checkbox id="users-allow-dashboard" v-model="allowPartnerDashboard"></b-form-checkbox>
        </b-form-group>
        <b-form-group label="Country" label-for="users-select-geo" horizontal>
          <b-form-select id="users-select-geo" v-model="item.country" :options="geoOptions"></b-form-select>
        </b-form-group>
        <b-form-group label="Language" label-for="users-select-lang" horizontal>
          <b-form-select id="users-select-lang" v-model="selectedLang" :options="langOptions"></b-form-select>
        </b-form-group>
      </form>
      <transition name="fade">
        <b-alert :show="alert.dismissCountdown" dismissible :variant="alert.variant" @dismissed="alert.dismissCountdown = 0">
          {{ alert.msg }}
        </b-alert>
      </transition>
    </b-modal>
</template>

<script>
export default {
  name: 'AddUser',

  props: {
  },

  data() {
    return {
      username: null,
      item: {
        email: null,
        password: null,
        type: 'partner',
        country: 'NL',
        is_test_account: true,
        status: 'subscribed'
      },
      showPassword: false,
      allowPartnerDashboard: false,
      alert: {
        dismissCountdown: 0,
        variant: "warning",
        msg: ""
      },

      typeOptions: [],
      geoOptions: [],
      langOptions: [],
      partnerOptions: [],

      selectedLang: this.$config.defaultLanguage,
      usernameState: null
    }
  },

  watch: {
    username(value) {
      value = value || ""
      value = value.trim()
      if(value == "") {
        return null
      }
      else {
        fetch(`/admin/api/v2/users/is-user/${value}`)
        .then(resp => {
          if(resp.status == 200) this.usernameState = false
          else {
            this.usernameState = this.$helpers.isValidEmail(value)
          }
        })
      }
    }
  },

  methods: {
    show() {
      fetch('/admin/api/v2/users/type-options')
      .then(function(resp) {
        return resp.json();
      })
      .then(resp => {
        this.typeOptions = resp.data.filter(t => t.value != 'customer')
        fetch('/admin/api/v2/partners/select-options')
        .then(function(resp) {
          return resp.json();
        })
        .then(resp => {
          this.partnerOptions = resp.data
          fetch('/admin/api/v2/users/geo-options')
          .then(function(resp) {
            return resp.json();
          })
          .then(resp => {
            this.geoOptions = resp.data
            fetch('/admin/api/v2/users/lang-options')
            .then(function(resp) {
              return resp.json();
            })
            .then(resp => {
              this.langOptions = resp.data
              this.$refs.modal.show();
            })
          })
        })
      })
    },

    clear() {
      this.username = null
      this.usernameState = null
      this.item = {
        email: null,
        password: null,
        type: 'partner',
        partner_type: null,
        partner: null,
        country: 'NL',
        is_test_account: true,
        status: 'subscribed'
      }
    },

    isValid() {
      const usernameValid = this.usernameState != false
      const passwordValid = this.item.password && this.item.password.length > 1
      const partnerValid = this.item.type == 'partner' ? !!this.item.partner : true

      return usernameValid && passwordValid && partnerValid
    },

    createUser() {
      this.item.email = this.username
      this.item.locale = `${this.selectedLang}_${this.item.country}`
      this.item.partner_type = (this.item.type == 'partner' ? (this.allowPartnerDashboard ? 'admin' : 'user') : null)

      this.$http.postJSON(
          'admin/api/v2/users',
          {data: this.item},
          () => {
          this.$root.app.showAlert("Save successful", "success")
          this.$root.$emit('user-refresh')
          },
          (status, html) => {
            if(status == 400 && JSON.parse(html).type == "username_already_exists") {
              this.$root.app.showErrorDialog("Username already exists. Please pick a unique username.", "Error")
            }
            else {
              this.$root.app.showErrorDialog(html, status.toString())
            }
          }
      )
    },

  },

  created() {
    this.clear()
  }
}
</script>


<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
