var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "affiliate-offers", attrs: { id: _vm.id } },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _c(
            "div",
            _vm._l(_vm.discounts, function (ac) {
              return _c(
                "span",
                { key: ac.offer_id, staticClass: "offer" },
                [
                  _c(
                    "b-badge",
                    {
                      staticClass: "m-1",
                      attrs: { tag: "form", variant: "primary" },
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Offer",
                            "label-for": "offer-input",
                            horizontal: "",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "offer-input",
                              value: _vm.getOfferName(ac.offer_id),
                              readonly: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Discount",
                            "label-for": "amount-input",
                            horizontal: "",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "amount-input",
                              readonly: _vm.readonly,
                            },
                            model: {
                              value: ac.euro_amount,
                              callback: function ($$v) {
                                _vm.$set(ac, "euro_amount", $$v)
                              },
                              expression: "ac.euro_amount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
          !_vm.readonly
            ? _c(
                "div",
                { staticClass: "edit-keywords-button ml-auto" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "", variant: "primary" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.showEditor.apply(null, arguments)
                        },
                      },
                    },
                    [_c("Icon", { attrs: { icon: "edit" } }), _vm._v(" Edit ")],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c("EditPromoCodeOffers", {
        ref: "editKeywords",
        attrs: { offers: _vm.offers, offerList: _vm.offerList },
        on: {
          update: function ($event) {
            return _vm.$emit("update", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }