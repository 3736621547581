<template>
  <div :data-object-id="media.id">
    <div class="tree-node shadow-sm d-flex align-items-center justify-content-between" :class="{highlight: highlighted}" @click="selectNode">
      <div class="tree-node-left flex-grow-1 d-flex align-items-center">

        <div :class="publishStatusClass" class="shadow-sm grip mr-2 d-flex align-items-center justify-content-center">
          <MediaIcon v-if="$store.state.media.searchMode || media.category_type == 'root' || ($helpers.isTopLevel(media.type) && media.type != 'category')" v-b-tooltip.hover :title="$helpers.prettify(media.type)" :size="15" :type="media.type"/>
          <font-awesome-icon v-else icon="grip-vertical"/>
        </div>

        <template v-if="!(media.type == 'category' && $store.state.media.searchMode)">
          <div @click.stop="foldOut" v-if="$helpers.hasChildren(media.type)" class="pointer">
            <Icon :size="24" :icon="foldIcon"/>
          </div>
        </template>

        <div v-else style="min-width: 24px"></div>

        <div class="mx-2">
          <b-img-lazy v-if="nodeImageUrl" fluid :src="nodeImageUrl"/>
        </div>

        <div class="ml-4">
          <span v-if="showSerialNr(media)">{{media.serial_nr}} </span>
          <span v-if="media.type == 'category' && !$store.state.media.searchMode">{{ `${media.meta.title} (${childrenCount})` }}</span>
          <span v-else>{{ media.meta.title }}</span>
        </div>

        <div class="ml-2 font-weight-bold text-light" style="font-size: 0.8em">
          <span v-if="media.meta.secondary_title">{{media.meta.secondary_title}} </span>
          <span v-else-if="media.artists && media.artists.length > 0">{{ media.artists.join(', ') }}</span>
        </div>

        <template v-if="media.duplicate_of != null">
          <div class="ml-3" v-b-tooltip.hover :title="`This item is a duplicate of ${media.duplicate_of}.`">
            <b-badge variant="warning">
              <font-awesome-icon icon="clone"/>
            </b-badge>
          </div>
        </template>

        <template v-if="media.type == 'category' && $store.state.media.searchMode">
          <span class="text-light">{{ media.parent_crumbs }}</span>
        </template>

      </div>

      <div class="tree-node-right d-flex justify-content-between mr-3">
        <div v-if="$helpers.isTopLevel(media.type)" style="margin-top: 6px;" class="text-light">
          {{partner}}
        </div>

        <div v-if="$helpers.isTopLevel(media.type) && media.type != 'category' && partner" style="margin-top: 5px;" class="mx-1">
          <Icon icon="Scheiding" :size="24"/>
        </div>

        <template v-if="$helpers.isTopLevel(media.type) || media.type == 'episode' || media.type == 'issue' || media.type == 'audio' || media.type == 'album_track' || media.type == 'podcast_episode'">

          <span v-if="media.type != 'category'" style="margin-top: 5px">
            <b-badge>{{$helpers.prettify(media.plan)}}</b-badge>
          </span>

          <template v-if="media.type != 'category'">
            <span v-if="media['asset_format']" style="margin-left: 0.75rem; margin-top: 5px;">
              <b-badge>{{media.asset_format.startsWith('external') ? 'external' : 'internal'}}</b-badge>
            </span>
          </template>

          <div class="labels ml-1" style="margin-top: 6px;">
            <template v-if="media.category_type == 'dynamic'">
              <template v-if="$_.isEqual(media.category_filter.type, $helpers.getContentTopLevelTypes())">
                <span class="mx-1">
                  <b-badge variant="secondary">
                    All Types
                  </b-badge>
                </span>
              </template>
              <template v-else>
                <span v-for="k in media.category_filter.type" :key="k" class="mx-1">
                  <b-badge variant="secondary">
                    {{ $helpers.prettify(k) }}
                  </b-badge>
                </span>
              </template>
            </template>

            <span class="ml-1" v-if="media.category_type == 'manual'">
              <b-badge>manual</b-badge>
            </span>

            <template v-if="media.type != 'category' || media.category_type == 'dynamic'">
              <span :id="'labels-more-' + media.id" class="ml-2">
                <b-badge :variant="labelsAggregate.length > 0 ? 'secondary' : 'dark'" :class="{'badge-dark': labelsAggregate.length == 0}" class="py-1">
                  <font-awesome-icon icon="filter"/>
                </b-badge>
              </span>

              <b-popover placement="right" :target="'labels-more-' + media.id" triggers="hover focus">
                <div v-for="k in labelsAggregate" :key="k">
                  <b-badge variant="secondary">
                    {{ k }}
                  </b-badge>
                </div>
              </b-popover>
            </template>
          </div>
        </template>

        <div v-if="!(media.type == 'season' || media.type == 'year' || media.type == 'product' || media.type == 'product_category')" style="margin-top: 5px;" class="mx-1">
          <Icon icon="Scheiding" :size="24"/>
        </div>

          <b-button-group v-if="actionsState == 'reordering'">
            <b-button v-b-tooltip.hover title="Save Reordering" @click.stop="saveReordering" variant="primary">
              <Icon icon="Save"/>
            </b-button>
            <b-button v-b-tooltip.hover title="Restore original order" @click.stop="restoreReordering" variant="primary">
              <font-awesome-icon icon="undo-alt"/>
            </b-button>
          </b-button-group>

          <template v-if="actionsState == 'default'">
            <b-button class="mx-1" v-if="!$helpers.isTopLevel(media.type) || media.type == 'category' && media.allow_archiving" v-b-tooltip.hover title="Archive" @click.stop="archiveModalVisible = true" variant="primary">
              <Icon icon="Archive"/>
            </b-button>
            <b-button class="mx-1" v-else-if="media.allow_archiving == false" disabled variant="secondary">
              <Icon icon="Archive"/>
            </b-button>


            <b-button :disabled="!(userType == 'admin' || userType == 'editor_in_chief')" class="mx-1" @click.stop="publish" v-b-tooltip.hover :title="isPublished ? 'Unpublish item' : 'Publish item'" :variant="isPublished ? 'danger' : 'primary'">
              <Icon icon="globe"/>
            </b-button>

            <template v-if="childMediaTypes.length > 1">
              <b-dropdown class="dropdown mx-1" variant="primary">
                <template slot="button-content">
                  <Icon icon="Add"/>
                </template>
                <b-dropdown-item href="#" v-for="childType in childMediaTypes" :key="childType" @click.stop="addChild(childType)">
                  <span>{{$helpers.prettify(childType)}}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template v-else-if="childMediaTypes.length == 1 && media.type != 'photo_package'">
              <b-button class="mx-1" v-b-tooltip.hover :title="'Add ' + $helpers.prettify(childMediaTypes[0])" @click.stop="addChild(childMediaTypes[0])" variant="primary">
                <Icon icon="Add"/>
              </b-button>
            </template>

            <template v-else>
              <b-button class="mx-1" disabled variant="secondary">
                <Icon icon="Add"/>
              </b-button>
            </template>

            <b-button v-if="$helpers.isPlayable(media.type) && !media.asset_format.startsWith('external') && (userType == 'admin' || userType == 'editor_in_chief')" :id="`download-asset-${media.id}`" class="mx-1" v-b-tooltip.hover title="Download primary asset" variant="primary" @click.stop="downloadPrimaryAsset(media.id)">
              <font-awesome-icon icon="download"/>
            </b-button>
            
            <b-button class="mx-1" v-if="($helpers.isTopLevel(media.type) || media.type == 'category') && media.type != 'external' && media.type != 'photo_package'" v-b-tooltip.hover title="Preview" :href="getPreviewUrl(media)" target="_blank" rel="noopener noreferrer" @click.stop="" variant="primary">
              <div style="padding-top: 2px"><font-awesome-icon icon="play-circle"/></div>
            </b-button>
            <b-button class="mx-1" v-else disabled variant="secondary">
              <div style="padding-top: 2px"><font-awesome-icon icon="play-circle"/></div>
            </b-button>

            <b-button class="mx-1" v-if="$helpers.hasDetailPage(media.type)" v-b-tooltip.hover title="Edit Embedded Dashboard" @click.stop="dashboardVisible ? closeDashboard() : showDashboard()" variant="primary">
              <Icon icon="Embedded_dashboard"/>
            </b-button>
            <b-button class="mx-1" v-else-if="media.type != 'category'" disabled variant="secondary">
              <Icon icon="Embedded_dashboard"/>
            </b-button>

            <b-button class="mx-1" v-if="$helpers.hasDetailPage(media.type) && media.type != 'playlist_page'" v-b-tooltip.hover title="Edit Embedded Playlists" @click.stop="playlistsVisible ? closePlaylists() : showPlaylists()" variant="primary">
              <font-awesome-icon icon="list-ol"/>
            </b-button>
            <b-button class="mx-1" v-else-if="media.type != 'category'" disabled variant="secondary">
              <font-awesome-icon icon="list-ol"/>
            </b-button>

            <b-button class="mx-1" v-if="media.type != 'photo_package'" v-b-tooltip.hover title="Edit" @click.stop="editorVisible ? closeEditor() : showEditor()" variant="primary">
              <Icon icon="edit"/>
            </b-button>
            <b-button class="mx-1" v-else v-b-tooltip.hover :title="media.id" disabled variant="secondary">
              <Icon icon="edit"/>
            </b-button>

            <b-popover :target="`download-asset-${media.id}`" triggers="click blur" placement="bottom">
              <a class="bright-download-url" :href="downloadUrl" target="_blank">Download file...</a>
            </b-popover>

          </template>

      </div>
    </div>
    <div v-if="editorVisible">
      <EditMedia :media-id="media.id" @close="closeEditor" @save="$emit('save', $event)"/>
    </div>
    <div v-if="dashboardVisible">
      <EmbeddedDashboard :item="mediaExpanded" @save="$emit('save', $event)" @close="closeDashboard"></EmbeddedDashboard>
    </div>
    <div v-if="playlistsVisible">
      <EmbeddedPlaylists :item="mediaExpanded" @save="$emit('save', $event)" @close="closePlaylists"></EmbeddedPlaylists>
    </div>
    <draggable v-if="childrenVisible" v-model="mediaExpanded.children" @update="updatedOrder" class="collapse-children" :disabled="draggingDisabled" handle=".grip">
      <template v-if="mediaExpanded && mediaExpanded.children.length == 0">
        <p class="text-center mt-2" style="color: #9FA9AE">No subitems</p>
      </template>
      <template v-else>
        <TreeNode v-for="m in childItems" :key="m.id" :media="m" :ref="m.id" @node-selected="selectChildNode" @editor-visible="handleChildEdit" @save="$emit('save', $event)"/>
      </template>
    </draggable>
    <SimpleModal :value='closeModalVisible' @ok="doCloseEditor" @cancel="closeModalVisible = false" msg="There are unsaved edits. Are you sure you want to close the editor and discard all changes?"/>
    <SimpleModal :value='archiveModalVisible' @ok="doArchive" @cancel="archiveModalVisible = false" msg="Are you sure you want to archive this item?"/>
  </div>
</template>

<script>
import EditMedia from "./EditMedia.vue"
import Draggable from "vuedraggable"
import EmbeddedDashboard from "./EmbeddedDashboard.vue"
import EmbeddedPlaylists from "./EmbeddedPlaylists.vue"
import SimpleModal from "./SimpleModal.vue"

export default {
  name: 'TreeNode',

  components: {
    EditMedia,
    Draggable,
    EmbeddedDashboard,
    EmbeddedPlaylists,
    SimpleModal
  },

  props: {
    media: Object,
    partner: String
  },

  data: function() {
    return {
      childrenVisible: false,
      editorVisible: false,
      dashboardVisible: false,
      playlistsVisible: false,
      actionsVisible: false,
      foldIcon: "Expand_collapsed",
      type: null,
      highlighted: false,
      actionsState: "default",
      childEditorsOpen: 0,
      badgeAggregate: [],
      closeModalVisible: false,
      archiveModalVisible: false,
      isPublished: false,
      downloadUrl: null
    }
  },

  watch: {
    media(value) {
      if(this.childrenVisible || this.dashboardVisible || this.playlistsVisible) {
        this.fetchExpanded()
      }

      // for cases where publish status is changed externally
      this.isPublished = (value.status == "published")
    }
  },

  computed: {
    userType() {
      if(this.$store.state.session.user) {
        return this.$store.state.session.user.type
      }

      return null
    },

    childMediaTypes() {
      return this.$helpers.childMediaTypes(this.media.type)
    },

    nodeImageUrl() {
      if(this.media.meta) {
        const type = this.media.type
        if(type != "season" && type != "year" && type != "category" && this.media.meta.covers && this.media.meta.covers.length > 0) {
          return this.$helpers.resizeImageUrl(this.firstImageUrl(this.media.meta.covers), 68)
        }

        if(this.media.meta.slides && this.media.meta.slides.length > 0) {
          return this.$helpers.resizeImageUrl(this.firstImageUrl(this.media.meta.slides), 68)
        }

      }

      if(this.media.thumbnails && this.media.thumbnails.length > 0) {
          return this.$helpers.resizeImageUrl(this.firstImageUrl(this.media.thumbnails), 68)
      }

      return null;
    },

    labelsAggregate() {
      const labels = this.media.type == 'category' ? this.media.category_filter.labels : this.media.labels;

      return labels || [];
    },

    actionsTitle() {
      switch(this.actionsState) {
        case "default": return ""
        case "reordering": return "Reordering"
      }

      return null
    },

    hasDashboard() {
      const type = this.media.type

      return type != "category" && type != "season" && type != "year" && type != "external"
    },

    draggingDisabled() {
      return !!(this.editorVisible || (this.childEditorsOpen > 0))
    },

    publishStatusClass() {
      return {
        'bg-live': this.isPublishStatus('live'),
        'bg-incomplete': this.isPublishStatus('incomplete'),
        'bg-complete': this.isPublishStatus('complete'),
        'bg-overdue': this.isPublishStatus('overdue'),
        'bg-upcoming': this.isPublishStatus('upcoming'),
        'bg-done': this.isPublishStatus('done'),
        'bg-invalid': this.isPublishStatus('invalid')
        }
    },

    mediaExpanded() {
      const mediaExpanded = this.$store.state.media.expanded[this.media.id]
      return mediaExpanded ? mediaExpanded : {children: [], dashboard: [], playlists: []}

    },

    childItems() {
      return this.mediaExpanded.children
    },

    childrenCount() {
      if(this.media.type == "category") {
        if(this.media.category_type == "root") {
          return this.$_.sumBy(this.childItems, (item) => item.category_content ? item.category_content.length : 0)
        }
        else {
          return this.media.category_count ? this.media.category_count : this.media.children ? this.media.children.length : 0
        }
      }
      else {
        return this.childItems.length
      }
    },

    mediaIcon() {
      switch(this.media.type) {
        case "video":
          return "Menu_Videos"

        case "series":
          return "Menu_Series"

        case "book":
          return "Menu_books"

        case "magazine":
          return "Menu_Magazines"

        case "photo":
          return "Menu_camera"

        case "music_album":
          return "Menu_music"

        case "audio":
          return "Menu_audio"

        case "podcast":
          return "Menu_podcast"

        case "brand":
          return "Menu_Brands"

        case "artist_page":
          return "Menu_Categories"

        case "playlist_page":
          return "Menu_Categories"

        case "page":
          return "Menu_Categories"

        case "external":
          return "Menu_externals"

        case "category":
          return "Menu_Categories"
      }

      return null
    }
  },

  methods: {
    getId() {
      return this.media.id
    },

    isPublishStatus(status) {
      return this.media.publish_status == status
    },

    getPreviewUrl(media) {
      if(media.category_type == "root") {
        return `${this.$store.state.app.appHost}/`
      }
      else {
        return `${this.$store.state.app.appHost}/${media.default_language}/${this.$helpers.slugify(media.type)}/${media.meta.slug}`
      }
    },


    firstImageUrl(images) {
      return (images && images.length > 0 && images[0].img_set.length > 0) ? this.selectImage(images[0].img_set).url : ""
    },

    selectImage(imageSet) {
      let a = imageSet.sort((a, b) => a.aspect_ratio < b.aspect_ratio ? -1 : a.aspect_ratio > b.aspect_ratio ? 1 : 0)
      return a[0]
    },

    foldOut(fold) {
      if(this.childrenVisible || fold === false) {
        if(this.childEditorsOpen > 0) {
          this.$root.app.showAlert("Closing is not allowed while an editor is open", "warning")
        }
        else {
        this.childrenVisible = false;
        this.maybeClearExpanded()
        this.foldIcon = "Expand_collapsed"
        }
      }
      else {
        this.fetchExpanded(() => {
          this.childrenVisible = true;
          this.closeDashboard()
          this.closePlaylists()
          this.foldIcon = "expand_more-24px"
        })
      }
    },

    fetchExpanded(handler) {
      this.$store.dispatch('media/fetchExpandedItem', this.media.id)
        .then(() => { if(handler) handler() })
    },

    doArchive() {
      this.$store.dispatch('media/archive', {id: this.media.id, isCategory: this.media.type == 'category'})
        .then(() => this.$root.app.showAlert("Item successfully archived", "success"))
        .catch(error => this.$root.app.showAlert(`Error archiving item: ${error.text}`, "danger"))
    },

    highlight(hl) {
      this.highlighted = hl;
    },

    selectNode(ev) {
      const mod = this.$helpers.getModifier(ev, this.$os)
      this.$emit('node-selected', this, mod)
    },

    selectChildNode(node, mod, range) {
      // if the selected node is a direct child of this item, include all sibblings as range of SHIFT+click actions
      if(this.$helpers.indexOfById(this.childItems, node.media.id) != -1) {
        range = {}
        range.items = this.childItems
        range.nodes = {}
        this.childItems.forEach(m => {
          let node = this.$refs[m.id]
          if(node) range.nodes[m.id] = node
        })
      }

      this.$emit('node-selected', node, mod, range)
    },

    showEditor() {
      this.$store.dispatch('media/showEditor', this.media.id)
        .then(() => {
          this.$emit('editor-visible', true)
          this.editorVisible = true
          this.actionsVisible = false
        })
    },

    closeEditor() {
      this.$store.dispatch('media/closeEditor', this.media.id)
        .then(() => {
          this.doCloseEditor()
        })
        .catch(() => {
          this.closeModalVisible = true
        })
    },

    doCloseEditor() {
      this.$emit('editor-visible', false)
      this.editorVisible = false
      this.closeModalVisible = false
    },

    showDashboard() {
      this.$emit('editor-visible', true)
      this.fetchExpanded(() => {
        this.dashboardVisible = true
        this.playlistsVisible = false
        this.actionsVisible = false
        this.foldOut(false)
      })
    },

    closeDashboard() {
      this.$emit('editor-visible', false)
      this.dashboardVisible = false
      this.maybeClearExpanded()
    },

    showPlaylists() {
      this.$emit('editor-visible', true)
      this.fetchExpanded(() => {
        this.playlistsVisible = true
        this.dashboardVisible = false
        this.actionsVisible = false
        this.foldOut(false)
      })
    },

    closePlaylists() {
      this.$emit('editor-visible', false)
      this.playlistsVisible = false
      this.maybeClearExpanded()
    },

    maybeClearExpanded() {
      if(!(this.dashboardVisible || this.playlistsVisible || this.childrenVisible) && this.$store.state.media.expanded[this.media.id]) {
        this.$store.commit('media/clearExpandedItem', this.media.id)
      }
    },

    // TODO
    addChild(mediaType) {
      this.actionsVisible = false
      this.$store.dispatch('media/addNewItem', {type: mediaType, parent: this.media})
    },

    updatedOrder() {
      this.actionsState = "reordering"
      this.actionsVisible = true
    },

    saveReordering() {
      this.$store.dispatch('media/persistExpandedItemReordering', this.media.id)
      this.actionsVisible = false
      setTimeout(() => this.actionsState = "default", 500)
    },

    restoreReordering() {
      this.$store.commit('media/restoreExpandedItemReordering', this.media.id)
      this.actionsVisible = false
      setTimeout(() => this.actionsState = "default", 500)
    },

    handleChildEdit(open) {
      if(open) {
        this.childEditorsOpen += 1
      }
      else {
        this.childEditorsOpen -= 1
      }

      if(!this.$helpers.isTopLevel(this.media.type)) {
        this.$emit('editor-visible', open)
      }
    },

    showSerialNr(media) {
      return media.serial_nr && (
        media.type == 'season' ||
        media.type == 'episode' ||
        media.type == 'album_track' ||
        media.type == 'podcast_episode' ||
        media.type == 'year' ||
        media.type == "issue")
    },

    publish() {
      if(this.isPublished) {
        this.$store.dispatch('media/unpublishMedia', {ids: this.media.id, recursive: this.$helpers.hasChildren(this.media.type)})
          .then(() => {
            this.isPublished = false
          })
          .catch((error) => {
            this.$root.app.showAlert(`Error unpublishing media item: ${error}`, 'danger')
          })
      }
      else {
        this.$store.dispatch('media/publishMedia', {ids: this.media.id, recursive: this.$helpers.hasChildren(this.media.type)})
          .then(() => {
            this.isPublished = true
          })
          .catch((error) => {
            this.$root.app.showAlert(`Error publishing media item: ${error}`, 'danger')
          })
      }
    },

    downloadPrimaryAsset(mediaId) {
      fetch(
        "admin/api/v2/media/temp-download-url",
        {method: 'POST', body: JSON.stringify({data: {id: mediaId}})}
      )
      .then(resp => resp.json())
      .then(resp => {
        if(resp.status == "ok") {
          this.downloadUrl = resp.data
        }
        else if(resp.type == "no_primary_asset") {
          this.downloadUrl = null
          setTimeout(() => this.$root.$emit('bv::hide::popover'), 1)
          this.$root.app.showAlert("Primary asset unavailable or not ready", "warning")
        }
        else {
          console.log(resp)
          this.downloadUrl = null
          setTimeout(() => this.$root.$emit('bv::hide::popover'), 1)
          this.$root.app.showAlert(`Error getting temporary download url: ${this.$helpers.prettify(resp.type)}`, "danger")
        }
      })
      .catch(e => {
        console.log(e)
        this.downloadUrl = null
        setTimeout(() => this.$root.$emit('bv::hide::popover'), 1)
        this.$root.app.showAlert(`Error getting temporary download url: ${e.text}`, "danger")
      })
    }
  },

  created() {
    if(this.media.category_type == 'root' && !this.$store.state.media.searchMode) {
      this.foldOut(true)
    }

    this.isPublished = this.media.status == 'published'
  }
}
</script>

<style scoped>
.row > [class*="col"] {
  border: 1px silver;
  text-align: left;
}

.btn-link {
  text-decoration: none;
}

.btn-link:hover {
  text-decoration: none;
}

.collapse-children {
  margin-left: 30px;
}

.tree-node-col {
  background: #00000066 0% 0% no-repeat padding-box;
  height: 70px;
  line-height: 70px;
  user-select: none;
  padding-left: 0px;
}

.tree-node-img {
  height: 70px;
  line-height: 70px;
}

.highlight {
  color: white;
  background: transparent linear-gradient(90deg, #152D4A3D 0%, #0F2640 100%) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 5px #233F74A8;
  border: 1px solid #17638A;
  opacity: 1;
}

.pointer:hover {
  cursor: pointer;
}

.labels {
  margin-top: 7px;
  line-height: 20px;
}

.keyword {
  margin-left: 4px;
}

.tree-node {
  margin-top: 0.3rem;
  background: #00000066;
}

.dropdown {
  line-height: 24px;
}

.grip {
  width: 24px;
  height: 70px;
  text-align: center;
}

.badge-dark {
  color: #8B91A0 !important;
}

.bright-download-url {
  text-color: #E5E9EC !important;
  color: #E5E9EC !important;
}

</style>

