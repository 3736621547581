import { render, staticRenderFns } from "./UserTemplate.vue?vue&type=template&id=213909d6&"
import script from "./UserTemplate.vue?vue&type=script&lang=js&"
export * from "./UserTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/zambal/dev/ott_cms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('213909d6')) {
      api.createRecord('213909d6', component.options)
    } else {
      api.reload('213909d6', component.options)
    }
    module.hot.accept("./UserTemplate.vue?vue&type=template&id=213909d6&", function () {
      api.rerender('213909d6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/UserTemplate.vue"
export default component.exports