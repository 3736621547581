var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: _vm.id } },
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _vm.firstImageUrl
                ? _c(
                    "b-col",
                    [
                      _c("b-img", {
                        staticClass: "img-preview",
                        attrs: {
                          center: "",
                          thumbnail: "",
                          src: _vm.firstImageUrl,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center flex-row-reverse",
                  staticStyle: { "padding-right": "0px" },
                },
                [
                  (_vm.images && _vm.images.length > 0) || !_vm.allowCopyIfEmpty
                    ? _c(
                        "b-button",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            id: "edit-button",
                            size: "",
                            variant: _vm.showEditor ? "warning" : "primary",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.showEditor = !_vm.showEditor
                            },
                          },
                        },
                        [
                          _c("Icon", { attrs: { icon: "edit" } }),
                          _vm._v(" Edit "),
                        ],
                        1
                      )
                    : _vm.allowCopyIfEmpty &&
                      (_vm.type == "cover" || _vm.type == "slide")
                    ? _c(
                        "b-dropdown",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            id: "copy-button",
                            size: "",
                            variant: "primary",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.copyFrom.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "button-content" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "copy" },
                              }),
                              _vm._v(" Copy from "),
                            ],
                            1
                          ),
                          _vm._l(
                            _vm.$store.state.app.languages,
                            function (lang, code) {
                              return _c(
                                "b-dropdown-item",
                                {
                                  key: code,
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.copyFrom(code)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(lang) + " ")]
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c("AddImage", {
                    attrs: {
                      "item-id": _vm.itemId,
                      format: _vm.type,
                      language: _vm.language,
                    },
                    on: {
                      finished: function ($event) {
                        return _vm.$emit("reload")
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showEditor
        ? _c("ImageEdit", {
            attrs: {
              "item-id": _vm.itemId,
              "item-type": _vm.itemType,
              language: _vm.language,
              value: _vm.images,
              type: _vm.type,
              "allow-link-to": _vm.allowLinkTo,
            },
            on: {
              input: function ($event) {
                return _vm.update($event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }