var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-input-group",
        [
          _c("b-form-input", {
            attrs: { id: _vm.id, readonly: "" },
            model: {
              value: _vm.item.title,
              callback: function ($$v) {
                _vm.$set(_vm.item, "title", $$v)
              },
              expression: "item.title",
            },
          }),
          _c(
            "b-input-group-append",
            [
              _vm.status == "view"
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: "danger" },
                      on: { click: _vm.handleDelete },
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "trash-alt" } }),
                      _vm._v(" Delete "),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-button",
                {
                  attrs: { variant: _vm.buttonVariant },
                  on: { click: _vm.handleButton },
                },
                [
                  _c("font-awesome-icon", { attrs: { icon: _vm.buttonIcon } }),
                  _vm._v(" " + _vm._s(_vm.buttonText) + " "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            title: "Create Embedded Asset",
            size: "lg",
            "no-close-on-backdrop": "",
            "auto-focus-button": "ok",
          },
          on: { ok: _vm.handleEditorOk, cancel: _vm.handleEditorCancel },
        },
        [
          _c(
            "b-form",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "ID",
                    "label-for": "id-input",
                    horizontal: "",
                  },
                },
                [
                  _c("b-input", {
                    attrs: {
                      id: "id-input",
                      value: _vm.editItem.id,
                      readonly: "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Platform",
                    "label-for": "platform-select",
                    horizontal: "",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "platform-select",
                      options: _vm.platformOptions,
                    },
                    model: {
                      value: _vm.editItem.platform,
                      callback: function ($$v) {
                        _vm.$set(_vm.editItem, "platform", $$v)
                      },
                      expression: "editItem.platform",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Language",
                    "label-for": "language-select",
                    horizontal: "",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: { id: "language-select", options: _vm.langOptions },
                    model: {
                      value: _vm.editItem.languages,
                      callback: function ($$v) {
                        _vm.$set(_vm.editItem, "languages", $$v)
                      },
                      expression: "editItem.languages",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Name",
                    "label-for": "title-input",
                    horizontal: "",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "title-input", state: _vm.nameState },
                    model: {
                      value: _vm.editItem.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.editItem, "title", $$v)
                      },
                      expression: "editItem.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Duration",
                    "label-for": "duration-input",
                    type: "time",
                    horizontal: "",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "duration-input",
                      value: _vm.durationString,
                      state: _vm.durationState,
                    },
                    on: { blur: _vm.setDuration },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Year",
                    "label-for": "year-input",
                    type: "number",
                    horizontal: "",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "year-input", state: _vm.yearState },
                    model: {
                      value: _vm.editItem.year,
                      callback: function ($$v) {
                        _vm.$set(_vm.editItem, "year", _vm._n($$v))
                      },
                      expression: "editItem.year",
                    },
                  }),
                ],
                1
              ),
              _vm.editItem.platform == "streamable"
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Embed code",
                        "label-for": "embed-code-input",
                        horizontal: "",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: { id: "embed-code-input", state: _vm.linkState },
                        model: {
                          value: _vm.editItem.embed_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.editItem, "embed_code", $$v)
                          },
                          expression: "editItem.embed_code",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Link",
                        "label-for": "link-input",
                        horizontal: "",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: { id: "link-input", state: _vm.linkState },
                        model: {
                          value: _vm.editItem.link,
                          callback: function ($$v) {
                            _vm.$set(_vm.editItem, "link", $$v)
                          },
                          expression: "editItem.link",
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _c(
                "b-alert",
                {
                  attrs: {
                    show: _vm.alert.dismissCountdown,
                    dismissible: "",
                    variant: _vm.alert.variant,
                  },
                  on: {
                    dismissed: function ($event) {
                      _vm.alert.dismissCountdown = 0
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.alert.msg) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }