<template>
  <b-modal ref="modal" :title="title" size="xl" hide-footer>
    <span class="mb-4">
      <span class="font-weight-bold">Media item ID </span>
      {{ media_id }}
    </span>

    <b-table class="mt-3" :items="items" :fields="fields" striped small borderless></b-table>
  </b-modal>
</template>

<script>
export default {
  name: 'RevShareContentDetailsModal',

  data: function() {
    return {
      title: "",
      media_id: "",
      items: [],
      fields: [
        {key: 'geo', label: 'Country', sortable: true},
        {key: 'count', label: 'Views', sortable: true},
        {key: 'length', label: 'Duration', sortable: true, formatter: this.$helpers.durationToString},
        {key: 'premium_points', label: 'Points', sortable: true},
        {key: 'share', label: 'Share (%)', sortable: true, formatter: this.$helpers.percentageFormatter},
        {key: 'amount', label: 'Amount (€)', sortable: true, formatter: this.$helpers.currencyFormatter}
      ]
    };
  },

  props: {
  },

  methods: {
    show(id, title, items) {
      this.media_id = id
      this.title = title
      this.items = items

      this.$refs.modal.show();
    },

  }
}
</script>

<style scoped>
</style>
