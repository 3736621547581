<template>
  <div class="import-media">
    <b-modal  ref="modal"
              v-model="visible"
              size="lg"
              :title="insert ? 'Import NEW media' : 'Import media'"
              @hidden="clear"
              @ok="$root.$emit('media-refresh')"
              ok-only>

      <div class="example-simple">
          <div class="upload">
            <ul>
              <li v-for="file in files" :key="file.id">
                <span>{{file.name}}</span> -
                <span>{{file.size | formatSize}}</span> -
                <span v-if="file.error">{{file.error}}</span>
                <span v-else-if="file.success">success</span>
                <span v-else-if="file.active">active</span>
                <span v-else></span>
                <div v-if="file.error" style="padding-right: 3rem">
                  <h4 class="my-2">Import aborted, because there were error(s) found at:</h4>
                  <b-alert variant="danger" show>
                    <ul class="text-left">
                      <li v-for="error in file.response" :key="error">{{ error }}</li>
                    </ul>
                  </b-alert>
                </div>
              </li>
            </ul>
            <div class="example-btn">
              <file-upload
                class="btn btn-primary"
                :post-action="`/admin/upload/media-excel?insert=${insert}`"
                extensions="xlsx"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                :multiple="false"
                :size="1024 * 1024 * 10"
                v-model="files"
                @input-filter="inputFilter"
                @input-file="inputFile"
                ref="upload">
                <Icon icon="Add"/>
                Select file
              </file-upload>
              <button type="button" class="btn btn-success" v-if="!$refs.upload || !$refs.upload.active" @click.prevent="$refs.upload.active = true">
                <font-awesome-icon icon="arrow-up"/>
                Start Upload
              </button>
              <button type="button" class="btn btn-danger"  v-else @click.prevent="$refs.upload.active = false">
                <font-awesome-icon icon="stop"/>
                Stop Upload
              </button>
            </div>
          </div>
      </div>

    </b-modal>
  </div>
</template>

<style>
.example-simple label.btn {
  margin-bottom: 0;
  margin-right: 1rem;
}
</style>

<script>
import FileUpload from 'vue-upload-component'
export default {
  name: 'ImportMedia',

  components: {
    FileUpload,
  },

  data() {
    return {
      files: [],
    }
  },

  computed: {
    visible: {
      get() {
        return this.$store.state.media.modals.import.visible
      },

      set(value) {
        const insert = this.$store.state.media.modals.import.insert
        this.$store.commit('media/updateModal', {import: {visible: value, insert: insert}})
      }
    },

    insert: {
      get() {
        return this.$store.state.media.modals.import.insert
      },

      set(value) {
        const visible = this.$store.state.media.modals.import.visible
        this.$store.commit('media/updateModal', {import: {visible: visible, insert: value}})
      }
    }

  },

  methods: {
    show() {
      this.$refs.modal.show();
    },

    clear() {
      this.files = [];
    },

    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },

    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
      }
      if (newFile && oldFile) {
        // update
      }

      if (!newFile && oldFile) {
        // remove
      }
    }
  }
}
</script>
