<template>
    <b-modal  ref="modal"
              title="Add Affiliate"
              no-close-on-backdrop
              no-close-on-esc
              @ok="createAffiliate"
              @hidden="clear">
      <form autocomplete="off">
        <b-form-group label="ID" label-for="id-input" horizontal>
          <id-input id="id-input" :value="item.id"></id-input>
        </b-form-group>
        <b-form-group label="Description" label-for="description-input" horizontal>
          <b-form-input id="description-input" v-model="item.description" ></b-form-input>
        </b-form-group>
        <b-form-group label="Type" label-for="affiliates-select-type" horizontal>
          <b-form-select id="affiliates-select-type" v-model="item.type" :options="typeOptions"></b-form-select>
        </b-form-group>
        <b-form-group label="Partner" label-for="affiliates-select-partner" horizontal>
          <b-form-select id="affiliates-select-partner" v-model="item.partner" :options="partnerOptions"></b-form-select>
        </b-form-group>
        <b-form-group label="Schedule" label-for="publication-comp" horizontal>
          <Publication id="publication-comp" from-field="active_from" to-field="active_to" v-model="item"/>
        </b-form-group>
        <b-form-group label="Registration URL" label-for="url-input" horizontal>
          <b-input-group>
            <b-form-input id="url-input" v-model="item.url" ></b-form-input>
            <b-input-group-append>
              <b-button v-b-tooltip.hover title="Use Default URL" variant="secondary" @click.stop="useDefaultUrl">
                <Icon icon="globe"/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Affiliate URL" label-for="link-input" horizontal>
          <b-form-input id="link-input" v-model="affiliateUrl" readonly></b-form-input>
        </b-form-group>
        <b-form-group label="Embed Promo Code" label-for="embedded-promo-code" horizontal>
          <div id="embedded-promo-code">
            <div class="d-flex flex-row-reverse align-items-center mb-2">
              <b-button :pressed.sync="usePromoCode" :variant="usePromoCode ? 'success' : 'warning'">{{usePromoCode ? 'Disable' : 'Enable'}}</b-button>
            </div>
            <b-form-input v-if="usePromoCode" v-model="promoCode.code" ></b-form-input>
          </div>
        </b-form-group>
        <b-form-group v-if="item.type == 'recurring'" label="Recurring compensation amount" label-for="amount-input" horizontal>
          <b-form-input id="amount-input" v-model="recurringCompensation"></b-form-input>
        </b-form-group>
        <b-form-group label="Offers" label-for="affiliate-offer-editor" horizontal>
          <AffiliateOffers id="affiliate-offer-editor" :type="item.type" :affiliate-compensations="item.affiliate_compensations" :use-promo-code="usePromoCode" @update="updateAffiliateCompensations"></AffiliateOffers>
        </b-form-group>
      </form>
      <transition name="fade">
        <b-alert :show="alert.dismissCountdown" dismissible :variant="alert.variant" @dismissed="alert.dismissCountdown = 0">
          {{ alert.msg }}
        </b-alert>
      </transition>
    </b-modal>
</template>

<script>
import IdInput from "./IdInput.vue"
import Publication from "./Publication.vue"
import AffiliateOffers from "./AffiliateOffers.vue"

export default {
  name: 'AddAffiliate',

  components: {
    Publication,
    IdInput,
    AffiliateOffers
  },

  props: {
  },

  data() {
    return {
      item: {
        id: null,
        description: null,
        type: 'one-time',
        url: null,
        partner: null,
        status: 'active',
        active_from: null,
        active_to: null,
        affiliate_compensations: [],
        promo_code: null
      },

      usePromoCode: false,

      defaultUrl: "",

      promoCode: {
        code: '',
        description: null,
        partner: null,
        status: 'active',
        active_from: null,
        active_to: null,
        discounts: []
      },

      alert: {
        dismissCountdown: 0,
        variant: "warning",
        msg: ""
      },

      typeOptions: [],
      partnerOptions: [],
      recurringCompensation: 0,
      offers: []
    }
  },

  computed: {
    affiliateUrl() {
      if(this.item.url) {
        if(this.item.url.match("\\?")) {
          if(this.usePromoCode) {
            return `${this.item.url}&affiliate_id=${this.item.id}&promo_code=${this.promoCode.code}`
          }
          else {
            return `${this.item.url}&affiliate_id=${this.item.id}`
          }

        }
        else {
          if(this.usePromoCode) {
            return `${this.item.url}?affiliate_id=${this.item.id}&promo_code=${this.promoCode.code}`
          }
          else {
            return `${this.item.url}?affiliate_id=${this.item.id}`
          }
        }
      }
      else {
        return ''
      }
    }
  },

  methods: {
    show() {
      fetch('/admin/api/v2/affiliates/generate-id')
      .then(function(resp) {
        return resp.json();
      })
      .then(resp => {
        this.item.id = resp.data

        fetch('/admin/api/v2/affiliates/default-url')
        .then(function(resp) {
          return resp.json();
        })
        .then(resp => {
          this.defaultUrl = resp.data

          fetch('/admin/api/v2/affiliates/type-options')
          .then(function(resp) {
            return resp.json();
          })
          .then(resp => {
            this.item.active_from = new Date().toISOString()
            this.typeOptions = resp.data.filter(t => t.value != 'customer')

            fetch('/admin/api/v2/partners/select-options?affiliates-enabled')
            .then(function(resp) {
              return resp.json();
            })
            .then(resp => {
              this.partnerOptions = resp.data
              this.$refs.modal.show();
            })
          })
        })
      })
    },

    clear() {
      this.item = {
        id: null,
        description: null,
        type: 'one-time',
        url: null,
        partner: null,
        status: 'active',
        active_from: null,
        active_to: null,
        affiliate_compensations: [],
        promo_code: null
      }

      this.promoCode = {
        code: '',
        description: null,
        partner: null,
        status: 'active',
        active_from: null,
        active_to: null,
        discounts: []
      }

      this.usePromoCode = false

      this.offers = []
    },

    createAffiliate(ev) {
      if(this.item.partner && this.affiliateUrl && this.affiliateUrl.length > 0 && this.affiliateUrl.startsWith("https://") && this.item.description && this.item.description.length > 0) {
        this.updateAffiliateCompensations(this.offers)
        let item = this.$_.clone(this.item)
        item.url = this.affiliateUrl

        if(this.usePromoCode) {
          this.promoCode.partner = item.partner
          this.promoCode.description = `Embedded promo code for affiliate ${item.id}`
          this.promoCode.active_from = item.active_from
          this.promoCode.active_to = item.active_to
          item.promo_code = this.promoCode
        }

        if((this.usePromoCode && this.promoCode.code && this.promoCode.code.length > 0) || !this.usePromoCode) {

          this.$http.postJSON(
              'admin/api/v2/affiliates',
              {data: item},
              () => {
              this.$root.app.showAlert("Save successful", "success")
              this.$root.$emit('affiliate-refresh')
              },
              (status, html) => {
                this.$root.app.showErrorDialog(html, status.toString())
              }
          )
        }
        else {
          this.alert.msg = "Please enter a promo code"
          this.alert.dismissCountdown = 4
          ev.preventDefault()
        }
      }
      else {
        if(!this.item.partner) {
          this.alert.msg = "Please select a partner"
        }
        else if(!this.item.description || this.item.description.length == 0) {
          this.alert.msg = "Please provide a description"
        }
        else {
          if(this.affiliateUrl && this.affiliateUrl.length > 0) {
            this.alert.msg = "Please provide a URL that starts with https://"
          }
          else {
            this.alert.msg = "Please provide a URL"
          }
        }

        this.alert.dismissCountdown = 4
        ev.preventDefault()
      }
    },

    updateAffiliateCompensations(offers) {
      this.offers = offers

      let getAmount = (offerId, amountType) => {
        if(this.item.type == 'recurring' && amountType == 'compensation') {
          return parseInt(this.$helpers.currencyParser(this.recurringCompensation) * 100 + 0.00001)
        }
        else if(this.item.type == 'recurring' && amountType == 'discount') {
          let items = this.item.affiliate_compensations

          for(let i = 0; i < items.length; ++i) {
            if(items[i].offer_id == offerId) {
              return parseInt(this.$helpers.currencyParser(items[i].euro_discount) * 100 + 0.00001)
            }
          }

          return 0;
        }
        else if(this.item.type == 'one-time') {
          let items = this.item.affiliate_compensations

          for(let i = 0; i < items.length; ++i) {
            if(items[i].offer_id == offerId) {
              const amount = amountType == 'discount' ? items[i].euro_discount : items[i].euro_amount
              return parseInt(this.$helpers.currencyParser(amount) * 100 + 0.00001)
            }
          }

          return 0;
        }
      }

      let getEuroAmount = (offerId, amountType) => {
        if(this.item.type == 'recurring' && amountType == 'compensation') {
          return this.recurringCompensation
        }
        else if(this.item.type == 'recurring' && amountType == 'discount') {
          let items = this.item.affiliate_compensations

          for(let i = 0; i < items.length; ++i) {
            if(items[i].offer_id == offerId) {
              return items[i].euro_discount
            }
          }

          return 0;
        }
        else {
          let items = this.item.affiliate_compensations

          for(let i = 0; i < items.length; ++i) {
            if(items[i].offer_id == offerId) {
              return (amountType == 'discount' ? items[i].euro_discount : items[i].euro_amount)
            }
          }

          return 0;
        }
      }

      this.item.affiliate_compensations = offers.map(o => {
        return {
          affiliate_id: this.item.id,
          offer_id: o,
          amount: getAmount(o, 'compensation'),
          euro_amount: getEuroAmount(o, 'compensation'),
          discount: getAmount(o, 'discount'),
          euro_discount: getEuroAmount(o, 'discount')
        }
      })

      this.promoCode.discounts = offers.map(o => {
        return {
          offer_id: o,
          amount: getAmount(o, 'discount'),
          euro_amount: getEuroAmount(o, 'discount')
        }
      })

    },

    useDefaultUrl() {
      this.item.url = this.defaultUrl
    }
  },

  created() {
    this.clear()
  }
}
</script>


<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
