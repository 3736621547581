<template>
  <div id="app" style="height: 100vh">
    <template v-if="$store.state.app.initReady">
      <b-container @show-menu="menuActive = true" @hide-menu="menuActive = false" id="main-container" fluid>
        <b-row>
          <b-col id="menu-col" :class="{active: menuActive}" :cols="menuActive ? 2 : 1">
            <div class="d-flex justify-content-center w-100 my-3">
              <b-img :src="$config.logo" height="27px"></b-img>
            </div>
            <div style="overflow-y: auto; max-height: calc(100vh - 50px); min-width: 175px">
              <Menu style="margin-top: 20px;" ref="menu"/>
            </div>
          </b-col>
          <b-col id="content-col">
            <Bar :is-media-page="isMediaPage" :active-page-title="activePageTitle" ref="bar"/>
            <b-overlay :show="$store.state.app.overlay" rounded="sm">
              <div class="mx-4">
                <router-view/>
              </div>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
      <ErrorDialog ref='appErrorDialog'/>
    </template>

    <template v-else-if="loggedInChecked && !$store.state.session.loggedIn">
        <Login/>
    </template>

    <template v-else>
        <!-- Display nothing, we don't know if user is logged in yet -->
        <div class="loader-holder">
            <div class="lds-dual-ring"></div>
        </div>
    </template>

    <transition name="fade">
      <footer v-if="alert.dismissCountdown > 0" class="footer">
        <b-alert :show="alert.dismissCountdown" dismissible :variant="alert.variant" @dismissed="alert.dismissCountdown = 0">
          {{ alert.content }}
        </b-alert>
      </footer>
    </transition>

  </div>
</template>

<script>
import Bar from './components/Bar.vue'
import Menu from './components/Menu.vue'
import ErrorDialog from './components/ErrorDialog.vue'
import Login from './views/Login.vue'

export default {
  name: 'app',

  components: {
    Bar,
    Menu,
    ErrorDialog,
    Login
  },

  data: function() {
    return {
      menuActive: true,
      activePageTitle: "",
      alert: {
        content: "",
        variant: "succes",
        dismissCountdown: 0,

      },
      alertDefaults: {
        dismissCountdown: 4,
        variant: "info"
      },
      isMediaPage: false
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.session.loggedIn
    },
    loggedInChecked() {
      return this.$store.state.session.loggedInChecked;
    },
  },

  watch: {
    loggedIn(value) {
      if(value) {
        this.$store.dispatch('fetchAll')
      }
    }
  },

  methods: {
    showMenu(show) {
      this.menuActive = show
    },

    showAlert(content, variant, countdown) {
      this.alert = {
        content: content,
        variant: variant || this.alertDefaults.variant,
        dismissCountdown: countdown || this.alertDefaults.dismissCountdown
      }
    },

    showErrorDialog(body, header) {
      this.$refs.appErrorDialog.show(body, header)
    },

    setActivePageTitle(title, isMediaPage) {
      this.activePageTitle = title
      this.isMediaPage = isMediaPage
    },
  },

  created() {
      this.$store.dispatch('session/fetchUser')
  },

  mounted() {
    this.$root.$on('menu-expand', (active) => {
      this.menuActive = active
    })
  }
}
</script>


<style lang="scss">
@import './custom.scss';

#app {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #E5E9EC;
  scrollbar-width: thin;
}

#main-container {
  margin-top: 0px;
}

#menu-col {
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
  max-width: 12%;
}

#menu-col.active {
  margin-left: 0px;
}

#content-col {
  overflow-y: scroll;
  height: 100vh;
  padding-left: 0px;
  padding-right: 0px;
  background: transparent radial-gradient(closest-side at 47% 48%, #1B3B5B 0%, #072034 100%) 0% 0% no-repeat padding-box;
  box-shadow: -3px 3px 10px #00000033;
  opacity: 1;

}
/* From https://loading.io/css/, under CC0 license so free to use: */
.loader-holder {
  /* Center the loader both horizontally and vertically */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
