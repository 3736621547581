var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rev-share mt-3" },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-row-reverse" },
        [_c("ProcessAdyenReport", { ref: "processAdyenReportModal" })],
        1
      ),
      _c(
        "b-tabs",
        {
          staticClass: "mt-3",
          attrs: { pills: "" },
          on: { input: _vm.activeTab },
        },
        [
          _c(
            "b-tab",
            { attrs: { title: "Content" } },
            [_c("hr", { staticClass: "mt-3" }), _c("RevShareContentOverview")],
            1
          ),
          _vm.$config.enableShopping
            ? _c(
                "b-tab",
                { attrs: { title: "Shopping" } },
                [_c("hr", { staticClass: "mt-3" }), _c("ShoppingOverview")],
                1
              )
            : _vm._e(),
          _vm.$config.enableAffiliates
            ? _c(
                "b-tab",
                { attrs: { title: "Affiliates" } },
                [_c("hr", { staticClass: "mt-3" }), _c("AffiliateOverview")],
                1
              )
            : _vm._e(),
          _vm.$config.enablePromoCodes
            ? _c(
                "b-tab",
                { attrs: { title: "Promo Codes" } },
                [_c("hr", { staticClass: "mt-3" }), _c("PromoCodeOverview")],
                1
              )
            : _vm._e(),
          _c(
            "b-tab",
            { attrs: { title: "Billing" } },
            [_c("hr", { staticClass: "mt-3" }), _c("RevShareBilling")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }