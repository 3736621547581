var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-2", attrs: { fluid: "" } },
    [
      _c("b-table", {
        ref: "table",
        attrs: {
          selectable: "",
          selectedVariant: "secondary",
          "select-mode": "multi",
          items: _vm.items,
          fields: _vm.fields,
          filter: _vm.filter,
        },
        on: { "row-selected": _vm.rowSelected },
      }),
      _c("TranscodeAssets", { ref: "transcodeAssetsModal" }),
      _c("SimpleModal", {
        attrs: {
          value: _vm.deleteModalVisible,
          msg: "Are you sure you want to delete the selected assets?",
        },
        on: {
          ok: _vm.doRemove,
          cancel: function ($event) {
            _vm.deleteModalVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }