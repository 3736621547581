var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        {
          staticClass: "mt-3",
          attrs: { pills: "" },
          on: { input: _vm.activeTab },
        },
        [
          _c(
            "b-tab",
            { attrs: { title: "Content" } },
            [
              _c("hr", { staticClass: "mt-3" }),
              _c(
                "div",
                { staticClass: "d-flex align-items-center my-4" },
                [
                  _c(
                    "b-form",
                    { attrs: { inline: "" } },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "mr-sm-2",
                          attrs: { for: "stats-select-partner" },
                        },
                        [_vm._v("Partner")]
                      ),
                      _c("b-form-select", {
                        staticClass: "mr-2",
                        attrs: {
                          id: "stats-select-partner",
                          value: _vm.params.selectedProvider,
                          options: _vm.partnerOptions,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.updateProvider($event)
                          },
                        },
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "mr-sm-2 ml-2",
                          attrs: { for: "stats-select-country" },
                        },
                        [_vm._v("Geo Location")]
                      ),
                      _c("b-form-select", {
                        staticClass: "mr-2",
                        attrs: {
                          id: "stats-select-country",
                          value: _vm.params.selectedGeo,
                          options: _vm.geoOptions,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.updateGeo($event)
                          },
                        },
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "mr-sm-2 ml-2",
                          attrs: { for: "stats-select-media" },
                        },
                        [_vm._v("Media Type")]
                      ),
                      _c("b-form-select", {
                        attrs: {
                          id: "stats-select-media",
                          value: _vm.params.selectedMediaType,
                          options: _vm.mediaTypeOptions,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.updateMediaType($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.$config.enableArtistsEverywhere
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "ml-4 btn dropdown-toggle btn-link",
                            attrs: {
                              "aria-haspopup": "true",
                              "aria-expanded": "false",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.mediaArtistsFilter.show()
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "user-group" },
                            }),
                            _c("span", { staticClass: "mx-2" }, [
                              _vm._v("Artists"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.$config.enableGenres
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "ml-4 btn dropdown-toggle btn-link",
                            attrs: {
                              "aria-haspopup": "true",
                              "aria-expanded": "false",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.mediaGenresFilter.show()
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "user-group" },
                            }),
                            _c("span", { staticClass: "mx-2" }, [
                              _vm._v("Genres"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "ml-2 btn dropdown-toggle btn-link",
                      attrs: {
                        "aria-haspopup": "true",
                        "aria-expanded": "false",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.mediaLabelFilter.show()
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "tags" } }),
                      _c("span", { staticClass: "mx-2" }, [_vm._v("Labels")]),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("div", { staticClass: "my-4 pt-4" }, [
                _c(
                  "h5",
                  [
                    _c("span", [_vm._v("Total Views: ")]),
                    _c("b-badge", [_vm._v(_vm._s(_vm.totalCount))]),
                    _c("span", { staticClass: "ml-4" }, [
                      _vm._v("Total Duration: "),
                    ]),
                    _c("b-badge", [
                      _vm._v(
                        _vm._s(_vm.$helpers.durationToString(_vm.totalTime))
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.contentStatsHrefData,
                    download: "content_stats.xlsx",
                    target: "_blank",
                  },
                },
                [_vm._v("Download")]
              ),
              _c(
                "div",
                { staticClass: "d-flex" },
                _vm._l(_vm.filterLabels, function (label) {
                  return _c(
                    "b-badge",
                    {
                      key: label.text,
                      staticClass: "mx-1 mt-3",
                      staticStyle: { opacity: "80%", "font-size": "0.9rem" },
                      attrs: { variant: "dark" },
                    },
                    [
                      label.icon
                        ? _c("Icon", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            attrs: {
                              title: _vm.$helpers.prettify(label.type),
                              icon: label.icon,
                            },
                          })
                        : _vm._e(),
                      _c("span", { staticClass: "mx-1" }, [
                        _vm._v(_vm._s(label.text)),
                      ]),
                      label.type != "stats"
                        ? _c(
                            "b-badge",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.removeLabel(label)
                                },
                              },
                            },
                            [_vm._v("✖")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
              _c("b-table", {
                ref: "table",
                staticClass: "mt-3",
                attrs: {
                  items: _vm.contentItems,
                  fields: _vm.contentFields,
                  striped: "",
                  hover: "",
                  small: "",
                  "per-page": _vm.perPage,
                  "current-page": _vm.currentPage,
                },
                on: { "row-clicked": _vm.handleSelectedRow },
                scopedSlots: _vm._u([
                  {
                    key: "cell(show_details)",
                    fn: function (row) {
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-center" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mr-2 text-white",
                                attrs: {
                                  id: row.item.media_id,
                                  size: "sm",
                                  variant: "primary",
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "search" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: row.item.media_id,
                                  "custom-class": "text-white",
                                  triggers: "hover",
                                  placement: "top",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "title",
                                      fn: function () {
                                        return [_vm._v("Details")]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("div", { staticClass: "text-white" }, [
                                  _c(
                                    "form",
                                    { attrs: { autocomplete: "off" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "ID",
                                            "label-for": `id-input-${row.item.media_id}`,
                                            horizontal: "",
                                            "label-cols": 2,
                                          },
                                        },
                                        [
                                          _c("id-input", {
                                            attrs: {
                                              id: `id-input-${row.item.media_id}`,
                                              value: row.item.media_id,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.perPage
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-content-center" },
                    [
                      _c("b-pagination", {
                        attrs: {
                          "total-rows": _vm.contentItems.length,
                          "per-page": _vm.perPage,
                        },
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("ContentDetailsModal", { ref: "contentDetailsModal" }),
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Partner Content" } },
            [
              _c("hr", { staticClass: "mt-3" }),
              _c(
                "b-form",
                { staticClass: "mt-4", attrs: { inline: "" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "mr-sm-2",
                      attrs: { for: "stats-select-partner-country" },
                    },
                    [_vm._v("Geo Location")]
                  ),
                  _c("b-form-select", {
                    staticClass: "mr-2",
                    attrs: {
                      id: "stats-select-partner-country",
                      value: _vm.partnerParams.selectedGeo,
                      options: _vm.geoOptions,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.updateProviderGeo($event)
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "mr-sm-2",
                      attrs: { for: "stats-select-partner-media" },
                    },
                    [_vm._v("Media Type")]
                  ),
                  _c("b-form-select", {
                    attrs: {
                      id: "stats-select-partner-media",
                      value: _vm.partnerParams.selectedMediaType,
                      options: _vm.mediaTypeOptions,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.updateProviderMediaType($event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c("b-table", {
                ref: "table",
                staticClass: "mt-3",
                attrs: {
                  items: _vm.partnerItems,
                  fields: _vm.partnerFields,
                  striped: "",
                  hover: "",
                  small: "",
                  "per-page": _vm.perPage,
                  "current-page": _vm.partnerCurrentPage,
                },
              }),
              _vm.perPage
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-content-center" },
                    [
                      _c("b-pagination", {
                        attrs: {
                          "total-rows": _vm.partnerItems.length,
                          "per-page": _vm.perPage,
                        },
                        model: {
                          value: _vm.partnerCurrentPage,
                          callback: function ($$v) {
                            _vm.partnerCurrentPage = $$v
                          },
                          expression: "partnerCurrentPage",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Users" } },
            [
              _c("hr", { staticClass: "mt-3" }),
              _c(
                "b-form",
                { attrs: { inline: "" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "mr-sm-2 ml-2",
                      attrs: { for: "stats-select-user-subscription" },
                    },
                    [_vm._v("Subscription Type")]
                  ),
                  _c("b-form-select", {
                    staticClass: "mr-2",
                    attrs: {
                      id: "stats-select-user-subscription",
                      value: _vm.userSubscriptionParams.selectedSubscription,
                      options: _vm.userSubscriptionOptions,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.updateUserSubscription($event)
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "mr-sm-2 ml-2",
                      attrs: { for: "stats-select-user-type" },
                    },
                    [_vm._v("User Type")]
                  ),
                  _c("b-form-select", {
                    staticClass: "mr-2",
                    attrs: {
                      id: "stats-select-user-type",
                      value: _vm.userSubscriptionParams.selectedType,
                      options: _vm.userTypeOptions,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.updateSubscriptionType($event)
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "mr-sm-2 ml-2",
                      attrs: { for: "stats-select-user-country" },
                    },
                    [_vm._v("Geo Location")]
                  ),
                  _c("b-form-select", {
                    staticClass: "mr-2",
                    attrs: {
                      id: "stats-select-user-country",
                      value: _vm.userSubscriptionParams.selectedGeo,
                      options: _vm.geoOptions,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.updateUserGeo($event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c("canvas", {
                staticClass: "mt-3",
                attrs: { id: "user-chart" },
              }),
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Current Users" } },
            [
              _c("hr", { staticClass: "mt-3" }),
              _c(
                "b-form",
                { staticClass: "mb-4", attrs: { inline: "" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "mr-sm-2 ml-2",
                      attrs: { for: "stats-select-current-user-type" },
                    },
                    [_vm._v("User Type")]
                  ),
                  _c("b-form-select", {
                    staticClass: "mr-2",
                    attrs: {
                      id: "stats-select-current-user-type",
                      value: _vm.userSubscriptionParams.selectedType,
                      options: _vm.userTypeOptions,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.updateSubscriptionType($event)
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "mr-sm-2 ml-2",
                      attrs: { for: "stats-select-current-user-country" },
                    },
                    [_vm._v("Geo Location")]
                  ),
                  _c("b-form-select", {
                    staticClass: "mr-2",
                    attrs: {
                      id: "stats-select-current-user-country",
                      value: _vm.userSubscriptionParams.selectedGeo,
                      options: _vm.geoOptions,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.updateUserGeo($event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "m-1" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.statsHrefData,
                        download: "current_customers_stats.xlsx",
                        target: "_blank",
                      },
                    },
                    [_vm._v("Download")]
                  ),
                  _vm.$config.hasPayingCustomers
                    ? [
                        _c("b-table", {
                          attrs: {
                            hover: "",
                            striped: "",
                            fixed: "",
                            items: _vm.currentPremiumCustomers,
                            fields: _vm.currentCustomersFields,
                            caption: "Paying customers",
                            "caption-top": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "cell(subscription_name)",
                                fn: function (row) {
                                  return [
                                    row.item.subscription_name == "Subtotal"
                                      ? _c("strong", [
                                          _vm._v(
                                            _vm._s(row.item.subscription_name)
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$helpers.prettify(
                                                row.item.subscription_name
                                              )
                                            )
                                          ),
                                        ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3712155929
                          ),
                        }),
                      ]
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("b-table", {
                        attrs: {
                          hover: "",
                          striped: "",
                          fixed: "",
                          items: _vm.currentFreeCustomers,
                          fields: _vm.currentCustomersFields,
                          "thead-class": _vm.$config.hasPayingCustomers
                            ? "no-head"
                            : "",
                          caption: "Non paying customers",
                          "caption-top": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(subscription_name)",
                            fn: function (row) {
                              return [
                                row.item.subscription_name == "Subtotal"
                                  ? _c("strong", [
                                      _vm._v(
                                        _vm._s(row.item.subscription_name)
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$helpers.prettify(
                                            row.item.subscription_name
                                          )
                                        )
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm.$config.hasPayingCustomers
                    ? _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c("b-table", {
                            attrs: {
                              hover: "",
                              striped: "",
                              fixed: "",
                              items: _vm.currentTotalCustomers,
                              fields: _vm.currentCustomersFields,
                              "thead-class": "no-head",
                              caption: "All customers",
                              "caption-top": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(subscription_name)",
                                  fn: function (row) {
                                    return [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$helpers.prettify(
                                              row.item.subscription_name
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3662327525
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("EditLabels", {
        ref: "mediaArtistsFilter",
        attrs: {
          labels: _vm.params.selectedArtists,
          "allow-new": false,
          "allow-manage": false,
          "label-type": "artist",
        },
        on: { update: _vm.updateArtists },
      }),
      _c("EditLabels", {
        ref: "mediaGenresFilter",
        attrs: {
          labels: _vm.params.selectedGenres,
          "allow-new": false,
          "allow-manage": false,
          "label-type": "genre",
        },
        on: { update: _vm.updateGenres },
      }),
      _c("EditLabels", {
        ref: "mediaLabelFilter",
        attrs: {
          labels: _vm.params.selectedLabels,
          "allow-new": false,
          "allow-manage": false,
          "label-type": "label",
        },
        on: { update: _vm.updateLabels },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }