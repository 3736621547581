import { render, staticRenderFns } from "./AddAdRevenue.vue?vue&type=template&id=ba42b1f0&scoped=true&"
import script from "./AddAdRevenue.vue?vue&type=script&lang=js&"
export * from "./AddAdRevenue.vue?vue&type=script&lang=js&"
import style0 from "./AddAdRevenue.vue?vue&type=style&index=0&id=ba42b1f0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba42b1f0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/zambal/dev/ott_cms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ba42b1f0')) {
      api.createRecord('ba42b1f0', component.options)
    } else {
      api.reload('ba42b1f0', component.options)
    }
    module.hot.accept("./AddAdRevenue.vue?vue&type=template&id=ba42b1f0&scoped=true&", function () {
      api.rerender('ba42b1f0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/AddAdRevenue.vue"
export default component.exports