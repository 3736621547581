import helpers from '../Helpers'

export default {
  namespaced: true,

  state: () => ({
    initReady: false,
    appHost: null,
    languages: {},
    countries: {},
    overlay: false,
    contentType: null
 }),

  getters: {
    languageCodes: state => {
      return Object.keys(state.languages)
    },

    languageNames: state => {
      return Object.values(state.languages)
    },

    countryFormatter: state => (code) => {
      return state.countries[code]
    }
  },

  mutations: {
    initReady(state) {
      state.initReady = true
    },

    putAppHost(state, appHost) {
      state.appHost = appHost
    },

    putCountries(state, countries) {
      state.countries = countries
    },

    putLanguages(state, languages) {
      state.languages = languages
    },
    putContentType(state, ct) {
      state.contentType = ct
    }
  },

  actions: {
    initReady({commit}) {
      return new Promise(resolve => {
        commit('initReady')
        resolve()
      })
    },

    fetchAppHost({commit}) {
      return fetch("/admin/api/v2/media/app-host").then(helpers.JSONHandler).then(resp => {
        commit('putAppHost', resp.data)
      })
    },

    fetchCountries({commit}) {
      return fetch("/admin/api/v2/media/countries").then(helpers.JSONHandler).then(resp => {
        commit('putCountries', resp.data)
      })
    },

    fetchLanguages({commit}) {
      return fetch("/admin/api/v2/media/languages").then(helpers.JSONHandler).then(resp => {
        commit('putLanguages', resp.data)
      })
    }
  }
}